/**
 * 存储授权信息
 */
import $ from "jquery";

let login_time;


export function getToken(){
    return localStorage.getItem('token');
}
export function getUsername(){
    return localStorage.getItem("username");
}

export function setToken(token){
    localStorage.setItem('token',token);
}
export function setUsername(username){
    localStorage.setItem('username',username);
}

export function removeToken(){
    localStorage.clear();
}

export function removeLogin_time(){
    login_time = null;
}



//验证token
export function verifyToken(){
    let flag = false;
    $.ajax({
        url: "/api/v1/auth/verifyToken",//请求路径
        beforeSend: function (XMLHttpRequest) {
            XMLHttpRequest.setRequestHeader("token", getToken());
        },
        data: JSON.stringify({"token":getToken()}),
        type: "POST",//GET
        async: false,
        dataType: "json",//需要返回JSON对象(如果Ajax返回的是手动拼接的JSON字符串,需要Key,Value都有引号)
        contentType:"application/json",
        success: function(result) {
            //处理 resp.responseText;
            let json_result = JSON.parse(JSON.stringify(result))
            if (json_result.msg === "ok") {
                console.log("token验证成功")
                flag = true;
            }else{
                console.log("验证未成功");
                flag = false;
            }
        },
        error: function(a, b, c) {
            //a,b,c三个参数,具体请参考JQuery API
        }
    })

    return flag;
}


export function ifLogined(){//是否已经登录
    //先验证一遍token
    if(!verifyToken()){
        return false;
    }

    //初次登陆会设置登录时间，因为每次路由都会调用该函数，当已登录30分钟以内时，不会再路由跳转时判断token
    //当超过30分钟时，会判断一次token
    if (login_time == null){
        if (verifyToken()){
            let temp = new Date();
            login_time = Date.UTC(temp.getFullYear(),temp.getMonth(),temp.getDay(),
                temp.getHours(),temp.getMinutes(),temp.getSeconds());
            return true;
        }else {
            return false;
        }
    }else {
        let temp = new Date();
        let now_time =  Date.UTC(temp.getFullYear(),temp.getMonth(),temp.getDay(),
            temp.getHours(),temp.getMinutes(),temp.getSeconds());
        if (now_time - login_time>1800000){
            login_time = null
            return verifyToken();
        }else{
            return true;
        }
    }
}