import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {Button, Card, Form, message, Modal, Popconfirm, Select, Space, Table, Typography} from "antd";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {convertListToString, deepCopyObj} from "../../../utlis/StringUtils";

function EditUser(props) {
    const {Text} = Typography;
    const {Option} = Select;
    const [dataSourceOfTable,setDataSourceOfTable] = useState([]);
    const handleTextIngetColumnSearchProps = (text)=>{
        if (text==="在线") {
            return <Text type={"danger"}>在线</Text>
        }else if (text==="离线"){
            return <Text type={"success"}>离线</Text>
        }else{
            return text;
        }
    }
    const [loadingOfCard,setLoadingOfCard] = useState(false);
    const [roleExistedForOption,setRoleExistedForOption] = useState([]);
    const [roleExistedArray,setRoleExistedArray] = useState([]);
    const [rolesHad,setRolesHad] = useState(null);
    const getAllRole = ()=>{
        post(APIOBJECT["getAllRole"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let roleArray = resultAfterHandle["data"];
                setRoleExistedArray(roleArray);
                let tempArray = [];
                for (let index in roleArray){
                    tempArray.push(<Option key={Number(roleArray[index]["idOfRole"])}>{roleArray[index]["nameOfRole"]}</Option>)
                }
                setRoleExistedForOption(tempArray);
            }
        }).catch(error=>console.log("出错啦！"+error))
    }
    const [formOfModifyUser] = Form.useForm();
    const [formOfModifyUserPassword] = Form.useForm();
    const [formOfModifyManager] = Form.useForm();
    const [confirmLoading1, setConfirmLoading1] = useState(false);//用于modal的ok的按钮的loading状态
    const [visibleOfFormModifyUserPassword,setVisibleOfFormModifyUserPassword] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);//用于modal的ok的按钮的loading状态
    const [allProjectArray,setAllProjectArray] = useState([]);
    const [dataSourceOfManagerList,setDataSourceOfManagerList] = useState([]);
    const [rawManagerList,setRawManagerList] = useState([]);
    const [visibleOfModifyManager,setVisibleOfModifyManager] = useState(false);
    /**
     * 用于构建可搜索table的固定部分,做了一些修改
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ?
            (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}} searchWords={[searchText]}
                          autoEscape textToHighlight={text ? text.toString() : ''}/>) : (handleTextIngetColumnSearchProps(text)),});
    const [visibleOfModifyModal,setVisibleOfModifyModal] = useState(false);
    /**
     * 用于构建可搜索table的固定部分
     */
    const getUserList = ()=>{
        post(APIOBJECT["getAllUserWithRoleAndSituationOfOnline"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let result = resultAfterHandle["data"];
                let tempArray = [];
                for (let index in result){
                    let roleArray = result[index]["roleList"];
                    let tempRoleString = convertListToString(roleArray);
                    tempArray.push({username:result[index]["username"],
                        roles:tempRoleString,isOnline:result[index]["isOnline"]?"在线":"离线"})
                }
                // console.log(tempArray);
                setDataSourceOfTable(tempArray);
            }
        }).catch(error=>message.error("出错啦！"+error))
    }

    const getAllManagerAndTheirProject = ()=>{
        post(APIOBJECT["getAllManagerAndTheirProject"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setRawManagerList(resultAfterHandle["data"]);
                let dataSourceForManagerList = deepCopyObj(resultAfterHandle["data"]);
                for (let item of dataSourceForManagerList){
                    item["projectResponsibleName"] = convertProjectListToString(item["responsibleProject"]);
                }
                setDataSourceOfManagerList(dataSourceForManagerList);
            }
        }).catch(error=>message.error("出错啦！"+error))
    }

    const convertProjectListToString = (projectList) =>{
        let result = "";
        for (let project of projectList){
            if(result === ""){
                result += "【"+project["projectId"]+"】"+project["projectName"];
            }else{
                result += "，【"+project["projectId"]+"】"+project["projectName"]+"，";
            }
        }
        return result;
    }

    const getAllProject = ()=>{
        post(APIOBJECT["getAllProject"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                console.log(resultAfterHandle);
                setAllProjectArray(resultAfterHandle["data"]);
            }
        }).catch(error=>message.error("getAllProject出错了！"+error))
    }

    const kickUser = (username)=>{
        setLoadingOfCard(true);
        post(APIOBJECT["kickUser"],JSON.stringify({username:username})).then(resultBeforeHandle=>{
            let resultAfterhandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterhandle!==false){
                message.success("踢下线成功！");
                getUserList()
            }
        }).catch(error=>message.error("出错啦！"+error))
        setLoadingOfCard(false);
    }
    const deleteUser = (username)=>{
        setLoadingOfCard(true);
        console.log("删除用户"+username);
        post(APIOBJECT["deleteUser"],JSON.stringify({username:username})).then(resultBeforeHandle=>{
            let resultAfterhandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterhandle!==false){
                message.success("删除成功！");
                getUserList()
            }
        }).catch(error=>message.error("出错啦！"+error))
        setLoadingOfCard(false);
    }
    const modifyUser = (username)=>{
        let userObjectArray = dataSourceOfTable.filter(item=>item["username"]===username);
        if (userObjectArray.length===0){
            message.error("出现了bug，请刷新重新尝试或联系管理员！");
            return;
        }
        let userObject = userObjectArray[0];
        setRolesHad(userObject["roles"]);
        let roleHasList = userObject["roles"].split(",");
        let roleList = [];

        for (let roleObj of roleExistedArray){
            console.log(roleObj["nameOfRole"])
            if (roleHasList.includes(roleObj["nameOfRole"])){
                roleList.push(String(roleObj["idOfRole"]));
            }
        }
        formOfModifyUser.setFieldsValue({username:userObject["username"],roleList:roleList});
        setVisibleOfModifyModal(true);
    }
    const modifyUserPermissionSubmit = (value)=>{
        setConfirmLoading1(true);
        post(APIOBJECT["modifyUserPermission"],JSON.stringify(value)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("权限修改成功！");
                setTimeout(()=>{
                    window.location.reload();
                },500)
            }
            setConfirmLoading1(false)
        }).catch(error=>{
                message.error("出现错误！"+error)
                setConfirmLoading1(false)
        })
    }
    const modifyUserPassword = (username)=>{
        formOfModifyUserPassword.setFieldsValue({username:username});
        setVisibleOfFormModifyUserPassword(true);
    }
    const modifyUserPasswordSubmit = (value)=>{
        setConfirmLoading2(true);
        post(APIOBJECT["modifyUserPassword"],JSON.stringify({username:value["username"],password:value["password"]})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("密码修改成功！");
                setTimeout(()=>{
                    window.location.reload();
                },500)
            }
            setConfirmLoading2(false);
        }).catch(error=>{
            message.error("出现了错误！"+error);
            setConfirmLoading2(false);
        })
    }

    const columnsForUserList = [
        {
            title:"用户名",
            key:"username",
            dataIndex:"username",
            ...getColumnSearchProps("username")
        },
        {
            title: "拥有的角色",
            key:"roles",
            dataIndex: "roles",
            ...getColumnSearchProps("roles")
        },
        {
            title: "是否在线",
            key:"isOnline",
            dataIndex: "isOnline",
            filter:[{text:"在线",value:"在线"},{text: "离线",value: "离线"}],
            ...getColumnSearchProps("isOnline")
        },
        {
            title: "操作",
            width:50,
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Popconfirm title={"确定将该用户踢下线吗？"} onConfirm={()=>{
                            if (record["isOnline"]==="离线"){
                                message.error("当前系统检测该用户为离线，若您确定该用户为在线，请刷新，刷新后系统将重新检测。")
                            }else{
                                kickUser(record["username"]);
                            }
                        }
                        }>
                            <Button type={"dashed"} danger size={"small"}>踢下线</Button>
                        </Popconfirm>

                        <Popconfirm title={"确定删除该用户吗？"} onConfirm={()=>{deleteUser(record["username"]);}}>
                            <Button type={"primary"} danger size={"small"}>删除</Button>
                        </Popconfirm>

                        <Popconfirm title={"确定修改此用户权限吗？"} onConfirm={()=>{modifyUser(record["username"]);}}>
                            <Button type={"primary"} size={"small"}>修改权限</Button>
                        </Popconfirm>

                        <Popconfirm title={"确定修改此用户密码吗？"} onConfirm={()=>{modifyUserPassword(record["username"]);}}>
                            <Button size={"small"}>修改密码</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    const columnsForManagerList = [
        {
            title:"用户名",
            key:"username",
            width:200,
            dataIndex:"username",
            ...getColumnSearchProps("username")
        },
        {
            title:"负责的项目",
            key:"projectResponsibleName",
            dataIndex:"projectResponsibleName",
            ...getColumnSearchProps("projectResponsibleName")
        },
        {
            title: "操作",
            width:200,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Button type={"primary"} onClick={()=>{
                        let managerObj = rawManagerList.filter(item=>item["username"] === record["username"])[0];
                        let arrayForProjectSelect = [];
                        for (let project of managerObj["responsibleProject"]){
                            arrayForProjectSelect.push(String(project["projectId"]))
                        }
                        formOfModifyManager.setFieldsValue({username:record["username"],projectIds:arrayForProjectSelect});
                        setVisibleOfModifyManager(true);
                    }}>修改其负责的项目</Button>
                )
            }
        }
    ]

    const navigate = useNavigate();

    const handleSubmitChangeManagerAndHisProjects = (value)=>{
        post(APIOBJECT["modifyManagerAndHisProject"],JSON.stringify(value)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getAllManagerAndTheirProject();
                message.success("修改成功！");
                setVisibleOfModifyManager(false);
            }
        }).catch(error=>message.error("handleSubmitChangeManagerAndHisProjects出错了！"+error))
    }

    useEffect(() => {
        setLoadingOfCard(true);
        getUserList();
        getAllRole();
        getAllManagerAndTheirProject();
        getAllProject();
        setLoadingOfCard(false);
        return () => {};
    }, []);


    return (
        <>
            <Card title={"查看、修改用户"} loading={loadingOfCard}>
                <Table columns={columnsForUserList} rowKey={record => record["username"]} dataSource={dataSourceOfTable}/>

                <Modal title={"修改用户权限"} okText={"提交申请"} cancelText={"取消"} onCancel={()=>setVisibleOfModifyModal(false)}
                       onOk={()=>formOfModifyUser.submit()} visible={visibleOfModifyModal} confirmLoading={confirmLoading1}>

                    <Form form={formOfModifyUser} onFinish={value=>modifyUserPermissionSubmit(value)}>
                        <Space direction={"vertical"} size={"middle"} style={{"width":"100%"}}>
                        <Form.Item name={"username"} label={"用户名"}>
                            <Input disabled type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"roleList"} label={"拥有的角色："}>
                            <Select mode="multiple" style={{width: '100%'}} allowClear placeholder={"请选择角色"} onChange={value=>console.log("选择了"+value)}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {roleExistedForOption}
                            </Select>
                        </Form.Item>
                            <Text>该用户已拥有的角色：</Text><Text type={"success"}>{rolesHad}</Text>
                        </Space>
                    </Form>

                </Modal>


                <Modal title={"修改用户密码"} okText={"提交申请"} cancelText={"取消"} onCancel={()=>setVisibleOfFormModifyUserPassword(false)}
                        visible={visibleOfFormModifyUserPassword} onOk={()=>formOfModifyUserPassword.submit()} confirmLoading={confirmLoading2}>
                    <Form onFinish={value=>modifyUserPasswordSubmit(value)} form={formOfModifyUserPassword}>
                        <Form.Item name={"username"} label={"用户名"}>
                            <Input placeholder={"用户名"} disabled/>
                        </Form.Item>
                        <Form.Item name={"password"} rules={[{required:true, message:"请输入密码"}]} label={"请输入密码："}>
                            <Input type={"password"} placeholder={"用户密码"}/>
                        </Form.Item>
                        <Form.Item name={"passwordConfirm"} rules={[{required:true, message:"请再次输入密码"},
                            ({getFieldValue})=>({validator(_,value){if (value!==getFieldValue("password"))
                            {return Promise.reject(new Error("两次密码输入不一致！"));}return Promise.resolve();}})]} label={"请再次输入密码："}>
                            <Input type={"password"} placeholder={"请再次用户密码"}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>
            <Card title={"修改项目经理及其负责的对象"}>
                <Table columns={columnsForManagerList} scroll={{x: 1300}} dataSource={dataSourceOfManagerList} rowKey={record => record["username"]}/>
                <Modal width={1000} title={"修改项目经理负责的项目"} open={visibleOfModifyManager} onCancel={()=>setVisibleOfModifyManager(false)} okText={"提交"} cancelText={"取消"} onOk={()=>formOfModifyManager.submit()}>
                    <Form form={formOfModifyManager} onFinish={handleSubmitChangeManagerAndHisProjects}>
                        <Form.Item name={"username"} label={"用户名"}>
                            <Input placeholder={"用户名"} disabled/>
                        </Form.Item>
                        <Form.Item name={"projectIds"} label={"负责的项目"} required={true} rules={[{required:true,message:"请选择负责的项目！"}]}>
                            <Select mode="multiple" style={{width: '100%'}} allowClear placeholder={"请选择关联的项目"} onChange={value=>console.log("选择了"+value)}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                {allProjectArray.map(item=><Option key={item["projectId"]}>{"【"+item["projectId"]+"】"+item["projectName"]}</Option>)}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>
        </>
    );
}

export default EditUser;