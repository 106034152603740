import React, {useEffect, useRef, useState} from 'react';
import {Button, Collapse, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Typography} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";

function EditSupplier(props) {
    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) :
            (text==="已禁用"?<Text type={"danger"}>已禁用</Text>:text==="进行中"||text==="未禁用"?<Text type={"success"}>{text}</Text>:text),});
    /**
     * 用于构建可搜索table的固定部分
     */


    const navigate = useNavigate();
    const [formOfAddSupplier] = Form.useForm();
    const [formOfChangeSupplier] = Form.useForm();
    const {Panel} = Collapse;
    const {Text} = Typography;
    const {Option} = Select;
    const [dataSourceOfSupplierTable,setDataSourceOfSupplierTable] = useState([]);
    const [loadingOfSupplierTable,setLoadingOfSupplierTable] = useState(true);
    const [loadingOfAddSupplierButton,setLoadingOfAddSupplierButton] = useState(false);
    const [visibleOfChangeSupplierModal,setVisibleOfChangeSupplierModal] = useState(false);
    const [loadingOfChangeSupplierButton,setLoadingOfChangeSupplierButton] = useState(false);



    const getAllNotLogicallyDeletedSupplier = ()=>{
        setLoadingOfSupplierTable(true);
        post(APIOBJECT["getAllNotLogicallyDeletedSupplier"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfSupplierTable(resultAfterHandle["data"]);
            }
            setLoadingOfSupplierTable(false);
        }).catch(error=>{
            setLoadingOfSupplierTable(false);
            message.error("getAllNotLogicallyDeletedSupplier出错了！"+error)
        })
    }
    const columnsOfSupplierTable = [
        {
            title: "供应商名称",
            key:"name",
            dataIndex: "name",
            width: 200,
            fixed:'left',
            ...getColumnSearchProps("name")

        },
        {
            title: "负责人",
            key:"personInCharge",
            dataIndex: "personInCharge",
            width: 100,
            ...getColumnSearchProps("personInCharge")
        },
        {
            title: "联系电话",
            key:"phoneNumber",
            dataIndex: "phoneNumber",
            width: 130,
            ...getColumnSearchProps("phoneNumber")
        },
        {
            title: "地址",
            key:"address",
            dataIndex: "address",
            ...getColumnSearchProps("address")
        },
        {
            title: "状态",
            key:"disabledName",
            dataIndex: "disabledName",
            width: 80,
            ...getColumnSearchProps("disabledName")
        },
        {
            title: "操作",
            width:190,
            fixed:'right',
            render:(text,record,index)=>{
                return (<Space>
                    <Popconfirm title={"确认删除这个供应商吗？删除之后入库无法选择这个项目了，此操作不可撤销！"} cancelText={"取消"} okText={"删除"}
                                onConfirm={()=>handleSubmitDeleteSupplier(record["supplierId"])}>
                        <Button type={"dashed"} danger>删除</Button>
                    </Popconfirm>
                    <Button type={"primary"} onClick={()=>handleClickChangeSupplierButton(record["supplierId"])}>修改</Button>
                </Space>)
            }
        }
    ]
    const handleSubmitDeleteSupplier = (supplierId)=>
        post(APIOBJECT["handleDeleteSupplier"],JSON.stringify({supplierId:supplierId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getAllNotLogicallyDeletedSupplier();
            }
        }).catch(error=>message.error("handleSubmitDeleteSupplier出错了！"+error))

    const handleSubmitChangeSupplier = (fieldsValue)=>{
        setLoadingOfChangeSupplierButton(true);
        post(APIOBJECT["handleChangeSupplier"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getAllNotLogicallyDeletedSupplier();
                message.success(resultAfterHandle["msg"]);
            }
            setLoadingOfChangeSupplierButton(false);
            setVisibleOfChangeSupplierModal(false);
        }).catch(error=>{
            setLoadingOfChangeSupplierButton(false);
            message.error("handleSubmitChangeSupplier出错了！"+error)
        })
        console.log(fieldsValue);
    }
    const handleAddSupplier = (fieldsValue)=>{
        setLoadingOfAddSupplierButton(true);
        post(APIOBJECT["handleAddSupplier"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getAllNotLogicallyDeletedSupplier();
                setLoadingOfAddSupplierButton(false);
                formOfAddSupplier.resetFields();
            }
        }).catch(error=>{
            setLoadingOfAddSupplierButton(false);
            message.error("handleAddSupplier出错了！"+error);
        })
    }
    const handleClickChangeSupplierButton = (supplierId)=>{
        let supplierObject = dataSourceOfSupplierTable.filter(item=>item["supplierId"] === supplierId)[0];
        formOfChangeSupplier.setFieldsValue(supplierObject);
        setVisibleOfChangeSupplierModal(true);
    }


    useEffect(() => {
        getAllNotLogicallyDeletedSupplier();
        return () => {};
    }, []);


    return (
        <>
            <Modal title={"修改供应商信息"} visible={visibleOfChangeSupplierModal} onCancel={()=>setVisibleOfChangeSupplierModal(false)}
                   footer={[<Button key={"cancelButton"} danger onClick={()=>setVisibleOfChangeSupplierModal(false)} loading={loadingOfChangeSupplierButton}>取消</Button>,
                       <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeSupplier.submit()} loading={loadingOfChangeSupplierButton}>提交申请</Button>]}>
                <Form form={formOfChangeSupplier} onFinish={handleSubmitChangeSupplier}>
                    <Form.Item name={"supplierId"} label={"供应商编号"} rules={[{required:true,message:"请输入供应商编号！"}]}>
                        <Input disabled type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"name"} label={"供应商名称"} rules={[{required:true,message:"请输入供应商名称！"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"personInCharge"} label={"负责人"} rules={[{required:true,message:"请输入负责人信息！"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"phoneNumber"} label={"联系电话"} rules={[{required:true,message:"请输入联系电话"}]}>
                        <Input type={"number"}/>
                    </Form.Item>
                    <Form.Item name={"address"} label={"地址"} rules={[{required:true,message:"请输入地址"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"disabled"} label={"是否被禁用"} rules={[{required:true,message:"请选择是否禁用！"}]}>
                        <Select style={{width:"100%"}}>
                            <Option key={"0"} value={"0"}>未禁用</Option>
                            <Option key={"1"} value={"1"}>禁用</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Collapse defaultActiveKey={["1"]}>
                <Panel key={1} header={"新增供应商"}>
                    <Form form={formOfAddSupplier} onFinish={handleAddSupplier}>
                        {/*<Form.Item name={"supplierId"} label={"供应商编号"} rules={[{required:true,message:"请输入供应商编号！"}]}>*/}
                        {/*    <Input type={"text"}/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item name={"supplierName"} label={"供应商名称"} rules={[{required:true,message:"请输入供应商名称！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"personInCharge"} label={"负责人"} rules={[{required:true,message:"请输入负责人信息！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"phoneNumber"} label={"联系电话"} rules={[{required:true,message:"请输入联系电话"}]}>
                            <Input type={"number"}/>
                        </Form.Item>
                        <Form.Item name={"address"} label={"地址"} rules={[{required:true,message:"请输入地址"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"} loading={loadingOfAddSupplierButton}>提交</Button>
                        </Form.Item>
                    </Form>
                </Panel>
                <Panel key={2} header={"查看、修改、删除供应商"}>
                    <Table loading={loadingOfSupplierTable} dataSource={dataSourceOfSupplierTable}  scroll={{x: 1300}}
                           columns={columnsOfSupplierTable} bordered rowKey={record => record["supplierId"]}
                    />
                </Panel>
            </Collapse>
        </>
    );
}

export default EditSupplier;