import React from 'react';
import {Result} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

function DefaultPage(props) {

    const navigate = useNavigate()

    return (
        <div>
            <Result
                icon={<LoadingOutlined />}
                title="正在加载中"
                subTitle="请稍候片刻"
            />
            <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021014961号-1</a>
        </div>
    );
}

export default DefaultPage;