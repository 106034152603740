import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {Button, Card, Checkbox, Form, Input, message, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import "antd/dist/antd.min.css"
import "./Login.css"
import {setToken, setUsername} from "../utlis/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {loginApi, verifyToken} from "../service/auth";
import axios from "axios";
import {setCookie} from "../utlis/CookieUtils";
import PubSub from 'pubsub-js'
import {handleResult} from "../utlis/response";
import {post} from "../utlis/request";
import {APIOBJECT} from "../static/CONSTANTS";

function Login(props) {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false);
    const {setLogined,setRouteTable}=props;

    const onFinish = (values) => {
        setLoading(true);
        loginApi(JSON.stringify({"username":values.username,"password":values.password})).then(
            result =>{
                let resultTruth = handleResult(result,message);
                if (resultTruth===false){//说明返回false，登录失败
                    if (setLogined!==null&&setRouteTable!==null){
                        setLogined(false);
                        setLoading(false);
                    }
                    message.error("用户名或密码错误！");
                }else{
                    let json_result = resultTruth["data"];
                    setToken(json_result["token"]);
                    setUsername(values.username);
                    if (setLogined!=null&&setRouteTable!=null){
                        console.log("登录成功啦！！！")
                        setRouteTable(json_result["routeTable"]);
                        setLogined(true);
                    }
                    navigate("/admin",{replace:true});
                }
            }
        ).catch(error=>{
            message.error("出现异常了！请联系管理员。");
            console.log(error);
            setLoading(false);
        })
    };


    useEffect(() => {
        verifyToken().then(resultBeforeHandle => {
            let resultAfterHandle = handleResult(resultBeforeHandle,message);//别传navigate，不然会一直鬼畜刷新
            if (resultAfterHandle!==false){
                // message.info("您已登录！请先退出登录再进行登录操作！");
                navigate("/admin",{setLogined:setLogined,setRouteTable:setRouteTable});
            }
        }).catch(error=>{message.error("出错啦！"+error);})
        return () => {};
    }, []);

    return (
        <Card title={"后台管理页面"} className="login-form">
        <Spin spinning={loading}>
            <Form name="normal_login"
                initialValues={{remember: true,}}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入你的用户名!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入你的密码！',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021014961号-1</a>
        </Card>
    );
}

export default Login;