export let APIOBJECT={
    loginUrl : "/api/v1/auth/manager_login",
    verifyTokenUrl:"/api/v1/auth/verifyToken",
    logoutUrl:"/api/v1/auth/logout",
    getEntityList:"/api/v1/entity/get_all_entities",
    getRuseProperties:"/api/v1/entity/get_reuse_properties",
    getMenuByToken:"/api/v1/menu/get",
    addEntity:"/api/v1/entity/add",
    getEntitiesIdAndName:"/api/v1/entity/get_all_entities_id_and_name",//得到所有实体的id和name
    addReuseProperty:"/api/v1/entity/add_reuse_property",//添加属性到属性库中
    removeReuseProperty:"/api/v1/entity/remove_reuse_property",//将属性从属性库中解绑
    deleteEntity:"/api/v1/entity/delete_entity_by_id",//删除实体，会一并删除实体所属的编号
    getEntityById:"/api/v1/entity/get_entity_by_id",//根据id获取实体以及实体中的绑定至属性库的复用属性
    frozeOrThawProperty:"/api/v1/entity/froze_or_thaw_options_of_properties",//冻结或者解冻用户
    addPropertiesToExistedEntity:"/api/v1/entity/add_properties",//为已存在的实体添加属性
    addOptionsToExistedProperty:"/api/v1/entity/add_options",//为已存在的属性添加可选项
    deleteReuseProperty:"/api/v1/entity/delete_reuse_property",//删除属性库中的属性，所有绑定都会解绑，但是所有实体的属性还是会在
    queryNumberByEntity:"/api/v1/query_number/query",//通过实体条件查询编号，若编号库中没有这种属性组合的实体编号，则会马上创建并添加至数据库中
    queryEntityByNumber:"/api/v1/query_entity/query",//通过编号查询实体
    getAllUsername:"/api/v1/user/getAllUsername",//获取所有的用户名，用于检测用户输入的是否重复
    getAllRole:"/api/v1/user/getAllRole",//得到所有角色信息，包括角色的名字和描述
    addUser:"/api/v1/user/add",//添加用户
    getAllUserWithRoleAndSituationOfOnline:"/api/v1/user/getAllUserWithRoleAndSituationOfOnline",//得到所有用户的信息与在线情况（除了admin）
    kickUser:"/api/v1/user/kickUser",//踢出用户，除了自己和admin
    deleteUser:"/api/v1/user/deleteUser",//删除用户，除了自己和admin
    modifyUserPermission:"/api/v1/user/modifyUserPermission",//调整用户的权限
    modifyUserPassword:"/api/v1/user/modifyUserPassword",//修改密码通过管理员
    changePassword:"/api/v1/auth/changePassword",//自己修改密码的url
    getAllCategory:"/api/v1/entity/getAllCategory",//获得当前所有的大类
    getAllEntitiesAndCategory:"/api/v1/entity/getAllEntitiesAndCategory",//得到所有的实体和种类
    addCategory:"/api/v1/entity/addCategory",//添加种类
    getEntitiesByCategoryId:"/api/v1/entity/getEntitiesByCategory",//通过种类来获得实体列表
    handleConstraint:"/api/v1/entity/handleConstraint",//处理有关约束的请求，包括增删改等等
    changeCategoryOfEntity:"/api/v1/entity/changeCategoryOfEntity",//改变一个实体的大类到另一个大类
    changeCategoryName:"/api/v1/entity/changeCategoryName",//修改大类的名称
    queryWarehouseAll:"/api/v1/warehouse/queryAll",//查询仓库中的所有实体，即已入库的
    queryWarehouseLocationByBianhao:"/api/v1/warehouse/queryWarehouseLocationByBianhao",//通过编号查询所在的具体库位以及各个库位的库存
    getAllWarehouseLocation:"/api/v1/warehouse/getAllWarehouseLocation",//得到当前所有的库位信息
    handleInSubmitToTemporaryTable:"/api/v1/warehouse/handleInSubmitToTemporaryTable",//提交入库记录到暂存表中
    getSubmittedInRecordInTemporaryTable:"/api/v1/warehouse/getSubmittedInRecordInTemporaryTable",//得到用户的入库暂存表信息，无需提交用户名，系统会根据token得到用户名
    getAllSupplier:"/api/v1/warehouse/getAllSupplier",//得到所有的供应商
    handleChangeSubmittedTemporaryRecord:"/api/v1/warehouse/handleChangeSubmittedTemporaryRecord",//修改暂存表中的某条入库记录，按照创建的时间来索引，前提是用户不会在1秒内上传两条记录
    deleteInRecordInTemporaryTable:"/api/v1/warehouse/deleteRecordInTemporaryTable",//删除已经提交到暂存表的入库暂存记录，用recordCreatedTime来区分
    combineInRecordTemporaryToMain:"/api/v1/warehouse/combineInRecordTemporaryToMain",//将入库暂存表合并到主库中
    getAllProject:"/api/v1/warehouse/getAllProject",//得到所有的项目信息
    handleSubmitExRecordToTemporaryTable:"/api/v1/warehouse/handleSubmitExRecordToTemporaryTable",//增加记录到出库暂存中，需要和已有的记录判断是否超出了能出库的数量
    getSubmittedOutRecordInTemporaryTable:"/api/v1/warehouse/getSubmittedOutRecordInTemporaryTable",//得到暂存的出库记录
    handleChangeOutRecordSubmittedTemporaryRecord:"/api/v1/warehouse/handleChangeOutRecordSubmittedTemporaryRecord",//修改已经提交到暂存表中的出库记录
    handleDeleteOutRecordSubmittedTemporaryRecord:"/api/v1/warehouse/handleDeleteOutRecordSubmittedTemporaryRecord",//删除已经提交到暂存表中的某条出库记录
    combineOutRecordTemporaryToMain:"/api/v1/warehouse/combineOutRecordTemporaryToMain",//将暂存表中的出库数据合并到主库中
    getAbleOutAndInWarehouseLocationAndLegalQuantity:"/api/v1/warehouse/getAbleOutAndInWarehouseLocationAndLegalQuantity",//移库时的第一步，查询这个编号的所有可用的出库位置、数量以及入库位置
    getSubmittedMoveRecordInTemporaryTable:"/api/v1/warehouse/getSubmittedMoveRecordInTemporaryTable",//得到已经提交到暂存库的移库记录
    handleSubmitAddMoveRecordToTemporaryTable:"/api/v1/warehouse/handleSubmitAddMoveRecordToTemporaryTable",//提交移库记录到暂存表
    handleChangeMoveRecordSubmittedTemporaryRecord:"/api/v1/warehouse/handleChangeMoveRecordSubmittedTemporaryRecord",//修改已经提交到暂存表里的移库记录
    deleteMoveRecordInTemporaryTable:"/api/v1/warehouse/deleteMoveRecordInTemporaryTable",//删除某条已经提交到暂存库的移库记录
    combineMoveRecordTemporaryToMain:"/api/v1/warehouse/combineMoveRecordTemporaryToMain",//合并移库记录到主库中
    handleLonelySubmitCheckRecord:"/api/v1/warehouse/handleLonelySubmitCheckRecord",//提交单独盘库到主库中
    handleSubmitWholeCheckToTemporaryTable:"/api/v1/warehouse/handleSubmitWholeCheckToTemporaryTable",//提交整体盘库的某条记录到暂存表中
    getSubmittedWholeCheckRecordInTemporaryTable:"/api/v1/warehouse/getSubmittedWholeCheckRecordInTemporaryTable",//得到所有已经提交暂存库的整体盘库记录
    handleChangeWholeCheckRecordInTemporaryTable:"/api/v1/warehouse/handleChangeWholeCheckRecordInTemporaryTable",//修改暂存库中的整体盘库记录，每人只能修改自己提交的申请
    handleDeleteWholeCheckRecordInTemporaryTable:"/api/v1/warehouse/handleDeleteWholeCheckRecordInTemporaryTable",//删除暂存表中的某条整体盘库记录
    handleSummaryWholeCheckRecordInTemporaryTable:"/api/v1/warehouse/handleSummaryWholeCheckRecordInTemporaryTable",//汇总所有的记录  需检查
    getSummaryWholeCheckRecord:"/api/v1/warehouse/getSummaryWholeCheckRecord",//得到汇总表中待定的汇总数据。未完成
    refuseSummaryWholeCheckRecord:"/api/v1/warehouse/refuseSummaryWholeCheckRecord",//拒绝待定的已汇总整体盘库记录
    acceptSummaryWholeCheckRecord:"/api/v1/warehouse/acceptSummaryWholeCheckRecord",//接受待定的已汇总的整体盘库记录
    getAllNotLogicallyDeletedProject:"/api/v1/warehouse/getAllNotLogicallyDeletedProject",//得到所有没被逻辑删除的项目信息
    handleAddProject:"/api/v1/warehouse/handleAddProject",//新增项目信息
    backSummaryWholeCheckRecordInTemporaryTable:"/api/v1/warehouse/backSummaryWholeCheckRecordInTemporaryTable",//回溯到汇总之前的步骤
    handleChangeProject:"/api/v1/warehouse/handleChangeProject",//改变项目信息
    handleDeleteProject:"/api/v1/warehouse/handleDeleteProject",//删除项目信息
    getAllNotLogicallyDeletedSupplier:"/api/v1/warehouse/getAllNotLogicallyDeletedSupplier",//得到所有没有被逻辑删除的供应商信息
    handleAddSupplier:"/api/v1/warehouse/handleAddSupplier",//新增供应商信息
    handleChangeSupplier:"/api/v1/warehouse/handleChangeSupplier",//修改供应商信息
    handleDeleteSupplier:"/api/v1/warehouse/handleDeleteSupplier",//删除供应商信息
    getAllNotLogicallyDeletedWarehouseLocation:"/api/v1/warehouse/getAllNotLogicallyDeletedWarehouseLocation",//获得所有没被逻辑删除的库位
    handleAddWarehouseLocation:"/api/v1/warehouse/handleAddWarehouseLocation",//新增库位
    handleChangeWarehouseLocation:"/api/v1/warehouse/handleChangeWarehouseLocation",//修改库位信息
    handleDeleteWarehouseLocation:"/api/v1/warehouse/handleDeleteWarehouseLocation",//逻辑删除库位
    getInWarehouseOperationList:"/api/v1/warehouse/getInWarehouseOperationList",//得到已经操作完成的入库记录
    getOutWarehouseOperationList:"/api/v1/warehouse/getOutWarehouseOperationList",//得到已经操作完成的出库记录
    getMoveWarehouseOperationList:"/api/v1/warehouse/getMoveWarehouseOperationList",//得到已经操作完成的移库记录
    getLonelyCheckWarehouseOperationList:"/api/v1/warehouse/getLonelyCheckWarehouseOperationList",//得到已经操作完成的单独盘库记录
    getWholeCheckWarehouseOperationList:"/api/v1/warehouse/getWholeCheckWarehouseOperationList",//得到在库的整体盘库记录
    deleteImageByUrl:"/api/entity/deleteImageByUrl",//根据imageurl删除图片
    uploadEntityImage:"https://api.wealthcd.com:5678/api/entity/uploadEntityImage",//上传实体关系的图片
    getImageByBianhaoOrEntityId:"/api/entity/getImageByBianhaoOrEntityId",//根据bianhao或者实体id获得图片以及实体关系名字
    changeImageByEntityId:"/api/entity/changeImageByEntityId",//改变实体图片
    queryFuzzily:"/api/v1/query_number/queryFuzzily",//模糊查询
    getPropertiesStringOfBianhao:"/api/v1/query_entity/getPropertiesStringOfBianhao",//根据bianhao得到string格式的属性和值情况
    getReusePropertiesAndImgOfEntity:"/api/v1/entity/getReusePropertiesAndImgOfEntity",//根据entityId获得它的所有复用属性id
    modifyEntity:"/api/v1/entity/modifyEntity",//修改实体关系
    getAllApplicant:"/api/v1/applicant/getAllApplicant",//获得所有申请人
    addApplicant:"/api/v1/applicant/addApplicant",//添加申请人
    getAllApplicantsName:"/api/v1/applicant/getApplicantName",//得到所有申请人的名字
    getProjectByManager:"/api/v1/getProjectByManager",//得到项目经理负责的未结束的项目
    addRequisitionDetail:"/api/v1/addRequisitionDetail",//添加要货单细节
    addRequisitionDetailToExistedRequisition:"/api/v1/addRequisitionDetailToExistedRequisition",//想已有的要货单增添细节
    getRequisitionByManager:"/api/v1/getRequisitionByManager",//获得当前项目经理提出的要货单
    getRequisitionDetail:"/api/v1/getRequisitionDetail",//根据要货单id得到该要货单的具体信息
    deleteRequisitionDetailByRequisitionDetailId:"/api/v1/deleteRequisitionDetailByRequisitionDetailId",//根据要货单细节id进行删除操作
    changeRequisitionDetailById:"/api/v1/changeRequisitionDetailById",//根据要货单细节id进行修改操作
    makeTemporaryStorageRequisitionStatusInReview:"/api/v1/makeTemporaryStorageRequisitionStatusInReview",//将暂存的要货单提交进行审核
    getRequisitionByWarehouseMan:"/api/v1/getRequisitionByWarehouseMan",//由库管人员得到所有的要货单信息
    getRequisitionPurchaseSituation:"/api/v1/getRequisitionPurchaseSituation",//获取要货单合并后分析的结果，但不做提交操作
    submitAnalysedSituation:"/api/v1/submitAnalysedSituation",//提交要货单合并后的分析结果，提交以后库存会锁住
    getPurchaseOrder:"/api/v1/getPurchaseOrder",//得到采购单
    changePurchaseOrderDetail:"/api/v1/changePurchaseOrderDetail",//修改采购单细节
    rejectRequisition:"/api/v1/rejectRequisition",//驳回要货单
    closeRequisitionDetail:"/api/v1/closeRequisitionDetail",//关闭要货单细节
    getAllManagerAndTheirProject:"/api/v1/auth/getAllManagerAndTheirProject",//得到所有项目经理及她们负责的项目
    modifyManagerAndHisProject:"/api/v1/auth/modifyManagerAndHisProject",//修改项目经理及其负责的项目

}
export let HTTPSTATUSCODE={
    UNAUTHORIZED:401,//认证失败
    FORBIDDEN:403,   //权限不足
    OK:200,
    customError:999
}
export let base64Object={
    errorPic:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsay" +
        "hILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7////39v///y4Dmn+LgeHANwDrkl1AuO+pmgAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAwqADAAQAAAABAAAAwwAAAAD9b/HnAAAHlklEQVR4Ae3dP3PTWBSGcbGzM6GCKqlIBRV0dHRJFarQ0eUT8LH4BnRU0NHR0UEFVdIlFRV7TzRksomPY8uykTk/zewQfKw/9znv4yvJynLv4uLiV2dBoDiBf4qP3/ARuCRABEFAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghg" +
        "gQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghggQAQZQKAnYEaQBAQaASKIAQJEkAEEegJmBElAoBEgghgg0Aj8i0JO4OzsrPv69Wv+hi2qPHr0qNvf39+iI97soRIh4f3z58/u7du3SXX7Xt7Z2enevHmzfQe+oSN2apSAP" +
        "j09TSrb+XKI/f379+08+A0cNRE2ANkupk+ACNPvkSPcAAEibACyXUyfABGm3yNHuAECRNgAZLuYPgEirKlHu7u7XdyytGwHAd8jjNyng4OD7vnz51dbPT8/7z58+NB9+/bt6jU/TI+AGWHEnrx48eJ/EsSmHzx40L18+fLyzxF3ZVMjEyDCiEDjMYZZS5wiPXnyZFbJaxMhQIQRGzHvWR7XCyOCXsOmiDAi1HmPMMQjDpbpEiDCiL358eNHurW/5SnWdIBbXiDCiA38/Pnzrce2YyZ4//59F3ePLNMl4PbpiL2J0L979+7yDtHDhw8vtzzvdGnEXdvUigSIsCLAWavHp/+qM0BcXMd/q25n1vF57TYBp0a3mUzilePj4+7k5KSLb6gt6ydAhPUzXnoPR0dHl79WGTNCfBnn1uvSCJdegQhLI1vvCk+fPu2ePXt2tZOYEV6" +
        "/fn31dz+shwAR1sP1cqvLntbEN9MxA9xcYjsxS1jWR4AIa2Ibzx0tc44fYX/16lV6NDFLXH+YL32jwiACRBiEbf5KcXoTIsQSpzXx4N28Ja4BQoK7rgXiydbHjx/P25TaQAJEGAguWy0+2Q8PD6/Ki4R8EVl+bzBOnZY95fq9rj9zAkTI2SxdidBHqG9+skdw43borCXO/ZcJdraPWdv22uIEiLA4q7nvvCug8WTqzQveOH26fodo7g6uFe/a17W3+nFBAkRYENRdb1vkkz1CH9cPsVy/jrhr27PqMYvENYNlHAIesRiBYwRy0V+8iXP8+/fvX11Mr7L7ECueb/r48eMqm7FuI2BGWDEG8cm+7G3NEOfmdcTQw4h9/55lhm7DekRYKQPZF2ArbXTAyu4kDYB2YxUzwg0gi/41ztHnfQG26HbGel/crVrm7tNY+/1btkOEAZ2M05r4FB7r9GbAIdxaZYrHdOsgJ" +
        "/wCEQY0J74TmOKnbxxT9n3FgGGWWsVdowHtjt9Nnvf7yQM2aZU/TIAIAxrw6dOnAWtZZcoEnBpNuTuObWMEiLAx1HY0ZQJEmHJ3HNvGCBBhY6jtaMoEiJB0Z29vL6ls58vxPcO8/zfrdo5qvKO+d3Fx8Wu8zf1dW4p/cPzLly/dtv9Ts/EbcvGAHhHyfBIhZ6NSiIBTo0LNNtScABFyNiqFCBChULMNNSdAhJyNSiECRCjUbEPNCRAhZ6NSiAARCjXbUHMCRMjZqBQiQIRCzTbUnAARcjYqhQgQoVCzDTUnQIScjUohAkQo1GxDzQkQIWejUogAEQo121BzAkTI2agUIkCEQs021JwAEXI2KoUIEKFQsw01J0CEnI1KIQJEKNRsQ80JECFno1KIABEKNdtQcwJEyNmoFCJAhELNNtScABFyNiqFCBChULMNNSdAhJyNSiECRCjUbEPNCRAhZ6NSiAARCjXb" +
        "UHMCRMjZqBQiQIRCzTbUnAARcjYqhQgQoVCzDTUnQIScjUohAkQo1GxDzQkQIWejUogAEQo121BzAkTI2agUIkCEQs021JwAEXI2KoUIEKFQsw01J0CEnI1KIQJEKNRsQ80JECFno1KIABEKNdtQcwJEyNmoFCJAhELNNtScABFyNiqFCBChULMNNSdAhJyNSiECRCjUbEPNCRAhZ6NSiAARCjXbUHMCRMjZqBQiQIRCzTbUnAARcjYqhQgQoVCzDTUnQIScjUohAkQo1GxDzQkQIWejUogAEQo121BzAkTI2agUIkCEQs021JwAEXI2KoUIEKFQsw01J0CEnI1KIQJEKNRsQ80JECFno1KIABEKNdtQcwJEyNmoFCJAhELNNtScABFyNiqFCBChULMNNSdAhJyNSiEC/wGgKKC4YMA4TAAAAABJRU5ErkJggg=="
}
export let constantBaseURL="https://api.wealthcd.com:5678"

export let taxRateArray = [
    {
        value:0,
        label:"0%"
    },
    {
        value:0.01,
        label:"1%"
    },
    {
        value:0.03,
        label:"3%"
    },
    {
        value:0.06,
        label:"6%"
    },
    {
        value: 0.09,
        label:"9%"
    },
    {
        value: 0.13,
        label:"13%"
    }
]

export let purchaseStatusForPurchaseOrderDetail = [
    {
        value:0,
        label:"未采购"
    },
    {
        value:1,
        label:"采购中"
    },
    {
        value:2,
        label:"采购完成"
    }
]

export let makeInvoiceStatusForPurchaseOrderDetail = [
    {
        label:"未开票",
        value:0
    },
    {
        label: "已开增值税专票",
        value: 1
    },
    {
        label: "已开普通发票",
        value: 2
    }
]