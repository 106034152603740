import React, {useEffect, useRef, useState} from 'react';
import "../../../static/css/datePicker.css"
import {
    Button, Card, Collapse, Empty, Form, Input, message, Modal,
    Popconfirm, Select, Space, Spin, Table, Tooltip, Typography, Cascader, Divider, DatePicker
} from "antd";
import {useNavigate, useOutlet} from "react-router-dom";
import {
    APIOBJECT,
    makeInvoiceStatusForPurchaseOrderDetail,
    purchaseStatusForPurchaseOrderDetail,
    taxRateArray
} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import {deepCopyObj} from "../../../utlis/StringUtils";
import {CalendarOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import {judgeIfNum} from "../../../utlis/UniversalFunctions";
import QueryPurchaseOrder from "../../../components/Query/QueryPurchaseOrder";
const {Option} = Select;

function EditRequisitionByWarehouseman(props) {


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const { RangePicker } = DatePicker;
    /**用于构建时间范围选择的固定部分
     */
    const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const getColumnSearchProps2 = (dataIndex) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{padding: 4}}>
                    <RangePicker style={{ marginBottom: 8 }}
                                 value={selectedKeys[0]}
                                 onChange={e => {
                                     setSelectedKeys(e ? [e] : [])
                                 }}
                                 onPressEnter={() => {
                                     confirm();
                                 }}
                                 locale={locale}
                    />
                    <Space>
                        <Button type="primary" onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                            搜索
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small" style={{width: 90,}}>
                            重置
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (<CalendarOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
            ),
            onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(value[0], value[1], 'day', '[]') : "" ,
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
            },
            render: text => moment(text).format("YYYY/MM/DD HH:mm:ss")}
    );

    /**用于构建时间范围选择的固定部分
     */

    const { Panel } = Collapse;
    const [dataSourceOfRequisition,setDataSourceOfRequisition] = useState([]);
    const [visibleOfAnalysedResultModal,setVisibleOfAnalysedResultModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);//用于在table中选择的项
    const navigate = useNavigate();
    const localesForRowSelection = {selectionAll:'全选所有',selectInvert:'反选当页',selectNone:'清空所有'}
    const [dataSourceOfAnalysedResult,setDataSourceOfAnalysedResult] = useState([]);
    const [loadingOfAnalyseButton,setLoadingOfAnalyseButton] = useState(false)
    const [loadingOfRejectButton,setLoadingOfRejectButton] = useState(false);
    const [loadingOfSubmitAnalyseResultButton,setLoadingOfSubmitAnalyseResultButton] = useState(false);
    const [freshOfQueryPurchaseOrder,setFreshOfQueryPurchaseOrder] = useState(true);


    useEffect(() => {
        getRequisition();
        return () => {
        };
    }, []);



    const columnsOfRequisition = [
        {
            title:"要货单id",
            key:"idForShow",
            dataIndex:"idForShow",
            width: 80,
            ...getColumnSearchProps("idForShow")
        },
        {
            title:"提交日期",
            key:"create_time",
            dataIndex:"create_time",
            width: 150,
            ...getColumnSearchProps2("create_time")
        },
        {
            title:"关联的项目",
            key:"projectName",
            dataIndex:"projectName",
            width: 300,
            ...getColumnSearchProps("projectName")
        },
        {
            title: "当前状态",
            key:"status",
            dataIndex:"status",
            width: 200,
            ...getColumnSearchProps("status")
        },
        {
            title: "提出者",
            key:"operator",
            dataIndex:"operator",
            width: 200,
            ...getColumnSearchProps("operator")
        },
        {
            title: "更新时间",
            key:"update_time",
            dataIndex:"update_time",
            width: 200,
            ...getColumnSearchProps2("update_time")
        }
    ]


    const getRequisition = () =>{
        post(APIOBJECT["getRequisitionByWarehouseMan"]).then(resultBeforeHandle =>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                let reversedArray = resultAfterHandle["data"].reverse()
                for(let item of reversedArray){
                    reversedArray["idForShow"] = "YHD-"+item["id"];
                }
                setDataSourceOfRequisition(reversedArray);
            }
        }).catch(error => message.error("getRequisition出错"+error))
    }


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: 'even',
                text: '选择所有审核中的要货单',
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = dataSourceOfRequisition.filter(item=>item["status"] === "审核中").map(item=>item["id"]);
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };

    const handleClickAnalyseButton = ()=>{
        if(selectedRowKeys.length === 0){
            message.error("请先选择要分析的要货单！");
            return;
        }
        //判断要分析的要货单是否都是审核中的状态
        if (dataSourceOfRequisition.filter(item => selectedRowKeys.includes(item["id"])).filter(item => item["status"] !== "审核中").length !== 0) {
            message.error("您选择了未处于审核中状态的要货单，请重新选择！");
            return;
        }
        setLoadingOfAnalyseButton(true);
        post(APIOBJECT["getRequisitionPurchaseSituation"],JSON.stringify({requisitionIds:selectedRowKeys})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                setDataSourceOfAnalysedResult(resultAfterHandle["data"]);
                setVisibleOfAnalysedResultModal(true);
            }
        }).catch(e=>message.error("handleClickAnalyseButton出错了！"+e)).finally(()=>setLoadingOfAnalyseButton(false))
    }

    const handleClickSubmitAnalyseResultButton = ()=>{
        if(selectedRowKeys.length === 0){
            message.error("请先选择要分析的要货单！");
            return;
        }
        //判断要分析的要货单是否都是审核中的状态
        if (dataSourceOfRequisition.filter(item => selectedRowKeys.includes(item["id"])).filter(item => item["status"] !== "审核中").length !== 0) {
            message.error("您选择了未处于审核中状态的要货单，请重新选择！");
            return;
        }
        setLoadingOfSubmitAnalyseResultButton(true);
        post(APIOBJECT["submitAnalysedSituation"],JSON.stringify({requisitionIds:selectedRowKeys})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                message.success(resultAfterHandle["msg"]);
                setVisibleOfAnalysedResultModal(false);
                setFreshOfQueryPurchaseOrder(!freshOfQueryPurchaseOrder);
                getRequisition();
            }
        }).catch(e=>message.error("handleClickAnalyseButton出错了！"+e)).finally(()=>setLoadingOfSubmitAnalyseResultButton(false))
    }

    const columnsOfAnalysedResult = [
        {
            title:"名称",
            key:"name",
            dataIndex:"name",
            width: 200,
            fixed:"left",
            ...getColumnSearchProps("name")
        },
        {
            title:"详情",
            key:"propertiesString",
            dataIndex:"propertiesString",
            width: 300,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title:"实际需要购买的数量",
            key:"quantity",
            dataIndex:"quantity",
            width: 200,
            ...getColumnSearchProps("quantity")
        },
        {
            title:"编号",
            key:"bianhao",
            dataIndex:"bianhao",
            ellipsis: true,
            width: 80,
            ...getColumnSearchProps("bianhao")
        },
    ]

    const getProjectNameFromId = () =>{
        if(selectedRowKeys.length === 0){
            return "";
        }
        let filteredArray = dataSourceOfRequisition.filter(item=>selectedRowKeys.includes(item["id"])).map(item=>item["projectName"]);
        return filteredArray.join();
    }

    const expandedRowRenderForRequisitionDetail = (row)=>{
        const columnsOfRequisitionDetail = [
            {
                title:"名称",
                key:"name",
                dataIndex:"name",
                width: 300,
                fixed:"left",
                ...getColumnSearchProps("name")
            },
            {
                title:"编号",
                key:"bianhao",
                dataIndex:"bianhao",
                ellipsis: true,
                width: 80,
                ...getColumnSearchProps("bianhao")
            },
            {
                title:"详情",
                key:"propertiesString",
                dataIndex:"propertiesString",
                width: 300,
                ...getColumnSearchProps("propertiesString")
            },
            {
                title:"需要数量",
                key:"need_quantity",
                dataIndex:"need_quantity",
                width: 150,
                ...getColumnSearchProps("need_quantity")
            },
            {
                title:"已得数量",
                key:"got_quantity",
                dataIndex:"got_quantity",
                width: 150,
                ...getColumnSearchProps("got_quantity")
            },
            {
                title:"单位",
                key:"unit",
                dataIndex:"unit",
                width: 100,
                ...getColumnSearchProps("unit")
            },
            {
                title:"期望到货周期（天）",
                key:"required_arrival_date",
                dataIndex:"required_arrival_date",
                width: 150,
                ...getColumnSearchProps("required_arrival_date")
            },
            {
                title:"是否关闭",
                key:"closed",
                dataIndex:"closed",
                width: 150,
                render:(text,record)=>{
                    return Number(text) === 0 ? "否" : "是"
                }
            },
            {
                title:"创建时间",
                key:"create_time",
                dataIndex:"create_time",
                width: 200,
                ...getColumnSearchProps2("create_time")
            },
            {
                title:"更新时间",
                key:"update_time",
                dataIndex:"update_time",
                width: 200,
                ...getColumnSearchProps2("update_time")
            }
        ]
        return (<Table scroll={{x: 1300}} columns={columnsOfRequisitionDetail} bordered
                       dataSource={dataSourceOfRequisition.length === 0 ? []:dataSourceOfRequisition.filter(item => item["id"] === row["id"])[0]["details"]}
                       rowKey={record => record["id"]}
        />)
    }

    const handleClickRejectButton = ()=>{
        if(selectedRowKeys.length === 0){
            message.error("请先选择要驳回的要货单！");
            return;
        }
        //判断要分析的要货单是否都是审核中的状态
        if (dataSourceOfRequisition.filter(item => selectedRowKeys.includes(item["id"])).filter(item => item["status"] !== "审核中").length !== 0) {
            message.error("您选择了未处于审核中状态的要货单，请重新选择！");
            return;
        }
        setLoadingOfRejectButton(true);
        post(APIOBJECT["rejectRequisition"],JSON.stringify({requisitionIds:selectedRowKeys})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                message.success(resultAfterHandle["msg"])
                getRequisition();
            }
        }).catch(e=>message.error("handleClickRejectButton出错了！"+e)).finally(()=>setLoadingOfRejectButton(false))
    }


    return (
        <Space direction="vertical" style={{"width":"100%"}}>
            <Collapse defaultActiveKey={['1','2']} >
                <Panel key={"1"} header={"查询编辑已有的要货单"}>
                    <Space>
                        <Button type={"primary"} loading={loadingOfAnalyseButton} onClick={handleClickAnalyseButton}>分析</Button>
                        <Popconfirm  placement="rightTop" title={"驳回后，可由项目经理修改后再次提交！确认是否驳回？"}
                                     onConfirm={handleClickRejectButton} okText={"确认驳回"} cancelText={"取消"} okButtonProps={{loading:loadingOfRejectButton}}>
                            <Button danger type={"default"}>驳回</Button>
                        </Popconfirm>
                    </Space>
                    <Table locale={localesForRowSelection} rowSelection={rowSelection} columns={columnsOfRequisition} expandedRowRender={expandedRowRenderForRequisitionDetail}
                           dataSource={dataSourceOfRequisition} scroll={{x: 1300}} bordered rowKey={record => record["id"]}/>
                    <Modal width={1400} title={"分析结果仅做参考，实际提交的结果可能会因为库存的实时变化而调整"} open={visibleOfAnalysedResultModal}
                           cancelText={"取消提交"} okText={"提交至系统"} onCancel={()=>setVisibleOfAnalysedResultModal(false)} onOk={handleClickSubmitAnalyseResultButton}
                        okButtonProps={{loading:loadingOfSubmitAnalyseResultButton}}
                    >
                        <span>分析的要货单id为:<strong>{selectedRowKeys.join()}</strong></span><br/>
                        <span>涉及的项目有:<strong>{getProjectNameFromId()}</strong></span>
                        <Table scroll={{x: 1300}} bordered columns={columnsOfAnalysedResult} dataSource={dataSourceOfAnalysedResult} rowKey={record => record["bianhao"]}/>
                    </Modal>
                </Panel>
                <Panel key={"2"} header={"查看、编辑采购单"}>
                    <QueryPurchaseOrder canChangePurchaseOrderDetail={true} fresh={freshOfQueryPurchaseOrder}/>
                </Panel>
            </Collapse>
        </Space>

    );
}

export default EditRequisitionByWarehouseman;