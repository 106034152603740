import {HTTPSTATUSCODE} from "../static/CONSTANTS";
import {removeToken} from "./auth";

export function handleResult(result,messageAntdComponent,navigateComponent){
    if (result==null) {
        return
    }else{
        if(result["code"]===HTTPSTATUSCODE["OK"]){
            return result;
        }else if (result["code"]===HTTPSTATUSCODE["UNAUTHORIZED"]){//说明没登录，如果传了navigate，就直接导到login页面
            console.log(result)
            //当然只有login页面的请求没有传这个navigate，其他页面的请求都有传
            if (messageAntdComponent!=null&&result["msg"]!==undefined&&navigateComponent!=null){
                messageAntdComponent.error("错误信息："+result["msg"]);
            }
            if (navigateComponent!=null){ //加上这个用于区别是否是app.js，如果没有这个if，则会一直刷新。
                removeToken();
                window.location.href = window.location.href
            }

            // if (navigateComponent!=null&&functionToLoginOfSetLogined!=null&&functionToLoginOfSetRouteTable!=null){
            //     // navigateComponent("/login",{setLogined:functionToLoginOfSetLogined,setRouteTable:functionToLoginOfSetRouteTable});//直接导航到login的话，有俩个函数没有参数，所以还是导航到最初的页面
            // }else{
            //     console.log("传过来给login的函数有空！");
            // }
            return false;
        } else{
            if (messageAntdComponent!=null&&result["msg"]!==undefined){
                messageAntdComponent.error(result["msg"]);
            }

            return false;
        }
    }
}