import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useRoutes} from "react-router-dom";
import "./App.css"
import Login from "./pages/Login";
import {verifyToken} from "./service/auth";
import DefaultPage from "./pages/DefaultPage";
import PageNotFound from "./pages/PageNotFound";
import Frame from "./components/Frame"
import {message} from "antd";
import {handleResult} from "./utlis/response";
import {transferStringToElement} from "./utlis/UniversalFunctions";



function App(props) {

    const [Logined,setLogined] =useState();
    const [RouteTable,setRouteTable]=useState();
    const routeTableArray = [
        {
            //如果已经登录了，到login页面就会自动navigate到Frame中
            path:"/login",
            element: <Login/>
        },
        {
          path:"/app",
          element: <App/>
        },
        {
            path:"*",
            element: <PageNotFound setRouteTable={setRouteTable}/>
        }
    ];

    useEffect(()=>{
        verifyToken().then(result => {
                let resultAfterHandle = handleResult(result,message);
                if (resultAfterHandle===false){
                    setLogined(false);
                }else{
                    setLogined(true);
                }
            }).catch(error=>{
                console.log(error)
                setLogined(false);
                message.error("出错了！"+error)
            })
    }, [Logined]);


    let temp_routes = []
    let tempRouteTable = RouteTable===undefined?[]:RouteTable;
    for (let index in tempRouteTable){
        tempRouteTable[index]["icon"]=transferStringToElement(tempRouteTable[index]["icon"]);
        for (let index2 in tempRouteTable[index]["children"]){
            tempRouteTable[index]["children"][index2]["icon"]=transferStringToElement(tempRouteTable[index]["children"][index2]["icon"]);
            tempRouteTable[index]["children"][index2]["element"]=transferStringToElement(tempRouteTable[index]["children"][index2]["element"]);
        }
        temp_routes.push(tempRouteTable[index]);
    }

    routeTableArray.push({path:"/admin",element: <Frame  setLogined={setLogined} setRouteTable={setRouteTable}/>,children:temp_routes})
    const mainRoutesTable = useRoutes(routeTableArray);

    if (Logined===undefined && RouteTable===undefined){
        return <DefaultPage/>
    }

    return (
        <div>
            {Logined?mainRoutesTable:
                    <Routes>
                        <Route path={"/login"} element={<Login setLogined={setLogined} setRouteTable={setRouteTable}/>}/>
                        <Route path={"*"} element={<Navigate to={"/login"}/>}/>
                    </Routes>
            }
        </div>)
    // ):(
    //     <Routes>
    //         <Route path={"/login"} element={<Login setLogined={setLogined}/>}/>
    //         <Route path={"*"} element={<Navigate to={"/login"}/>}/>
    //     </Routes>
    // )
    // return (<div>{mainRoutesTable}</div>)
}

export default App;