import React, {useEffect, useState} from 'react';
import {Button, Card, Divider, Empty, Form, message, Result, Select, Space, Spin, Typography} from "antd";
import $ from "jquery";
import {getToken} from "../../../utlis/auth";
import Modal from "antd/es/modal/Modal";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import {useNavigate} from "react-router-dom";
const {Option} = Select;
function FrozenProperty(props) {
    const {Text} = Typography;
    const [options_of_select,setOptions_of_select] = useState(null);
    const [properties_to_be_chosen,setProperties_to_be_chosen] = useState(null);
    const [options_to_be_frozen,setOptions_to_be_frozen] = useState(null);
    const [options_to_be_thawed,setOptions_to_be_thawed] = useState(null);
    const [type_of_form,setType_of_form] = useState(null);
    const [isModalFinalVisible,setIsModalFinalVisible] = useState(false);
    const [result_loding,setResult_loding] = useState(false);
    const [if_return_result,setIf_return_result] = useState(null);
    const [form_frozen] = Form.useForm();
    const navigate = useNavigate();
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const [confirmLoading,setConfirmLoading] = useState(false);


    useEffect(() => {
        post(APIOBJECT["getAllCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setCategoryArrayForSelect(resultAfterHandle["data"]);
            }else{
                message.error("当前大类为空，请先添加大类！");
            }
        }).catch(error=>message.error("出现错误！"+error))


        return () => {
        };
    }, []);

    const loadEntitiesByCategorySelect = (categoryId)=>{
        form_frozen.resetFields();
        post(APIOBJECT["getEntitiesByCategoryId"],JSON.stringify({"categoryId":categoryId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let options_array = [];
                let result = resultAfterHandle["data"];
                for (let index in result){
                    options_array.push({
                        label:result[index]["chinese_name"],
                        value:result[index]["id_of_entity"]
                    })
                }
                setOptions_of_select(options_array);
            }
        }).catch(error=>message.error("出错了！"+error))
    }

    const load_properties = (value)=>{
        //代表重新选择了
        form_frozen.resetFields(["to_be_frozen_option","frozen_property"]);
        setProperties_to_be_chosen(null);
        setOptions_to_be_frozen(null);
        setOptions_to_be_thawed(null);
        post(APIOBJECT["getEntityById"],JSON.stringify({"id_of_entity":value})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let result = resultAfterHandle["data"]
                let constraint = result["constraint"]
                let frozen_properties = constraint["frozen_properties"];
                let keys_of_frozen_properties = [];
                for (let key in frozen_properties){
                    keys_of_frozen_properties.push(key);
                }
                let properties = constraint["properties"];
                let temp_properties_object_array = [];//用于使用map遍历构造属性选择框
                for (let key in properties){
                    let options_temp = properties[key]["options"];
                    let options_frozen = [];
                    let options_available = [];
                    if (keys_of_frozen_properties.includes(key)){
                        options_frozen=frozen_properties[key];
                        options_available=options_temp.filter((item)=>!frozen_properties[key].includes(item))
                    }else{//说明没有冻结的属性
                        options_available=options_temp;
                    }
                    temp_properties_object_array.push({
                        id_of_property:key,
                        chinese_name_of_property:properties[key]["chinese_name"],
                        options_available,
                        options_frozen:options_frozen
                    })
                }
                console.log(temp_properties_object_array);
                setProperties_to_be_chosen(temp_properties_object_array);
            }
        }).catch(error=>message.error("发生了错误！请联系管理员！"+error))
    }

    const load_options = (id_of_property)=>{
        let object_json = properties_to_be_chosen.filter((item)=>item.id_of_property==id_of_property);
        let temp = [];
        if (object_json.length!==0){
            for(let index in object_json[0].options_available){
                temp.push({
                    key:object_json[0].options_available[index],
                    value:object_json[0].options_available[index],
                    label:object_json[0].options_available[index],
                    disabled:false
                })
            }
            for(let index in object_json[0].options_frozen){
                temp.push({
                    key:object_json[0].options_frozen[index],
                    value:object_json[0].options_frozen[index],
                    label:object_json[0].options_frozen[index],
                    disabled:true
                })
            }
        }
        setOptions_to_be_frozen(temp);

        setOptions_to_be_thawed(temp.map((item)=>{return {
                ...item,
                disabled:!item.disabled
            }}))


    }

    const postFrozenOrThawRequest = (value,typeOfForm)=>{
        setConfirmLoading(true);
        let jsonData = JSON.stringify({"id_of_entity":value["entity"], "id_of_property":value["frozen_property"],
            "type":typeOfForm, "to_be_frozen_option":value["to_be_frozen_option"],"to_be_thawed_option":value["to_be_thawed_option"]})
        post(APIOBJECT["frozeOrThawProperty"],jsonData).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let result = resultAfterHandle;
                setIf_return_result({...result})
                setResult_loding(false);
                setTimeout(()=>{
                    window.location.reload();
                },1000)
            }else{
                setIf_return_result({...resultBeforeHandle})
                setConfirmLoading(false);
            }
        }).catch(error=>{
            message.error("出错了！请与管理员联系！"+error)
            setConfirmLoading(false);
        })

    }


    const handle_submit_form=(value)=>{

        if (if_return_result!=null&&if_return_result["code"]!=null&&if_return_result["code"]===200){
            message.error("不要重复点击提交！静候刷新即可！");
            return;
        }
        setResult_loding(true);

        //to_be_frozen_option
        if (type_of_form==null){
            setResult_loding(false);
            return;
        }else if (type_of_form==="froze"){
            if (typeof(value.to_be_frozen_option)=="undefined"){
                setResult_loding(false);
                message.error("请选择冻结的选项！");
            }else{
                postFrozenOrThawRequest(value,type_of_form)
            }
        }else if (type_of_form==="thaw"){
            if(typeof(value.to_be_thawed_option)=="undefined"){
                message.error("请选择解冻的选项！");
                setResult_loding(false);
            }else{
                console.log(value);
                postFrozenOrThawRequest(value,type_of_form)
            }
        }else{
            console.error("type为空！请重新选择！");
            setResult_loding(false);
        }
    }

    return (
        <Card title={"解冻or冻结"}>

            <Modal title="提交以及结果显示框" visible={isModalFinalVisible}
                   onOk={()=>form_frozen.submit()}
                   onCancel={()=>setIsModalFinalVisible(false)}
                   okText={type_of_form == null?"错误请勿点击！":type_of_form === "froze"?"冻结":"解冻"}
                   cancelText={"取消"}
                   confirmLoading={confirmLoading}
            >

                <Card title={"提交结果"} type="inner" bordered loading={result_loding}>
                    {if_return_result==null?
                        <Empty/>:
                        <Result
                            status={if_return_result["code"]===200?"success":"error"}
                            title={if_return_result["code"]===200?"成功！":"失败！"}
                            subTitle={if_return_result["code"]===200?"冻结or解冻成功！稍后页面将刷新":if_return_result["msg"]}
                        />}

                </Card>
            </Modal>



            <Form form={form_frozen}
                onFinish={(value)=>handle_submit_form(value)}
            >
                <Space direction={"vertical"} style={{"width":"100%"}} size={"large"}>
                <Text type={"warning"}>冻结属性后，该属性有值的编码并不会删除，仍可通过编码查询到具体的值，
                    但是在通过条件查询编码时再选择该属性的冻结值了。</Text>
                <div>
                    <Text>第一步：请先选择大类：</Text>
                    <Select
                        showSearch
                        placeholder="请选择一个大类"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        onSelect={(value)=>loadEntitiesByCategorySelect(value)}
                        style={{"width":"100%"}}
                    >
                        {categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> )}
                    </Select>
                </div>

                <div>
                    <Text>第二步：请选择实体：</Text>
                    <Form.Item
                        name={"entity"}
                        rules={[{
                            required:true,
                            message:"请先选择实体！"
                        }]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择实体"
                            optionFilterProp="children"
                            options={options_of_select}
                            // onChange={()=>setResult_of_number_query(null)}
                            // onSearch={onSearch}
                            onSelect={(value)=>load_properties(value)}
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                </div>
                <div>
                    <span>第三步：选择要冻结或解冻的属性：</span>
                    <Form.Item
                        name={"frozen_property"}
                        rules={[{
                            required:true,
                            message:"请选择属性！！"
                        }]}
                    >
                        <Select placeholder={"请选择要冻结的属性"}
                                optionFilterProp="children"
                                onSelect={(value)=>load_options(value)}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {properties_to_be_chosen!=null?properties_to_be_chosen.map((item)=><Option key={item.id_of_property} value={item.id_of_property}>{item.chinese_name_of_property}</Option> ):""}
                        </Select>
                    </Form.Item>
                </div>

                <Card type={"inner"}
                    title={"冻结属性"}
                      extra={<Button type={"primary"} onClick={()=>{
                          setType_of_form("froze");
                          setIsModalFinalVisible(true);
                      }} danger>冻结属性</Button>}
                >
                    <span>第四步：选择要冻结该属性的可选值：</span>

                        <Form.Item name={"to_be_frozen_option"}>
                            <Select placeholder={"请选择要冻结的属性的可选值"}
                                    style={{"width":"100%"}}
                                    mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    options={options_to_be_frozen}
                                    onSelect={(value => console.log(value))}
                                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}>
                            </Select>
                        </Form.Item>

                </Card>
                <Divider plain>分割线</Divider>
                <Card type={"inner"}
                      title={"解冻属性"}
                      extra={<Button type={"primary"} onClick={()=>{
                          setType_of_form("thaw");
                          setIsModalFinalVisible(true);
                      }
                      }>解冻属性</Button>}
                      bordered
                >
                    <span>第四步：选择要解冻该属性的可选值：</span>
                    <Form.Item name={"to_be_thawed_option"}>
                    <Select placeholder={"请选择要解冻的属性可选值"}
                            optionFilterProp="children"
                            style={{"width":"100%"}}
                            mode="multiple"
                            allowClear
                            options={options_to_be_thawed}
                            onSelect={(value => console.log(value))}
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}>
                    </Select>
                    </Form.Item>


                </Card>
            </Space>
            </Form>
        </Card>
    );
}

export default FrozenProperty;