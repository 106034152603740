import React, {useEffect, useRef, useState} from 'react';
import "../../static/css/datePicker.css"
import {
    Button, Card, Collapse, Empty, Form, Input, message, Modal,
    Popconfirm, Select, Space, Spin, Table, Tooltip, Typography, Cascader, Divider, DatePicker, Row, Col
} from "antd";
import {useNavigate, useOutlet} from "react-router-dom";
import {
    APIOBJECT,
    makeInvoiceStatusForPurchaseOrderDetail,
    purchaseStatusForPurchaseOrderDetail,
    taxRateArray
} from "../../static/CONSTANTS";
import {CalendarOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import {post} from "../../utlis/request";
import {compareByTime, isSameDay, judgeIfNum, transferMainUniqueIdToPlainId} from "../../utlis/UniversalFunctions";
import {deepCopyObj} from "../../utlis/StringUtils";
import {handleResult} from "../../utlis/response";
const {Option} = Select;

function QueryPurchaseOrder(props) {



    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const { RangePicker } = DatePicker;
    /**用于构建时间范围选择的固定部分
     */
    const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const getColumnSearchProps2 = (dataIndex) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{padding: 4}}>
                    <RangePicker style={{ marginBottom: 8 }}
                                 value={selectedKeys[0]}
                                 onChange={e => {
                                     setSelectedKeys(e ? [e] : [])
                                 }}
                                 onPressEnter={() => {
                                     confirm();
                                 }}
                                 locale={locale}
                    />
                    <Space>
                        <Button type="primary" onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                            搜索
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small" style={{width: 90,}}>
                            重置
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (<CalendarOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
            ),
            onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(value[0], value[1], 'day', '[]') : "" ,
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
            },
            render: text => moment(text).format("YYYY/MM/DD HH:mm:ss")}
    );

    /**用于构建时间范围选择的固定部分
     */

    const {canChangePurchaseOrderDetail,fresh} = props;
    const navigate = useNavigate();
    const [dataSourceOfPurchaseOrder,setDataSourceOfPurchaseOrder] = useState([]);
    const [visibleOfChangingPurchaseOrderDetailModal,setVisibleOfChangingPurchaseOrderDetailModal] = useState(false);
    const [supplierArray,setSupplierArray] = useState([]);
    const [formOfChangePurchaseOrderDetail] = Form.useForm();
    const [changingPurchaseOrderDetail,setChangingPurchaseOrderDetail] = useState(null);//当前打开了修改页面的采购单细节
    const [loadingOfSubmitChangePurchaseOrderDetailButton,setLoadingOfSubmitChangePurchaseOrderDetailButton] = useState(false);

    useEffect(() => {
        getSupplier();

        return () => {
        };
    }, []);

    useEffect(()=>{
        getPurchaseOrder();
    },[fresh])

    const columnsOfPurchaseOrder = [
        {
            title:"创建时间",
            key:"create_time",
            dataIndex:"create_time",
            fixed:"left",
            width: 80,
            ...getColumnSearchProps2("create_time")
        },
        {
            title:"采购单编号",
            key:"mainUniqueId",
            dataIndex:"mainUniqueId",
            ellipsis: true,
            width: 150,
            fixed:"left",
            ...getColumnSearchProps("mainUniqueId")
        },
        {
            title:"生成人",
            key:"operator",
            dataIndex:"operator",
            width: 100,
            ...getColumnSearchProps("operator")
        },
        {
            title:"状态",
            key:"status",
            dataIndex:"status",
            width: 100,
            filters:[
                {
                    text:"采购中",
                    value:"采购中"
                },
                {
                    text: "已完成",
                    value: "已完成"
                }
            ],
            onFilter: (value, record) => record["status"].indexOf(value) === 0,
        },
        {
            title:"更新时间",
            key:"update_time",
            dataIndex:"update_time",
            width: 150,
            ...getColumnSearchProps2("update_time")
        }
    ]


    const expandedRowRenderForPurchaseOrderDetail = (row) => {
        const columnsOfPurchaseOrderDetails = [
            {
                title:"名称",
                key:"name",
                dataIndex:"name",
                width: 300,
                fixed:"left",
                ...getColumnSearchProps("name")
            },
            {
                title:"编号",
                key:"bianhao",
                dataIndex:"bianhao",
                ellipsis: true,
                width: 80,
                ...getColumnSearchProps("bianhao")
            },
            {
                title:"详情",
                key:"propertiesString",
                dataIndex:"propertiesString",
                width: 300,
                ...getColumnSearchProps("propertiesString")
            },
            {
                title:"需买数量",
                key:"need_quantity",
                dataIndex:"need_quantity",
                width: 150,
                ...getColumnSearchProps("need_quantity")
            },
            {
                title:"实际购买数量",
                key:"real_purchase_quantity",
                dataIndex:"real_purchase_quantity",
                width: 150,
                ...getColumnSearchProps("real_purchase_quantity")
            },
            {
                title:"已到数量",
                key:"got_quantity",
                dataIndex:"got_quantity",
                width: 110,
                ...getColumnSearchProps("got_quantity")
            },
            {
                title:"单位",
                key:"unit",
                dataIndex:"unit",
                width: 90,
                ...getColumnSearchProps("unit")
            },
            {
                title:"总价",
                key:"sumPrice",
                dataIndex:"sumPrice",
                width: 100,
                ...getColumnSearchProps("sumPrice")
            },
            {
                title:"供应商",
                key:"supplier",
                dataIndex:"supplier",
                width: 200,
                ...getColumnSearchProps("supplier")
            },
            {
                title:"开票情况",
                key:"if_make_out_VAT_special_invoiceForDisplaying",
                dataIndex:"if_make_out_VAT_special_invoiceForDisplaying",
                width: 150,
                filters:[
                    {
                        text:"未开票",
                        value:"未开票"
                    },
                    {
                        text: "已开增值税专票",
                        value: "已开增值税专票"
                    },
                    {
                        text: "已开普通发票",
                        value: "已开普通发票"
                    }
                ],
                onFilter: (value, record) => record["if_make_out_VAT_special_invoiceForDisplaying"].indexOf(value) === 0,
            },
            {
                title:"税率",
                key:"taxRate",
                dataIndex:"taxRate",
                width: 80,
                render:(text)=>{
                    if (judgeIfNum(text)){
                        return String(Number(text) * 100) + "%";
                    }else{
                        return ""
                    }
                }
            },
            {
                title:"交货周期",
                key:"delivery_time",
                dataIndex:"delivery_time",
                width: 110,
                ...getColumnSearchProps("deliveryTime")
            },
            {
                title:"运费",
                key:"transportation_expenses",
                dataIndex:"transportation_expenses",
                width: 100,
                ...getColumnSearchProps("transportation_expenses")
            },

            {
                title:"是否已购",
                key:"if_get_purchased_for_purchaserForDisplaying",
                dataIndex:"if_get_purchased_for_purchaserForDisplaying",
                width: 110,
                filters:[
                    {
                        text:"未采购",
                        value:"未采购"
                    },
                    {
                        text:"采购中",
                        value:"采购中"
                    },
                    {
                        text: "已采购",
                        value: "已采购"
                    }
                ],
                onFilter: (value, record) => record["if_get_purchased_for_purchaserForDisplaying"].indexOf(value) === 0,
            },
            {
                title:"需外勤？",
                key:"if_need_help_of_field_personnelForDisplaying",
                dataIndex:"if_need_help_of_field_personnelForDisplaying",
                width: 150,
                filters:[
                    {
                        text:"需要",
                        value:"需要"
                    },
                    {
                        text: "不需要",
                        value: "不需要"
                    }
                ],
                onFilter: (value, record) => record["if_need_help_of_field_personnelForDisplaying"].indexOf(value) === 0,
            },
            {
                title:"创建时间",
                key:"create_time",
                dataIndex:"create_time",
                width: 180,
                ...getColumnSearchProps2("create_time")
            },
            {
                title:"更新时间",
                key:"update_time",
                dataIndex:"update_time",
                width: 180,
                ...getColumnSearchProps2("update_time")
            }
        ]
        let operationCol = {
            title: "操作",
            width:150,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Button type={"primary"} size={"small"} onClick={()=>handleClickChangePurchaseOrderDetailButton(record)}>修改</Button>
                    </Space>
                )
            }
        }

        if(canChangePurchaseOrderDetail === true){
            columnsOfPurchaseOrderDetails.push(operationCol);
        }

        return (<Table scroll={{x: 1300}} columns={columnsOfPurchaseOrderDetails} bordered
                       dataSource={dataSourceOfPurchaseOrder.length === 0 ? []:dataSourceOfPurchaseOrder.filter(item => item["mainUniqueId"] === row["mainUniqueId"])[0]["details"]}
                       rowKey={record => record["bianhao"]+record["mainUniqueId"]}
        />)
    }

    const handleClickChangePurchaseOrderDetailButton=(record)=>{
        if (canChangePurchaseOrderDetail !== true){
            message.error("当前不允许修改！");
            return;
        }
        let recordCopy = deepCopyObj(record);
        recordCopy["if_get_purchased_for_purchaser"] = record["if_get_purchased_for_purchaser"] === "未采购" ? 0 : 1;
        recordCopy["if_need_help_of_field_personnel"] = record["if_need_help_of_field_personnel"] === "不需要" ? 0 : 1;
        recordCopy["if_make_out_VAT_special_invoice"] = makeInvoiceStatusForPurchaseOrderDetail.filter(item=>item["value"] === record["if_make_out_VAT_special_invoice"])[0]["value"];
        setVisibleOfChangingPurchaseOrderDetailModal(true);
        formOfChangePurchaseOrderDetail.resetFields();
        if(recordCopy["supplier_id"]){
            formOfChangePurchaseOrderDetail.setFieldsValue({...recordCopy,supplierId:recordCopy["supplier_id"]});
        }else{
            formOfChangePurchaseOrderDetail.setFieldsValue({...recordCopy});

        }


        setChangingPurchaseOrderDetail(recordCopy);
    }

    const getSupplier = ()=>{
        post(APIOBJECT["getAllSupplier"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]!=null&&resultAfterHandle["data"]!==undefined){
                    setSupplierArray(resultAfterHandle["data"]);
                }else{
                    message.error("无供应商信息！请先添加供应商！");
                }

            }
        }).catch(error=>message.error("getSupplier出错了！"+error))
    }


    const getPurchaseOrder = ()=>{
        // console.log("getPurchaseOrder发送请求了！");
        post(APIOBJECT["getPurchaseOrder"]).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                let sortedArray = resultAfterHandle["data"].sort(compareByTime("create_time","inverted"));
                setDataSourceOfPurchaseOrder(sortedArray);
            }
        }).catch(e=>message.error("getPurchaseOrder出错了！"+e))
    }

    const handleSubmitChangingPurchaseOrderDetail = (value) =>{
        setLoadingOfSubmitChangePurchaseOrderDetailButton(true);
        post(APIOBJECT["changePurchaseOrderDetail"],JSON.stringify({...value,got_quantity:changingPurchaseOrderDetail["got_quantity"],need_quantity:changingPurchaseOrderDetail["need_quantity"]
            ,purchase_order_id:changingPurchaseOrderDetail["purchase_order_id"],bianhao:changingPurchaseOrderDetail["bianhao"]})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                getPurchaseOrder();
                message.success(resultAfterHandle["msg"]);
                setVisibleOfChangingPurchaseOrderDetailModal(false);
            }
        }).catch(e=>console.log("handleSubmitChangingPurchaseOrderDetail出错了！"+e)).finally(()=>setLoadingOfSubmitChangePurchaseOrderDetailButton(false))
    }

    return (
        <>
            <Table expandedRowRender={expandedRowRenderForPurchaseOrderDetail} columns={columnsOfPurchaseOrder} dataSource={dataSourceOfPurchaseOrder} scroll={{x: 1300}} bordered rowKey={record => record["mainUniqueId"]}/>
            <Modal width={1000} title={"修改采购单细节"} open={visibleOfChangingPurchaseOrderDetailModal} cancelText={"取消"} okText={"提交修改"}
                   onOk={()=>formOfChangePurchaseOrderDetail.submit()} onCancel={()=>setVisibleOfChangingPurchaseOrderDetailModal(false)}
                   okButtonProps={{loading:loadingOfSubmitChangePurchaseOrderDetailButton}}
            >
                <span>该采购细节所属的采购单id为：<strong>{changingPurchaseOrderDetail == null ? "" : changingPurchaseOrderDetail["purchase_order_id"]}</strong></span><br/>
                <span>采购单创建时间：<strong>{changingPurchaseOrderDetail == null ? "" : dataSourceOfPurchaseOrder.filter(item=>item["mainUniqueId"] === changingPurchaseOrderDetail["purchase_order_id"])[0]["create_time"]}</strong></span>
                <Form form={formOfChangePurchaseOrderDetail} onFinish={handleSubmitChangingPurchaseOrderDetail}>
                    <Form.Item name={"id"} label={"采购单细节id"}>
                        <Input disabled/>
                    </Form.Item>
                    <Row>
                        <Col span={10}>
                            <Form.Item label={"名称"}>
                                <span><strong>{changingPurchaseOrderDetail == null ? "" : changingPurchaseOrderDetail["name"]}</strong></span>
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item label={"详情"}>
                                <span><strong>{changingPurchaseOrderDetail == null ? "" : changingPurchaseOrderDetail["propertiesString"]}</strong></span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item name={"real_purchase_quantity"} label={"实际购买数"}
                                       rules={[
                                           {required:false,message:"请输入数量"},
                                           ({getFieldValue})=>({
                                               validator(_,value){
                                                   if (!judgeIfNum(value)){
                                                       return Promise.reject(new Error("数量不正确！"));
                                                   } else{
                                                       return Promise.resolve();
                                                   }
                                               }
                                           })
                                       ]}>
                                <Input type={"number"} step={"any"}/>
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item label={"已到数量"} name={"got_quantity"}>
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name={"sumPrice"} label={"总价"}
                               rules={[
                                   {required:false,message:"请输入总计"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("总价不正确！"));
                                           } else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} step={"any"}/>
                    </Form.Item>
                    <Form.Item name={"supplierId"} label={"供应商"} rules={[{required:false,message:"请选择供应商！"}]}>
                        <Select showSearch placeholder="请选择供应商"
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {supplierArray.map(item=><Option disabled={item["disabled"] !== "0"} key={item["supplierId"]} value={item["supplierId"]}>{item["name"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"taxRate"} label={"税率"}>
                        <Select showSearch placeholder={"请选择税率"} optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={taxRateArray}/>
                    </Form.Item>
                    <Form.Item name={"if_make_out_VAT_special_invoice"} label={"开票情况"}>
                        <Select showSearch placeholder={"请选择开票情况"} optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={makeInvoiceStatusForPurchaseOrderDetail}/>
                    </Form.Item>
                    <Form.Item name={"delivery_time"} label={"交货周期"}
                               rules={[
                                   {required:false,message:"请输入交货周期"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if(value === "" || typeof(value) === 'undefined'){
                                               return Promise.resolve();
                                           }
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("交货周期不正确！"));
                                           } else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} step={"any"}/>
                    </Form.Item>
                    <Form.Item name={"transportation_expenses"} label={"运费"}
                               rules={[
                                   {required:false,message:"请输入运费"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("运费不正确！"));
                                           } else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} step={"any"}/>
                    </Form.Item>
                    <Form.Item name={"if_get_purchased_for_purchaser"} label={"是否已购"}>
                        <Select showSearch placeholder={"请选择税率"} optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={purchaseStatusForPurchaseOrderDetail}/>
                    </Form.Item>
                    <Form.Item name={"if_need_help_of_field_personnel"} label={"需外勤？"}>
                        <Select showSearch placeholder={"请选择税率"} optionFilterProp={"children"}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[{value:0,label:"不需要"},{value:1,label:"需要"}]}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
}

export default QueryPurchaseOrder;