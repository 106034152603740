import {BackTop, Layout, Menu, message, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import "./index.css"
import "antd/dist/antd.min.css"
import {Outlet, Route, Routes, useNavigate, useRoutes} from "react-router-dom";
import User_Pic from "../User_Pic"
import {post} from "../../utlis/request";
import {APIOBJECT} from "../../static/CONSTANTS";
import DefaultPage from "../../pages/DefaultPage";
import {handleResult} from "../../utlis/response";
import {transferStringToElement} from "../../utlis/UniversalFunctions";

const { Header, Content, Footer, Sider } = Layout;

const App = (props) => {

    const navigate = useNavigate()
    const {setLogined,setRouteTable}=props;
    const [itemOfMenu,setItemOfMenu]=useState([])
    const [collapsed, setCollapsed] = useState(true);
    const [routesArray,setRoutesArray] = useState([]);
    const [username,setUsername]=useState();

    const handleMenu = (rawMenuJsonArray)=>{
        let temp_routes = []
        let tempRouteTable = rawMenuJsonArray===undefined?[]:rawMenuJsonArray;
        for (let index in tempRouteTable){
            // console.log(tempRouteTable[index])
            tempRouteTable[index]["icon"]=transferStringToElement(tempRouteTable[index]["icon"]);
            for (let index2 in tempRouteTable[index]["children"]){
                tempRouteTable[index]["children"][index2]["icon"]=transferStringToElement(tempRouteTable[index]["children"][index2]["icon"]);
                tempRouteTable[index]["children"][index2]["element"]=transferStringToElement(tempRouteTable[index]["children"][index2]["element"]);
            }
            temp_routes.push(tempRouteTable[index]);
        }
        return temp_routes;
    }
    let mainRoutes=useRoutes(itemOfMenu);
    useEffect(() => {
        let adminRoutes;
        post(APIOBJECT["getMenuByToken"], null).then(resultBeforeHandle => {
            let resultAfterHandle = handleResult(resultBeforeHandle, message, navigate);
            // console.log(resultAfterHandle);
            if (resultAfterHandle !== false) {
                let resultObject = resultAfterHandle["data"]
                let result = resultObject["menu"];
                setUsername(resultObject["username"]);
                // console.log("这里的result是！！！！！！！！！！！！！！！")
                setRouteTable(result);
                adminRoutes = handleMenu(result);
                let temp = adminRoutes !== undefined ? adminRoutes.map(route => {
                    console.log(route.name)
                    if (route.path == null) {
                        return {
                            key: route.path,
                            icon: route.icon,
                            label: route.name
                        }
                    } else {
                        return {
                            key: route.path,
                            icon: route.icon,
                            label: route.name,
                            children: route.children && route.children.map(children => {
                                return {
                                    key: children.path,
                                    icon: children.icon,
                                    label: children.name
                                }
                            })

                        }
                    }
                }) : []
                // console.log("temp是")
                setItemOfMenu(temp);
            }
        }).catch(error => message.error("出错啦！" + error))

        return () => {};
    },[]);



    const tiaozhuan = (e)=>{
        navigate(e.key);
    }

    if (itemOfMenu===[]||username==null){
        return <DefaultPage/>
    }
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <BackTop />
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div className="logo" style={{height: "32px",margin: "16px", background: "rgba(255, 255, 255, 0.3)"}}/>
                <Menu theme="dark"  mode="inline"
                      onClick={tiaozhuan}
                      items={itemOfMenu}
                />

            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                    }}
                >
                    <User_Pic setLogined={setLogined} setRouteTable={setRouteTable} username={username}/>
                </Header>
                <Content
                style={{margin:"20px 20px 20px 20px"}}>
                    <Outlet/>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    {/*{itemOfMenu.length===0?"haha":itemOfMenu[0]["key"]}*/}
                    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021014961号-1</a>
                </Footer>
            </Layout>
        </Layout>
    );

}
export default App;