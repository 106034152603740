import {
    AppstoreAddOutlined,
    BankOutlined,
    BarcodeOutlined,
    BuildFilled,
    ContainerOutlined,
    ControlOutlined,
    CreditCardOutlined,
    DeliveredProcedureOutlined,
    EditFilled,
    EditOutlined,
    FieldNumberOutlined,
    FileTextTwoTone,
    FilterFilled,
    IdcardOutlined,
    LockFilled,
    PlusOutlined,
    ProfileOutlined,
    PropertySafetyOutlined,
    ReconciliationOutlined,
    SearchOutlined,
    SecurityScanOutlined,
    ShoppingCartOutlined,
    UnorderedListOutlined,
    UserAddOutlined,
    UserOutlined,
    UserSwitchOutlined,
    HighlightFilled,
    SwitcherOutlined, ScheduleOutlined, FileSearchOutlined
} from "@ant-design/icons";

import React, {useRef, useState} from "react";
import AddUser from "../pages/admin/userManagement/AddUser";
import EditUser from "../pages/admin/userManagement/EditUser";
import EditCategory from "../pages/admin/entity/EditCategory";
import AddPropertiesConstraint from "../pages/admin/properties/AddPropertiesConstraint";
import QueryUser from "../pages/admin/userManagement/QueryUser";
import Dashboard from "../pages/admin/userManagement/dashboard";
import Add_Entity from "../pages/admin/entity/Add_Entity";
import FrozenProperty from "../pages/admin/entity/Frozen_property";
import Add_property from "../pages/admin/entity/Add_property/Add_property";
import PropertiesRepository from "../pages/admin/properties/Properties_repository";
import List from "../pages/admin/entity/List";
import Query_number from "../pages/admin/entity/Query_number";
import CheckWarehouse from "../pages/admin/warekhouse/CheckWarehouse";
import Ex_warehouse from "../pages/admin/warekhouse/Ex_warehouse";
import MoveWarehouse from "../pages/admin/warekhouse/MoveWarehouse";
import Warehousing from "../pages/admin/warekhouse/Warehousing";
import QueryWarehouse from "../pages/admin/warekhouse/QueryWarehouse";
import EditProject from "../pages/admin/warekhouse/EditProject";
import EditSupplier from "../pages/admin/warekhouse/EditSupplier";
import EditWarehouseLocation from "../pages/admin/warekhouse/EditWarehouseLocation";
import CheckLogOfWarehouse from "../pages/admin/warekhouse/CheckLogOfWarehouse";
import ApplicantsEdit from "../pages/admin/userManagement/ApplicantsEdit";
import RequisitionEdit from "../pages/admin/manager/RequisitionEdit";
import {post} from "./request";
import {APIOBJECT} from "../static/CONSTANTS";
import {handleResult} from "./response";
import {message} from "antd";
import EditRequisitionByWarehouseman from "../pages/admin/warekhouse/EditRequisitionByWarehouseman";
import Decimal from "decimal.js";

export const transferStringToElement = (stringConstants)=>{
    switch (stringConstants){
        case "FileSearchOutlined":
            return <FileSearchOutlined />;
        case "EditRequisitionByWarehouseman":
            return <EditRequisitionByWarehouseman/>
        case "ScheduleOutlined":
            return <ScheduleOutlined />
        case "SwitcherOutlined":
            return <SwitcherOutlined />
        case "RequisitionEdit":
            return <RequisitionEdit/>
        case "ApplicantsEdit":
            return <ApplicantsEdit/>
        case "HighlightFilled":
            return <HighlightFilled/>
        case "AddUser":
            return <AddUser/>
        case "EditUser":
            return <EditUser/>
        case "EditCategory":
            return <EditCategory/>
        case "AddPropertiesConstraint":
            return <AddPropertiesConstraint/>
        case "FilterFilled":
            return <FilterFilled />
        case "QueryUser":
            return <QueryUser/>
        case "FileTextTwoTone":
            return <FileTextTwoTone />
        case "CheckLogOfWarehouse":
            return <CheckLogOfWarehouse/>
        case "ReconciliationOutlined":
            return <ReconciliationOutlined />
        case "EditProject":
            return <EditProject/>
        case "ShoppingCartOutlined":
            return <ShoppingCartOutlined />
        case "EditSupplier":
            return <EditSupplier/>
        case "BarcodeOutlined":
            return <BarcodeOutlined />
        case "EditWarehouseLocation":
            return <EditWarehouseLocation/>
        case "CheckWarehouse":
            return <CheckWarehouse/>
        case "Ex_warehouse":
            return <Ex_warehouse/>
        case "MoveWarehouse":
            return <MoveWarehouse/>
        case "Warehousing":
            return <Warehousing/>
        case "Dashboard":
            return <Dashboard/>
        case "Add_Entity":
            return <Add_Entity/>
        case "FrozenProperty":
            return <FrozenProperty/>
        case "Add_property":
            return <Add_property/>
        case "PropertiesRepository":
            return <PropertiesRepository/>
        case "ContainerOutlined":
            return <ContainerOutlined />
        case "PropertySafetyOutlined":
            return <PropertySafetyOutlined/>
        case "QueryWarehouse":
            return <QueryWarehouse/>
        case "ControlOutlined":
            return <ControlOutlined />
        case "CreditCardOutlined":
            return <CreditCardOutlined />
        case "DeliveredProcedureOutlined":
            return <DeliveredProcedureOutlined />
        case "BankOutlined":
            return <BankOutlined />
        case "List":
            return <List/>
        case "Query_number":
            return <Query_number/>
        case "UserAddOutlined":
            return <UserAddOutlined/>
        case "UserSwitchOutlined":
            return <UserSwitchOutlined/>
        case "IdcardOutlined":
            return <IdcardOutlined/>
        case "PlusOutlined":
            return <PlusOutlined/>
        case "LockFilled":
            return <LockFilled/>
        case "BuildFilled":
            return <BuildFilled />
        case "AppstoreAddOutlined":
            return <AppstoreAddOutlined/>
        case "ProfileOutlined":
            return <ProfileOutlined/>
        case "SecurityScanOutlined":
            return <SecurityScanOutlined/>
        case "FieldNumberOutlined":
            return <FieldNumberOutlined/>
        case "UserOutlined":
            return <UserOutlined/>
        case "EditOutlined":
            return <EditOutlined/>
        case "UnorderedListOutlined":
            return <UnorderedListOutlined/>
        case "EditFilled":
            return <EditFilled />
        default:
            return stringConstants;
    }
}


export const handleResponseResultOfQueryEntityDetailsTable = (resultAfterHandle)=>{
    let result = resultAfterHandle["data"];
    let orderOfProperties = result["orderOfProperties"]
    let dataSourceOfOuter = [{
        id_of_entity:result["propertiesData"]["id_of_entity"],
        name_of_entity:result["propertiesData"]["chinese_name"]
    }]
    let dataSourceOfInner = [];
    for (let index in orderOfProperties){
        dataSourceOfInner.push({
            chinese_name:result["propertiesData"]["properties"][orderOfProperties[index]]["chinese_name"],
            id_of_property:orderOfProperties[index],
            value_of_property:result["propertiesData"]["properties"][orderOfProperties[index]]["value"]===""?"空值"
                :result["propertiesData"]["properties"][orderOfProperties[index]]["value"]
        })
    }
    return {outer:dataSourceOfOuter,inner:dataSourceOfInner}
}

export const transferPercentageToNum = (percentage)=>{
    let num = Number(percentage.replace("%",""));
    return num/100
}
export const judgeIfNum = (num) =>{
    if(num === undefined){
        return false;
    }else{
        let regexForFloat = new RegExp("^\\d+(\\.\\d+)?$");
        let regexForInteger = new RegExp("^[0-9]*$");
        return regexForFloat.test(num) || regexForInteger.test(num);
    }
}

export const getUnitOfBianhao = (bianhao,message,navigate) =>{
    return post(APIOBJECT["queryEntityByNumber"],JSON.stringify({"number_of_entity":bianhao})).then(resultBeforeHandle=>{
        let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
        return new Promise((resolve,reject)=>{
                resolve(resultAfterHandle)
        })
    })
}

export const judgeLegalWarehousingWithPurchaseOrder = (bianhao,quantity,purchaseOrderObj) =>{
    if (!bianhao || !quantity || !purchaseOrderObj){
        return "编号、数量或者采购单对象有误！";
    }
    if(!judgeIfNum(quantity)){
        return "数量有误！";
    }
    let {details} = purchaseOrderObj;
    let filteredArray = details.filter(item=>item["bianhao"] === bianhao);
    if(filteredArray.length === 0){
        return "当前采购单没有该项实体，请切换实体或者采购单！";
    }
    let detailItem = filteredArray[0];
    let newQuantityDecimal = new Decimal(quantity);
    let needQuantityDecimal = new Decimal(detailItem["real_purchase_quantity"]).sub(new Decimal(detailItem["got_quantity"]));
    if(newQuantityDecimal.comparedTo(needQuantityDecimal) > 0){
        return "您最多可以入库的数量为"+needQuantityDecimal.toString();
    }
    return true;
}

export const compareByTime = (prop,align) =>{
    return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        if(align=="positive"){//正序
            return new Date(value1)-new Date(value2);
        }else if(align=="inverted"){//倒序
            return new Date(value2)-new Date(value1);
        }
    }
}
export function isSameDay(startTime, endTime) {
    const startTimeMs = new Date(startTime).setHours(0,0,0,0);
    const endTimeMs = new Date(endTime).setHours(0,0,0,0);
    return startTimeMs === endTimeMs
}

export function judgeWarehouseIsNormal(name){
    return name.indexOf("-") !== -1;
}

export function getCascaderOptionArrayForWarehouse(warehouseArray){
    let options = [];
    //先分成两类
    if(warehouseArray === undefined){
        return [];
    }
    let personArray = warehouseArray.filter(item => item["locationName"].indexOf("-") === -1);
    let normalArray = warehouseArray.filter(item => item["locationName"].indexOf("-") !== -1);
    if (personArray.length > 0){
        options.push({
            value: 'person',
            label: '人员',
            children:personArray.map(item => {
                return {
                    ...item,disabled:item["disabled"] === 1,label:item["locationName"],value:item["locationId"]
                }
            })
        })
    }
    let count = 0;
// normalArray = [{locationId:"fdsfs",locationName:"办公室-货架1-层1-位1"}]
    for(let item of normalArray){
        let splitArray = item["locationName"].split("-");
        let currentLevel = options;
        for(let i = 0; i < splitArray.length; i++){
            let existItem = currentLevel.find(item2 => item2["label"] === splitArray[i]);
            if (existItem){
                if(i === splitArray.length - 1){
                    existItem.value = item["locationId"];
                } else if (!existItem.children){
                    existItem.children = [];
                }
                existItem.key = count++;  // Assign a new key to the existing item
                currentLevel = existItem.children;
            } else {
                let newItem = {
                    label : splitArray[i],
                    key: count++,
                    value:count++
                }
                if (i === splitArray.length - 1){
                    newItem["value"] = item["locationId"];
                } else {
                    newItem["children"] = []
                }
                currentLevel.push(newItem);
                currentLevel = newItem.children;
            }
        }
    }
    return options;
}

export function convertArrayResult(array){
    return array[array.length - 1];
}

export function findOptionPath(options, targetValue, path = []) {
    for (let option of options) {
        // 创建一个新的路径数组，包括当前选项的值
        const newPath = [...path, option.value];
        // 如果当前选项的值等于目标值，那么返回新的路径
        if (option.value === targetValue) {
            return newPath;
        }
        // 如果当前选项有子选项，递归搜索子选项
        if (option.children) {
            const childPath = findOptionPath(option.children, targetValue, newPath);
            if (childPath.length > 0) {
                return childPath;
            }
        }
    }
    // 如果在这个选项数组中没有找到目标值，返回一个空数组
    return [];
}
