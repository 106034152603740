import React, {useEffect, useRef, useState} from 'react';
import {Button, Collapse, Form, Input, message, Modal, Popconfirm, Radio, Select, Space, Table, Typography} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import FormList from "../../../components/Edit/Warehouse/FormList"
import {judgeWarehouseIsNormal} from "../../../utlis/UniversalFunctions";
function EditWarehouseLocation(props) {
    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) :
            (text==="已禁用"?<Text type={"danger"}>已禁用</Text>:text==="进行中"||text==="未禁用"?<Text type={"success"}>{text}</Text>:text),});
    /**
     * 用于构建可搜索table的固定部分
     */
    const navigate = useNavigate();
    const [formOfAddWarehouseLocation] = Form.useForm();
    const [formOfChangeWarehouseLocation] = Form.useForm();
    const {Panel} = Collapse;
    const {Text} = Typography;
    const {Option} = Select;
    const [dataSourceOfWarehouseLocationTable,setDataSourceOfWarehouseLocationTable] = useState([]);
    const [loadingOfWarehouseLocationTable,setLoadingOfWarehouseLocationTable] = useState(true);
    const [loadingOfAddWarehouseLocationButton,setLoadingOfAddWarehouseLocationButton] = useState(false);
    const [visibleOfChangeWarehouseLocationModal,setVisibleOfChangeWarehouseLocationModal] = useState(false);
    const [loadingOfChangeWarehouseLocationButton,setLoadingOfChangeWarehouseLocationButton] = useState(false);
    const { confirm } = Modal;
    const [warehouseType,setWarehouseType] = useState("normal type")
    const columnsOfWarehouseLocationTable = [
        {
            title: "库位名称",
            key:"name",
            dataIndex: "name",
            ...getColumnSearchProps("name")
        },
        {
            title: "库位描述",
            key:"description",
            dataIndex: "description",
            ...getColumnSearchProps("description")
        },
        {
            title: "禁用状态",
            key:"disabledName",
            dataIndex: "disabledName",
            ...getColumnSearchProps("disabledName")
        },
        {
            title: "操作",
            render:(text,record,index)=>{
                return (<Space>
                    <Popconfirm title={"确认删除这个项目吗？删除之后入库无法选择这个项目了，此操作不可撤销！"} cancelText={"取消"} okText={"删除"}
                                onConfirm={()=>handleSubmitDeleteWarehouseLocation(record["warehouseLocationId"])}>
                        <Button type={"dashed"} danger>删除</Button>
                    </Popconfirm>
                    <Button type={"primary"} onClick={()=>handleClickChangeWarehouseLocationButton(record["warehouseLocationId"])}>修改</Button>
                </Space>)
            }
        }
    ]
    const onChangeWarehouseLocationType = (e) => {
        setWarehouseType(e.target.value)
    }

    const getAllNotLogicallyDeletedWarehouseLocation = ()=>{
        setLoadingOfWarehouseLocationTable(true);
        post(APIOBJECT["getAllNotLogicallyDeletedWarehouseLocation"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            // console.log(resultAfterHandle["data"]);
            if (resultAfterHandle!==false){
                setDataSourceOfWarehouseLocationTable(resultAfterHandle["data"]);
            }
            setLoadingOfWarehouseLocationTable(false);
        }).catch(error=>{
            message.error("getAllNotLogicallyDeletedWarehouseLocation出错了！"+error);
            setLoadingOfWarehouseLocationTable(false);
        })
    }

    useEffect(() => {
        getAllNotLogicallyDeletedWarehouseLocation();
        return () => {};
    }, []);

    const handleSubmitAddWarehouseLocation = (fieldsValue)=>{
        let warehouseName = "";
        if(warehouseType === "normal type"){
            for(let value of fieldsValue['warehouseNameArray']){
                warehouseName+=value+"-";
            }
            warehouseName = warehouseName.slice(0, warehouseName.length - 1)
        }else{
            warehouseName = fieldsValue["personName"]
        }
        confirm({
            title: '库位名称一览',
            icon: <ExclamationCircleOutlined />,
            content: warehouseName,
            onOk() {
                setLoadingOfAddWarehouseLocationButton(true);
                post(APIOBJECT["handleAddWarehouseLocation"],
                    {...fieldsValue,
                        isNormal:warehouseType === "normal type"
                    })
                    .then(resultBeforeHandle=>{
                    let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                    if (resultAfterHandle!==false){
                        message.success(resultAfterHandle["msg"]);
                        getAllNotLogicallyDeletedWarehouseLocation();
                    }
                    setLoadingOfAddWarehouseLocationButton(false);
                }).catch(error=>{
                    message.error("handleSubmitAddWarehouseLocation出错了！"+error);
                    setLoadingOfAddWarehouseLocationButton(false);
                })
            },
            okText:"确认添加",
            cancelText:"取消添加",
        });
    }

    const handleClickChangeWarehouseLocationButton = (warehouseLocationId)=>{
        let filterElement = dataSourceOfWarehouseLocationTable.filter(item=>item["warehouseLocationId"] === warehouseLocationId)[0];
        let warehouseName = filterElement["name"];
        console.log(filterElement);
        if(judgeWarehouseIsNormal(warehouseName)){
            setWarehouseType("normal type");
            filterElement["warehouseNameArray"] = warehouseName.split("-");
        }else{
            setWarehouseType("person type");
            filterElement["personName"] = warehouseName;
        }
        formOfChangeWarehouseLocation.setFieldsValue(filterElement);
        setVisibleOfChangeWarehouseLocationModal(true);
    }

    const handleSubmitChangeWarehouseLocation = (fieldsValue)=>{
        setLoadingOfChangeWarehouseLocationButton(true);
        post(APIOBJECT["handleChangeWarehouseLocation"]
            ,JSON.stringify({...fieldsValue,isNormal:warehouseType === "normal type"}))
            .then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getAllNotLogicallyDeletedWarehouseLocation();
                setVisibleOfChangeWarehouseLocationModal(false);
                message.success(resultAfterHandle["msg"]);
            }
            setLoadingOfChangeWarehouseLocationButton(false);
        }).catch(error=>{
            message.error("handleSubmitChangeWarehouseLocation出错了！"+error);
            setLoadingOfChangeWarehouseLocationButton(false);
        })
    }

    const handleSubmitDeleteWarehouseLocation = (warehouseLocationId)=>
        post(APIOBJECT["handleDeleteWarehouseLocation"],JSON.stringify({warehouseLocationId:warehouseLocationId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getAllNotLogicallyDeletedWarehouseLocation();
            }
        }).catch(error=>message.error("handleSubmitDeleteWarehouseLocation出错了"+error))

    const layout = {
        labelCol: {
            span: 2,
        },
        wrapperCol: {
            span: 16,
        },
    };

    return (
        <>
            <Modal width={800} title={"修改库位信息"} visible={visibleOfChangeWarehouseLocationModal} onCancel={()=>setVisibleOfChangeWarehouseLocationModal(false)}
                   footer={[<Button key={"cancelButton"} danger onClick={()=>setVisibleOfChangeWarehouseLocationModal(false)} loading={loadingOfChangeWarehouseLocationButton}>取消</Button>,
                       <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeWarehouseLocation.submit()} loading={loadingOfChangeWarehouseLocationButton}>提交申请</Button>]}>
                <Form labelCol={{span:3}} form={formOfChangeWarehouseLocation} onFinish={handleSubmitChangeWarehouseLocation}>
                    <Radio.Group onChange={onChangeWarehouseLocationType} value={warehouseType}>
                        <Radio value={"normal type"}>普通类型</Radio>
                        <Radio value={"person type"}>人员类型</Radio>
                    </Radio.Group>
                    {warehouseType === "normal type" ?
                        <FormList warehouseType = {warehouseType}/>
                        :
                        <Form.Item
                            name={"personName"}
                            label={"人员信息"}
                            rules={[
                                {
                                    validator: async (_,value) => {
                                        if(warehouseType === "person type" && !value ){
                                            return Promise.reject(new Error('请输入人员名字'));
                                        }else if(value.indexOf("-") !== -1){
                                            return Promise.reject(new Error("人员类型的名字不可包含符号'-'"))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>}
                    <Form.Item name={"description"} label={"描述"}>
                        <Input placeholder={"描述可有可无"} type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"disabled"} label={"是否被禁用"} rules={[{required:true,message:"请选择是否禁用！"}]}>
                        <Select style={{width:"100%"}}>
                            <Option key={"0"} value={"0"}>未禁用</Option>
                            <Option key={"1"} value={"1"}>禁用</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"warehouseLocationId"} label={"ID"}>
                        <Input disabled={true} placeholder={"库位ID"} type={"text"}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Collapse defaultActiveKey={["1"]}>
                <Panel key={1} header={"添加库位"}>
                    <Radio.Group onChange={onChangeWarehouseLocationType} value={warehouseType}>
                        <Radio value={"normal type"}>普通类型</Radio>
                        <Radio value={"person type"}>人员类型</Radio>
                    </Radio.Group>
                    <p>请按照层级添加库位信息，每一层的名字会用“-”拼接起来作为最终的呈现名称。</p>
                    <Form {...layout} form={formOfAddWarehouseLocation} onFinish={handleSubmitAddWarehouseLocation}>
                        {warehouseType === "normal type" ?
                            <FormList warehouseType = {warehouseType}/>
                            :
                        <Form.Item
                            name={"personName"}
                            label={"人员信息"}
                            rules={[
                                {
                                    validator: async (_,value) => {
                                        if(warehouseType === "person type" && !value ){
                                            return Promise.reject(new Error('请输入人员名字'));
                                        }else if(value.indexOf("-") !== -1){
                                            return Promise.reject(new Error("人员类型的名字不可包含符号'-'"))
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input/>
                        </Form.Item>}

                        <Form.Item name={"description"} label={"描述"}>
                            <Input placeholder={"描述可有可无"} type={"text"}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"} loading={loadingOfAddWarehouseLocationButton}>提交</Button>
                        </Form.Item>
                    </Form>
                </Panel>
                <Panel key={2} header={"查看、修改、删除库位"}>
                    <Table columns={columnsOfWarehouseLocationTable} dataSource={dataSourceOfWarehouseLocationTable} bordered
                           rowKey={record => record["warehouseLocationId"]} loading={loadingOfWarehouseLocationTable}/>
                </Panel>
            </Collapse>
        </>
    );
}

export default EditWarehouseLocation;