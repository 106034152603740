import React, {useEffect, useState} from 'react';
import {Button, Col, Empty, Form, Input, message, Modal, Row, Space, Tooltip} from "antd";
import {LockOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";



function Index(props) {
    const {property_chinese_name,property_id,delete_function,properties_array,
        setProperties_array,markChangableArray,deleteMarkAbleArray,change_tab_name,moveTab} = props;
    const [form_of_add_properties] = Form.useForm();
    const [form_of_modify_properties] = Form.useForm();
    const [ableToChange,setAbleToChange] = useState(true);
    const [visibleOfModal,setVisibleOfModal] = useState(false);
    const [disable_of_form_of_properties, setDisable_of_form_of_properties] = useState(false);

    const handlesubmit=(result)=>{
        console.log(result);
        const properties_temp_array = properties_array;
        const id_of_property=Object.keys(result)[0];
        const options = result[id_of_property];

        setDisable_of_form_of_properties(true);//锁定表单

        for(let i=0;i<properties_array.length;i++){//仅仅修改options的部分
            if (properties_temp_array[i].id_of_property === Object.keys(result)[0]){
                properties_array[i].options=result[id_of_property];
                //有重复的就直接修改options之后直接return
                return
            }
        }
        //下面是添加新的属性
        let temp_chinese_name;
        for(let i=0;i<property_chinese_name.length;i++){
            if (property_chinese_name[i]!=null) {
                temp_chinese_name = property_chinese_name[i];
            }
        }
        console.log("temp_chinese_name是"+temp_chinese_name);
        console.log(property_chinese_name);
        properties_temp_array.push(getObjectProperty(temp_chinese_name,Object.keys(result)[0],options))
        setProperties_array(properties_temp_array);
    }



    const handleChangePropertyName = (result)=>{
        let tempArray = properties_array;
        for(let index in tempArray){
            if (tempArray[index]["id_of_property"] === result["idOfProperty"]){
                tempArray[index]["chinese_name"] = result["nameOfProperty"];
                break;
            }
        }
        change_tab_name(result["idOfProperty"],result["nameOfProperty"]);
        setProperties_array(tempArray);
        setVisibleOfModal(false);
    }


    useEffect(() => {
        console.log("Dynamic_form初始化了",property_id,properties_array)
        console.log(property_id)
        console.log("markChangableArray是")
        console.log(markChangableArray)
        console.log("markChangableArray是21321321")
        let ableTemp=markChangableArray.filter(item=>item["id_of_property"]===property_id)
        console.log(ableTemp)
        console.log("markChangableArray是4543543")
        if (ableTemp.length===1){
            setAbleToChange(ableTemp[0]["ableToChange"]);
            console.log("设置了！"+ableTemp["ableToChange"])
        }else{
            setAbleToChange(true);
        }

        for (let i=0;i<properties_array.length;i++){
            if (properties_array[i].id_of_property === property_id){
                console.log("进行锁定")
                setDisable_of_form_of_properties(true);//properties_array中有id一致的说明是锁定过的。
                form_of_add_properties.setFieldsValue({[property_id]:properties_array[i].options})
                return
            }
        }
        setDisable_of_form_of_properties(false);//若没有return则表明是新添加的属性

    }, [property_id]);


    const getObjectProperty = (chinese_name,id_of_property,options)=>{
        return{
            chinese_name:chinese_name,
            id_of_property:id_of_property,
            options:options
        }
    }



    if (property_id === null){
        return(<Empty />)
    }else{
        return (
            <div>
                <Modal title={"修改属性名称"} visible={visibleOfModal}
                       onCancel={()=>setVisibleOfModal(false)}
                       cancelText={"取消"}
                       okText={"确认修改"}
                       onOk={()=>form_of_modify_properties.submit()}>

                    <Form form={form_of_modify_properties} onFinish={handleChangePropertyName}>
                        <Form.Item label={"属性id"} name={"idOfProperty"}>
                            <Input type={"text"} disabled={true}/>
                        </Form.Item>
                        <Form.Item label={"属性名称"} name={"nameOfProperty"}
                                   rules={[{required: true, message:"请输入正确的名字",}]}>
                            <Input type={"text"} placeholder={"属性名称"}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"}>修改</Button>
                        </Form.Item>
                    </Form>

                </Modal>
                <Form form={form_of_add_properties}
                      onFinish={(r)=>handlesubmit(r)}
                      disabled={disable_of_form_of_properties}>
                    <Form.List
                        name={property_id}
                        rules={[
                            {
                                validator: async (_, names) => {
                                    if (!names || names.length === 0) {
                                        return Promise.reject(new Error('至少填写一个可选项!'));
                                    }
                                    if (Array.from(new Set(names)).length<names.length){//说明有重复的
                                        return Promise.reject(new Error('有重复的选项！'));
                                    }
                                },
                            },
                        ]}
                        // initialValue={initial_value_for_dynamic_card.length==0?null:initial_value_for_dynamic_card}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={"第"+(index+1)+"个可选值"}
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "请填写该属性的可选项或删除该属性!",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input
                                                placeholder={property_id}
                                                style={{
                                                    width: '60%',
                                                }}
                                            />
                                        </Form.Item>
                                        {disable_of_form_of_properties?
                                            <Tooltip title={"已被锁定，若要修改请先解锁！"} color={"orange"}>
                                                <LockOutlined className="dynamic-delete-button"/>
                                            </Tooltip>
                                            :
                                            <Tooltip title="删除该可选项" color={"red"}>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                           </Tooltip>
                                        }
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{
                                            width: '60%',
                                        }}
                                        icon={<PlusOutlined />}
                                    >
                                        添加可选项
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                </Form>
                <div>
                        {disable_of_form_of_properties?
                            ableToChange? <Button type={"default"} onClick={()=>setDisable_of_form_of_properties(false)}>修改属性</Button>:
                                <Space size={"middle"}>
                                    <Button type={"primary"} danger onClick={()=>{delete_function(property_id);deleteMarkAbleArray(property_id);}}>删除该属性</Button>
                                    <Button type={"default"} danger onClick={()=>{moveTab(property_id,-1)}}>前移一位</Button>
                                    <Button type={"default"} danger onClick={()=>{moveTab(property_id,1)}}>后移一位</Button>
                                </Space>

                            : <Space size={"middle"}>
                                <Button type={"primary"} onClick={()=>form_of_add_properties.submit()}>锁定该属性</Button>
                                <Button type={"primary"} onClick={()=>{
                                    form_of_modify_properties.setFieldsValue({idOfProperty:property_id,nameOfProperty:property_chinese_name.filter(item => item !== null)[0]});
                                    setVisibleOfModal(true)
                                }}>修改属性的名称</Button>
                                <Button type={"default"} danger onClick={()=>{moveTab(property_id,-1)}}>前移一位</Button>
                                <Button type={"default"} danger onClick={()=>{moveTab(property_id,1)}}>后移一位</Button>

                                <Button type={"primary"} danger onClick={()=>{delete_function(property_id);deleteMarkAbleArray(property_id);}}>删除该属性</Button>
                            </Space>}


                </div>
            </div>
        );
    }

}

export default Index;