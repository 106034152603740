import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Collapse, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Typography} from "antd";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {handleResult} from "../../../utlis/response";

function EditProject(props) {

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) :
            (text==="已禁用"?<Text type={"danger"}>已禁用</Text>:text==="进行中"||text==="未禁用"?<Text type={"success"}>{text}</Text>:<Text>{text}</Text>),});
    /**
     * 用于构建可搜索table的固定部分
     */


    const navigate = useNavigate();
    const [formOfAddProject] = Form.useForm();
    const [formOfChangeProject] = Form.useForm();
    const {Panel} = Collapse;
    const {Text} = Typography;
    const {Option} = Select;
    const [dataSourceOfProjectTable,setDataSourceOfProjectTable] = useState([]);
    const statusOfProject = [
        {
            value:"notStarted",
            label:"未开始"
        },
        {
            value: "inProgress",
            label:"进行中"
        },
        {
            value: "inWarranty",
            label:"质保期"
        },
        {
            value: "finished",
            label: "已结束"
        }
    ]
    const [loadingOfProjectTable,setLoadingOfProjectTable] = useState(true);
    const [loadingOfAddProjectButton,setLoadingOfAddProjectButton] = useState(false);
    const [visibleOfChangeProjectModal,setVisibleOfChangeProjectModal] = useState(false);
    const [loadingOfChangeProjectButton,setLoadingOfChangeProjectButton] = useState(false);


    const columnsOfProjectTable = [
        {
            title:"项目编号",
            key:"projectId",
            dataIndex:"projectId",
            ...getColumnSearchProps("projectId")
        },
        {
            title: "项目名称",
            key:"projectName",
            dataIndex: "projectName",
            ...getColumnSearchProps("projectName")

        },
        {
            title: "客户",
            key:"client",
            dataIndex: "client",
            ...getColumnSearchProps("client")
        },
        {
            title: "客户负责人",
            key:"personInChargeOfClients",
            dataIndex: "personInChargeOfClients",
            ...getColumnSearchProps("personInChargeOfClients")
        },
        {
            title: "施工负责人",
            key:"personInChargeOfConstruction",
            dataIndex: "personInChargeOfConstruction",
            ...getColumnSearchProps("personInChargeOfConstruction")
        },
        {
            title: "施工状态",
            key:"statusName",
            dataIndex: "statusName",
            ...getColumnSearchProps("statusName")
        },
        {
            title: "禁用状态",
            key:"disabledName",
            dataIndex: "disabledName",
            ...getColumnSearchProps("disabledName")
        },
        {
            title: "操作",
            render:(text,record,index)=>{
                return (<Space>
                    <Popconfirm title={"确认删除这个项目吗？删除之后出库时就无法选择这个项目了，此操作不可撤销！"} cancelText={"取消"} okText={"删除"}
                                onConfirm={()=>handleSubmitDeleteProject(record["projectId"])}>
                        <Button type={"dashed"} danger>删除</Button>
                    </Popconfirm>
                    <Button type={"primary"} onClick={()=>handleClickChangeButton(record["projectId"])}>修改</Button>
                </Space>)
            }
        }
    ]

    const getAllNotLogicallyDeletedProject = ()=>{
        setLoadingOfProjectTable(true);
        post(APIOBJECT["getAllNotLogicallyDeletedProject"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                console.log(resultAfterHandle);
                setDataSourceOfProjectTable(resultAfterHandle["data"]);
            }
            setLoadingOfProjectTable(false);
        }).catch(error=>{
            message.error("getAllNotLogicallyDeletedProject出错了！"+error);
            setLoadingOfProjectTable(false);
        })
    }
    const handleAddProject = (fieldsValue)=>{
        setLoadingOfAddProjectButton(true);
        post(APIOBJECT["handleAddProject"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getAllNotLogicallyDeletedProject();
                formOfAddProject.resetFields();
            }
            setLoadingOfAddProjectButton(false);
        }).catch(error=>{
            message.error("handleAddProject出错！"+error);
            setLoadingOfAddProjectButton(false);
        })
    }
    const handleClickChangeButton = (projectId)=>{
        let toBeChangedProjectObject = dataSourceOfProjectTable.filter(item=>item["projectId"] === projectId)[0];
        formOfChangeProject.setFieldsValue(toBeChangedProjectObject);
        console.log(toBeChangedProjectObject);
        setVisibleOfChangeProjectModal(true);
    }
    const handleSubmitChangeProject = (fieldsValue)=>{
        console.log(fieldsValue);
        setLoadingOfChangeProjectButton(true);
        post(APIOBJECT["handleChangeProject"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"])
                getAllNotLogicallyDeletedProject();
                setVisibleOfChangeProjectModal(false);
            }
            setLoadingOfChangeProjectButton(false);
        }).catch(error=>{
            message.error("handleSubmitChangeProject出错了！"+error);
            setLoadingOfChangeProjectButton(false);
        })
    }
    const handleSubmitDeleteProject = (projectId)=>
        post(APIOBJECT["handleDeleteProject"],JSON.stringify({projectId:projectId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getAllNotLogicallyDeletedProject();
                message.success(resultAfterHandle["msg"])
            }
        }).catch(error=>message.error("handleSubmitDeleteProject出错了！"+error))
    useEffect(() => {
        getAllNotLogicallyDeletedProject();
        return () => {};
    }, []);


    return (
        <>
            <Modal title={"修改项目"} visible={visibleOfChangeProjectModal} onCancel={()=>setVisibleOfChangeProjectModal(false)}
                   footer={[<Button key={"cancelButton"} danger onClick={()=>setVisibleOfChangeProjectModal(false)} loading={loadingOfChangeProjectButton}>取消</Button>,
                       <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeProject.submit()} loading={loadingOfChangeProjectButton}>提交申请</Button>]}>
                <Form form={formOfChangeProject} onFinish={handleSubmitChangeProject}>
                    <Form.Item name={"projectId"} label={"项目号"} rules={[{required:true,message:"请输入项目号！"}]}>
                        <Input disabled type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"projectName"} label={"项目名称"} rules={[{required:true,message:"请输入项目名称！"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"client"} label={"客户"} rules={[{required:true,message:"请输入客户信息！"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"personInChargeOfConstruction"} label={"施工负责人"} rules={[{required:true,message:"请输入施工负责人"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"personInChargeOfClients"} label={"客户负责人"} rules={[{required:true,message:"客户负责人"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item name={"status"} label={"状态"} rules={[{required:true,message:"请选择项目状态"}]}>
                        <Select showSearch placeholder="请选择状态"
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {statusOfProject.map(item=><Option key={item["value"]} value={item["value"]}>{item["label"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"disabled"} label={"是否被禁用"} rules={[{required:true,message:"请选择是否禁用！"}]}>
                        <Select style={{width:"100%"}}>
                            <Option key={"0"} value={"0"}>未禁用</Option>
                            <Option key={"1"} value={"1"}>禁用</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Collapse defaultActiveKey={["1"]}>
                <Panel key={1} header={"新增项目信息"}>
                    <Form form={formOfAddProject} onFinish={handleAddProject}>
                        <Form.Item name={"projectId"} label={"项目号"} rules={[{required:true,message:"请输入项目号！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"projectName"} label={"项目名称"} rules={[{required:true,message:"请输入项目名称！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"client"} label={"客户"} rules={[{required:true,message:"请输入客户信息！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"personInChargeOfConstruction"} label={"施工负责人"} rules={[{required:true,message:"请输入施工负责人"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"personInChargeOfClients"} label={"客户负责人"} rules={[{required:true,message:"客户负责人"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"status"} label={"状态"} rules={[{required:true,message:"请选择项目状态"}]}>
                            <Select showSearch placeholder="请选择状态"
                                    optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{"width":"100%"}}>
                                {statusOfProject.map(item=><Option key={item["value"]} value={item["value"]}>{item["label"]}</Option> )}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"} loading={loadingOfAddProjectButton}>提交</Button>
                        </Form.Item>
                    </Form>
                </Panel>
                <Panel key={2} header={"查看、修改、删除项目"}>
                    <Table loading={loadingOfProjectTable} dataSource={dataSourceOfProjectTable} columns={columnsOfProjectTable} bordered rowKey={record => record["projectId"]}/>
                </Panel>
            </Collapse>
        </>
    );
}

export default EditProject;