import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Tooltip} from "antd";
import $ from "jquery";
import {getToken} from "../../../../utlis/auth";
import {
    LockOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import Cards_of_Add_Properties from "../../../../components/Cards_of_Add_Entity/Cards_of_Add_Properties"
import "./Add_property.css"
import {APIOBJECT} from "../../../../static/CONSTANTS";
import {useNavigate} from "react-router-dom";
import {get, post} from "../../../../utlis/request";
import {handleResult} from "../../../../utlis/response";
import Highlighter from "react-highlight-words";

function AddProperty(props) {
    const {Option} = Select;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [formOfAddProperties] = Form.useForm();
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const [options_of_select,setOptions_of_select] = useState(null);
    const [data_source_of_table,setData_source_of_table] = useState(null);
    const [loading_of_table,setLoading_of_table] = useState(true);
    const [visible_of_Modal1,setVisible_of_Modal1] = useState(false);
    const [visible_of_Modal2,setVisible_of_Modal2] = useState(false);
    const [properties_as_props_for_card,setProperties_as_props_for_card] = useState([]);
    const [selected_entity,setSelected_entity] = useState(null);
    const [when_add_options_already_have_options,setWhen_add_options_already_have_options]=useState(null);
    const [form_of_add_options] = Form.useForm();
    const [reuse_properties_array,setReuse_properties_array] = useState([]);
    const [markChangableArray,setMarkChangableArray] = useState([]);
    const navigate =useNavigate();
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});





    useEffect(() => {
        post(APIOBJECT["getAllCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setCategoryArrayForSelect(resultAfterHandle["data"]);
            }else{
                message.error("当前大类为空，请先添加大类！");
            }
        }).catch(error=>message.error("出现错误！"+error))
        return () => {
        };
    }, []);



    const load_properties = (value)=>{
        setLoading_of_table(true);
        post(APIOBJECT["getEntityById"],JSON.stringify({"id_of_entity":value})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            let result = resultAfterHandle["data"];
            if (resultAfterHandle!==false){
                setReuse_properties_array(result["reuse_properties"])
                let constraint = result["constraint"]
                let json_of_properties = constraint["properties"];
                let frozen_properties = constraint["frozen_properties"];
                let frozen_keyset = [];
                for (let key in frozen_properties){
                    frozen_keyset.push(key);
                }
                // console.log(frozen_keyset)
                let temp_data_source = [];
                for (let key in json_of_properties){
                    //先判断这个key是否有冻结属性
                    let options = [];

                    if (frozen_keyset.indexOf(key)!==-1){
                        let frozen_temp = frozen_properties[key];
                        let all_option=json_of_properties[key]["options"];
                        for (let index in all_option){
                            let disabled = false;
                            if (frozen_temp.indexOf(all_option[index])!==-1){
                                disabled = true;
                            }

                            options.push(
                                {
                                    label:all_option[index],
                                    value:all_option[index],
                                    disabled:disabled
                                }
                            )



                        }
                        temp_data_source.push(
                            {
                                chinese_name_of_property:json_of_properties[key]["chinese_name"],
                                id_of_property:key,
                                options:options
                            })

                    }else{
                        temp_data_source.push(
                            {
                                chinese_name_of_property:json_of_properties[key]["chinese_name"],
                                id_of_property:key,
                                options:json_of_properties[key]["options"].map((option)=>{
                                    return{
                                        label:option,
                                        value:option,
                                        disabled:false
                                    }
                                })
                            })
                    }

                }
                setData_source_of_table(temp_data_source);
                setLoading_of_table(false);
            }
            setSelected_entity(value)
        }).catch(error=>{
            message.error("出错啦，请联系管理员！"+error)
        })
    }
    const modal2_visible = (record)=>{
        if (record==null){
            message.error("record为空");
            return;
        }
        setWhen_add_options_already_have_options(record);
        setVisible_of_Modal2(true);
    }

    const add_reuse_property = (record)=>{
        //  /api/v1/entity/add_reuseproperty
        let jsonData=JSON.stringify({
            "token":getToken(),
            "options":record["options"].map((item)=>item.label),
            "chinese_name": record["chinese_name_of_property"],
            "id_of_entity":selected_entity,
            "id_of_property":record["id_of_property"]
        })
        post(APIOBJECT["addReuseProperty"],jsonData).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!=false){
                load_properties(selected_entity);
            }
        }).catch(error=>{
            message.error("出错啦，请联系管理员！"+error);
        })

    }


    const remove_reuse_property = (record)=>{
        let jsonData=JSON.stringify({"token":getToken(), "options":record["options"].map((item)=>item.label),
            "id_of_entity":selected_entity, "id_of_property":record["id_of_property"],})
        post(APIOBJECT["removeReuseProperty"],jsonData).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                load_properties(selected_entity);
            }
        }).catch(error=>{
            message.error("出错啦！请联系管理员！"+error);
        })
    }

    const columns = [
        {
            title:"属性名",
            key:"chinese_name_of_property",
            dataIndex:"chinese_name_of_property",
            width:300,
            ...getColumnSearchProps("chinese_name_of_property")
        },
        {
            title: "属性id",
            key:"id_of_property",
            dataIndex: "id_of_property",
            width:300,
            ...getColumnSearchProps("id_of_property")
        },
        {
            title: "所有可选值",
            key:"options",
            dataIndex: "options",
            render:(options,record)=>{
                return(
                    <Select showSearch optionFilterProp="children"
                        placeholder={record.chinese_name_of_property}
                        options={options} filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}/>
                )
            }
        },
        {
            title: "操作",
            width:200,
            render:(txt,record,index)=> {
                for (let index in reuse_properties_array){
                    if (reuse_properties_array[index]["id_of_reuse_property"] === record["id_of_property"]){
                        return(<Space size={"large"}>
                            <Button type={"danger"} size={"middle"} onClick={()=>modal2_visible(record)}>添加可选值</Button>
                            <Popconfirm title={"该属性已绑定至属性库。请注意：解绑后，无法同步添加可选项！"} okText={"解绑属性库"} placement="leftTop"
                                        icon={<QuestionCircleOutlined style={{color: 'red',}}/>} cancelText={"取消"} onConfirm={()=>remove_reuse_property(record)}>
                                <Button type={"dashed"} danger>从属性库解绑</Button>
                        </Popconfirm>
                        </Space>)
                    }
                }
                return(<Space size={"large"}>
                    <Button type={"danger"} size={"middle"} onClick={()=>modal2_visible(record)}>添加可选值</Button>
                    {/*<Tooltip title={"可添加至属性库，添加至属性库后，您可在为已存在的实体添加新属性或添加新实体时直接使用。"} color={"green"}>*/}
                    <Popconfirm title={"添加至属性库后，您可在为已存在的实体添加新属性或添加新实体时直接使用"} okText={"添加至属性库"} placement="leftTop"
                                cancelText={"取消"} onConfirm={()=>add_reuse_property(record)}>
                        <Button type={"dashed"}>添加至属性库</Button>
                    </Popconfirm>
                    {/*</Tooltip>*/}
                </Space>)

            }


        }
    ]
    const handle_submit_add_property = ()=>{
        if (properties_as_props_for_card.length===0){
            message.error("请添加属性后再点击提交！");
            return;
        }


        let list_id_of_properties = [];
        for (let item in data_source_of_table){
            list_id_of_properties.push(data_source_of_table[item]["id_of_property"]);
        }

        for (let index in properties_as_props_for_card){
            if (list_id_of_properties.indexOf(properties_as_props_for_card[index]["id_of_property"])!==-1){//说明有添加了有重复的属性id
                message.error("重复添加了属性！id为"+properties_as_props_for_card[index]["id_of_property"]+"已存在！");
                setProperties_as_props_for_card([]);
                return;
            }
        }
        let jsonData = JSON.stringify({"properties":properties_as_props_for_card, "id_of_entity":selected_entity,});
        post(APIOBJECT["addPropertiesToExistedEntity"],jsonData).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setTimeout(()=>{
                    window.location.reload();
                },400)
            }
        }).catch(error=>message.error("出错啦！请联系管理员！"+error))
    }

    const handle_submit_add_options = (value)=>{
        if (value==null){
            message.error("value为空，请重新添加！");
            return;
        }

        let temp_array = reuse_properties_array.filter((item)=>item["id_of_reuse_property"]===when_add_options_already_have_options["id_of_property"])
        let jsonData = JSON.stringify(
            {"to_be_added_options":value["to_be_added_options"],
                "id_of_property":when_add_options_already_have_options["id_of_property"],
            "id_of_entity":selected_entity,
                "bind":temp_array.length!==0?temp_array: [{result:"false"}]
            });

        post(APIOBJECT["addOptionsToExistedProperty"],jsonData).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setTimeout(()=>{
                    window.location.reload();
                },350)
            }
        }).catch(error=>message.error("出现了异常！请联系管理员！"+error))
    }

    const loadEntitiesByCategorySelect = (categoryId)=>{
        setLoading_of_table(true);
        formOfAddProperties.resetFields(["id_of_entity"])
        form_of_add_options.resetFields();
        console.log(categoryId);

        post(APIOBJECT["getEntitiesByCategoryId"],JSON.stringify({"categoryId":categoryId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let options_array = [];
                let result = resultAfterHandle["data"];
                for (let index in result){
                    options_array.push({
                        label:result[index]["chinese_name"],
                        value:result[index]["id_of_entity"]
                    })
                }
                setOptions_of_select(options_array);
            }
        }).catch(error=>message.error("出错了！"+error))

    }


    return (
        <Card title={"添加属性或可选值"}>
            <Modal title={"添加属性"}
                   visible={visible_of_Modal1}
                   onCancel={()=>setVisible_of_Modal1(false)}
                   onOk={()=>handle_submit_add_property()}
                   okText={"提交修改申请"}
                   cancelText={"取消"}
                   width={"60%"}
                   style={{"height":"60%"}}
            >
                <Cards_of_Add_Properties markChangableArray={markChangableArray} setMarkChangableArray={setMarkChangableArray}
                    properties_array={properties_as_props_for_card} setProperties_array={setProperties_as_props_for_card}/>
            </Modal>

            <Modal title={when_add_options_already_have_options==null?"出错了，请联系管理员！":
                "为id是"+when_add_options_already_have_options["id_of_property"]+"的"+
                when_add_options_already_have_options["chinese_name_of_property"]+"属性添加可选值"}
                   visible={visible_of_Modal2}
                   okText={"提交修改申请"}
                   cancelText={"取消"}
                   onCancel={()=>setVisible_of_Modal2(false)}
                   onOk={()=>form_of_add_options.submit()}
                >
                <Space direction={"vertical"} size={"middle"} style={{"width":"100%"}}>

                        <span>已有的可选值</span>
                        <Select placement={"topLeft"} showSearch optionFilterProp="children"
                            placeholder={when_add_options_already_have_options!=null?
                                when_add_options_already_have_options["chinese_name_of_property"]:""}
                            options={when_add_options_already_have_options!=null?when_add_options_already_have_options["options"]:null}
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                            style={{width:"100%"}}/>


                    <Card title={"添加可选值"}>
                        <Form form={form_of_add_options}
                            onFinish={(value)=>handle_submit_add_options(value)}
                        >
                            <Form.List
                                name={"to_be_added_options"}
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            if (!names || names.length === 0) {
                                                return Promise.reject(new Error('至少填写一个可选项!'));
                                            }
                                            if (Array.from(new Set(names)).length<names.length){//说明有重复的
                                                return Promise.reject(new Error('有重复的选项！'));
                                            }
                                            for (let key in names){
                                                let temp = when_add_options_already_have_options["options"].filter((option)=>{
                                                    return names[key]===option.value;
                                                })
                                                if (temp.length!==0){
                                                    return Promise.reject(new Error('与存在的属性：'+names[key]+"重合！"));
                                                }
{}                                            }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={"第"+(index+1)+"个属性"}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "请填写该属性的可选项或删除该属性!",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input
                                                        // placeholder={property_id}
                                                        style={{
                                                            width: '60%',
                                                        }}
                                                    />
                                                </Form.Item>
                                                    <Tooltip title="删除该可选项" color={"red"}>
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    </Tooltip>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{
                                                    width: '60%',
                                                }}
                                                icon={<PlusOutlined />}
                                            >
                                                添加可选项
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form>
                    </Card>
                </Space>
            </Modal>


            <Form form={formOfAddProperties}>
                <Space
                    direction={"vertical"}
                    size={"middle"}
                    style={{"width":"100%"}}
                >
               <Form.Item name={"categoryId"} rules={[{required: true, message:"请选择大类",}]} label={"第一步：选择大类"}>
                   <Select
                       showSearch
                       placeholder="请选择一个大类"
                       optionFilterProp="children"
                       filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                       onSelect={(value)=>loadEntitiesByCategorySelect(value)}
                   >
                       {categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> )}
                   </Select>
               </Form.Item>


                <Form.Item
                    name={"id_of_entity"}
                    label={"第二步：选择已有的实体"}
                    rules={[{required: true, message:"请选择大类",}]}
                >
                    <Select
                        showSearch
                        placeholder="请选择实体"
                        optionFilterProp="children"
                        options={options_of_select}
                        onSelect={(value)=>load_properties(value)}
                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                    />
                </Form.Item>
                <Card title={"已有属性和可选值情况"} type={"inner"} loading={loading_of_table}
                      extra={<Button type={"primary"} onClick={()=>{
                                if(data_source_of_table==null){
                                    message.error("请先选择实体！");
                                    return;
                                }
                                setVisible_of_Modal1(true);
                      }}>添加属性</Button>}
                >
                    <Table columns={columns} dataSource={data_source_of_table} rowKey={record => record.id_of_property}/>
                </Card>

                </Space>
            </Form>
        </Card>
    );
}

export default AddProperty;
