import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Button, Card, Collapse, Divider, Form,
    Input, message, Modal, Popconfirm, Select, Space, Table, Tooltip, Typography
} from "antd";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import QueryWarehouse from "./QueryWarehouse";
import {ExclamationCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {getUsername} from "../../../utlis/auth";
import {convertArrayResult, findOptionPath, judgeIfNum} from "../../../utlis/UniversalFunctions";
import Decimal from "decimal.js";
import WarehouseLocationTable from "../../../components/Query/WarehouseLocationTable";
import CascaderSelect from "../../../components/Edit/Warehouse/CascaderSelect";


function CheckWarehouse(props) {

    /**
     * 盘库的逻辑：
     * 盘库分为两种盘库模式，一种是单独盘库，另一种是整体盘库
     *
     * 1.单独盘库应用的场景，比如记录中显示商品A在库位L1有10个，但是经过库房管理人员盘点发现，库位L1只有8个，这个时候
     * 库管就可以提交单独盘库，其本质就是修改库存。单独盘库步骤，提交存在的bianhao以及所在的库位以及数量岛服务器，
     * 服务器分为两种情况进行修改，若这个bianhao在库位上没有，那么系统就会新增bianhao和库位L1记录到仓库中。若有，则会
     * 修改数量。
     * 注意单独盘库每次只能提交一条修改记录，且没有暂存表，因为应用场景不同。
     *
     * 2.整体盘库支持多人同时盘库，盘库的暂存记录提交到temporaryTable中，当temporary中所有inProgress的盘库记录就是表示在多人协同盘库。当且仅当
     * check_warehouse没有待定的盘库记录时，才能开启新的整体盘库流程。
     *
     *
     * 当第一个提交盘库申请的用户（即组织者）提交汇总请求时，系统才会汇总，每个正在整体盘库的用户收到盘库暂存表是所有正在盘库的用户提交到暂存表
     * 的盘库记录。
     *
     * 当正在整体盘库的用户提交盘库记录到暂存表时，
     * （1）系统会根据他提交的bianhao和库位，寻找在暂存表中是否有bianhao和库位一致的，若有一致的，则会提醒用户是否重复盘点了（主要是前端根据前端接收到的数组来提醒）。
     * （2）系统会检测temporary表是否有状态为summary的盘库暂存记录（有就说明盘库记录汇总了但是还没有决定是否被接受），如果有，则系统不接受新的盘库记录到暂存表，
     * 只有在决定完是否采纳之后，所有summary状态才会变成finished，这个时候系统会接受新的盘库记录。
     *
     * 由第一个人提交汇总后，所有用户的盘库进程都结束，汇总至check_warehouse表，随后所有拥有
     * 盘库权限的人都可以去check_warehouse表查看已经盘库的数据，刚盘库完
     *
     */

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */


    const navigate = useNavigate();
    const { Panel } = Collapse;
    const { confirm } = Modal;
    const {Paragraph,Text} = Typography;
    const [formOfLonelyCheck] = Form.useForm();
    const [formOfWholeCheckSubmit] = Form.useForm();
    const [formOfChangeWholeCheckRecord] = Form.useForm();
    const {Option} = Select;
    const [allWarehouseLocation,setAllWarehouseLocation] = useState([]);
    const [visibleOfQuaeryWarehouse,setVisibleOfQuaeryWarehouse] = useState(false);
    const [loadingOfSubmitLonelyCheckRecordButton,setLoadingOfSubmitLonelyCheckRecordButton] = useState(false);
    const [loadingOfSubmitWholeCheckRecordToTemporaryTable,setLoadingOfSubmitWholeCheckRecordToTemporaryTable] = useState(false);
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [loadingOfApplySummaryButton,setLoadingOfApplySummaryButton] = useState(false);
    const [loadingOfSummaryWholeRecordTable,setLoadingOfSummaryWholeRecordTable] = useState(true);
    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [visibleOfChangeWholeCheckRecordInTemporaryTableModal,setVisibleOfChangeWholeCheckRecordInTemporaryTableModal] = useState(false);
    const [submittedWholeCheckArray,setSubmittedWholeCheckArray] = useState([]);
    const [dataForSummaryWholeRecord,setDataForSummaryWholeRecord] = useState({});
    const [visibleOfLocationModal,setVisibleOfLocationModal] = useState(false);
    const [loadingOfLocationButton,setLoadingOfLocationButton] = useState(false);
    const [toBeCheckedBianhao,setToBeCheckedBianhao] = useState("");
    const [allOptions,setAllOptions] = useState([]);
    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            console.log("不相同！")
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisibleOfPropertiesModal(true);
    }
    const getAllWarehouseLocation = ()=>{
        post(APIOBJECT["getAllWarehouseLocation"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setAllWarehouseLocation(resultAfterHandle["data"]);
            }
        }).catch(error=>message.error("getAllWarehouseLocation出错了！"+error))
    }
    const handleSubmitLonelyCheck = (fieldsValue)=>{
        setLoadingOfSubmitLonelyCheckRecordButton(true);
        post(APIOBJECT["handleLonelySubmitCheckRecord"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setTimeout(()=>window.location.href = window.location.href,1000);
            }
            setLoadingOfSubmitLonelyCheckRecordButton(false);
        }).catch(error=>{
            message.error("handleSubmitLonelyCheck出错了！"+error);
            setLoadingOfSubmitLonelyCheckRecordButton(false);
        })
    }
    const handleSubmitWholeCheckToTemporaryTable = (fieldsValue)=>{
        setLoadingOfSubmitWholeCheckRecordToTemporaryTable(true);
        let warehouseLocation = convertArrayResult(fieldsValue["warehouseLocation"]);
        let {bianhao} = fieldsValue;
        fieldsValue["warehouseLocation"] = warehouseLocation;
        let filterArray = submittedWholeCheckArray.filter(item=>item["bianhao"]===bianhao&&item["warehouseLocationId"]===warehouseLocation);
        if (filterArray.length!==0){
            confirm({
                title: '系统检测到您可能重复盘点了！',
                icon: <ExclamationCircleOutlined />,
                content: "库位"+filterArray[0]["warehouseLocationName"]+"已盘点实体"+filterArray[0]["bianhao"]+"，数量为"+filterArray[0]["quantity"],
                onOk() {
                    return post(APIOBJECT["handleSubmitWholeCheckToTemporaryTable"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
                        let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                        if(resultAfterHandle!==false){
                            getSubmittedWholeCheckInTemporaryTable();
                            formOfWholeCheckSubmit.resetFields();
                            message.success(resultAfterHandle["msg"]);
                        }
                        setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
                    }).catch(error=>{
                        message.error("handleSubmitWholeCheckToTemporaryTable出错了！"+error);
                        setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
                    })
                },
                onCancel(){
                    setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
                    setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
                }
            });
        }else{
            post(APIOBJECT["handleSubmitWholeCheckToTemporaryTable"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if(resultAfterHandle!==false){
                    getSubmittedWholeCheckInTemporaryTable();
                    formOfWholeCheckSubmit.resetFields();
                    message.success(resultAfterHandle["msg"]);
                }
                setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
            }).catch(error=>{
                message.error("handleSubmitWholeCheckToTemporaryTable出错了！"+error);
                setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
            })
        }
    }
    const getSubmittedWholeCheckInTemporaryTable = ()=>{
        setLoadingOfSubmitWholeCheckRecordToTemporaryTable(true);
        post(APIOBJECT["getSubmittedWholeCheckRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setSubmittedWholeCheckArray(resultAfterHandle["data"])
            }
            setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
        }).catch(error=>{
            message.error("getSubmittedWholeCheckInTemporaryTable出错了！"+error);
            setLoadingOfSubmitWholeCheckRecordToTemporaryTable(false);
        })
    }
    const handleClickChangeButton = (recordCreatedTime,operator)=>{
        let formData = submittedWholeCheckArray.filter(item=>item["recordCreatedTime"]===recordCreatedTime&&item["operator"]===operator)[0];
        formData["warehouseLocation"] = findOptionPath(allOptions,formData["warehouseLocation"])
        formOfChangeWholeCheckRecord.setFieldsValue(formData);
        setVisibleOfChangeWholeCheckRecordInTemporaryTableModal(true);
    }
    const postChangeWholeCheckRecordRequest = (fieldsValue)=>
        post(APIOBJECT["handleChangeWholeCheckRecordInTemporaryTable"],
            JSON.stringify({...fieldsValue,warehouseLocation:convertArrayResult(fieldsValue["warehouseLocation"])}))
            .then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getSubmittedWholeCheckInTemporaryTable();
                setVisibleOfChangeWholeCheckRecordInTemporaryTableModal(false);
            }
        }).catch(error=>message.error("postChangeWholeCheckRecordRequest出错了！"+error))
    const handleDeleteWholeCheckRecordInTemporaryTable = (operator,recordCreatedTime)=>
        post(APIOBJECT["handleDeleteWholeCheckRecordInTemporaryTable"],JSON.stringify({operator:operator,recordCreatedTime:recordCreatedTime})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getSubmittedWholeCheckInTemporaryTable();
            }
        }).catch(error=>message.error("handleDeleteWholeCheckRecordInTemporaryTable出错了！"+error))
    const refuseSummaryWholeCheckRecord =()=>
        post(APIOBJECT["refuseSummaryWholeCheckRecord"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getSubmittedWholeCheckInTemporaryTable();
                getSummaryWholeRecord();
                message.success(resultAfterHandle["msg"])
            }
        }).catch(error=>message.error("refuseSummaryWholeCheckRecord出错了！"+error))

    const acceptSummaryWholeCheckRecord = (type)=>{
        if (dataForSummaryWholeRecord["missSituation"].length >= 4 || dataForSummaryWholeRecord["extraSituation"].length >= 3){
            confirm({
                title: '系统检测到漏掉的编号或多出的编号过多，您确认提交此请求吗？',
                icon: <ExclamationCircleOutlined />,
                content: '若漏掉的编号过多，可能给存在选错库位或者将覆盖盘库理解为追加盘库的情况，请核实后再进行提交！若本次盘库情况符合实际，您可以进行提交！',
                onOk() {
                    post(APIOBJECT["acceptSummaryWholeCheckRecord"],JSON.stringify({type:type})).then(resultBeforeHandle=>{
                        let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                        if (resultAfterHandle!==false){
                            message.success(resultAfterHandle["msg"]);
                            //重新刷新一下，因为库存详情改变了
                            setTimeout(()=>{
                                window.location.href = window.location.href
                            },800)
                        }
                    }).catch(error=>message.error("acceptSummaryWholeCheckRecord出错了！"+error))
                },
            });
        }else{
            post(APIOBJECT["acceptSummaryWholeCheckRecord"],JSON.stringify({type:type})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    message.success(resultAfterHandle["msg"]);
                    //重新刷新一下，因为库存详情改变了
                    setTimeout(()=>{
                        window.location.href = window.location.href
                    },800)
                }
            }).catch(error=>message.error("acceptSummaryWholeCheckRecord出错了！"+error))
        }

    }



    const getSummaryWholeRecord = ()=>{
        setLoadingOfSummaryWholeRecordTable(true);
        post(APIOBJECT["getSummaryWholeCheckRecord"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                console.log(resultAfterHandle);
                if (resultAfterHandle["data"]!==null&&resultAfterHandle!==undefined){
                    setDataForSummaryWholeRecord(resultAfterHandle["data"]);
                }else {
                    setDataForSummaryWholeRecord({});
                }
            }
            setLoadingOfSummaryWholeRecordTable(false)
        }).catch(error=>{
            message.error("getSummaryWholeRecord出错了！"+error);
            setLoadingOfSummaryWholeRecordTable(false);
        })
    }
    const backBeforeSummary = ()=>
        post(APIOBJECT["backSummaryWholeCheckRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getSummaryWholeRecord();
                getSubmittedWholeCheckInTemporaryTable();
            }
        }).catch(error=>{
            message.error("backBeforeSummary出错了！"+error);
        })

    const handleChangeWholeCheckRecordInTemporaryTable = (fieldsValue)=>{
        let username = getUsername();
        if (username===null||username===undefined){
            message.error("获取不到用户名，请重新登录！若频繁出现此错误，请联系管理员！");
        }else{
            if (fieldsValue["operator"]!==username){
                message.error("只能修改本人的盘库记录！");
            }else{
                //判断一下修改后的bianhao和库位组合是否在已提交的记录中有
                let filterArray = submittedWholeCheckArray.filter(item=>
                    item["bianhao"]===fieldsValue["bianhao"]&&item["warehouseLocationId"]===fieldsValue["warehouseLocation"]
                &&item["recordCreatedTime"]!==fieldsValue["recordCreatedTime"]);

                if (filterArray.length!==0){//说明是有已经提交的
                    confirm({
                        title: '系统检测到您可能重复盘点了！',
                        icon: <ExclamationCircleOutlined />,
                        content: "库位"+filterArray[0]["warehouseLocationName"]+"已盘点实体"+filterArray[0]["bianhao"]+"，数量为"+filterArray[0]["quantity"],
                        onOk() {
                            return postChangeWholeCheckRecordRequest(fieldsValue);
                        },
                    });
                }else{
                    postChangeWholeCheckRecordRequest(fieldsValue);
                }
            }
        }
    }
    const returnAcceptAndNotRefuseButton = ()=>{
        if (dataForSummaryWholeRecord===undefined||dataForSummaryWholeRecord["summaryData"] === null||dataForSummaryWholeRecord["summaryData"] === undefined){
            return "";
        }else{
            return (
                <Space size={"large"}>
                    <Popconfirm placement="topLeft" title={"是否覆盖?覆盖之后的主库库存就是本次盘库的数据！此操作不可撤销！只有组织者可以操作。"}
                                okText={"确认覆盖"} cancelText={"取消"} onConfirm={()=>acceptSummaryWholeCheckRecord("partiallyCover")}>
                        <Button type={"dashed"} danger>部分覆盖主库</Button>
                    </Popconfirm>
                    {/*<Popconfirm placement="topLeft" title={"是否追加?追加之后的主库会保留并且追加上本次的盘库记录，并不会覆盖，此操作不可撤销。"}*/}
                    {/*            okText={"确认追加"} cancelText={"取消"} onConfirm={()=>acceptSummaryWholeCheckRecord("append")}>*/}
                    {/*    <Button type={"default"} danger>追加主库</Button>*/}
                    {/*</Popconfirm>*/}
                    <Popconfirm title={"拒绝之后，本次汇总数据不会覆盖主库数据，仅供参考，且可以开始新的盘库流程。只有组织者可以操作。"} okText={"确认拒绝"} cancelText={"取消"}
                        onConfirm={refuseSummaryWholeCheckRecord}>
                        <Button type={"primary"}>拒绝覆盖</Button>
                    </Popconfirm>
                    <Popconfirm title={"回溯之后，回到点击汇总按钮的上一步，您可以继续修改盘库记录。"} okText={"确认回溯"} cancelText={"取消"}
                                onConfirm={backBeforeSummary}>
                        <Button type={"dashed"}>回溯</Button>
                    </Popconfirm>
                </Space>
            )
        }
    }

    const columnsForTemporaryTable = [
        {
            title: "操作者",
            key:"operator",
            dataIndex: "operator",
            width:150,
            ellipsis: true,
            ...getColumnSearchProps("operator")
        },
        {
            title: "提交时间",
            key:"recordCreatedTime",
            dataIndex: "recordCreatedTime",
            width:180,
            ellipsis: true,
            ...getColumnSearchProps("recordCreatedTime")
        },
        {
            title:"实体名称",
            key:"entityName",
            dataIndex:"entityName",
            width:150,
            ellipsis: true,
            ...getColumnSearchProps("entityName")
        },
        {
            title:"编号",
            key:"bianhao",
            dataIndex:"bianhao",
            width:100,
            ellipsis: true,
            ...getColumnSearchProps("bianhao")
        },
        {
            title:"属性",
            key:"propertiesString",
            dataIndex:"propertiesString",
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "数量",
            key:"quantity",
            dataIndex: "quantity",
            width:90,
            ellipsis: true,
            ...getColumnSearchProps("quantity")
        },
        {
            title: "单位",
            key:"unit",
            dataIndex: "unit",
            width:90,
            ellipsis: true,
            ...getColumnSearchProps("unit")
        },
        {
            title: "库位名称",
            key:"warehouseLocationName",
            dataIndex: "warehouseLocationName",
            ...getColumnSearchProps("warehouseLocationName")
        },
        {
            title: "操作",
            width:220,
            fixed:'right',
            render:(text,record,index)=>{
                return (<Space>
                    <Button size={"small"} type={"primary"} onClick={()=>handleClickChangeButton(record["recordCreatedTime"],record["operator"])}>修改</Button>
                    <Popconfirm title={"确认删除该条暂存记录？"} okText={"删除"} cancelText={"取消"}
                                onConfirm={()=>handleDeleteWholeCheckRecordInTemporaryTable(record["operator"],record["recordCreatedTime"])}
                    ><Button size={"small"} danger>删除</Button></Popconfirm>
                    <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])} loading={loadingOfCheckBianhaoPropertiesButton}>实体详情</Button>
                </Space>)
            }
        }
    ]
    const columnsForCheckWarehouse = [
        {
            title:"实体编号",
            key:"bianhao",
            width:100,
            ellipsis: true,
            dataIndex:"bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title:"实体名称",
            key:"entityName",
            width:300,
            ellipsis: true,
            dataIndex:"entityName",
            ...getColumnSearchProps("entityName")
        },
        {
            title:"具体型号",
            key:"propertiesString",
            ellipsis: true,
            dataIndex:"propertiesString",
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "实际数量",
            key:"formerQuantity",
            width:200,
            ellipsis: true,
            dataIndex: "formerQuantity",
            render:(text,record,index)=>{
                if (text === "-1"){//说明这个库位上没有这个实体
                    return <Text type={"danger"}>无</Text>
                }
                let quantity = new Decimal(record["quantity"]);
                let realQuantity = new Decimal(text);
                if (quantity.comparedTo(realQuantity) !== 0){
                    return <Text type={"warning"}>{text+record["unit"]}</Text>
                }else{
                    return <Text type={"success"}>{text+record["unit"]}</Text>
                }
            }
        },
        {
            title: "盘点数量",
            key:"quantity",
            width:200,
            ellipsis: true,
            dataIndex: "quantity",
            render:(text,record,index)=>{
                return <Text>{text+record["unit"]}</Text>
            }
        },
        {
            title: "库位名称",
            key:"locationName",
            width:200,
            ellipsis: true,
            dataIndex: "locationName",
            ...getColumnSearchProps("locationName")
        },
        {
            title: "操作",
            width:200,
            render:(text,record,index)=>{
                return (<Space>
                    <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])} loading={loadingOfCheckBianhaoPropertiesButton}>实体详情</Button>
                </Space>)
            }
        }
    ]
    const doSummaryWholeRecord = ()=>{
        setLoadingOfApplySummaryButton(true);
        post(APIOBJECT["handleSummaryWholeCheckRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getSubmittedWholeCheckInTemporaryTable();
                getSummaryWholeRecord();
            }
            setLoadingOfApplySummaryButton(false);
        }).catch(error=>{
            message.error("applySummaryWholeRecord出错了！"+error);
            setLoadingOfApplySummaryButton(false);
        })
    }

    const columnsForMissingBianhao = [
        {
            title:"实体编号",
            key:"bianhao",
            width:80,
            ellipsis: true,
            dataIndex:"bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title:"实体名称",
            key:"name",
            width:150,
            dataIndex:"name",
            ...getColumnSearchProps("name")
        },
        {
            title:"具体属性",
            key:"propertiesString",
            width:200,
            dataIndex:"propertiesString",
            ...getColumnSearchProps("propertiesString")
        },
        {
            title:"漏掉的数量",
            key:"missedQuantity",
            width:100,
            dataIndex:"missedQuantity",
            ...getColumnSearchProps("missedQuantity")
        },
        {
            title: "操作",
            width: 100,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                        <Button type={"default"} size={"small"} onClick={()=>{
                            setLoadingOfLocationButton(true);
                            if(toBeCheckedBianhao!==record["bianhao"]){
                                setToBeCheckedBianhao(record["bianhao"]);
                            }
                            setVisibleOfLocationModal(true);
                        }} >库位详情</Button>
                    </Space>)
            }
        }
    ]

    const columnsForExtraBianhao = [
        {
            title:"实体编号",
            key:"bianhao",
            width:80,
            ellipsis: true,
            dataIndex:"bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title:"实体名称",
            key:"name",
            width:150,
            dataIndex:"name",
            ...getColumnSearchProps("name")
        },
        {
            title:"具体属性",
            key:"propertiesString",
            width:200,
            dataIndex:"propertiesString",
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "操作",
            width: 100,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                        <Button type={"default"} size={"small"} onClick={()=>{
                            setLoadingOfLocationButton(true);
                            if(toBeCheckedBianhao!==record["bianhao"]){
                                setToBeCheckedBianhao(record["bianhao"]);
                            }
                            setVisibleOfLocationModal(true);
                        }} >库位详情</Button>
                    </Space>)
            }
        }
    ]

    const CustomSplitedText = ({ text, delimiter }) => {
        let uniqueArray = [...new Set(text.split(delimiter))];
        return (
            <>
                {uniqueArray.map((item, index, array) => (
                    <span key={index}>
        <Text strong style={{ color: "green" }}>
          {item}
        </Text>
                        {index < array.length - 1 && <Text>{delimiter}</Text>}
      </span>
                ))}
            </>
        );
    }


    useEffect(() => {
        getAllWarehouseLocation();
        getSubmittedWholeCheckInTemporaryTable();
        getSummaryWholeRecord();
        return () => {};
    }, []);

    return (
        <>
        <Modal width={1100} title={"已有库存情况"} visible={visibleOfQuaeryWarehouse} cancelText={"好的"} okText={"明白了"}
               onOk={()=>setVisibleOfQuaeryWarehouse(false)} onCancel={()=>setVisibleOfQuaeryWarehouse(false)}>
            <QueryWarehouse/>
        </Modal>
        <Modal width={1000} title={"属性详情"} visible={visibleOfPropertiesModal} okText={"明白了"} cancelText={"关闭界面"}
               onCancel={()=>setVisibleOfPropertiesModal(false)} onOk={()=>setVisibleOfPropertiesModal(false)}>
            <QueryEntityDetailsTable bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
        </Modal>
        <Modal title={"修改盘库记录时只能修改自己添加的记录"} visible={visibleOfChangeWholeCheckRecordInTemporaryTableModal} okText={"修改"} cancelText={"取消"}
            onOk={()=>formOfChangeWholeCheckRecord.submit()} onCancel={()=>setVisibleOfChangeWholeCheckRecordInTemporaryTableModal(false)}>
            <Form form={formOfChangeWholeCheckRecord} onFinish={handleChangeWholeCheckRecordInTemporaryTable}>
                <Form.Item name={"recordCreatedTime"} label={"记录提交时间"} rules={[{required:true,message:"记录提交时间不能为空！"}]}>
                    <Input disabled={true} type={"text"}/>
                </Form.Item>
                <Form.Item name={"operator"} label={"操作员"} rules={[{required:true,message:"操作员不能为空！"}]}>
                    <Input disabled={true} type={"text"}/>
                </Form.Item>
                <Form.Item name={"bianhao"} label={"编号"} rules={[{required:true,message:"编号不能为空！"}]}>
                    <Input type={"text"}/>
                </Form.Item>
                <CascaderSelect labelName={"库位"} warehouseItemArray={allWarehouseLocation} itemName={"warehouseLocation"}/>
                <Form.Item label={"数量"} name={"quantity"}
                           rules={[{required:true,message:"请输入数量"},
                               ({getFieldValue})=>({
                                   validator(_,value){
                                       if (!judgeIfNum(value)){
                                           return Promise.reject(new Error("数量必须为正数！小数位必须补全"));
                                       } else{
                                           return Promise.resolve();
                                       }
                                   }
                               })
                           ]}>
                    <Input type={"number"}/>
                </Form.Item>
            </Form>
        </Modal>
        <Modal width={900} title={"各个库位的情况"} visible={visibleOfLocationModal} onOk={()=>setVisibleOfLocationModal(false)}
               onCancel={()=>setVisibleOfLocationModal(false)} okText={"好的"} cancelText={"取消"}>
            <WarehouseLocationTable bianhao={toBeCheckedBianhao} setLoading={setLoadingOfLocationButton}/>
        </Modal>
        <Collapse defaultActiveKey={["1"]}>
            <Panel key={1} header={"整体盘库"}>
                <Card type={"inner"} title={"提交整体盘库记录"} extra={<Button type={"primary"} onClick={()=>checkPropertiesOfBianhao(formOfWholeCheckSubmit.getFieldValue("bianhao"))}>查看编号属性及单位</Button>}>
                    <Form form={formOfWholeCheckSubmit} onFinish={handleSubmitWholeCheckToTemporaryTable}>
                        <Form.Item label={"编号"} name={"bianhao"} rules={[{required:true,message:"请输入编号"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <CascaderSelect labelName={"库位"} warehouseItemArray={allWarehouseLocation} itemName={"warehouseLocation"} setOptionsForProp={setAllOptions}/>
                        {/*<Form.Item label={"库位"} name={"warehouseLocation"} rules={[{required:true,message:"请选择库位！"}]}>*/}
                        {/*    <Select showSearch placeholder="请选择库位"*/}
                        {/*            optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}*/}
                        {/*            style={{"width":"100%"}}>*/}
                        {/*        {allWarehouseLocation.map(item=><Option disabled={item["disabled"] !== "0"} key={item["locationId"]} value={item["locationId"]}>{item["locationName"]}</Option> )}*/}
                        {/*    </Select>*/}
                        {/*</Form.Item>*/}
                        <Form.Item label={"数量"} name={"quantity"}
                                   rules={[{required:true,message:"请输入数量"},
                                       ({getFieldValue})=>({
                                           validator(_,value){
                                               if (!judgeIfNum(value)){
                                                   return Promise.reject(new Error("数量必须为正数！小数部分补全。"));
                                               } else{
                                                   return Promise.resolve();
                                               }
                                           }
                                       })
                                   ]}>
                            <Input type={"number"}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"} htmlType={"submit"} loading={loadingOfSubmitWholeCheckRecordToTemporaryTable}>提交</Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Divider/>
                <Card type={"inner"} title={"本次整体盘库流程中已提交的记录，包括其他人提交的"}
                      extra={<Tooltip color={"orange"} title={"汇总后，所有用户提交的整体盘库数据都会汇总到一块并生成汇总表，您可以在下一个slide中查看。注意！汇总之后，" +
                      "只有处理完汇总完（即选择接受或不接受）的整体盘库数据才能开启下一次的整体盘库流程！"}><Button type={"primary"} loading={loadingOfApplySummaryButton} onClick={doSummaryWholeRecord}>汇总</Button></Tooltip>}>
                    <Table loading={loadingOfSubmitWholeCheckRecordToTemporaryTable} columns={columnsForTemporaryTable} scroll={{x: 1300}}
                           bordered rowKey={record => record["operator"]+record["recordCreatedTime"]} dataSource={submittedWholeCheckArray}/>
                </Card>
            </Panel>
            <Panel key={3} header={"已提交但并未决定是否接受的盘库记录"}>
                {dataForSummaryWholeRecord===undefined||dataForSummaryWholeRecord["summaryData"] === null||dataForSummaryWholeRecord["summaryData"] === undefined?
                <Text>暂时没有已提交且待定的整体盘库记录</Text>:
                    <>
                        <Paragraph>汇总时间：<Text type={"success"}>{dataForSummaryWholeRecord["summaryCreatedTime"]}</Text></Paragraph>
                        <Paragraph><Text>操作人员（第一位就是组织者）：<Text type={"success"}>{dataForSummaryWholeRecord["operators"]}</Text></Text></Paragraph>
                        <Paragraph>
                            <Text>涉及的库位有：
                                <Text>
                                    {dataForSummaryWholeRecord["summaryData"] === null||dataForSummaryWholeRecord["summaryData"] === undefined?"暂无"
                                    :(<CustomSplitedText text={dataForSummaryWholeRecord["summaryData"].map((item) => item["locationName"]).join("  ||  ")} delimiter={"  ||  "}/>)}
                                </Text>
                            </Text>
                        </Paragraph>
                        <Paragraph>实体编号数量情况：</Paragraph>

                            {dataForSummaryWholeRecord["ifAccordWithExistedBianhao"]==="符合"?<Paragraph><Text type={"success"}>整体盘库中的实体种类数量与在库的实体种类数量一致</Text></Paragraph>
                            : dataForSummaryWholeRecord["ifAccordWithExistedBianhao"].indexOf("多了")!==-1&&dataForSummaryWholeRecord["ifAccordWithExistedBianhao"].indexOf("漏了")!==-1?
                                <>
                                    <Collapse defaultActiveKey={['1','2']}>
                                        <Panel key={"1"} header={"漏了以下编号，共"+dataForSummaryWholeRecord["missSituation"].length+"个"}>
                                            <Table dataSource={dataForSummaryWholeRecord["missSituation"]} columns={columnsForMissingBianhao} scroll={{x: 1300}} rowKey={record => record["bianhao"]}/>
                                        </Panel>
                                        <Panel key={"2"} header={"多了以下编号，共"+dataForSummaryWholeRecord["extraSituation"].length+"个"}>
                                            <Table dataSource={dataForSummaryWholeRecord["extraSituation"]} columns={columnsForExtraBianhao} scroll={{x: 1300}} rowKey={record => record["bianhao"]}/>
                                        </Panel>
                                    </Collapse>
                                </>:
                                <Collapse>
                                    <Panel key={"1"} header={"漏了以下编号"}>
                                        <Text type={"danger"}>{dataForSummaryWholeRecord["ifAccordWithExistedBianhao"]}</Text>
                                    </Panel>

                                </Collapse>
                                }

                    </>
                }
                <Table dataSource={dataForSummaryWholeRecord===undefined||dataForSummaryWholeRecord["summaryData"] === null||dataForSummaryWholeRecord["summaryData"] === undefined?[]:dataForSummaryWholeRecord["summaryData"]}
                       bordered columns={columnsForCheckWarehouse} rowKey={record => record["bianhao"]+record["locationId"]} loading={loadingOfSummaryWholeRecordTable}/>
                {returnAcceptAndNotRefuseButton()}
            </Panel>
        </Collapse>
        </>
    );
}

export default CheckWarehouse;