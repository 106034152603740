import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Input, message, Select, Space} from "antd";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {useNavigate} from "react-router-dom";
const {Option} = Select;


function AddUser(props) {
    const navigate = useNavigate();
    const [usernameExisted,setUsernameExisted] = useState([]);
    const [roleExisted,setRoleExisted] = useState([]);
    const [formOfAddUser] = Form.useForm();
    const [loadingOfCard,setLoadingOfCard] = useState(false);

    useEffect(() => {
        post(APIOBJECT["getAllUsername"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setUsernameExisted(resultAfterHandle["data"]);
            }
        }).catch(error=>console.log("出错啦！"+error))

        post(APIOBJECT["getAllRole"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let roleArray = resultAfterHandle["data"];
                let tempArray = [];
                for (let index in roleArray){
                    tempArray.push(<Option key={roleArray[index]["idOfRole"]}>{roleArray[index]["nameOfRole"]}</Option>)
                    // tempArray.push({label:roleArray[index]["nameOfRole"],key:roleArray[index]["idOfRole"]})
                }
                setRoleExisted(tempArray);
            }
        }).catch(error=>console.log("出错啦！"+error))


        return () => {};
    }, []);


    const handleSubmit = (value)=>{
        setLoadingOfCard(true)
        post(APIOBJECT["addUser"],JSON.stringify({username:value["username"],password:value["password"],listRoleId:value["roleList"]})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("添加成功！")
                setLoadingOfCard(false);
                setTimeout(()=>{
                    window.location.reload();
                },800)
            }else{
                setLoadingOfCard(false);
            }
        }).catch(error=>{
            message.error("出错啦！"+error);
            setLoadingOfCard(false);
        })
    }


    return (
        <Card title={"添加用户"} loading={loadingOfCard}>
            <Form onFinish={handleSubmit} form={formOfAddUser}>
                <Form.Item name={"username"} rules={[   {
                            required:true,
                            message:"请填写用户名！"
                        },
                        ({getFieldValue})=>({
                            validator(_,value){
                                if (usernameExisted.indexOf(value)!==-1){
                                    return Promise.reject(new Error("该username已存在！请换一个username！"));
                                }else{
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]} label={"用户名"}>
                    <Input placeholder={"用户名"}/>
                </Form.Item>
                <Form.Item name={"password"} rules={[{required:true, message:"请输入密码"}]} label={"请输入密码："}>
                    <Input type={"password"} placeholder={"用户密码"}/>
                </Form.Item>
                <Form.Item name={"passwordConfirm"} rules={[
                    {
                        required:true,
                        message:"请再次输入密码"
                    },
                    ({getFieldValue})=>({
                        validator(_,value){
                            if (value!==getFieldValue("password")){
                                return Promise.reject(new Error("两次密码输入不一致！"));
                            }
                            return Promise.resolve();
                        }
                    })
                ]} label={"请再次输入密码："}>
                    <Input type={"password"} placeholder={"用户密码"}/>
                </Form.Item>
                <Form.Item label={"用户的角色："} name={"roleList"} rules={[{
                    required:true,
                    message:"请选择角色！"
                }]}>
                    <Select mode="multiple" style={{width: '100%'}} allowClear placeholder={"请选择角色"}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                        {roleExisted}
                    </Select>
                </Form.Item>
            </Form>
            <Space size={"middle"}>
                <Button type={"dashed"} onClick={()=>formOfAddUser.resetFields()}>重置</Button>
                <Button type={"primary"} onClick={()=>{formOfAddUser.submit()}}>提交</Button>
            </Space>


        </Card>
    );
}

export default AddUser;