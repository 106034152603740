import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Collapse, Input, message, Modal, Popconfirm, Select, Space, Typography,Table,DatePicker} from "antd";
import {CalendarOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import ExportJsonExcel from "js-export-excel";
import moment from "moment";
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import Decimal from "decimal.js";

function CheckLogOfWarehouse(props) {

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) :
            (text==="不存在"||text==="待定"?<Text type={"danger"}>{text}</Text>:text==="是"?<Text type={"danger"}>是</Text>:text),});
    /**
     * 用于构建可搜索table的固定部分
     */



    /**用于构建时间范围选择的固定部分
     */
    const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const getColumnSearchProps2 = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <RangePicker style={{ marginBottom: 8 }}
                                        value={selectedKeys[0]}
                                        onChange={e => {
                                            setSelectedKeys(e ? [e] : [])
                                        }}
                                        onPressEnter={() => {
                                            confirm();
                                        }}
                             locale={locale}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<CalendarOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(value[0], value[1], 'day', '[]') : "" ,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: text => moment(text).format("YYYY/MM/DD HH:mm:ss")}
    );

     /**用于构建时间范围选择的固定部分
     */

    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const {Panel} = Collapse;
    const {Text} = Typography;
    const {Option} = Select;
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [dataSourceOfInWarehouseTable,setDataSourceOfInWarehouseTable] = useState([]);
    const [dataSourceOfOutWarehouseTable,setDataSourceOfOutWarehouseTable] = useState([]);
    const [dataSourceOfMoveWarehouseTable,setDataSourceOfMoveWarehouseTable] = useState([]);
    const [dataSourceOfLonelyCheckWarehouseTable,setDataSourceOfLonelyCheckWarehouseTable] = useState([]);
    const [dataSourceOfWholeCheckWarehouseTable,setDataSourceOfWholeCheckWarehouseTable] = useState([]);
    const [dataSourceOfInnerTableOfWholeCheckWarehouseTable,setDataSourceOfInnerTableOfWholeCheckWarehouseTable] = useState([]);
    const [loadingOfInWarehouseTable,setLoadingOfInWarehouseTable] = useState(false);
    const [loadingOfOutWarehouseTable,setLoadingOfOutWarehouseTable] = useState(false);
    const [loadingOfMoveWarehouseTable,setLoadingOfMoveWarehouseTable] = useState(false);
    const [loadingOfLonelyCheckWarehouseTable,setLoadingOfLonelyCheckWarehouseTable] = useState(false);
    const [loadingOfWholeCheckWarehouseTable,setLoadingOfWholeCheckWarehouseTable] = useState(false);
    const [loadingOfButtonOfGetExcelOfInWarehouseOperation,setLoadingOfButtonOfGetExcelOfInWarehouseOperation] = useState(false);
    const [loadingOfButtonOfGetExcelOfOutWarehouseOperation,setLoadingOfButtonOfGetExcelOfOutWarehouseOperation] = useState(false);
    const [visibleOfInnerTableOfWholeCheckModal,setVisibleOfInnerTableOfWholeCheckModal] = useState(false);
    const [visibleOfFilteringDataWhenGetExcel,setVisibleOfFilteringDataWhenGetExcel] = useState(false);
    const [arrayOfRangePickerForGettingExcel,setArrayOfRangePickerForGettingExcel] = useState([]);
    const [modeOfGettingExcel,setModeOfGettingExcel] = useState(null);

    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisibleOfPropertiesModal(true);
    }

    const getInWarehouseOp = ()=>{
        setLoadingOfInWarehouseTable(true);
        post(APIOBJECT["getInWarehouseOperationList"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfInWarehouseTable(resultAfterHandle["data"]);
            }
            setLoadingOfInWarehouseTable(false);
        }).catch(error=>{
            message.error("getInWarehouseOp出错了！"+error);
            setLoadingOfInWarehouseTable(false);
        })
    }
    const getOutWarehouseOp = ()=>{
        setLoadingOfOutWarehouseTable(true);
        post(APIOBJECT["getOutWarehouseOperationList"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfOutWarehouseTable(resultAfterHandle["data"]);
            }
            setLoadingOfOutWarehouseTable(false);
        }).catch(error=>{
            message.error("getOutWarehouseOp出错了！"+error);
            setLoadingOfOutWarehouseTable(false);
        })
    }
    const getMoveWarehouseOp = ()=>{
        setLoadingOfMoveWarehouseTable(true);
        post(APIOBJECT["getMoveWarehouseOperationList"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfMoveWarehouseTable(resultAfterHandle["data"]);
            }
            setLoadingOfMoveWarehouseTable(false);
        }).catch(error=>{
            message.error("getMoveWarehouseOp出错了！"+error);
            setLoadingOfMoveWarehouseTable(false);
        })
    }
    const getLonelyCheckWarehouseOp = ()=>{
        setLoadingOfLonelyCheckWarehouseTable(true);
        post(APIOBJECT["getLonelyCheckWarehouseOperationList"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfLonelyCheckWarehouseTable(resultAfterHandle["data"]);
            }
            setLoadingOfLonelyCheckWarehouseTable(false);
        }).catch(error=>{
            message.error("getLonelyCheckWarehouseOp！"+error);
            setLoadingOfLonelyCheckWarehouseTable(false);
        })
    }
    const getWholeCheckWarehouseOp = ()=>{
        setLoadingOfWholeCheckWarehouseTable(true);
        post(APIOBJECT["getWholeCheckWarehouseOperationList"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfWholeCheckWarehouseTable(resultAfterHandle["data"]);
            }
            setLoadingOfWholeCheckWarehouseTable(false);
        }).catch(error=>{
            message.error("getWholeCheckWarehouseOp！"+error);
            setLoadingOfWholeCheckWarehouseTable(false);
        })
    }

    const handleClickDetailsOfWholeCheckButton = (idOfWholeCheck)=>{
        let filterElement = dataSourceOfWholeCheckWarehouseTable.filter(item=>item["id"] === idOfWholeCheck)[0];
        console.log(filterElement);
        filterElement["arrayOfResultOfWholeCheck"].map(item=>item["time"] = filterElement["time"]);
        setDataSourceOfInnerTableOfWholeCheckWarehouseTable(filterElement["arrayOfResultOfWholeCheck"]);
        setVisibleOfInnerTableOfWholeCheckModal(true);
    }

    useEffect(() => {
        getWholeCheckWarehouseOp();
        getLonelyCheckWarehouseOp();
        getMoveWarehouseOp();
        getOutWarehouseOp();
        getInWarehouseOp();
        return () => {};
    }, []);



    const columnsOfInWarehouseTable = [
        {
            title:"操作时间",
            key:"time",
            dataIndex:"time",
            width:170,
            fixed:'left',
            ...getColumnSearchProps2("time")
        },
        {
            title: "实体名称",
            key:"entityName",
            dataIndex: "entityName",
            ellipsis: true,
            width:300,
            fixed:'left',
            ...getColumnSearchProps("entityName")
        },
        {
            title: "操作员",
            key:"operator",
            dataIndex: "operator",
            ellipsis: true,
            width:100,
            ...getColumnSearchProps("operator")

        },
        {
            title: "实体编号",
            key:"bianhao",
            dataIndex: "bianhao",
            ellipsis: true,
            width:100,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "属性详情",
            key:"propertiesString",
            dataIndex: "propertiesString",
            width: 250,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "入库仓位",
            key:"locationName",
            dataIndex: "locationName",
            ellipsis: true,
            width:200,
            ...getColumnSearchProps("locationName")
        },
        {
            title: "数量",
            key:"quantity",
            dataIndex: "quantity",
            width:100,
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "单价",
            key:"price",
            dataIndex: "price",
            width:90,
            ...getColumnSearchProps("price")
        },
        {
            title: "含税总价",
            key:"sumPrice",
            dataIndex: "sumPrice",
            width:120,
            ...getColumnSearchProps("sumPricec")
        },
        {
            title: "税率",
            key:"taxRate",
            dataIndex: "taxRate",
            width:80,
            ...getColumnSearchProps("sumPricec")
        },
        {
            title: "税金",
            key: "taxValue",
            dataIndex: "taxValue",
            width:80,
            ...getColumnSearchProps("taxValue")
        },
        {
            title: "供应商名称",
            key:"supplierName",
            dataIndex: "supplierName",
            ellipsis: true,
            width:200,
            ...getColumnSearchProps("supplierName")
        },
        {
            title: "申请人",
            key:"applicant",
            dataIndex: "applicant",
            width:100,
            ...getColumnSearchProps("applicant")
        },
        {
            title: "关联的采购单",
            key:"purchaseOrderMainUniqueId",
            dataIndex: "purchaseOrderMainUniqueId",
            width:240,
            ...getColumnSearchProps("purchaseOrderMainUniqueId")
        },
        {
            title: "操作",
            width:100,
            fixed:'right',
            render:(text,record,index)=>{
                return (
                    <Button size={"small"} type={"primary"} loading={loadingOfCheckBianhaoPropertiesButton} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                )
            }
        }
    ]

    const columnsOfOutWarehouseTable = [
        {
            title:"时间",
            key:"time",
            dataIndex:"time",
            ellipsis: true,
            width:170,
            fixed:'left',
            ...getColumnSearchProps2("time")
        },
        {
            title: "实体名称",
            key:"entityName",
            dataIndex: "entityName",
            ellipsis: true,
            width:300,
            fixed:'left',
            ...getColumnSearchProps("entityName")
        },
        {
            title: "操作员",
            key:"operator",
            dataIndex: "operator",
            ellipsis: true,
            width:100,
            ...getColumnSearchProps("operator")

        },
        {
            title: "号",
            key:"bianhao",
            dataIndex: "bianhao",
            ellipsis: true,
            width:80,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "详情",
            key:"propertiesString",
            dataIndex: "propertiesString",
            width: 300,
            ellipsis: true,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "出库仓位",
            key:"locationName",
            dataIndex: "locationName",
            ellipsis: true,
            width:180,
            ...getColumnSearchProps("locationName")
        },
        {
            title: "数量",
            key:"quantity",
            dataIndex: "quantity",
            width:80,
            ellipsis: true,
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "均价",
            key:"emaPrice",
            dataIndex: "emaPrice",
            width:80,
            ellipsis: true,
            ...getColumnSearchProps("emaPrice")
        },
        {
            title: "项目编号",
            key:"projectId",
            dataIndex: "projectId",
            width:130,
            ellipsis: true,
            ...getColumnSearchProps("projectId")
        },
        {
            title: "项目名称",
            key:"projectName",
            dataIndex: "projectName",
            width:130,
            ellipsis: true,
            ...getColumnSearchProps("projectName")
        },
        {
            title: "申请人",
            key:"applicant",
            dataIndex: "applicant",
            width:100,
            ellipsis: true,
            ...getColumnSearchProps("applicant")
        },
        {
            title: "关联的要货单",
            key:"requisitionOrderId",
            dataIndex: "requisitionOrderId",
            width:150,
            ...getColumnSearchProps("requisitionOrderId")
        },
        {
            title: "操作",
            width:150,
            fixed:'right',
            render:(text,record,index)=>{
                return (
                    <Button type={"primary"} loading={loadingOfCheckBianhaoPropertiesButton} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                )
            }
        }
    ]

    const columnsOfMoveWarehouseTable = [
        {
            title:"操作时间",
            key:"time",
            width:170,
            ellipsis: true,
            dataIndex:"time",
            fixed:'left',
            ...getColumnSearchProps2("time")
        },
        {
            title: "实体名称",
            key:"entityName",
            width:300,
            ellipsis: true,
            fixed:'left',
            dataIndex: "entityName",
            ...getColumnSearchProps("entityName")
        },

        {
            title: "具体型号",
            key:"propertiesString",
            ellipsis: true,
            width: 300,
            dataIndex: "propertiesString",
            ...getColumnSearchProps("propertiesString")

        },
        {
            title: "操作员",
            key:"operator",
            width:100,
            ellipsis: true,
            dataIndex: "operator",
            ...getColumnSearchProps("operator")

        },
        {
            title: "号",
            key:"bianhao",
            width:80,
            ellipsis: true,
            dataIndex: "bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "出库仓位",
            width:200,
            ellipsis: true,
            key:"fromLocationName",
            dataIndex: "fromLocationName",
            ...getColumnSearchProps("fromLocationName")
        },
        {
            title: "入库仓位",
            width:200,
            ellipsis: true,
            key:"toLocationName",
            dataIndex: "toLocationName",
            ...getColumnSearchProps("toLocationName")
        },
        {
            title: "数量",
            width: 150,
            ellipsis:true,
            key:"quantity",
            dataIndex: "quantity",
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "申请人",
            width: 100,
            ellipsis:true,
            key:"applicant",
            dataIndex: "applicant",
            ...getColumnSearchProps("applicant")
        },
        {
            title: "操作",
            width: 200,
            fixed:'right',
            render:(text,record,index)=>{
                return (
                    <Button type={"primary"} loading={loadingOfCheckBianhaoPropertiesButton} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                )
            }
        }
    ]

    const columnsOfLonelyCheckWarehouseTable = [
        {
            title:"操作时间",
            key:"time",
            width:150,
            ellipsis: true,
            dataIndex:"time",
            ...getColumnSearchProps2("time")
        },

        {
            title: "操作员",
            key:"operator",
            width:100,
            ellipsis: true,
            dataIndex: "operator",
            ...getColumnSearchProps("operator")

        },
        {
            title: "实体名称",
            key:"entityName",
            width:300,
            ellipsis: true,
            dataIndex: "entityName",
            ...getColumnSearchProps("entityName")
        },
        {
            title: "具体型号",
            key:"propertiesString",
            ellipsis: true,
            dataIndex: "propertiesString",
            ...getColumnSearchProps("propertiesString")

        },
        {
            title: "实体编号",
            key:"bianhao",
            width:100,
            ellipsis: true,
            dataIndex: "bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "仓位",
            width:200,
            ellipsis: true,
            key:"locationName",
            dataIndex: "locationName",
            ...getColumnSearchProps("locationName")
        },
        {
            title: "修改前的数量",
            width:150,
            ellipsis: true,
            key:"formerQuantity",
            dataIndex: "formerQuantity",
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "修改后的数量",
            width:150,
            ellipsis: true,
            key:"quantity",
            dataIndex: "quantity",
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "操作",
            width:150,
            render:(text,record,index)=>{
                return (
                    <Button type={"primary"} size={"small"} loading={loadingOfCheckBianhaoPropertiesButton} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                )
            }
        }
    ]

    const columnsOfWholeCheckWarehouseTable = [
        {
            title:"整体盘库记录ID",
            key:"id",
            dataIndex:"id",
            ...getColumnSearchProps("id")
        },
        {
            title:"操作时间",
            key:"time",
            dataIndex:"time",
            ...getColumnSearchProps2("time")
        },
        {
            title: "操作员",
            key:"operators",
            dataIndex: "operators",
            ...getColumnSearchProps("operator")

        },
        {
            title: "组织者",
            key:"organizer",
            dataIndex: "organizer",
            ...getColumnSearchProps("organizer")

        },
        {
            title: "接受情况",
            key:"accepted",
            dataIndex: "accepted",
            ...getColumnSearchProps("accepted")
        },
        {
            title: "操作",
            render:(text,record,index)=>{
                return (
                    <Button type={"primary"} size={"small"} onClick={()=>handleClickDetailsOfWholeCheckButton(record["id"])}>盘点详情</Button>
                )
            }
        }
    ]

    const columnsOfInnerTableOfWholeCheckTable = [
        {
            title: "实体名称",
            key:"entityName",
            dataIndex: "entityName",
            ...getColumnSearchProps("entityName")
        },
        {
            title: "实体编号",
            key:"bianhao",
            dataIndex: "bianhao",
            width:130,
            ellipsis: true,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "属性",
            key:"propertiesString",
            dataIndex: "propertiesString",
            ellipsis: true,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "仓位",
            key:"locationName",
            dataIndex: "locationName",
            ...getColumnSearchProps("locationName")
        },
        {
            title: "旧数量",
            key:"formerQuantity",
            dataIndex: "formerQuantity",
            width:100,
            ellipsis: true,
            ...getColumnSearchProps("formerQuantity")
        },
        {
            title: "新数量",
            key:"quantity",
            dataIndex: "quantity",
            width:100,
            ellipsis: true,
            render:(text,record,index)=>{
                let recordCreateTime = new Date(record["time"]);
                let shiyongFugaiPankuTime = new Date("2023/03/15/16:34:51")
                if (recordCreateTime.getTime() < shiyongFugaiPankuTime.getTime()){
                    return (<Text>{record['formerQuantity'] === '不存在' ? record["quantity"] : Number(record["quantity"]) + Number(record["formerQuantity"])}</Text>)
                }else{
                    return (<Text>{record['formerQuantity'] === '不存在' ? record["quantity"] : Number(record["quantity"])}</Text>)
                }

            }
        },
        {
            title: "单位",
            key:"unit",
            dataIndex: "unit",
            width:80,
            ellipsis: true,
            ...getColumnSearchProps("unit")
        },
        {
            title: "操作",
            render:(text,record,index)=>{
                return (
                    <Button type={"primary"} loading={loadingOfCheckBianhaoPropertiesButton} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])}>实体详情</Button>
                )
            }
        }
    ]


    const getExcelUniversally = (dataSource,keysOfIndex,keysOfChineseName,fileName,sheetName,setLoading)=>{
        const datas= dataSource ? dataSource:'';//表格数据
        var option={};
        let dataTable = [];
        if (datas) {
            datas.map((item)=>{
                //keys是一个包含这键名的数组
                let obj = {};
                for (let i = 0;i<keysOfIndex.length;i++){
                    obj[keysOfIndex[i]] = item[keysOfIndex[i]];
                }
                dataTable.push(obj);
                return dataTable
            })

        }
        option.fileName = fileName;
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:sheetName,
                sheetFilter:keysOfIndex,
                sheetHeader:keysOfChineseName,
            }
        ];
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
        setLoading(false);
        message.success("导出成功！");
    }

    const getKeysOfColumns = (columns)=>{
        let resultObj = {};
        let keysOfIndex=[];
        let keysOfChineseName=[];
        columns.map(item=>{
            if (item["key"]!==undefined){
                keysOfIndex.push(item["key"]);
                keysOfChineseName.push(item["title"]);
            }
            return null;
        });
        resultObj["keysOfIndex"] = keysOfIndex;
        resultObj["keysOfChineseName"] = keysOfChineseName;
        return resultObj;
    }

    const getExcel = (dataSource,columns,fileName,setLoading)=>{
        setLoading(true);
        let {keysOfIndex,keysOfChineseName} = getKeysOfColumns(columns);
        getExcelUniversally(dataSource,keysOfIndex,keysOfChineseName,fileName
            ,"记录表",setLoading);
    }

    return (
        <>
            <Modal width={1300} title={"整体盘库详情"} visible={visibleOfInnerTableOfWholeCheckModal} okText={"明白了"} cancelText={"关闭界面"}
                   onCancel={()=>setVisibleOfInnerTableOfWholeCheckModal(false)} onOk={()=>setVisibleOfInnerTableOfWholeCheckModal(false)}>
                <Table columns={columnsOfInnerTableOfWholeCheckTable} dataSource={dataSourceOfInnerTableOfWholeCheckWarehouseTable}
                       rowKey={record => record["bianhao"]+record["locationName"]} bordered/>
            </Modal>
            <Modal width={1000} title={"属性详情"} visible={visibleOfPropertiesModal} okText={"明白了"} cancelText={"关闭界面"}
                   onCancel={()=>setVisibleOfPropertiesModal(false)} onOk={()=>setVisibleOfPropertiesModal(false)}>
                <QueryEntityDetailsTable bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
            </Modal>
            <Modal width={800} title={"筛选导出记录"} open={visibleOfFilteringDataWhenGetExcel}
                   cancelText={"取消"} okText={"导出"} onCancel={()=>setVisibleOfFilteringDataWhenGetExcel(false)}
                   onOk={()=>{
                       if(arrayOfRangePickerForGettingExcel.length !== 2){
                           message.error("请先选择日期后，再点击导出！");
                           return;
                       }
                       if(modeOfGettingExcel === null){
                           message.error("出错了，请联系管理员！");
                           return;
                       }
                       let data = [];
                       let columns = [];
                       let setLoadingFunc = setLoadingOfButtonOfGetExcelOfInWarehouseOperation();
                       let fileName = "记录表";
                       const unitObj = {dataIndex:"unit",key:"unit",title:"单位"}
                       if(modeOfGettingExcel === "out"){
                           data = dataSourceOfOutWarehouseTable;
                           columns = columnsOfOutWarehouseTable;
                           setLoadingFunc = setLoadingOfButtonOfGetExcelOfOutWarehouseOperation;
                           columns.splice(7, 0, unitObj);
                           fileName = new Date().toLocaleDateString()+"出库记录表";
                       }else if(modeOfGettingExcel === "in"){
                           data = dataSourceOfInWarehouseTable;
                           columns = columnsOfInWarehouseTable;
                           columns.splice(8, 0, unitObj);
                           setLoadingFunc = setLoadingOfButtonOfGetExcelOfInWarehouseOperation;
                           fileName = new Date().toLocaleDateString()+"入库记录表";
                       }

                       console.log(columns);
                       let filteredData = data
                           .filter(item=>moment(item["time"]).isBetween(arrayOfRangePickerForGettingExcel[0], arrayOfRangePickerForGettingExcel[1]));
                       setLoadingFunc(true);
                       getExcel(filteredData,columns,fileName,setLoadingFunc);
                       setVisibleOfFilteringDataWhenGetExcel(false)
                   }}>
                <RangePicker locale={locale} onChange={(dates,dateString)=>{setArrayOfRangePickerForGettingExcel(dates);}}/>

            </Modal>
            <Collapse defaultActiveKey={["1"]}>
                <Panel key={1} header={"入库记录"}
                       extra={<Button type={"primary"}
                                      onClick={()=>{
                                          setModeOfGettingExcel("in");
                                          setVisibleOfFilteringDataWhenGetExcel(true);
                                      }} loading={loadingOfButtonOfGetExcelOfInWarehouseOperation}>导出Excel</Button>}>
                    <Table dataSource={dataSourceOfInWarehouseTable} loading={loadingOfInWarehouseTable} scroll={{x: 1300}}
                           columns={columnsOfInWarehouseTable} bordered rowKey={record => record["time"]+record["operator"]+record["bianhao"]}/>
                </Panel>
                <Panel key={2} header={"出库记录"}
                       extra={<Button type={"primary"} onClick={
                           ()=>{
                               setModeOfGettingExcel("out");
                               setVisibleOfFilteringDataWhenGetExcel(true);
                           }
                       } loading={loadingOfButtonOfGetExcelOfOutWarehouseOperation}>导出Excel</Button>}>
                    <Table columns={columnsOfOutWarehouseTable} dataSource={dataSourceOfOutWarehouseTable} scroll={{x: 1300}}
                           loading={loadingOfOutWarehouseTable} bordered rowKey={record => record["time"]+record["operator"]+record["bianhao"]}/>
                </Panel>
                <Panel key={3} header={"移库记录"}>
                    <Table columns={columnsOfMoveWarehouseTable} dataSource={dataSourceOfMoveWarehouseTable} scroll={{x: 1300}}
                           loading={loadingOfMoveWarehouseTable} bordered rowKey={record => record["time"]+record["operator"]+record["bianhao"]}/>
                </Panel>
                <Panel key={4} header={"单独盘库记录"}>
                    <Table columns={columnsOfLonelyCheckWarehouseTable} dataSource={dataSourceOfLonelyCheckWarehouseTable}
                           loading={loadingOfLonelyCheckWarehouseTable} bordered rowKey={record => record["time"]+record["operator"]+record["bianhao"]}/>
                </Panel>
                <Panel key={5} header={"整体盘库记录"}>
                    <Table columns={columnsOfWholeCheckWarehouseTable} dataSource={dataSourceOfWholeCheckWarehouseTable}
                           loading={loadingOfWholeCheckWarehouseTable} bordered rowKey={record => record["id"]}/>
                </Panel>
            </Collapse>
        </>
    );
}

export default CheckLogOfWarehouse;