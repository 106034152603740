import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

function Index(props) {
    let {warehouseType} = props
    return (
        <Form.List name="warehouseNameArray"
                   rules={[
                       {
                           validator: async (_, names) => {
                               if (!names && warehouseType === "normal type") {
                                   return Promise.reject(new Error('请至少输入一个层级'));
                               }
                           },
                       },
                   ]}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                        <Form.Item  label={`第${index + 1}层级`} required={false} key={field.key}>
                            <Form.Item {...field} validateTrigger={['onChange', 'onBlur']}
                                       rules={[
                                           {
                                               validator: async (_,value) => {
                                                   if(!value){
                                                       return Promise.reject(new Error('请输入层级名称'));
                                                   }else if(value.indexOf("-") !== -1){
                                                       return Promise.reject(new Error("层级类型的名字不可包含符号'-'"))
                                                   }
                                               }
                                           }
                                       ]} noStyle>
                                <Input style={{width:"80%"}} placeholder="层级名称"/>
                            </Form.Item>
                            {fields.length > 1 ? (
                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)}/>
                            ) : null}
                        </Form.Item>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} style={{marginLeft: '8.3%',}}>
                            新增层级
                        </Button>
                        <Button type="dashed" onClick={() => {add('最顶层的层级', 0);}}
                                style={{marginLeft: '10%',}} icon={<PlusOutlined />}>
                            在最上层添加
                        </Button>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}
export default Index;