import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {
    Button, Card, Col, Form, Input, message, Select,
    Row, Modal, Tooltip, Space, Popconfirm, Table, Typography, Divider
} from "antd";
import WarehouseLocationTable from "../../../components/Query/WarehouseLocationTable";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {convertArrayResult, findOptionPath, judgeIfNum} from "../../../utlis/UniversalFunctions"
import QueryEntityByDetail from "../../../components/Query/QueryEntityByDetail";
import RequisitionQuery from "../../../components/Query/RequisitionQuery";
import Decimal from "decimal.js";
import CascaderSelect from "../../../components/Edit/Warehouse/CascaderSelect";

let index = 0;
function Ex_warehouse(props) {

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const {Text} = Typography;
    const navigate = useNavigate();
    const [arrayOfAllProject,setArrayOfAllProject] = useState([]);
    const [formOfExWarehouse] = Form.useForm();
    const [formOfChangeRecord] = Form.useForm();
    const [allWarehouseOfBianhaoWhenAdd,setAllWarehouseOfBianhaoWhenAdd] = useState([]);
    const [allWarehouseOfBianhaoWhenChange,setAllWarehouseOfBianhaoWhenChange] = useState([]);
    const [visibleOfLocationModal,setVisibleOfLocationModal] = useState(false);
    const [disableOfBianhaoInput,setDisableOfBianhaoInput] = useState(true);
    const [loadingOfSubmitAddExRecordButton,setLoadingOfSubmitAddExRecordButton] = useState(false);
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [visibleOfChangeModal,setVisibleOfChangeModal] = useState(false);
    const [submittedExRecordInTemporaryTable,setSubmittedExRecordInTemporaryTable] = useState(null);
    const [loadingOfTable,setLoadingOfTable] = useState(false);
    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [disableOfInputWhenChange,setDisableOfInputWhenChange] = useState(true);
    const [loadingOfButtonQueryWarehouseLocationWhenAdd,setLoadingOfButtonQueryWarehouseLocationWhenAdd] = useState(false);
    const [loadingOfButtonQueryWarehouseLocationWhenChange,setLoadingOfButtonQueryWarehouseLocationWhenChange] = useState(false);
    const [loadingOfSubmitChangeToTemporaryTableButton,setLoadingOfSubmitChangeToTemporaryTableButton] = useState(false);
    const [bianhaoForEntityLocationModal,setBianhaoForEntityLocationModal] = useState();
    const [visibleOfRequisitionQueryModal,setVisibleOfRequisitionQueryModal] = useState(false);
    const [arrayOfApplicants,setArrayOfApplicants] = useState([]);
    const [unitOfBianhao,setUnitOfBianhao] = useState("");
    const [name, setName] = useState('');
    const [requisitionForShow,setRequisitionForShow] = useState([]);
    const [disableOfProjectSelect,setDisableOfProjectSelect] = useState(false);
    const [optionsForCascader,setOptionsForCascader] = useState([]);
    const [changingRecord,setChangingRecord] = useState(null);
    let completeRequisition = useRef();
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setArrayOfApplicants([...arrayOfApplicants, name || `默认项${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const getAllApplicants = ()=>{
        post(APIOBJECT["getAllApplicantsName"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]!=null&&resultAfterHandle["data"]!==undefined){
                    setArrayOfApplicants(resultAfterHandle["data"]);
                }else{
                    message.error("无供应商信息！请先添加供应商！");
                }

            }
        }).catch(error=>message.error("getSupplier出错了！"+error))
    }
    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisibleOfPropertiesModal(true);
    }
    const {Option} = Select;
    const getAllProject = ()=>{
        post(APIOBJECT["getAllProject"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setArrayOfAllProject(resultAfterHandle["data"]);

            }
        }).catch(error=>message.error("getAllProject出错了！"+error))
    }
    const getWarehouseLocationWhenAdd=()=>{
        setLoadingOfButtonQueryWarehouseLocationWhenAdd(true);
        let bianhao = formOfExWarehouse.getFieldValue(["bianhao"]);
        if (bianhao===null||bianhao===undefined){
            message.error("请输入编号！");
            setLoadingOfButtonQueryWarehouseLocationWhenAdd(false);
        }else{
            setRequisitionByBianhao(bianhao);
            post(APIOBJECT["queryWarehouseLocationByBianhao"],
                JSON.stringify({bianhao:bianhao})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    setAllWarehouseOfBianhaoWhenAdd(resultAfterHandle["data"]);
                    setBianhaoForEntityLocationModal(bianhao);
                    setVisibleOfLocationModal(true);
                    setDisableOfBianhaoInput(true);//只有在成功找到库位时才锁定input
                }
                setLoadingOfButtonQueryWarehouseLocationWhenAdd(false);
            }).catch(error=>{
                message.error("getWarehouseLocationWhenAdd出错了！"+error);
                setLoadingOfButtonQueryWarehouseLocationWhenAdd(false);
            })
        }
    }
    /**
     * mode用来决定是否显示查询库位的界面；即LocationModal，如果是初次点修改进入修改Modal的话
     * 就不需要跳转出查询库位的界面；如果是在修改Modal中点击查询库位的话，则需要跳转显示出Modal
     * @param mode
     */
    const getWarehouseLocationWhenChange=(mode)=>{
        setLoadingOfButtonQueryWarehouseLocationWhenChange(true);
        let bianhao = formOfChangeRecord.getFieldValue(["bianhao"]);
        if (bianhao===null||bianhao===undefined){
            message.error("请输入编号！");
            setLoadingOfButtonQueryWarehouseLocationWhenChange(false);
        }else{
            setRequisitionByBianhao(bianhao);
            post(APIOBJECT["queryWarehouseLocationByBianhao"],
                JSON.stringify({bianhao:bianhao})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    setAllWarehouseOfBianhaoWhenAdd(resultAfterHandle["data"]);//因为要展示一下，所以这里把需要修改的编号的库位也赋值给已一开始的array
                    setAllWarehouseOfBianhaoWhenChange(resultAfterHandle["data"]);
                    if(mode === 'fromModal'){
                        setBianhaoForEntityLocationModal(bianhao);
                        setVisibleOfLocationModal(true);
                    }
                    setDisableOfBianhaoInput(true);//只有在成功找到库位时才锁定input
                }
                setLoadingOfButtonQueryWarehouseLocationWhenChange(false);
            }).catch(error=>{
                message.error("getWarehouseLocationWhenAdd出错了！"+error);
                setLoadingOfButtonQueryWarehouseLocationWhenChange(false);
            })
        }
    }
    const handleSubmitExRecordToTemporaryTable = (fieldsValue)=>{
        setLoadingOfSubmitAddExRecordButton(true);
        fieldsValue["warehouseLocation"] = convertArrayResult(fieldsValue["warehouseLocation"]);
        post(APIOBJECT["handleSubmitExRecordToTemporaryTable"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                formOfExWarehouse.resetFields();
                setDisableOfBianhaoInput(false);
                setDisableOfProjectSelect(false);
                // setArrayOfAllProject([]);
                setRequisitionForShow([]);
            }
            setLoadingOfSubmitAddExRecordButton(false);
            getSubmittedExRecordInTemporaryTable();
        }).catch(error=>{
            message.error("handleSubmitExRecordToTemporaryTable出错了！"+error);
            setLoadingOfSubmitAddExRecordButton(false);
        })
    }
    const getSubmittedExRecordInTemporaryTable = ()=>{
        setLoadingOfTable(true);
        post(APIOBJECT["getSubmittedOutRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                console.log(resultAfterHandle)
                if (resultAfterHandle["data"] === null||resultAfterHandle["data"] === undefined){
                    message.info("您的出库暂存表中无记录。")
                    setSubmittedExRecordInTemporaryTable([]);//置空，因为有可能是提交主库之后调用的
                }else{
                    setSubmittedExRecordInTemporaryTable(resultAfterHandle["data"]);
                }
            }
            setLoadingOfTable(false);
        }).catch(error=>{
            message.error("出错啦！"+error);
            setLoadingOfTable(false);
        })
    }
    const handleClickOnButtonOfChangeRecord = (recordCreatedTime)=>{
        let jsonObject = submittedExRecordInTemporaryTable["dataInProgress"].filter(item=>item["recordCreatedTime"] === recordCreatedTime)[0];
        setChangingRecord(jsonObject);
        let requisitionId = "";
        if(jsonObject["requisitionId"]){
            setDisableOfProjectSelect(true);
            requisitionId = jsonObject["requisitionId"].split("】")[1];
        }
        let filteredArray = completeRequisition["current"].filter(item=>{
            let details = item["details"];
            let filterArray = details.filter(detail=>detail["bianhao"] === jsonObject["bianhao"]);
            return filterArray && filterArray.length > 0;
        })
        setRequisitionForShow(filteredArray);
        let projectObject = arrayOfAllProject.filter(item=> item["projectName"] === jsonObject["projectName"])[0];

        formOfChangeRecord.setFieldsValue({...jsonObject,projectId:projectObject["projectId"]});
        if (requisitionId !== ""){
            formOfChangeRecord.setFieldsValue({requisitionId:Number(requisitionId)})
        }
        getWarehouseLocationWhenChange();
        setVisibleOfChangeModal(true);
    }
    useEffect(()=>{
        let tempResult = findOptionPath(optionsForCascader,changingRecord === null ? null : changingRecord["fromLocationId"]);
        if (tempResult.length !== 0){
            formOfChangeRecord.setFieldsValue({warehouseLocation:tempResult})
        }
    },[optionsForCascader])
    const handleSubmitChangeRecordToTemporaryTable = (fieldValues)=>{
        setLoadingOfSubmitChangeToTemporaryTableButton(true);
        fieldValues["warehouseLocation"] = convertArrayResult(fieldValues["warehouseLocation"]);
        post(APIOBJECT["handleChangeOutRecordSubmittedTemporaryRecord"],JSON.stringify(fieldValues))
            .then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setVisibleOfChangeModal(false);
                getSubmittedExRecordInTemporaryTable();
            }
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
        }).catch(error=>{
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
            message.error("handleSubmitChangeRecordToTemporaryTable出错了！"+error);
        })
    }
    const handleDeleteOutRecordSubmittedTemporaryRecord = (recordCreatedTime)=>{
        post(APIOBJECT["handleDeleteOutRecordSubmittedTemporaryRecord"],JSON.stringify({recordCreatedTime:recordCreatedTime})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getSubmittedExRecordInTemporaryTable();
            }
        }).catch(error=>message.error("handleDeleteOutRecordSubmittedTemporaryRecord出错了！"+error))
    }
    const combineOutRecordTemporaryToMain = ()=>{
        post(APIOBJECT["combineOutRecordTemporaryToMain"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                getSubmittedExRecordInTemporaryTable();
                message.success(resultAfterHandle["msg"]);
            }
        }).catch(error=>message.error("combineOutRecordTemporaryToMain出错了！"+error))
    }
    /**
     * 选择要货单后，项目直接根据要货单的信息进行锁定
     * @param requisitionId
     * @param form   对哪个form进行修改
     */
    const handleSelectRequisition = (requisitionId,form) =>{
        let requisitionObj = completeRequisition["current"].filter(item=>item["id"] === requisitionId)[0];
        let {projectName} = requisitionObj;
        projectName = projectName.split("】")[1];
        let projectsAfterFiltering = arrayOfAllProject.filter(item=>item["projectName"] === projectName);
        if (projectsAfterFiltering && projectsAfterFiltering.length === 1){
            let projectObj = projectsAfterFiltering[0];
            setDisableOfProjectSelect(true);
            form.setFieldsValue({projectId:projectObj["projectId"]});
        }
    }



    const getRequisition = () =>{
        post(APIOBJECT["getRequisitionByWarehouseMan"]).then(resultBeforeHandle =>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                //把有未关闭细节的筛选出来
                let filteredArray = resultAfterHandle["data"].filter(item=>{
                    for (let detail of item["details"]){
                        if (Number(detail["closed"]) === 0){
                            return true;
                        }
                    }
                    return false;
                })
                // console.log(resultAfterHandle["data"])
                // console.log(filteredArray)
                completeRequisition["current"] = filteredArray;
            }
        }).catch(error => message.error("getRequisition出错"+error))
    }

    const callBackFuncForRequisitionQuery = (value)=>{
        console.log(value);
    }

    useEffect(() => {
        getAllApplicants();
        getAllProject();
        getSubmittedExRecordInTemporaryTable();
        getRequisition();
        return () => {};
    }, []);

    const columns = [
        {
            title: "时间",
            key:"recordCreatedTime",
            dataIndex: "recordCreatedTime",
            width: 163,
            ...getColumnSearchProps("recordCreatedTime")
        },
        {
            title: "名称",
            key:"entityName",
            dataIndex: "entityName",
            width: 240,
            ...getColumnSearchProps("entityName")
        },
        {
            title:"具体型号",
            key:"propertiesString",
            dataIndex:"propertiesString",
            width: 300,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title:"号",
            key:"bianhao",
            dataIndex:"bianhao",
            ellipsis: true,
            width:80,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "数量",
            key:"quantity",
            dataIndex: "quantity",
            width:90,
            ellipsis: true,
            render:(text,record,index)=>{
                return record["quantity"]+record["unit"];
            }
        },
        {
            title: "均价",
            key:"emaPrice",
            dataIndex: "emaPrice",
            width: 100,
            ellipsis: true,
            ...getColumnSearchProps("emaPrice")
        },
        {
            title: "项目编号",
            key:"projectId",
            dataIndex: "projectId",
            width: 120,
            ellipsis: true,
            ...getColumnSearchProps("projectId")
        },
        {
            title: "项目名称",
            key:"projectName",
            dataIndex: "projectName",
            width: 150,
            ellipsis: true,
            ...getColumnSearchProps("projectName")
        },
        {
            title: "关联的要货单",
            key:"requisitionId",
            dataIndex: "requisitionId",
            width: 200,
            ellipsis: true,
            ...getColumnSearchProps("applicant")
        },
        {
            title: "申请人",
            key:"applicant",
            dataIndex: "applicant",
            width: 90,
            ellipsis: true,
            ...getColumnSearchProps("applicant")
        },
        {
            title: "出库名称",
            key:"locationName",
            dataIndex: "locationName",
            width: 200,
            ellipsis: true,
            ...getColumnSearchProps("locationName")
        },
        {
            title: "操作",
            fixed:'right',
            width: 200,
            render:(text,record,index)=>{
                return (<Space>
                    <Button type={"primary"} size={"small"} onClick={()=>handleClickOnButtonOfChangeRecord(record["recordCreatedTime"])}>修改</Button>

                    <Popconfirm title={"确认删除该条暂存记录？"} okText={"删除"}
                                cancelText={"取消"} onConfirm={()=>handleDeleteOutRecordSubmittedTemporaryRecord(record["recordCreatedTime"])}>
                        <Button size={"small"} danger>删除</Button>
                    </Popconfirm>

                    <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])} loading={loadingOfCheckBianhaoPropertiesButton}>详情</Button>
                </Space>)
            }
        }
    ]

    /**
     * 根据编号设置可选的要货单
     * @param bianhao
     */
    const setRequisitionByBianhao = (bianhao) =>{
        let filteredArray = completeRequisition["current"].filter(item=>{
            let details = item["details"];
            let filterArray = details.filter(detail=>detail["bianhao"] === bianhao);
            return filterArray && filterArray.length > 0;
        })
        setRequisitionForShow(filteredArray);
    }

    const callBackFuncForQueryEntityByDetail = (dataObj)=>{
        if(dataObj){
            let {unit,number_of_properties} = dataObj;
            setUnitOfBianhao(unit);
            if (visibleOfChangeModal){
                formOfChangeRecord.setFieldsValue({bianhao:number_of_properties})
                formOfChangeRecord.resetFields(["requisitionId"])
            }else{
                formOfExWarehouse.setFieldsValue({bianhao:number_of_properties});
                formOfExWarehouse.resetFields(["requisitionId"]);
            }
            setDisableOfProjectSelect(false);
            if (visibleOfChangeModal){
                getWarehouseLocationWhenChange();
                formOfChangeRecord.resetFields(["warehouseLocation"])
            }else{
                getWarehouseLocationWhenAdd();
            }

        }

    }

    return (
        <Card title={"出库"} extra={<Button type={"primary"} onClick={()=>checkPropertiesOfBianhao(formOfExWarehouse.getFieldValue("bianhao"))}>查看编号属性及单位</Button>}>
            <Modal width={1400} open={visibleOfRequisitionQueryModal} onCancel={()=>setVisibleOfRequisitionQueryModal(false)}>
                <RequisitionQuery canSubmitToExamineAndVerify={false} callBackFunc={callBackFuncForRequisitionQuery} from={"warehouse"} canChangeRequisitionDetail={false}/>
            </Modal>
            <Card type={"inner"} title={"选择实体"}>
                <QueryEntityByDetail setFunc={callBackFuncForQueryEntityByDetail}/>
            </Card>
            <Modal zIndex={2000} width={900} title={"各个库位的情况"} visible={visibleOfLocationModal} onOk={()=>setVisibleOfLocationModal(false)}
                   onCancel={()=>setVisibleOfLocationModal(false)} okText={"好的"} cancelText={"取消"}>
                <WarehouseLocationTable bianhao={bianhaoForEntityLocationModal}/>
            </Modal>
            <Card type={"inner"} title={"填写出库信息"} extra={<Button type={"primary"} onClick={()=>setVisibleOfRequisitionQueryModal(true)}>查看所有要货单</Button>}>
                <Form form={formOfExWarehouse} onFinish={handleSubmitExRecordToTemporaryTable}>
                    <Row>
                        <Col span={20}>
                            <Form.Item label={"编号"} name={"bianhao"} rules={[{required:true,message:"请填写出库的实体的编号"}]}>
                                <Input onChange={()=>setAllWarehouseOfBianhaoWhenAdd([])} type={"text"} disabled={disableOfBianhaoInput}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}><Button type={"dashed"} onClick={()=>{
                            setDisableOfBianhaoInput(false);
                            formOfExWarehouse.resetFields(["warehouseLocation"]);
                        }}>修改编号</Button></Col>
                        <Col span={2}>
                            <Tooltip title={"点击查询库位后，若编号为有效编号，则会被锁定。若想修改点击旁边的修改编号按钮即可。"} color={"blue"}>
                                <Button type={"primary"} loading={loadingOfButtonQueryWarehouseLocationWhenAdd} onClick={getWarehouseLocationWhenAdd}>查询库位</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <CascaderSelect labelName={"离开的库位"} warehouseItemArray={allWarehouseOfBianhaoWhenAdd} itemName={"warehouseLocation"}/>
                        </Col>
                        <Col span={6} offset={2}>
                            <Form.Item label={"出库的数量"} name={"quantity"}
                                       rules={[
                                           {required:true,message:"请输入数量"},
                                           ({getFieldValue})=>({
                                               validator(_,value){
                                                   let selectedLocationId = getFieldValue(["warehouseLocation"]);

                                                   if (!judgeIfNum(value)){
                                                       return Promise.reject(new Error("数量必须为正数！"));
                                                   }
                                                   if (selectedLocationId!==undefined){
                                                       //判断是否超过了可出库的数量
                                                       selectedLocationId = convertArrayResult(selectedLocationId);
                                                       let allowedOutQuantity = allWarehouseOfBianhaoWhenAdd.filter(item=>item["locationId"] === selectedLocationId)[0]["quantity"];
                                                       if (Number(value)>Number(allowedOutQuantity)){
                                                           return Promise.reject(new Error("出库的数量大于库存了！"));
                                                       }
                                                   }
                                                   let valueDecimal = new Decimal(value);
                                                   if (valueDecimal.comparedTo(new Decimal(0))===0){
                                                       return Promise.reject(new Error("出库的数量不能为0"))
                                                   }
                                                   return Promise.resolve();
                                               }
                                           })
                                       ]}>
                                <Input type={"number"}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={2}>
                            <Form.Item label={"申请人"} name={"applicant"} rules={[{required:true,message:"请输入申请人！"}]}>
                                <Select
                                    placeholder="请选择申请人"
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{margin: '8px 0',}}/>
                                            <Space style={{padding: '0 8px 4px',}}>
                                                <Input placeholder="请输入"
                                                       ref={inputRef}
                                                       value={name}
                                                       onChange={onNameChange}
                                                />
                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                    Add item
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item label={"项目"} name={"projectId"} rules={[{required:true,message:"请选择用于的项目！"}]}>
                                <Select showSearch placeholder="请选择使用的项目" disabled={disableOfProjectSelect}
                                        optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{"width":"100%"}}>
                                    {arrayOfAllProject.map(item=><Option disabled={item["disabled"] !== "0"} key={item["projectId"]} value={item["projectId"]}>{"【"+item["projectId"]+"】"+item["projectName"]}</Option> )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item label={"要货单"} name={"requisitionId"}>
                                <Select showSearch placeholder="请选择关联的要货单" allowClear onClear={()=>setDisableOfProjectSelect(false)}
                                        optionFilterProp="children" onSelect={(requsitionId)=>handleSelectRequisition(requsitionId,formOfExWarehouse)}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{"width":"100%"}}>
                                    {requisitionForShow.map(item=><Option key={item["id"]} value={item["id"]}>{"【"+item["create_time"]+"】"+item["id"]}</Option> )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>



                    <Form.Item>
                        <Button type={"primary"} htmlType={"submit"} loading={loadingOfSubmitAddExRecordButton}>提交到暂存表</Button>
                    </Form.Item>
                </Form>
            </Card>
            <Modal width={1000} title={"属性详情"} visible={visibleOfPropertiesModal} okText={"明白了"} cancelText={"关闭界面"}
                   onCancel={()=>setVisibleOfPropertiesModal(false)} onOk={()=>setVisibleOfPropertiesModal(false)}>
                <QueryEntityDetailsTable bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
            </Modal>

            <Modal width={1000} title={"修改暂存的出库记录"} visible={visibleOfChangeModal} okText={"修改"} cancelText={"取消"}
            onCancel={()=>{
                setVisibleOfChangeModal(false);
                formOfExWarehouse.resetFields();//因为为了共用一个modal展示属性，setAllWarehouseOfBianhaoWhenAdd设置了需要修改的bianhao的库位，
                formOfChangeRecord.resetFields();
                setDisableOfProjectSelect(false);
            }}
               footer={[<Button key={"cancelButton"} danger onClick={()=>{
                   setVisibleOfChangeModal(false);
               }} loading={loadingOfSubmitChangeToTemporaryTableButton}>取消</Button>,
                   <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeRecord.submit()} loading={loadingOfSubmitChangeToTemporaryTableButton}>提交申请</Button>]}>
                <QueryEntityByDetail setFunc={callBackFuncForQueryEntityByDetail}/>
                <Form form={formOfChangeRecord} onFinish={handleSubmitChangeRecordToTemporaryTable}>
                    <Form.Item label={"提交时间"} name={"recordCreatedTime"} rules={[{required:true,message:"提交时间不能为空！"}]}>
                        <Input type={"text"} disabled/>
                    </Form.Item>
                    <Row>
                        <Col span={20}>
                            <Form.Item label={"编号"} name={"bianhao"} rules={[{required:true,message:"请填写出库的实体的编号"}]}>
                                <Input onChange={()=>setAllWarehouseOfBianhaoWhenChange([])} type={"text"} disabled={disableOfInputWhenChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}><Button type={"dashed"} onClick={()=>{
                            setDisableOfInputWhenChange(false);formOfChangeRecord.resetFields(["warehouseLocation"]);
                        }}>修改编号</Button></Col>
                        <Col span={2}>
                            <Tooltip title={"点击查询库位后，若编号为有效编号，则会被锁定。若想修改点击旁边的修改编号按钮即可。"} color={"blue"}>
                                <Button type={"primary"} loading={loadingOfButtonQueryWarehouseLocationWhenChange} onClick={()=>getWarehouseLocationWhenChange('fromModal')}>查询库位</Button>
                            </Tooltip>
                        </Col>
                    </Row>

                    <Form.Item label={"出库的数量"} name={"quantity"}
                               rules={[
                                   {required:true,message:"请输入数量"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           let selectedLocationId = getFieldValue(["warehouseLocation"]);
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("数量必须为正数！并带有小数"));
                                           }
                                           if (selectedLocationId!==undefined){
                                               selectedLocationId = convertArrayResult(selectedLocationId);
                                               //判断是否超过了可出库的数量
                                               let allowedOutQuantity = allWarehouseOfBianhaoWhenChange.filter(item=>item["locationId"] === selectedLocationId)[0]["quantity"];
                                               if (Number(value)>Number(allowedOutQuantity)){
                                                   return Promise.reject(new Error("出库的数量大于库存了！"));
                                               }
                                           }
                                           let valueDecimal = new Decimal(value);
                                           if (valueDecimal.comparedTo(new Decimal(0))===0){
                                               return Promise.reject(new Error("出库的数量不能为0"))
                                           }
                                           return Promise.resolve();
                                       }
                                   })
                               ]}>
                        <Input type={"number"} step="0.01"/>
                    </Form.Item>
                    <Form.Item label={"项目"} name={"projectId"} rules={[{required:true,message:"请选择用于的项目！"}]}>
                        <Select showSearch placeholder="请选择使用的项目" disabled={disableOfProjectSelect}
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {arrayOfAllProject.map(item=><Option disabled={item["disabled"] !== "0"} key={item["projectId"]} value={item["projectId"]}>{"【"+item["projectId"]+"】"+item["projectName"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"申请人"} name={"applicant"} rules={[{required:true,message:"请输入申请人！"}]}>
                        <Select
                            placeholder="请选择申请人"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0',}}/>
                                    <Space style={{padding: '0 8px 4px',}}>
                                        <Input placeholder="请输入"
                                               ref={inputRef}
                                               value={name}
                                               onChange={onNameChange}
                                        />
                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                            Add item
                                        </Button>
                                    </Space>
                                </>
                            )}
                            options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                        />
                    </Form.Item>
                    <CascaderSelect labelName={"离开的库位"} warehouseItemArray={allWarehouseOfBianhaoWhenChange} itemName={"warehouseLocation"} setOptionsForProp={setOptionsForCascader}/>
                    {/*<Form.Item label={"离开的库位"} name={"warehouseLocation"} rules={[{required:true,message:"请选择库位！"}]}>*/}
                    {/*    <Select showSearch placeholder="请选择接受的库位" onChange={()=>formOfExWarehouse.validateFields(["quantity"])}*/}
                    {/*            optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}*/}
                    {/*            style={{"width":"100%"}}>*/}
                    {/*        {allWarehouseOfBianhaoWhenChange===undefined?[]:allWarehouseOfBianhaoWhenChange.map(item=><Option disabled={item["disabled"] !== "0"} key={item["locationId"]} value={item["locationId"]}>{item["locationName"]}</Option> )}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={"要货单"} name={"requisitionId"}>
                        <Select showSearch placeholder="请选择关联的要货单" allowClear onClear={()=>setDisableOfProjectSelect(false)}
                                optionFilterProp="children" onSelect={(requsitionId)=>handleSelectRequisition(requsitionId,formOfChangeRecord)}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {requisitionForShow.map(item=><Option key={item["id"]} value={item["id"]}>{"【"+item["create_time"]+"】"+item["id"]}</Option> )}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Card title={"暂存表"} type={"inner"} extra={submittedExRecordInTemporaryTable === null||submittedExRecordInTemporaryTable.length===0?"暂存表中无您的出库记录。":
                <Space><Text>第一条记录的提交时间：<Text type={"warning"}>{submittedExRecordInTemporaryTable["createdTime"]}</Text></Text>
                    <Popconfirm title={"确定合并到主库吗？该操作不可撤销！"} okText={"合并"} cancelText={"取消"} onConfirm={()=>combineOutRecordTemporaryToMain()}>
                        <Button type={"dashed"} danger >提交到主库</Button>
                    </Popconfirm></Space>}>
                <Table bordered columns={columns}  scroll={{x: 1300}}
                       dataSource={submittedExRecordInTemporaryTable===null?[]:submittedExRecordInTemporaryTable["dataInProgress"]}
                       loading={loadingOfTable} rowKey={record => record["recordCreatedTime"]}/>
            </Card>

        </Card>
    );
}

export default Ex_warehouse;