import React, {useEffect, useState} from 'react';
import {Form, Card, Input, Button, message, Steps, Modal, Space, Spin} from 'antd';
import Cards_of_Add_Entity from "../../../components/Cards_of_Add_Entity"
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {useLocation} from "react-router-dom";
function Add_Entity(props) {
    const {state} = useLocation();
    const [visibleOfAddCategoryModal,setVisibleOfAddCategoryModal] = useState(false);
    const [formOfAddCategory] = Form.useForm();
    const [allCategory,setAllCategory] = useState([]);
    const [confirmLoading,setConfirmLoading] = useState(false);
    const { Step } = Steps;

    const steps = [
        {
            title: '第一步：填写实体名和id',
            content: 'id只能以英文数字或下划线_组成，实体名用于呈现给使用人员尽量用中英文，方便理解。',
        },
        {
            title: '第二步：填入实体的属性和属性的可选值',
            content: '实体的属性由id和名字组成，id以英文数字或下划线_组成，名字用于呈现给使用人员尽量用中英文，方便理解。',
        },
        {
            title: "第三步：提交验证",
            content: "系统会自动验证填写的数据是否符合规定，并返回提交结果。"
        }
    ];
    const [current_step, setCurrent_step] = useState(0);


    useEffect(() => {
        console.log("state是")
        console.log(state)
        if (state!=null){
            if (state["ifModified"] != null && state["ifModified"]){
                message.info("目前是修改模式！")
            }
        }

        // console.log(ifModified)

    }, []);



    const onChange = (value) => {
        console.log('onChange:', current_step);
        setCurrent_step(value);
    };

    const handleSubmitAddCategoryFinal = (value)=>{
        post(APIOBJECT["addCategory"],JSON.stringify({"categoryId":value["categoryId"],"categoryName":value["categoryName"]})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle);
            if (resultAfterHandle!==false){
                setConfirmLoading(false);
                message.success("大类添加成功！");
                setTimeout(()=>window.location.href = window.location.href,500)
            }else{
                message.error("添加失败！失败原因："+resultBeforeHandle["msg"]);
                setConfirmLoading(false);
            }
        }).catch(error=>{
            message.error("出错了！"+error);
            setConfirmLoading(false);
        })

    }

    const handleSubmitAddCategory = (value)=>{
        if (allCategory.filter(item=>item["categoryName"]===value["categoryName"]).length!==0){
            message.error("该大类名称已存在！");
            return
            /*
            Modal.confirm({
                title:"大类名称已存在？",
                icon: <ExclamationCircleOutlined />,
                content: '您添加的大类名称在数据库中已存在，确认继续添加吗？',
                okText: '确认',
                cancelText: '取消',
                onOk:()=>{
                    setConfirmLoading(true);
                    handleSubmitAddCategoryFinal(value)
                }
            })*/
        }else{
            handleSubmitAddCategoryFinal(value)
        }
    }

    return (
        <Space
            direction="vertical"
            size="middle"
            style={{"width":"100%"}}
        >

        <Card title={"步骤条"} extra={<Button type={"primary"} onClick={()=>setVisibleOfAddCategoryModal(true)}>添加大类</Button>}>
            <Steps current={current_step} onChange={onChange} status={""}>
                {steps.map((item) => (<Step key={item.title} title={item.title} description={item.content}/>))}
            </Steps>
        </Card>
            <Modal title={"添加大类"} onOk={()=>formOfAddCategory.submit()} visible={visibleOfAddCategoryModal}
                   onCancel={()=>setVisibleOfAddCategoryModal(false)} confirmLoading={confirmLoading}>
                <Form form={formOfAddCategory} onFinish={handleSubmitAddCategory}>
                    <Form.Item name={"categoryId"} label={"大类id"} rules={[
                        {required:true,message:"请输入大类id"},
                        ({getFieldValue})=>({
                            validator(_,value){
                                if (allCategory.filter(item=>item["categoryId"]===value).length!==0){
                                    return Promise.reject(new Error("该大类id已存在！请换一个大类id！"));
                                }else{
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}>
                        <Input placeholder={"请输入大类id"}/>
                    </Form.Item>
                    <Form.Item name={"categoryName"} label={"大类名称"} rules={[
                        {required:true,message:"请输入大类名称"},
                        ({getFieldValue})=>({
                            validator(_,value){
                                if (allCategory.filter(item=>item["categoryName"]===value["categoryName"]).length!==0){
                                    return Promise.reject(new Error("该大类名称已存在！"));
                                }else{
                                    return Promise.resolve();
                                }
                            }
                        })
                    ]}>
                        <Input placeholder={"请输入大类名称"}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Cards_of_Add_Entity step_num={current_step} setAllCategory={setAllCategory} ifCopy={state==null?null:state["ifCopy"] ===null?null:state["ifCopy"]}
                                 ifModified={state==null?false:state["ifModified"]} modifyingEntity={state==null?null:state["entityInformation"]}/>
        </Space>
    );
}

export default Add_Entity;