import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Tooltip} from "antd";
import Dynamic_form from "../Dynamic_form";
import $ from "jquery";
import {getToken} from "../../../utlis/auth";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import {useNavigate} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {deepCopyObj} from "../../../utlis/StringUtils";

function Index(props) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});



    const {Option} = Select;
    const [form_modal] = Form.useForm();//用于调用表单的方法
    const [isModalVisible, setIsModalVisible] = useState(false);//用于模拟Modal
    const [properties_name_array,setProperties_name_array] = useState([]);//只用于存属性名，构造tab需要使用到它
    const {properties_array,setProperties_array,markChangableArray,setMarkChangableArray}=props;
    const [activeTabKey, setActiveTabKey] = useState(properties_array[0]?properties_array[0].id_of_property:null);
    const [datasource_of_reuse_properties,setDatasource_of_reuse_properties] = useState(null);
    const [visible_of_reuse_properties_modal,setVisible_of_reuse_properties_modal] = useState(false);

    //这里写是因为Dynamic_form会先于Cards_of_Add_Properties加载，这时activeTabKey通过usestate直接设置为null的话，Dynamic_form就会接受到null了...
    const navigate = useNavigate()
    //从上层传来的完整属性数组中初始化properties_name_array
    useEffect(() => {
        console.log("倒数第二层初始化了");
        console.log("propertiesarray是：",properties_array)
        const temp_array = properties_array.map((item)=>{
            return{
                key:item.id_of_property,
                tab:item.chinese_name
            }
        })
        console.log("在Cards_of_Add_Properties中进行初始化",temp_array)
        setActiveTabKey(temp_array[0]?temp_array[0].key:null)//默认激活数组中的第一个tab
        setProperties_name_array(temp_array);
        return () => {};
    }, []);

    const columns_of_reuse_properties_repository=[
        {
            title:"属性id",
            key:"id_of_reuse_property",
            dataIndex:"id_of_reuse_property",
            ...getColumnSearchProps("id_of_reuse_property")
        },
        {
            title: "属性名",
            key:"chinese_name",
            dataIndex: "chinese_name",
            ...getColumnSearchProps("chinese_name")
        },
        {
            title: "父实体id",
            key:"id_of_entity_from",
            dataIndex: "id_of_entity_from",
            ...getColumnSearchProps("id_of_entity_from")
        },
        {
            title: "可选值",
            key:"json_options",
            dataIndex: "json_options",
            render:(_,{json_options})=>
            {
                return (
                <Select
                    showSearch
                    optionFilterProp="children"
                    defaultValue={json_options[0]}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{"width":"100%"}}
                >
                    {json_options.map((item)=><Option key={item}>{item}</Option>)}
                </Select>
            )}

        },
        {
            title: "已绑定的实体",
            key:"bind_entities",
            dataIndex: "bind_entities",
            render:(_,{bind_entities})=>
            {
                return (
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={bind_entities[0]}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}
                    >
                        {bind_entities.map((item)=><Option key={item}>{item}</Option>)}
                    </Select>
                )}
        },
        {
            title: "操作",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Popconfirm title={"单独使用后不会与库产生关联"}  onConfirm={()=>lonely_use_property(record)}>
                            <Button type={"danger"} size={"small"}>单独使用</Button>
                        </Popconfirm>
                        <Popconfirm title={"组合使用后，该属性会被添加库中，集中管理"}  onConfirm={()=>bind_use_property(record)}>
                            <Button type={"danger"} size={"small"} danger>组合使用</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    const showModal = () => {
        setIsModalVisible(true);
    };

    const moveTab=(tabKey,direction)=>{
        let tempArrayForProperties_name_array = [];
        let tempArrayForProperties_array = [];
        let indexOfTab;
        let objOfProperties_name_array;
        let objOfProperties_array;

        if(properties_name_array.length !== properties_array.length){
            message.error("请点击锁定后再移动！");
            return;
        }

        for (let index in properties_name_array){
            tempArrayForProperties_name_array.push(deepCopyObj(properties_name_array[index]));
            if (properties_name_array[index]["key"] === tabKey){
                indexOfTab = Number(index);
                objOfProperties_name_array=deepCopyObj(properties_name_array[index]);
                objOfProperties_array = deepCopyObj(properties_array[index]);
            }
            tempArrayForProperties_array.push(deepCopyObj(properties_array[index]));
        }




        if(direction === 1){//即往后移一个
            if(indexOfTab === tempArrayForProperties_array.length - 1){
                message.error("已经是最后一个了，无法后移！");
                return;
            }else{
                tempArrayForProperties_name_array.splice(indexOfTab,1);
                tempArrayForProperties_name_array.splice(indexOfTab + 1,0,objOfProperties_name_array);

                tempArrayForProperties_array.splice(indexOfTab,1);
                tempArrayForProperties_array.splice(indexOfTab + 1,0,objOfProperties_array);
            }
        }else{
            //往前移动一位
            if(indexOfTab === 0){
                message.error("已经是第一个了，无法前移！");
                return;
            }else{
                tempArrayForProperties_name_array.splice(indexOfTab,1);
                tempArrayForProperties_name_array.splice(indexOfTab - 1,0,objOfProperties_name_array);

                tempArrayForProperties_array.splice(indexOfTab,1);
                tempArrayForProperties_array.splice(indexOfTab - 1,0,objOfProperties_array);
            }
        }
        console.log(tempArrayForProperties_name_array);
        console.log(tempArrayForProperties_array);
        setActiveTabKey(tabKey);
        setProperties_name_array(tempArrayForProperties_name_array);
        setProperties_array(tempArrayForProperties_array);
    }

    const change_tab_name = (tab_item_key,tab_item_name)=>{
        let tempArray = [];
        for(let index in properties_name_array){
            if (properties_name_array[index]["key"] === tab_item_key){
                tempArray.push({key:tab_item_key,tab:tab_item_name});
            }else{
                tempArray.push(deepCopyObj(properties_name_array[index]));
            }
        }
        setProperties_name_array(tempArray);
        setActiveTabKey(tab_item_key);
    }


    const remove_tab_item = (tab_item_key)=>{
        const new_properties_array = properties_name_array.filter(item => item.key!== tab_item_key);

        //更新Cards_of_Add_Entity中的properties_array
        const new_properties_array2 = properties_array.filter(item => item.id_of_property !== tab_item_key);
        setProperties_array(new_properties_array2);
        if (new_properties_array.length === 0){
            setProperties_name_array(new_properties_array);
            setActiveTabKey(null);
        }else{
            setActiveTabKey(new_properties_array[0].key);
            setProperties_name_array(new_properties_array);
        }

    }

    const onFinish_Modal = (result)=>{
        //判断是否有重复的属性，依据id来判断
        for (let i=0;i<properties_name_array.length;i++){
            if (properties_name_array[i].key === result.id_of_property){
                form_modal.resetFields(["id_of_property"]);
                message.error("已有该属性值！属性值为‘"+properties_name_array[i].tab+"‘；请添加重复的属性值！按照id进行区分！")
                return;
            }
        }
        setIsModalVisible(false);
        form_modal.resetFields();
        if (properties_name_array.length === 0){
            setProperties_name_array([{key:result.id_of_property,tab:result.name_of_property}])
            setActiveTabKey(result.id_of_property);
        }else{
            setProperties_name_array([...properties_name_array,{key:result.id_of_property,tab:result.name_of_property}])
            setActiveTabKey(properties_name_array[properties_name_array.length-1].key);
        }
    }

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const getRusedProperties = ()=>{
        post(APIOBJECT["getRuseProperties"],null,message,navigate).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message);
            if (resultAfterHandle!==false){
                // console.log(resultAfterHandle)
                setDatasource_of_reuse_properties(resultAfterHandle["data"]);
                setVisible_of_reuse_properties_modal(true);
            }
        })
    }

    const lonely_use_property = (record)=>{
        let temp = properties_array;
        //判断一些id是否有重复的
        for (let index in temp){
            if (temp[index]["id_of_property"]===record["id_of_reuse_property"]){
                message.error("在已添加的属性中已有重复的了！");
                return
            }
        }

        temp.push({chinese_name:record["chinese_name"],
            id_of_property:record["id_of_reuse_property"],
            options:record["json_options"]});
        setProperties_array(temp);

        let temp2 = markChangableArray;
        temp2.push({id_of_property:record["id_of_reuse_property"],ableToChange:true})

        setMarkChangableArray(temp2);
        if (properties_name_array.length === 0){
            setProperties_name_array([{key:record.id_of_reuse_property,tab:record.chinese_name}])
            setActiveTabKey(record.id_of_reuse_property);
        }else{
            setProperties_name_array([...properties_name_array,{key:record.id_of_reuse_property,tab:record.chinese_name}])
            setActiveTabKey(properties_name_array[properties_name_array.length-1].key);
        }
        setVisible_of_reuse_properties_modal(false);
        setIsModalVisible(false);
    }

    const bind_use_property = (record)=>{
        let temp = properties_array;
        //判断一些id是否有重复的
        for (let index in temp){
            if (temp[index]["id_of_property"]===record["id_of_reuse_property"]){
                message.error("在已添加的属性中已有重复的了！");
                return
            }
        }
        temp.push({chinese_name:record["chinese_name"],
            id_of_property:record["id_of_reuse_property"],
            options:record["json_options"],
            bind:{
                id_of_entity_from:record["id_of_entity_from"],
                id_of_reuse_property:record["id_of_reuse_property"]
            }
        });

        if (properties_name_array.length === 0){
            setProperties_name_array([{key:record.id_of_reuse_property,tab:record.chinese_name}])
            setActiveTabKey(record.id_of_reuse_property);
        }else{
            setProperties_name_array([...properties_name_array,{key:record.id_of_reuse_property,tab:record.chinese_name}])
            setActiveTabKey(properties_name_array[properties_name_array.length-1].key);
        }
        setProperties_array(temp);

        let temp2 = deepCopyObj(markChangableArray);
        temp2.push({id_of_property:record["id_of_reuse_property"],ableToChange:false})
        console.log("添加了！")
        setMarkChangableArray(temp2);

        setVisible_of_reuse_properties_modal(false);
        setIsModalVisible(false);
    }

    const deleteMarkAbleArray = (id_of_property)=>{
        let temp2 = deepCopyObj(markChangableArray);
        let temp = temp2.filter(item=>item["id_of_property"]!==id_of_property)
        setMarkChangableArray(temp);
    }

    return (
        <Card title={"添加属性"} tabList={properties_name_array}
            onTabChange={(key) => {
                onTabChange(key);
            }}
            activeTabKey={activeTabKey}
            extra={
                <Space size={"middle"}>
                    <Tooltip title={"属性首次被添加后，请点击锁定！否则会遗失数据！"} color={"red"}>
                         <Button type={"primary"} onClick={showModal}>添加属性</Button>
                    </Tooltip>
                </Space>
            }
        >

            <Modal title={"属性库"}
                   visible={visible_of_reuse_properties_modal}
                   onCancel={()=>setVisible_of_reuse_properties_modal(false)}
                    width={"80%"}
            >
                <Table columns={columns_of_reuse_properties_repository} dataSource={datasource_of_reuse_properties} rowKey={record => record["id_of_reuse_property"] + record["id_of_entity_from"]}/>
            </Modal>

            <Modal title="请输入属性的id和中文名" visible={isModalVisible}
                   onOk={()=>form_modal.submit()}
                   onCancel={()=>setIsModalVisible(false)}
                    footer={ [
                        <Tooltip title={"从属性库中选取属性"} color={"green"}>
                            <Button type={"dashed"} danger onClick={()=>getRusedProperties()}>从库中获取</Button>
                        </Tooltip>,
                        <Button type={"primary"} onClick={()=>setIsModalVisible(false)}>取消</Button>,
                        <Button type={"primary"} onClick={()=>form_modal.submit()}>确定</Button>
                    ]}
            >
                <Form form={form_modal} name={"form_modal"} onFinish={(r) => onFinish_Modal(r)} onFinishFailed={()=>message.error("请输入正确的id和名字！")}>
                    <Form.Item
                        label={"属性的id"}
                        name={"id_of_property"}
                        rules={[{
                            required: true,
                            message:"请输入正确的属性的id(由英文和数字或下划线组成)",
                            pattern:"^[A-Za-z0-9_]+$"
                        }
                        ]}
                    >
                        <Input type={"text"} placeholder={"请输入属性的id(由英文和数字或符号组成)"}/>
                    </Form.Item>

                    <Form.Item
                        label={"属性的名字"}
                        name={"name_of_property"}
                        rules={[{
                            required: true,
                            message:"请输入正确的名字",
                        }
                        ]}
                        help={"该项用于在选择属性时表示该属性，方便人员理解。"}
                    >
                        <Input type={"text"} placeholder={"请输入属性的名字（最好由中文组成，可以带有英文和数字）"}/>
                    </Form.Item>
                    {/*<h3>该项用于在选择属性时表示该属性，方便人员理解。</h3>*/}

                </Form>
                {/*<h2>属性的id</h2><input placeholder={"请输入属性的id(由英文和数字或符号组成)"} name={"id_of_property"} style={{"width":"100%"}}/><br/>*/}
                {/*<h2>属性的中文名</h2><input placeholder={"请输入属性的中文名"} name={"id_of_property"} style={{"width":"100%"}}/>*/}
            </Modal>



            <Dynamic_form property_id = {activeTabKey}
                          property_chinese_name ={properties_name_array.map((item)=>{
                              return item.key === activeTabKey?item.tab:null
                          })}
                          change_tab_name={change_tab_name}
                          delete_function = {remove_tab_item} properties_array={properties_array}
                          setProperties_array={setProperties_array} markChangableArray={markChangableArray}
                          deleteMarkAbleArray={deleteMarkAbleArray}//用于删除标记可编辑性数组的元素的函数
                          moveTab={moveTab}
            />


        </Card>
    );
}

export default Index;