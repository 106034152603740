import React, {useEffect, useRef, useState} from 'react';
import "./Query_number.css"
import {
    Button, Card, Collapse, Empty, Form, Input, message, Modal,
    Popconfirm, Select, Space, Spin, Table, Tooltip, Typography, Cascader, Divider
} from "antd";
import {useNavigate, useOutlet} from "react-router-dom";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import QRCode from 'qrcode.react';
import {deepCopyObj} from "../../../utlis/StringUtils";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {handleResponseResultOfQueryEntityDetailsTable} from "../../../utlis/UniversalFunctions";
import ImageOfEntity from "../../../components/Query/ImageOfEntity";
import WarehouseLocationTable from "../../../components/Query/WarehouseLocationTable";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const {Option} = Select;

function Query_number(props) {


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */



    const [options_of_select,setOptions_of_select] = useState(null);
    const [loading_of_properties_card,setLoading_of_properties_card] = useState(true);
    const [properties_to_be_chosen,setProperties_to_be_chosen] = useState(null);//用于渲染select组件的数组
    const [result_of_number_query,setResult_of_number_query] = useState(null);
    const [form_of_number_query] = Form.useForm();
    const { Panel } = Collapse;
    const [visible_of_modal,setVisible_of_modal] = useState(false);
    const [visibleOfQrcodeModal,setVisibleOfQrcodeModal] = useState(false);
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const [allProperties,setAllProperties] = useState([]);//用于存放从服务器获取的所有的属性
    const [constraintOfSelectingProperties,setConstraintOfSelectingProperties] = useState([]);
    const [keyArrayOfconstraintOfSelectingProperties,setKeyArrayOfconstraintOfSelectingProperties] = useState([]);//对其他属性有约束的属性id
    const [seletedOptionAndValue,setSeletedOptionAndValue]  = useState([]);//用于维护已选的属性以及其可选值

    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [textUnderQRCode,setTextUnderQRCode] = useState(null);
    const [entityNameFroQRCode,setEntityNameFroQRCode] = useState(null);
    const [visibleOfImageModal,setVisibleOfImageModal] = useState(false);
    const [selectedEntityId,setSelectedEntityId] = useState(null);
    const [visibleOfWarehouseLocationModal,setVisibleOfWarehouseLocationModal] = useState(false);
    const [visibleOfFuzzyQueryModal,setVisibleOfFuzzyQueryModal] = useState(false);
    const [dataSourceOfFuzzyQueryTable,setDataSourceOfFuzzyQueryTable] = useState([]);
    const [visibleOfLocationModal,setVisibleOfLocationModal] = useState(false);
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [toBeCheckedBianhao,setToBeCheckedBianhao] = useState("");
    const [loadingOfPropertyButton,setLoadingOfPropertyButton] = useState(false);
    const [loadingOfLocationButton,setLoadingOfLocationButton] = useState(false);
    const [dataForCascader,setDataForCascader] = useState([]);

    const { Text,Link,Paragraph,Title } = Typography;
    const navigate = useNavigate();
    const [typeOfQuery,setTypeOfQuery] = useState(null);
    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            // console.log("不相同！")
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisible_of_modal(true);
    }


    useEffect(() => {
        post(APIOBJECT["getAllEntitiesAndCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let allCategoryArr = resultAfterHandle["data"]["allCategory"];
                let allEntitiesArr = resultAfterHandle["data"]["allEntityConstraint"];
                setOptions_of_select(allEntitiesArr);
                setCategoryArrayForSelect(resultAfterHandle["data"]["allCategory"]);
                //构建用于Cascader的数组
                let dataForCacsader = [];
                for(let item of allCategoryArr){
                    dataForCacsader.push({
                        label:item["categoryName"],
                        value:item["categoryId"],
                        children:allEntitiesArr.filter(entity => entity["categoryId"] == item["categoryId"])
                            .map((entity) => {
                                return {label:entity["chinese_name"],value:entity["id_of_entity"]}
                            })
                    })
                }
                setDataForCascader(dataForCacsader);
            }else{
                message.error("当前大类或实体为空，请先添加大类或实体！");
            }
        }).catch(error=>message.error("出现错误！"+error))

        return () => {
        };


    }, []);

    const load_properties = (value)=>{
        setLoading_of_properties_card(true);
        setResult_of_number_query(null);
        setSelectedEntityId(value);
        let constraintOuterJson = JSON.parse(options_of_select.filter(item=>item["id_of_entity"] == value)[0]["constraint"]);//用于描述实体的json
        let frozen_properties = constraintOuterJson["frozen_properties"];
        let constraintOfProerties = constraintOuterJson["constraint"];//用于约束的规则
        let keyArrayOfconstraintOfProerties = [];
        for (let key in constraintOfProerties){
            keyArrayOfconstraintOfProerties.push(key);
        }
        setKeyArrayOfconstraintOfSelectingProperties(keyArrayOfconstraintOfProerties);
        setConstraintOfSelectingProperties(constraintOfProerties);
        let keys_of_frozen_properties = [];
        for (let key in frozen_properties){
            keys_of_frozen_properties.push(key);
        }

        let properties = constraintOuterJson["properties"];
        // console.log(constraintOuterJson)
        let orderOfProperties = constraintOuterJson["orderOfProperties"];
        let tempPropertiesArrayForAllProperties = [];//用于给allProperties赋值
        for (let index in orderOfProperties){
            // console.log(orderOfProperties[index])
            let options_temp = properties[orderOfProperties[index]]["options"];
            let options_frozen = [];
            let options_available = [];
            options_temp.push("");
            //处理冻结和解冻的属性
            if (keys_of_frozen_properties.includes(orderOfProperties[index])){
                options_frozen=frozen_properties[orderOfProperties[index]];
                options_available=options_temp.filter((item)=>!frozen_properties[orderOfProperties[index]].includes(item))
            }else{//说明没有冻结的属性
                options_available=options_temp;
            }
            tempPropertiesArrayForAllProperties.push({
                id_of_property:orderOfProperties[index],
                chinese_name_of_property:properties[orderOfProperties[index]]["chinese_name"],
                options_available,
                options_frozen:options_frozen
            })
        }


        let tempArray = [];
        tempArray.push(tempPropertiesArrayForAllProperties[0]);


        //判断一些是否有constraint约束
        if (keyArrayOfconstraintOfProerties.length===0){//说明没有约束规则，直接全部放进去
            setProperties_to_be_chosen(tempPropertiesArrayForAllProperties);
        }else{
            //有约束规则，则把第一个推进去
            setProperties_to_be_chosen(tempArray);
        }


                setAllProperties(tempPropertiesArrayForAllProperties);

            setLoading_of_properties_card(false)


        form_of_number_query.resetFields(["properties"]);//必须要重置一下，不重置的话，有可能出现不同实体的相同属性之间串值，举个例子
        //实体A的zhijing属性有80cm的可选值，实体B的zhijing属性没有80cm的可选值，在实体A的zhijing属性选择80cm之后，切换到实体B
        //实体B的zhijing属性就会展现出80cm的值（尽管这个值并不包含在可选值内）
    }

    const handlePreciseQuerySubmit=(values)=>{
        let properties={};
        if (keyArrayOfconstraintOfSelectingProperties.length!==0){//说明是约束模式
            if (seletedOptionAndValue.length!==allProperties.length){
                message.error("请选择完毕所有的属性再点击此按钮！");
                return;
            }
            for (let index in seletedOptionAndValue){
                // properties.push({[seletedOptionAndValue[index]["idOfProperty"]]:seletedOptionAndValue[index]["value"]});
                properties[seletedOptionAndValue[index]["idOfProperty"]]=seletedOptionAndValue[index]["value"];
            }
        }else{//说明是非约束模式
            properties = values["properties"];
            for (let key in properties){
                //检查是否有未选值的,未选值的是undefined
                if (typeof(properties[key])=="undefined"){
                    message.error("请将全部属性选值完毕后再进行查询！属性id为"+key+"未选值！")
                    return;
                }
            }

        }

        //构造textUnderQRCode
        let textUnderQRCode ="";
        let entityId = values["id_of_entity"][values["id_of_entity"].length - 1];
        console.log(entityId)
        let entityName = options_of_select.filter(item=>item["id_of_entity"] === entityId)[0]["chinese_name"] + "\\";

        console.log(properties_to_be_chosen)
        for(let index in properties_to_be_chosen){
            if (properties[properties_to_be_chosen[index]["id_of_property"]]==="空值"){
                continue;
            }
            if (Number(index) === properties_to_be_chosen.length-1){
                // console.log(properties[properties_to_be_chosen[index]["id_of_property"]])
                textUnderQRCode += properties[properties_to_be_chosen[index]["id_of_property"]];
            }else{
                // console.log(properties[properties_to_be_chosen[index]["id_of_property"]])
                textUnderQRCode += properties[properties_to_be_chosen[index]["id_of_property"]]+"\\";
            }
        }
        let rightSideMostLengthOfWords = 28;
        if (entityName.length<rightSideMostLengthOfWords){
            if (textUnderQRCode.length>28-entityName.length){
                let tempLength = entityName.length;
                entityName+="\\"+textUnderQRCode.substring(0,rightSideMostLengthOfWords-tempLength);
                textUnderQRCode=textUnderQRCode.slice(rightSideMostLengthOfWords-tempLength);
            }else{
                entityName+="\\"+textUnderQRCode;
                textUnderQRCode = "";
            }
        }
        setEntityNameFroQRCode(entityName);
        setTextUnderQRCode(textUnderQRCode);
        setResult_of_number_query(null);


        if (typeOfQuery!=null){
            post(APIOBJECT["queryNumberByEntity"],JSON.stringify({"properties":properties,
                "id_of_entity":entityId,"type":typeOfQuery})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false) {
                    let result = resultAfterHandle["data"];
                    setResult_of_number_query(result)
                }
            }).catch(error=>message.error("出错啦！"+error))
        }else{
            message.error("属性不能全选空！");
        }

    }

    const handle_query=(values)=>{
        checkPropertiesOfBianhao(values["number_of_entity"])
    }


    const handleOnSelectWhenSelectPropertiesOption = (idOfProperty,selectedValue)=>{
        //判断是否开启了constraint约束模式，判断条件就是看里json的constraint是否有值
        if (keyArrayOfconstraintOfSelectingProperties.length===0){//说明未开启
            return;
        }
        let tempArray3 = deepCopyObj(seletedOptionAndValue);
        let selectIndex = -1;
        for (let index in allProperties){
            if (allProperties[index]["id_of_property"]===idOfProperty){
                if (Number(index) === allProperties.length-1){
                    //说明选到了最后一个了。只需维护seletedOptionAndValue，不用再加<Option>了。

                    if (tempArray3.length===allProperties.length){//说明是已选过这个选项的值了，
                        //重新赋值
                        tempArray3[tempArray3.length-1] = {idOfProperty:idOfProperty,value:selectedValue};
                    }else{//说明还没有选这个值
                        tempArray3.push({idOfProperty:idOfProperty,value:selectedValue});
                    }

                    setSeletedOptionAndValue(tempArray3);
                    // console.log("选择的是最后一个，当前维护的已选的可选值的数组为");
                    // console.log(tempArray3);
                    return;
                }else{
                    selectIndex = Number(index);
                }
            }
        }

        if (selectIndex===-1){
            //说明没和从服务器获取的allProperties匹配上，则返回并报错
            message.error("未匹配上！请联系管理员！");
            return;
        }
        //有一种情况：选了A->B->C->D之后，又反过头来把B的值改了这个时候为了
        // 防止C和D能选到不能被选中的选项，所以要重新选择
        selectIndex = Number(selectIndex);

        let tempArray = deepCopyObj(properties_to_be_chosen);
        let tempArray2 = deepCopyObj(allProperties);


        if (selectIndex+1 === tempArray.length){//说明是正常的一步一步选择下来，当前选择的选项是最新的选项
            // console.log(tempArray2[selectIndex+1])
            tempArray3.push({idOfProperty:idOfProperty,value:selectedValue});
            setSeletedOptionAndValue(tempArray3);
            tempArray.push(handleOptionOfSelect(idOfProperty,selectedValue,tempArray2[selectIndex+1],tempArray3));//加入的元素肯定是处理过的，比如有按照前面的已选值，哪些可以选，哪些不能选。
        }else{//说明用户掉过头来又选择已经选择过的选项了
            tempArray.splice(selectIndex+1,tempArray.length-selectIndex-1);
            //比如已经选择了A->B->C->D，此时又重选了B，那么就会把原来的C和D，加入按照B已选的值处理过后的C，这里处理的步骤先省略
            let tempArray4 = [];
            //用于维护seletedOptionAndValue，删去之前已选的选项，因为前置条件已变化
            for (let index3 in tempArray3){
                if (tempArray3[index3]["idOfProperty"]===idOfProperty){
                    tempArray4.push({idOfProperty:idOfProperty,value:selectedValue});
                    // console.log("break了")
                    break;
                }else{
                    tempArray4.push(tempArray3[index3]);
                }
            }

            tempArray3=tempArray4;
            setSeletedOptionAndValue(tempArray3);
            tempArray.push(handleOptionOfSelect(idOfProperty,selectedValue,tempArray2[selectIndex+1],tempArray3));//加入处理过后的C
        }
        setProperties_to_be_chosen(tempArray);
        form_of_number_query.resetFields(["properties"]);
    }

    const handleOptionOfSelect = (idOfProperty,valueOfPreviousOption,item,seletedOptionAndValueTemp)=>{


        //idOfProperty是当前选择的属性的id;valueOfPreviousOption是当前选择的属性的选择的可选值
        // item是下一个要加入properties_to_be_chosen的select

        //如果没有开启约束模式，则直接返回即可
        if (keyArrayOfconstraintOfSelectingProperties.length===0){
            return item;
        }


        let arrayOfSelectedPropertyId = [];

        //得从数据库中查的返回的约束中取出，即取自本源
        let frozenValueOfOptionArray;//用于标注item中不可选的属性，这个数组由constraint中的信息组成，首先由item的options_frozen初始化
        let options_available;//得从数据库中查的返回的约束中取出，即取自本源
        //得从数据库中查的返回的约束中取出，即取自本源
        for (let index in allProperties){
            if (allProperties[index]["id_of_property"] === item["id_of_property"]){//获取值的时候必须做深拷贝，不然会改变
                frozenValueOfOptionArray=deepCopyObj(allProperties[index]["options_frozen"]);
                options_available=deepCopyObj(allProperties[index]["options_available"]);
            }
        }
        //先获得已选择的属性的id，并将它们组成array
        for (let index in seletedOptionAndValueTemp){
            arrayOfSelectedPropertyId.push(seletedOptionAndValueTemp[index]["idOfProperty"]);
        }
        // arrayOfSelectedPropertyId.push(idOfProperty);
        // console.log(arrayOfSelectedPropertyId);
        //seletedOptionAndValue不会包含刚刚选的这个，有一点延迟 所以需要自己做一些处理



        //step1.判断已选择的属性是否有约束规则
        //step2.若有，则判断该属性的已选择的值是否有约束规则，是否是对即将加入properties_to_be_chosen的item的约束
        //step3.若是，则取出禁选值，加入到frozenValueOfOptionArray，注意是去重加入


        for (let indexOuter in arrayOfSelectedPropertyId){
            //判断已选择的属性是否有constraint，当在keyArrayOfconstraintOfSelectingProperties能找到时，说明这个已选择的属性
            //有约束规则，则进一步去判断是否有对于item的禁选项
            if (keyArrayOfconstraintOfSelectingProperties.indexOf(arrayOfSelectedPropertyId[indexOuter])!==-1){
                // console.log("继续判断已选的属性的选择值是否有约束规则");
                //继续判断已选的属性的选择值是否有约束规则
                let valueOfThisSlectedOption = seletedOptionAndValueTemp
                    .filter(item=>item["idOfProperty"]===arrayOfSelectedPropertyId[indexOuter])[0]["value"];
                // console.log(valueOfThisSlectedOption);
                let constraintTempJson = constraintOfSelectingProperties[arrayOfSelectedPropertyId[indexOuter]];
                for (let indexInner in constraintTempJson){
                    if (indexInner===valueOfThisSlectedOption){//说明该可选值是有约束的，接下来判断该可选值的约束是否是对item的约束
                        // console.log("说明该可选值是有约束的，接下来判断该可选值的约束是否是对item的约束");
                        let innerestJson = constraintTempJson[indexInner];
                        if (innerestJson[item["id_of_property"]]!=null){//说明有约束
                            // console.log("马上加入数组！！！")
                            let frozenOptionArray = innerestJson[item["id_of_property"]];
                            //对不可选数组进行处理，去重添加至最外层的frozenValueOfOptionArray
                            for (let indexOffrozenOptionArray in frozenOptionArray){
                                if (frozenValueOfOptionArray.indexOf(frozenOptionArray[indexOffrozenOptionArray])===-1){
                                    frozenValueOfOptionArray.push(frozenOptionArray[indexOffrozenOptionArray]);
                                }
                            }

                        }
                    }
                }
            }
        }
        let itemForReturn = deepCopyObj(item);
        options_available = options_available.filter(itemAvailable=>frozenValueOfOptionArray.indexOf(itemAvailable)===-1);
        itemForReturn["options_frozen"]=frozenValueOfOptionArray;
        itemForReturn["options_available"] = options_available;


        return itemForReturn;

    }

    const handleClickCheckImageButton = ()=>{
        setVisibleOfImageModal(true);
    }

    const columnsOfFuzzyQueryTable = [
        {
            title: "实体编号",
            key:"bianhao",
            width: 100,
            ellipsis: true,
            dataIndex: "bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "具体型号",
            key:"propertyString",
            ellipsis: true,
            dataIndex: "propertyString",
            ...getColumnSearchProps("propertyString")
        },
        {
            title: "在库数量",
            key:"quantity",
            width: 150,
            ellipsis: true,
            dataIndex: "quantity",
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "操作",
            width: 200,
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Button type={"primary"} size={"small"} onClick={()=>{
                            setLoadingOfPropertyButton(true);
                            if(toBeCheckedBianhao===record["bianhao"]){
                                setLoadingOfPropertyButton(false);
                            }else{
                                setToBeCheckedBianhao(record["bianhao"]);
                            }
                            setVisibleOfPropertiesModal(true);}} loading={loadingOfPropertyButton}>属性详情</Button>
                        <Button type={"default"} size={"small"} onClick={()=>{
                            setLoadingOfLocationButton(true);
                            if(toBeCheckedBianhao===record["bianhao"]){
                                setLoadingOfPropertyButton(false);
                            }else{
                                setToBeCheckedBianhao(record["bianhao"]);
                            }
                            setVisibleOfLocationModal(true);
                        }} loading={loadingOfLocationButton}>库位详情</Button>
                    </Space>)
            }
        }
    ]

    const handleFuzzyQuerySubmit= ()=>{
        let fieldValue = form_of_number_query.getFieldValue();
        let noUndefinedProperties = {};
        if (keyArrayOfconstraintOfSelectingProperties.length!==0){
            //有约束
            for(let index in seletedOptionAndValue){
                noUndefinedProperties[seletedOptionAndValue[index]["idOfProperty"]] = seletedOptionAndValue[index]["value"];
            }

        }else{
            //无约束
            for (let key in fieldValue["properties"]){
                if (typeof (fieldValue["properties"][key]) !== "undefined"){
                    noUndefinedProperties[key] = fieldValue["properties"][key];
                }
            }
        }

        post(APIOBJECT["queryFuzzily"],JSON.stringify({id_of_entity:fieldValue["id_of_entity"][fieldValue["id_of_entity"].length - 1],properties:noUndefinedProperties})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"].length === 0){
                    message.error("查询无结果");
                } else{
                    console.log(resultAfterHandle)
                    setDataSourceOfFuzzyQueryTable(resultAfterHandle["data"]);
                    setVisibleOfFuzzyQueryModal(true);
                }
            }
        }).catch(error=>{
            message.error("handleFuzzyQuerySubmit出错了！"+error);
        })


    }

    const handleClearValue = (id_of_property)=>{
        if (keyArrayOfconstraintOfSelectingProperties.length === 0 ){//没有约束的时候直接返回
            return;
        }
        let copyOfSelectedOptionAndValue = {};
        for(let index in seletedOptionAndValue){
            if (seletedOptionAndValue[index]["idOfProperty"]!==id_of_property){
                copyOfSelectedOptionAndValue[index] = seletedOptionAndValue[index];
            }
        }
        setSeletedOptionAndValue(copyOfSelectedOptionAndValue);
    }

    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);


    return (
        <Space direction="vertical" style={{"width":"100%"}}>
            <Collapse defaultActiveKey={['1']} >
                <Panel header="由条件查编号" key="1">
                    <Card title={"根据条件查询编号"} style={{"width":"100%"}}>
                        <Form onFinish={(values)=>handlePreciseQuerySubmit(values)} form={form_of_number_query}>
                            <Space direction="vertical" style={{"width":"100%"}}>
                                <Form.Item label={"请选择实体"} name={"id_of_entity"} rules={[{required: true, message: '请选择属性!',}]}>
                                    <Cascader
                                        options={dataForCascader}
                                        placeholder="请选择实体"
                                        showSearch={{filter,matchInputWidth:false}}
                                        onChange={(valueArr) => {
                                            //得到的是['GJ', 'WLJDDLSTT']类型的结果，数组的最后一个元素是实体的id
                                            load_properties(valueArr[valueArr.length - 1]);
                                        }}
                                        size={"large"}
                                        showArrow={false}

                                    />
                                </Form.Item>

                                <Card title={"选择属性值"} type={"inner"} loading={loading_of_properties_card}
                                    extra={keyArrayOfconstraintOfSelectingProperties.length===0?
                                            <Text>该实体未设置约束，以默认模式进行属性选择</Text>:
                                            <Text type={"success"}>该实体已设置约束，将以约束模式进行属性选择</Text>}>
                                    <Space wrap size={"large"}>

                                        {/*{keyArrayOfconstraintOfSelectingProperties.length===}*/}

                                    {properties_to_be_chosen!=null?properties_to_be_chosen.map((item)=>{
                                        return (
                                            <Form.Item name={['properties', item.id_of_property]}
                                                       // initialValue={item["options_available"][0]}
                                                       label={item.chinese_name_of_property} key={item.id_of_property}>
                                                <Select key={item.id_of_property} style={{"width":"250px"}}
                                                        showSearch
                                                        allowClear = {true}
                                                    /**
                                                    // defaultValue={item["options_available"].length === 0?"":item["options_available"][0]}  控制属性的默认选择的可选值
                                                    */
                                                    onSelect={(value)=>handleOnSelectWhenSelectPropertiesOption(item.id_of_property,value)}

                                                    onClear={()=>handleClearValue(item.id_of_property)}

                                                    placeholder={"请选择"+item.chinese_name_of_property}

                                                    optionFilterProp="children"

                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>

                                                    {item.options_available.map((option)=><Option key={item.id_of_property+option} value={option}>{option===""?"空":option}</Option>)}

                                                    {item.options_frozen.map((f_option)=><Option key={item.id_of_property+f_option} value={f_option} disabled>{f_option}</Option>)}

                                                </Select>
                                            </Form.Item>
                                        )
                                    })
                                        :<Empty/>}

                                    </Space>
                                </Card>
                                <Space size={"large"}>
                                    <Button type={"primary"} onClick={()=>{
                                        setTypeOfQuery("code");
                                        form_of_number_query.submit();
                                    }}>生成编码</Button>
                                    <Button type={"primary"} onClick={()=>{
                                        setTypeOfQuery("query");
                                        form_of_number_query.submit();
                                    }}>普通查询</Button>
                                    <Button type={"primary"} onClick={handleFuzzyQuerySubmit}>模糊查询</Button>
                                </Space>

                                <Space direction={"vertical"} size={"middle"}>
                                    <Button size={"small"} type={"dashed"} onClick={handleClickCheckImageButton}>查看图片</Button>
                                    <Text>查询or生成的编号结果为：{result_of_number_query==null?<Spin spinning={true}/>:
                                        <Space>
                                            <Tooltip title="点击编号即可获得二维码">
                                                <Link onClick={()=>setVisibleOfQrcodeModal(true)}>{result_of_number_query["number_of_properties"]}</Link>
                                            </Tooltip>
                                            <Text>数量为:{result_of_number_query["quantity"]}</Text>
                                            <Button size={"small"} type={"primary"} onClick={()=>setVisibleOfWarehouseLocationModal(true)}>具体库位</Button>
                                        </Space>}
                                    </Text>
                                    {keyArrayOfconstraintOfSelectingProperties.length!==0?<Text strong={true} type={"success"}>属性选择路径为：{allProperties.map(item=>"->"+item["chinese_name_of_property"])}</Text>:""}
                                </Space>

                                <Modal visible={visibleOfImageModal} title={"实体关系图片"} width={1000} onOk={()=>setVisibleOfImageModal(false)} onCancel={()=>setVisibleOfImageModal(false)}>
                                    <ImageOfEntity bianhao={result_of_number_query==null?"":result_of_number_query["number_of_properties"]} entityId={selectedEntityId}/>
                                </Modal>
                                <Modal
                                    // bodyStyle={{height:"auto",minHeight:"660px"}}
                                    title={"二维码"} width={1000}
                                       onOk={()=>setVisibleOfQrcodeModal(false)} onCancel={()=>setVisibleOfQrcodeModal(false)} visible={visibleOfQrcodeModal}>
                                    <Space direction={"vertical"}>
                                        <Space>
                                            <QRCode value={result_of_number_query===null||result_of_number_query===undefined?""
                                                :result_of_number_query["number_of_properties"]} size={400}  fgColor="#000000" />
                                            <span style={{fontSize:"500%"}}>{entityNameFroQRCode===null?"":entityNameFroQRCode}</span>
                                        </Space>
                                        <span style={{fontSize:"500%"}}>{textUnderQRCode}</span>
                                    </Space>
                                </Modal>

                                <Modal width={1200} title={"模糊查询的结果（已编码但是数量为0的默认不显示）"} visible={visibleOfFuzzyQueryModal} onOk={()=>setVisibleOfFuzzyQueryModal(false)}
                                onCancel={()=>setVisibleOfFuzzyQueryModal(false)} >
                                    <Table bordered columns={columnsOfFuzzyQueryTable} dataSource={dataSourceOfFuzzyQueryTable} rowKey={record => record["bianhao"]}/>
                                </Modal>

                                <Modal width={900} title={"各个库位的情况"} visible={visibleOfLocationModal} onOk={()=>setVisibleOfLocationModal(false)}
                                       onCancel={()=>setVisibleOfLocationModal(false)} okText={"好的"} cancelText={"取消"}>
                                    <WarehouseLocationTable bianhao={toBeCheckedBianhao} setLoading={setLoadingOfLocationButton}/>
                                </Modal>

                                <Modal width={800} title={"具体属性情况"} visible={visibleOfPropertiesModal} onOk={()=>setVisibleOfPropertiesModal(false)}
                                       onCancel={()=>setVisibleOfPropertiesModal(false)} okText={"好的"} cancelText={"取消"}>
                                    <QueryEntityDetailsTable bianhao={toBeCheckedBianhao} setLoading={setLoadingOfPropertyButton}/>
                                </Modal>


                                <Modal title={"具体库位"} width={1000} visible={visibleOfWarehouseLocationModal} onCancel={()=>setVisibleOfWarehouseLocationModal(false)}
                                       onOk={()=>setVisibleOfWarehouseLocationModal(false)}>
                                    <WarehouseLocationTable bianhao={result_of_number_query==null?"":result_of_number_query["number_of_properties"]}/>
                                </Modal>
                            </Space>


                        </Form>


                    </Card>
                </Panel>


                <Panel header="由编号查实体" key="2">
                    <Card title={"根据编号查询实体"} style={{"width":"100%"}}>
                        <Form onFinish={(values)=>handle_query(values)} >
                            <Form.Item
                                label={"请输入编号"}
                                name={"number_of_entity"}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入你要查询的编号!',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type={"primary"} loading={loadingOfCheckBianhaoPropertiesButton} htmlType={"submit"}>查询</Button>
                        </Form>

                        <Modal width={1000} visible={visible_of_modal} onOk={()=>setVisible_of_modal(false)} onCancel={()=>setVisible_of_modal(false)}>
                            <QueryEntityDetailsTable bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
                        </Modal>

                    </Card>
                </Panel>
            </Collapse>
        </Space>

    );
}

export default Query_number;