import React, {useEffect, useRef, useState} from 'react';
import "../../static/css/datePicker.css"
import {
    Button, Card, Collapse, Empty, Form, Input, message, Modal,
    Popconfirm, Select, Space, Spin, Table, Tooltip, Typography, Cascader, Divider, DatePicker
} from "antd";
import {useNavigate, useOutlet} from "react-router-dom";
import {APIOBJECT} from "../../static/CONSTANTS";
import {post} from "../../utlis/request";
import {handleResult} from "../../utlis/response";
import {deepCopyObj} from "../../utlis/StringUtils";
import {judgeIfNum} from "../../utlis/UniversalFunctions";
import {CalendarOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
const {Option} = Select;

function RequisitionQuery(props) {


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const { RangePicker } = DatePicker;
    /**用于构建时间范围选择的固定部分
     */
    const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const getColumnSearchProps2 = (dataIndex) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{padding: 4}}>
                    <RangePicker style={{ marginBottom: 8 }}
                                 value={selectedKeys[0]}
                                 onChange={e => {
                                     setSelectedKeys(e ? [e] : [])
                                 }}
                                 onPressEnter={() => {
                                     confirm();
                                 }}
                                 locale={locale}
                    />
                    <Space>
                        <Button type="primary" onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                            搜索
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small" style={{width: 90,}}>
                            重置
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (<CalendarOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
            ),
            onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(value[0], value[1], 'day', '[]') : "" ,
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
            },
            render: text => moment(text).format("YYYY/MM/DD HH:mm:ss")}
    );

    /**用于构建时间范围选择的固定部分
     */

    /**
     * canSubmitToExamineAndVerify标记是否可以提交审核；fresh用于刷新操作，
     * callBackFunc为回调函数，得到要货单数据后的回调，from表示从哪里调用的这个组件（只有来源是warehouse的时候，才会查询所有的要货单）
     */
    const {canSubmitToExamineAndVerify,fresh,callBackFunc,from,canChangeRequisitionDetail} = props;
    const [formOfChangeRequisitionDetail] = Form.useForm();
    const [unitOfBianhao,setUnitOfBianhao] = useState("");
    const [dataSourceOfRequisition,setDataSourceOfRequisition] = useState([]);
    const [visibleOfChangingRequisitionDetailModal,setVisibleOfChangingRequisitionDetailModal] = useState(false);
    const [disabledOfChangeFormBianhao,setDisabledOfChangeFormBianhao] = useState(true);
    const navigate = useNavigate();



    useEffect(() => {
        getRequisition();
        return () => {
        };
    }, [fresh]);


    const handleChangeRequisitionDetail = (record)=>{
        formOfChangeRequisitionDetail.setFieldsValue({...record});
        setUnitOfBianhao(record["unit"]);
        setVisibleOfChangingRequisitionDetailModal(true);
    }

    const handleDeleteRequisitionDetail = (requisitionDetailId) =>{
        post(APIOBJECT["deleteRequisitionDetailByRequisitionDetailId"],
            JSON.stringify({requisitionDetailId:requisitionDetailId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle!==false){
                getRequisition();
                message.success("删除成功！");
            }

        }).catch(e=>message.error("handleDeleteRequisitionDetail出错了！"+e))
    }

    const columnsOfRequisition = [
        {
            title:"要货单id",
            key:"idForShow",
            dataIndex:"idForShow",
            width: 80,
            ...getColumnSearchProps("idForShow")
        },
        {
            title:"提交日期",
            key:"create_time",
            dataIndex:"create_time",
            width: 150,
            ...getColumnSearchProps2("create_time")
        },
        {
            title:"关联的项目",
            key:"projectName",
            dataIndex:"projectName",
            width: 300,
            ...getColumnSearchProps("projectName")
        },
        {
            title: "当前状态",
            key:"status",
            dataIndex:"status",
            width: 200,
            ...getColumnSearchProps("status")
        },
        {
            title: "操作",
            width:150,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <div style={{display:canSubmitToExamineAndVerify ? "inline" : "none"}}>
                        <Space size={"middle"}>
                            <Popconfirm disabled={record["status"] !== "暂存"} title={"确认提交审核吗？提交审核后，就无法修改此要货单了，只能进行关闭操作！"} okText={"提交审核"} cancelText={"取消"}
                                        onConfirm={()=>handleSubmitMakeTemporaryStorageRequisitionStatusInReview(record["id"])}>
                                <Button type={"default"} size={"small"} disabled={record["status"] !== "暂存"}>提交审核</Button>
                            </Popconfirm>
                            <Button type={"primary"} size={"small"} disabled={record["status"] !== "暂存"} onClick={()=>handleAddNewDetailToExistedRequisition(record["id"])}>增加新项</Button>
                        </Space>
                    </div>)
            }
        }
    ]

    const handleAddNewDetailToExistedRequisition = (requisitionId) =>{
        formOfChangeRequisitionDetail.setFieldsValue({id:requisitionId})
        setDisabledOfChangeFormBianhao(false);
        setVisibleOfChangingRequisitionDetailModal(true);
    }

    const handleSubmitMakeTemporaryStorageRequisitionStatusInReview = (requisitionId) =>{
        post(APIOBJECT["makeTemporaryStorageRequisitionStatusInReview"],JSON.stringify({requisitionId:requisitionId})).then(resultBeforeHandle =>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                message.success(resultAfterHandle["msg"]);
                let resultArray = deepCopyObj(dataSourceOfRequisition);
                for(let item of resultArray){
                    if(item["id"] === requisitionId){
                        console.log("进来了判断！")
                        item["status"] = "审核中";
                        break;
                    }
                }
                setDataSourceOfRequisition(resultArray);
            }
        }).catch(error => message.error("handleSubmitMakeTemporaryStorageRequisitionStatusInReview出错"+error))
    }

    const getRequisition = () =>{
        //根据来源不同，查询的api也不同。manager只能看自己提出的要货单；
        let apiUrl = APIOBJECT["getRequisitionByManager"];
        if (from === "warehouse"){
            apiUrl = APIOBJECT["getRequisitionByWarehouseMan"];
        }
        post(apiUrl).then(resultBeforeHandle =>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                let reverseArray = resultAfterHandle["data"].reverse()
                for (let item of reverseArray){
                    item["idForShow"] = "YHD-"+item["id"];
                }
                setDataSourceOfRequisition(reverseArray);
                if (callBackFunc){
                    callBackFunc(reverseArray);
                }
            }
        }).catch(error => message.error("getRequisition出错"+error))
    }


    const handleSubmitChangeRequisitionDetail = (record) =>{
        if (disabledOfChangeFormBianhao){
            post(APIOBJECT["changeRequisitionDetailById"],JSON.stringify({"requisitionDetailId":record["id"],"need_quantity":record["need_quantity"],
                "required_arrival_date":record["required_arrival_date"]})).then(resultBeforeHandle =>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if(resultAfterHandle !== false){
                    getRequisition();
                    message.success("修改成功！");
                    setUnitOfBianhao("");
                    setVisibleOfChangingRequisitionDetailModal(false);
                }
            }).catch(error => message.error("handleSubmitChangeRequisitionDetail出错"+error))
        }else{
            post(APIOBJECT["addRequisitionDetailToExistedRequisition"],JSON.stringify({requisitionId:record["id"],"quantity":record["need_quantity"],
                "required_arrival_date":record["required_arrival_date"],bianhao:record["bianhao"]})).then(resultBeforeHandle =>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if(resultAfterHandle !== false){
                    getRequisition();
                    message.success("新增要货细节成功！");
                    setUnitOfBianhao("");
                    setDisabledOfChangeFormBianhao(true);
                    setVisibleOfChangingRequisitionDetailModal(false);
                }
            }).catch(error => message.error("handleSubmitChangeRequisitionDetail出错"+error))
        }

    }

    /**
     * 项目经理关闭要货细节
     * @param requisitionDetailId
     */
    const handleCloseRequisitionDetail = (requisitionDetailId)=>{
        post(APIOBJECT["closeRequisitionDetail"],JSON.stringify({"requisitionDetailId":requisitionDetailId})).then(resultBeforeHandle =>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                getRequisition();
                message.success("关闭成功！");
            }
        }).catch(error => message.error("getRequisition出错"+error))
    }

    const expandedRowRenderForRequisitionDetail = (row)=>{

        const columnsOfRequisitionDetail = [
            {
                title:"名称",
                key:"name",
                dataIndex:"name",
                width: 300,
                fixed:"left",
                ...getColumnSearchProps("name")
            },
            {
                title:"编号",
                key:"bianhao",
                dataIndex:"bianhao",
                ellipsis: true,
                width: 80,
                ...getColumnSearchProps("bianhao")
            },
            {
                title:"详情",
                key:"propertiesString",
                dataIndex:"propertiesString",
                width: 300,
                ...getColumnSearchProps("propertiesString")
            },
            {
                title:"需要数量",
                key:"need_quantity",
                dataIndex:"need_quantity",
                width: 150,
                ...getColumnSearchProps("need_quantity")
            },
            {
                title:"已得数量",
                key:"got_quantity",
                dataIndex:"got_quantity",
                width: 150,
                ...getColumnSearchProps("got_quantity")
            },
            {
                title:"单位",
                key:"unit",
                dataIndex:"unit",
                width: 100,
                ...getColumnSearchProps("unit")
            },
            {
                title:"期望到货周期（天）",
                key:"required_arrival_date",
                dataIndex:"required_arrival_date",
                width: 150,
                ...getColumnSearchProps("required_arrival_date")
            },
            {
                title:"是否关闭",
                key:"closed",
                dataIndex:"closed",
                width: 150,
                render:(text,record,index)=>{
                    if (Number(text) === 0){
                        return "未关闭";
                    }else{
                        return "已关闭"
                    }
                }
            },
            {
                title:"创建时间",
                key:"create_time",
                dataIndex:"create_time",
                width: 200,
                ...getColumnSearchProps2("create_time")
            },
            {
                title:"更新时间",
                key:"update_time",
                dataIndex:"update_time",
                width: 200,
                ...getColumnSearchProps2("update_time")
            }
        ]
        let columnOfChanging = {
            title: "操作",
            width:200,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <div style={{display:row["status"] === '暂存'?"inline":"none"}}>
                            <Button type={"primary"} size={"small"}  onClick={()=>handleChangeRequisitionDetail(record)} >修改</Button>
                        </div>
                        <div style={{display:row["status"] === '暂存'?"inline":"none"}}>
                            <Popconfirm title={"确定删除该要货项吗？"} okText={"确认"} cancelText={"取消"} onConfirm={()=>{
                                handleDeleteRequisitionDetail(record["id"]);
                            }}>
                                <Button danger size={"small"}>删除</Button>
                            </Popconfirm>
                        </div>

                        <div style={{display:row["status"] !== '关闭'? row["status"] !== '暂存' ? "inline":"none":"none"}}>
                            <Popconfirm title={"确定关闭该要货项吗？"} okText={"确认"} cancelText={"取消"}
                                        onConfirm={()=>{
                                            if(Number(record["closed"]) === 1){
                                                message.error("当前细节已关闭，请勿重复关闭！");
                                            }else{
                                                handleCloseRequisitionDetail(record["id"])
                                            }
                            }}>
                                <Button danger size={"small"}>关闭</Button>
                            </Popconfirm>
                        </div>
                    </Space>
                )
            }
        }
        if (canChangeRequisitionDetail){
            columnsOfRequisitionDetail.push(columnOfChanging);
        }
        return (<Table scroll={{x: 1300}} columns={columnsOfRequisitionDetail} bordered
                       dataSource={dataSourceOfRequisition.length === 0 ? []:dataSourceOfRequisition.filter(item => item["id"] === row["id"])[0]["details"]}
                       rowKey={record => record["id"]}
        />)
    }



    return (
            <>
                <Table expandedRowRender={expandedRowRenderForRequisitionDetail} columns={columnsOfRequisition} dataSource={dataSourceOfRequisition} scroll={{x: 1300}} bordered rowKey={record => record["id"]}/>

                <Modal title={"修改要货单详情"} open={visibleOfChangingRequisitionDetailModal} onOk={()=>formOfChangeRequisitionDetail.submit()}
                       onCancel={()=>{
                           setVisibleOfChangingRequisitionDetailModal(false);
                           setUnitOfBianhao("");
                           setDisabledOfChangeFormBianhao(true);
                           formOfChangeRequisitionDetail.resetFields();
                       }} okText={"提交"} cancelText={"取消"}>
                    <Form form={formOfChangeRequisitionDetail} onFinish={handleSubmitChangeRequisitionDetail}>
                        <Form.Item name={"id"} label={"id"} required={[{required:true,message:"若出现此信息，请联系管理员！"}]}>
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item name={"bianhao"} label={"编号"} required={[{required:true,message:"若出现此信息，请联系管理员！"}]}>
                            <Input disabled = {disabledOfChangeFormBianhao}/>
                        </Form.Item>
                        <Form.Item label={"数量（"+unitOfBianhao+")"} name={"need_quantity"} rules={[
                            {required:true,message:"请输入数量"},
                            ({getFieldValue})=>({
                                validator(_,value){
                                    if (!judgeIfNum(value)){
                                        return Promise.reject(new Error("数量不正确！"));
                                    }else{
                                        return Promise.resolve();
                                    }
                                }
                            })
                        ]}>
                            <Input type={"number"} step={"any"}/>
                        </Form.Item>
                        <Form.Item label={"交货周期（天）"} name={"required_arrival_date"}
                                   rules={[
                                       {required:true,message:"请输入交货周期"},
                                       ({getFieldValue})=>({
                                           validator(_,value){
                                               if (!judgeIfNum(value)){
                                                   return Promise.reject(new Error("数量不正确！"));
                                               }else{
                                                   return Promise.resolve();
                                               }
                                           }
                                       })
                                   ]}>
                            <Input type={"number"}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
    );
}

export default RequisitionQuery;