import React, {useEffect, useRef, useState} from 'react';
import {Card, Table, Button, Popconfirm, Empty, Select, Typography, message, Space, Modal} from "antd";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import {getToken} from "../../../utlis/auth";
import {postAfterLogined} from "../../../service/getApi";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ImageOfEntity from "../../../components/Query/ImageOfEntity";

function List(props) {

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const navigate = useNavigate()
    // const {setLogined,setRouteTable}=props;//用于传给login页面的两个函数
    const [data_source_of_table,setData_source_of_table] = useState(null);
    const [data_source_of_nested_table,setData_source_of_nested_table] = useState(null);
    const [entityIdForImageModal,setEntityIdForImageModal] = useState(null);
    const [visibleOfImageModal,setVisibleOfImageModal] = useState(false);
    const [loading_of_card,setLoading_of_card] = useState(true);
    const [rawResultData,setRawResultData] = useState(null);
    const {Option} = Select;
    const {Text} = Typography;





    useEffect(() => {
        setLoading_of_card(true);
        get_list();
    }, []);


    const get_list = ()=>{
        post(APIOBJECT["getEntityList"],null).then(resultBeforeHandle=>{
            console.log(resultBeforeHandle);
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let result = resultAfterHandle["data"];
                let to_data_source_of_table=[];
                let datasource_of_nested_table = {};
                let resultArray =result;
                setRawResultData(resultArray);
                for(let index in resultArray){
                    to_data_source_of_table.push({
                        id_of_entity:resultArray[index]["id_of_entity"],
                        name_of_entity:resultArray[index]["chinese_name"],
                        categoryIdAndName:resultArray[index]["categoryId"]+resultArray[index]["categoryName"],
                        unit:resultArray[index]["unit"]
                    })
                    let properties_of_json = resultArray[index]["properties"];
                    let frozen_properties = resultArray[index]["frozen_properties"];
                    let temp_array=[]
                    for (let key2 in properties_of_json){
                        datasource_of_nested_table[resultArray[index]["id_of_entity"]] = [];//以实体名作为标志。以防不同实体有相同的属性id
                        temp_array.push({
                            chinese_name:properties_of_json[key2]["chinese_name"],
                            id_of_property:key2,
                            options:properties_of_json[key2]["options"],
                            frozen_properties:frozen_properties[key2]?frozen_properties[key2]:[]
                        })
                    }
                    datasource_of_nested_table[resultArray[index]["id_of_entity"]]=temp_array;
                }
                setData_source_of_nested_table(datasource_of_nested_table);
                setData_source_of_table(to_data_source_of_table);
            }
            setLoading_of_card(false);
        })
    }


    const delete_entity = (id_of_entity)=>{
        console.log("要删除的实体的id是：",id_of_entity)
        post(APIOBJECT["deleteEntity"],JSON.stringify({"id_of_entity":id_of_entity})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                get_list();
            }
        })

    }

    const handleCheckImageOfEntity = (entityId)=>{
        setEntityIdForImageModal(entityId);
        setVisibleOfImageModal(true);
    }

    const columns_of_nested_table = [
        {
            title:"属性名",
            key:"chinese_name",
            dataIndex:"chinese_name",
            ...getColumnSearchProps("chinese_name")
        },
        {
            title: "属性id",
            key:"id_of_property",
            dataIndex: "id_of_property",
            ...getColumnSearchProps("id_of_property")
        },
        {
            title: "所有可选项",
            key:"options",
            dataIndex: "options",
            render:(_,{options})=>(
                <Select showSearch optionFilterProp="children" defaultValue={options[0]}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{"width":"100%"}}
                >
                    {options.map((item)=><Option key={item}>{item}</Option>)}
                </Select>
            )
        },
        {
            title: "已被冻结的可选项",
            key:"frozen_properties",
            dataIndex: "frozen_properties",
            render:(_,{frozen_properties})=>
            {

                return frozen_properties.length!==0?(
                <Select
                    defaultValue={frozen_properties[0]}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{"width":"100%"}}
                >
                    {frozen_properties.map((item)=><Option key={item}>{item}</Option>)}
                </Select>
                ):
                    (<Text type="warning">暂无被冻结的属性</Text>)

            }
        }
    ]

    const columns =[
        {
            title:"实体id",
            key:"id_of_entity",
            dataIndex: "id_of_entity",
            ...getColumnSearchProps("id_of_entity")
        },
        {
            title: "实体名字",
            dataIndex:"name_of_entity",
            key:"name_of_entity",
            ...getColumnSearchProps("name_of_entity")
        },
        {
            title: "所属大类id+名称",
            dataIndex:"categoryIdAndName",
            key:"categoryIdAndName",
            ...getColumnSearchProps("categoryIdAndName")
        },
        {
            title: "单位",
            dataIndex:"unit",
            key:"unit",
            ...getColumnSearchProps("unit")
        },
        {
            title: "操作",
            render:(txt,record,index)=>{
                return (
                    <Space>
                        <Button type={"primary"} size={"small"} onClick={()=>handleCheckImageOfEntity(record["id_of_entity"])}>查看图片</Button>

                        <Popconfirm title={"确定删除此项？"}  onConfirm={
                            ()=>delete_entity(record.id_of_entity)
                        }
                        >
                            <Button type={"danger"} size={"small"}>删除</Button>
                        </Popconfirm>
                        <Popconfirm title={"修改该项会跳转至另一页，请注意"}  onConfirm={
                            ()=>{
                                let entityInformation = rawResultData.filter(item=>item['id_of_entity'] === record['id_of_entity'])[0];
                                console.log(entityInformation)
                                navigate("/admin/products/edit/add",{replace:true,
                                    state:{
                                    ifModified:true,
                                    entityInformation
                                }})
                            }
                        }
                        >
                            <Button type={"primary"} size={"small"}>修改</Button>
                        </Popconfirm>

                        <Popconfirm title={"拷贝修改该项会跳转至另一页，请注意"}  onConfirm={
                            ()=>{
                                let entityInformation = rawResultData.filter(item=>item['id_of_entity'] === record['id_of_entity'])[0];
                                navigate("/admin/products/edit/add",{replace:true,
                                    state:{
                                        ifModified:true,
                                        ifCopy:true,
                                        entityInformation
                                    }})
                            }
                        }
                        >
                            <Button type={"default"} danger size={"small"}>拷贝修改</Button>
                        </Popconfirm>

                    </Space>
                )

            }
        }
    ]

    const expandedRow = row =>{
        return <Table bordered columns={columns_of_nested_table} dataSource={data_source_of_nested_table[row.id_of_entity]} rowKey={record => record.id_of_property}/>
    }


    return (
        <>
            <Modal visible={visibleOfImageModal} title={"实体关系图片"} width={1000} onOk={()=>setVisibleOfImageModal(false)} onCancel={()=>setVisibleOfImageModal(false)}>
                <ImageOfEntity entityId={entityIdForImageModal}/>
            </Modal>
            <Card title={"当前已有的实体关系"}
            extra={<Button type={"primary"} size={"small"} onClick={()=>navigate("/admin/products/edit/add")}>新增</Button>} loading={loading_of_card}>
                <Table columns={columns} bordered expandedRowRender={expandedRow}
                       dataSource={data_source_of_table} rowKey={record => record.id_of_entity}/>
            </Card>
        </>
    );
}

export default List;