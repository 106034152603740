import React, {useEffect, useState} from 'react';
import Cards_of_Add_Properties from "./Cards_of_Add_Properties"
import Card from "antd/es/card/Card";
import {
    Button,
    Divider,
    Empty,
    Form,
    Image,
    Input,
    message,
    Popconfirm,
    Result,
    Select,
    Space,
    Table,
    Typography,
} from "antd";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {APIOBJECT, base64Object, constantBaseURL} from "../../static/CONSTANTS";
import {post} from "../../utlis/request";
import {handleResult} from "../../utlis/response";
import {useNavigate} from "react-router-dom";
import Upload from "antd/es/upload/Upload";
import {getToken} from "../../utlis/auth";


function Index(props) {
    //根据步骤来显示相应的步骤
    const {step_num,setAllCategory,ifModified,ifCopy,modifyingEntity} = props;
    const [disable_of_form_of_id_and_name, setDisable_of_form_of_id_and_name] = useState(false);
    const [id_and_name_of_entity,setId_and_name_of_entity] = useState();
    const [form_of_stepone] = Form.useForm();
    const [properties_array,setProperties_array] = useState([]);
    const [isModalFinalVisible, setIsModalFinalVisible] = useState(false);
    const [result_loding,setResult_loding] = useState(false);
    const { confirm } = Modal;
    const { Title,Text } = Typography;
    const {Option} = Select;
    const [if_return_result,setIf_return_result] = useState(null);
    const navigate = useNavigate();
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const [markChangableArray,setMarkChangableArray] = useState([]);
    const [imageUrl,setImageUrl] = useState(null);
    const [loadingOfUpload,setLoadingOfUpload] = useState(false);
    const [loadingOfClearImageButton,setLoadingOfClearImageButton] = useState(false);
    const [loadingOfModifyingButton,setLoadingOfModifyingButton] = useState(false);

    const handle_submit_idandname=(r)=>{
        console.log(r);
        let categoryArray = categoryArrayForSelect.filter(item=>item["categoryId"]===r["category"]);
        if (categoryArray.length!==1){
            message.error("大类填写有误！请核实！");
            return;
        }
        let categoryObject = categoryArray[0];
        setId_and_name_of_entity({"id_of_entity":r["id_of_entity"],"name_of_entity":r["name_of_entity"],"category":categoryObject,"unit":r["unit"]});
        setDisable_of_form_of_id_and_name(true);
    }

    const handle_submit_final_first = ()=>{
        if(!id_and_name_of_entity){
            message.error("请先填写完整实体的id和名字！")
        }else if(properties_array.length===0){
            message.error("实体的属性不能为空！")
        }else {
            setIsModalFinalVisible(true);
        }

    }

    const handle_submit_final_second = ()=>{
        confirm({
            title: if_return_result!=null?'系统检测到刚刚您提交过实体，请确认是否进行本次提交？':"确认提交此实体？",
            icon: <ExclamationCircleOutlined />,
            content: '稍后会有提交反馈，请留意！',
            onOk() {
                let jsonData;
                setResult_loding(true);
                jsonData=JSON.stringify({
                    "id_of_entity":id_and_name_of_entity["id_of_entity"],
                    "name_of_entity":id_and_name_of_entity["name_of_entity"],
                    "properties":properties_array,
                    "categoryId":id_and_name_of_entity["category"]["categoryId"],
                    "unit":id_and_name_of_entity["unit"],
                    "imageUrl":imageUrl
                })

                post(APIOBJECT["addEntity"],jsonData,message,navigate).then(resultBeforeHandle=>{
                    let resultAfterHandle = handleResult(resultBeforeHandle,message);
                    if (resultAfterHandle!==false){
                        setIf_return_result(resultAfterHandle);
                        setResult_loding(false);
                        setTimeout(()=>{
                            setIsModalFinalVisible(false);
                        },1000)

                    }else{
                        message.error(resultBeforeHandle["msg"])
                    }
                }).catch(error=>{
                    message.error("addEntity出错了"+error);
                })

            },
            onCancel() {
                console.log('提交请求Cancel');
            },
        })
    }

    const handle_Modify_Entity=()=>{
        if(!id_and_name_of_entity){
            message.error("请先填写完整实体的id和名字！");
            return;
        }else if(properties_array.length===0){
            message.error("实体的属性不能为空！");
            return;
        }
        let jsonData={
            "id_of_entity":id_and_name_of_entity["id_of_entity"],
            "name_of_entity":id_and_name_of_entity["name_of_entity"],
            "properties":properties_array,
            "categoryId":id_and_name_of_entity["category"]["categoryId"],
            "unit":id_and_name_of_entity["unit"],
            "imageUrl":imageUrl
        }
        setLoadingOfModifyingButton(true);
        post(APIOBJECT["modifyEntity"],JSON.stringify(jsonData)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message);
            if(resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
            }
        }).catch(error=>{
            message.error("modifyEntity出错了"+error);
        }).finally(()=>{
            setLoadingOfModifyingButton(false);
        })

    }

    const clearImageUploaded = ()=>{
        if (!imageUrl){
            message.error("您未上传图片，无法清除！");
        }else{
            setLoadingOfClearImageButton(true);
            post(APIOBJECT["deleteImageByUrl"],JSON.stringify({imageUrl:imageUrl})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    message.success(resultAfterHandle["msg"]);
                    setImageUrl(null);
                    setLoadingOfUpload(false);
                }
                setLoadingOfClearImageButton(false);
            }).catch(error=>{
                message.error("clearImageUploaded出错了！"+error);
                setLoadingOfClearImageButton(false);
            })
        }
    }

    //监听id_of_entity,当改变时，若是提交反馈id重复失败后改变的话，说明用户按照要求修改了id，则需要设置if_return_result==null
    useEffect(() => {
        if(if_return_result!=null){//说明是提交之后返回的
            setIf_return_result(null);
        }
        post(APIOBJECT["getAllCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setAllCategory(resultAfterHandle["data"]);
                setCategoryArrayForSelect(resultAfterHandle["data"]);
            }else{
                message.error("当前大类为空，请先添加大类！");
            }
        }).catch(error=>message.error("getAllCategory出现错误！"+error))

    }, [id_and_name_of_entity]);


    useEffect(() => {
        if(ifModified!=null&&ifModified){
            post(APIOBJECT["getReusePropertiesAndImgOfEntity"],JSON.stringify({id_of_entity:modifyingEntity["id_of_entity"]})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    let propertiesArrayTemp=[];
                    let markChangableArrayTemp = [];
                    let reusePropertiesArray = resultAfterHandle["data"]["reusePropertiesIdArray"];
                    for (let propertyIndex in modifyingEntity["orderOfProperties"]){
                        let idOfProperty = modifyingEntity["orderOfProperties"][propertyIndex];
                        let filterArray = reusePropertiesArray.filter(item => item["id_of_reuse_property"] === idOfProperty);
                        if (filterArray.length === 0){
                            propertiesArrayTemp.push({id_of_property:idOfProperty,chinese_name:modifyingEntity["properties"][idOfProperty]["chinese_name"]
                                ,options:modifyingEntity["properties"][idOfProperty]["options"]})
                        }else{
                            propertiesArrayTemp.push({id_of_property:idOfProperty,chinese_name:modifyingEntity["properties"][idOfProperty]["chinese_name"]
                                ,options:modifyingEntity["properties"][idOfProperty]["options"],bind:{...filterArray[0]}})
                        }
                        markChangableArrayTemp.push({id_of_property:idOfProperty,ableToChange:filterArray.length === 0});
                    }



                    setMarkChangableArray(markChangableArrayTemp);
                    setProperties_array(propertiesArrayTemp);
                    setId_and_name_of_entity({...modifyingEntity,
                        category:{categoryId:modifyingEntity["categoryId"],categoryName:modifyingEntity["categoryName"]}
                        ,name_of_entity:modifyingEntity["chinese_name"],properties:propertiesArrayTemp});
                    if (resultAfterHandle["data"]["imageUrl"]!=="none"){
                        setImageUrl(resultAfterHandle["data"]["imageUrl"]);
                    }

                }
            })
                .catch(error=>message.error("getReusePropertiesOfEntity出现错误！"+error))


            form_of_stepone.setFieldsValue({...modifyingEntity,category:modifyingEntity["categoryId"],name_of_entity:modifyingEntity["chinese_name"]})


        }
    }, []);



    const columns = [{
            title:"属性id",
            dataIndex:"id_of_property",
            key:"id_of_property"
        },
        {
            title: "属性名字",
            dataIndex: "chinese_name",
            key:"chinese_name"
        },
        {
            title: "属性可选值",
            dataIndex: "options",
            key: "options",
            render:(_,{options})=>(
                <Select defaultValue={options[0]}>
                    {options.map((item)=><Option key={item}>{item}</Option>)}
                </Select>
            )
        }
    ]


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error('你只能上传jpg或者png格式的图片');
        }

        const isLt2M = file.size / 1024 / 1024 < 4;

        if (!isLt2M) {
            message.error('图片必须小于4MB');
        }

        if (id_and_name_of_entity===null||id_and_name_of_entity===undefined){
            message.error("请先确认属性id和名字后再上传图片！");
            return false;
        }else{
            if (id_and_name_of_entity["id_of_entity"].length===0){
                message.error("请先确认属性id和名字后再上传图片！");
                return false;
            }
        }

        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingOfUpload(true);
            return;
        }
        console.log(info)
        if (info.file.status === 'done') {
            if (info["file"]["response"]["code"]===200){
                setImageUrl(info["file"]["response"]["msg"])
            }else{
                message.error("上传失败！")
            }
        }
    };
    const uploadButton = (
        <div>
            {loadingOfUpload ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{marginTop: 8,}}>Upload</div>
        </div>
    );

    if(step_num === 0){
        return(
            <Card title={"填写实体id和名字和选择大类"}>
                <Form form={form_of_stepone} name={"form_of_id_and_name"} onFinish={(r) => {handle_submit_idandname(r)}
                } onFinishFailed={()=>message.error("请输入正确的id和名字！")} disabled={disable_of_form_of_id_and_name}>
                    <Form.Item
                        label={"大类"}
                        name={"category"}
                        rules={[{required: true, message:"请选择大类",}]}>
                        <Select
                            showSearch
                            placeholder="请选择一个大类"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={"实体的id"}
                        name={"id_of_entity"}
                        rules={[{
                            required: true,
                            message:"请输入正确的实体的id(由英文和数字或下划线组成)",
                            pattern:"^[A-Za-z0-9_]+$"
                        }
                        ]}
                    >
                        {/*如果已经填写过实体的id和名字的话，placeholder直接就是填过的*/}
                        <Input type={"text"} disabled={ifModified!==undefined?ifCopy === undefined:false}
                               placeholder={id_and_name_of_entity?id_and_name_of_entity.id_of_entity:"请输入实体的id(由英文和数字或符号组成)"}/>
                    </Form.Item>

                    <Form.Item label={"实体的名字"} name={"name_of_entity"}
                        rules={[{required: true, message:"请输入正确的名字",}]}>
                        <Input type={"text"}
                               placeholder={id_and_name_of_entity?id_and_name_of_entity.name_of_entity:"请输入实体的id(由英文和数字或符号组成)"}/>
                    </Form.Item>
                    <Form.Item label={"实体单位"} name={"unit"}
                               rules={[{required: true, message:"请输入单位名",}]}>
                        <Input type={"text"}
                               placeholder={"单位可以是长度单位：米，厘米，也可以是重量单位千克。"}/>
                    </Form.Item>
                </Form>
                <Space size={"middle"}>
                    {/*如果表单是不可编辑的，则确定按钮为不可点击的灰色*/}
                    {disable_of_form_of_id_and_name?<Button type={"primary"} onClick={()=>form_of_stepone.submit()} disabled>确定</Button>
                        :<Button type={"primary"} onClick={()=>form_of_stepone.submit()}>确定</Button>}

                    {disable_of_form_of_id_and_name?<Button type={"dashed"} danger onClick={()=>setDisable_of_form_of_id_and_name(false)}>修改</Button>
                        :<Button type={"dashed"} danger onClick={()=>setDisable_of_form_of_id_and_name(false)} disabled>修改</Button>}
                </Space>
            </Card>
        )
    }else if(step_num === 1){
        return (<Cards_of_Add_Properties markChangableArray={markChangableArray} setMarkChangableArray={setMarkChangableArray}
                                         properties_array={properties_array} setProperties_array={setProperties_array}/>)
    }else if(step_num === 2){
        return(
            <Card title={"汇总"}
                extra={
                    ifModified!==undefined?ifCopy!==undefined?<Button type={"primary"} onClick={handle_submit_final_first}>提交</Button>:
                                <Popconfirm title={"确定修改吗？此操作不可撤销！"} onConfirm={handle_Modify_Entity}>
                                     <Button loading={loadingOfModifyingButton} type={"default"} danger>修改</Button>
                                 </Popconfirm>
                        :<Button type={"primary"} onClick={handle_submit_final_first}>提交</Button>
                }

            >
                <Title level={2}>待提交的实体所属的大类情况:{id_and_name_of_entity?
                    <Space direction={"vertical"} style={{"width":"100%"}}>
                        <Text type={"success"} children={"大类名称："+id_and_name_of_entity["category"]["categoryName"]} strong/>
                        <Text type={"success"} children={"大类id："+id_and_name_of_entity["category"]["categoryId"]}/>
                    </Space>
                    :
                    <Text type={"danger"} strong>还未添加</Text>}
                </Title>


                <Title level={2}>待提交的实体的id:{id_and_name_of_entity?
                    <>
                    <Text type={"success"} children={id_and_name_of_entity.id_of_entity} strong/>
                    <Text>;名字：<Text type={"success"} children={id_and_name_of_entity["name_of_entity"]} strong/>
                        ;单位：<Text  type={"success"} children={id_and_name_of_entity["unit"]} strong/>
                    </Text>
                    </>
                    :
                    <Text type={"danger"} strong>还未添加</Text>}
                </Title>

                <Title level={2}>
                    <Text>图片信息：</Text>
                    <Space>
                        {/*修改模式时不能改图片*/}
                        {ifModified!==undefined?
                            ifCopy === undefined?"":
                            <Space direction={"vertical"} size={"middle"}>
                            <Upload  listType="picture-card" headers={{token:getToken()}}
                            action={APIOBJECT["uploadEntityImage"]} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
                                {imageUrl ? ("已上传") : (uploadButton)}
                            </Upload>
                            <Button loading={loadingOfClearImageButton} danger onClick={clearImageUploaded}>清除图片</Button>
                            </Space>:
                            <Space direction={"vertical"} size={"middle"}>
                                <Upload  listType="picture-card" headers={{token:getToken()}}
                                         action={APIOBJECT["uploadEntityImage"]} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
                                    {imageUrl ? ("已上传") : (uploadButton)}
                                </Upload>
                                <Button loading={loadingOfClearImageButton} danger onClick={clearImageUploaded}>清除图片</Button>
                            </Space>
                            }


                        <Image src={constantBaseURL+imageUrl} width={400} fallback={base64Object["errorPic"]}/>
                    </Space>
                </Title>
                <Divider />
                <Title level={2}>
                    实体的属性相关信息：
                </Title>

                {properties_array?<Table columns={columns} dataSource={properties_array.map((item)=>{
                    return {...item,key:item.id_of_property}
                })}/>:<Empty/>}


                <Modal title="提交以及结果显示框" visible={isModalFinalVisible} onOk={handle_submit_final_second}
                       onCancel={()=>setIsModalFinalVisible(false)} okText={"提交申请"} cancelText={"取消"}>
                    <Card title={"提交结果"} type="inner" bordered loading={result_loding}>
                        {if_return_result==null?
                            <Empty/>:
                            <Result status={if_return_result["code"]===200?"success":"error"}
                            title={if_return_result["code"]===200?"提交成功":"出现错误"}
                            subTitle={if_return_result["code"]===200?"添加实体成功！":if_return_result["msg"]}/>}
                    </Card>
                </Modal>
            </Card>

        )
    }

}

export default Index;