import React, {useEffect, useRef, useState} from 'react';
import {Collapse, Form, message, Select, Space, Typography, Divider, Button, Table, TreeSelect,} from "antd";
import Card from "antd/es/card/Card";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {useNavigate} from "react-router-dom";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";


function AddPropertiesConstraint(props) {
    const { Panel } = Collapse;
    const {Text,Paragraph} = Typography;
    const {Option} = Select;
    const navigate = useNavigate();
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const [optionOfEntitySelect,setOptionOfEntitySelect] = useState([]);
    const [allPropertiesArray,setAllPropertiesArray] = useState([]);
    const [haveConstraintsPropertiesArray,setHaveConstraintsPropertiesArray] = useState([]);
    const [optionOfPropertiesSelect,setOptionOfPropertiesSelect] = useState([]);
    const [formOfAddPropertyConstraint] = Form.useForm();
    const [loadingOfExistedConstraintCard,setLoadingOfExistedConstraintCard] = useState(true);
    const [constrainedDataForOuterTable,setConstrainedDataForOuterTable] = useState([]);
    const [constrainedDataForInnerTable,setConstrainedInnerForOuterTable] = useState({});
    const [frozenPropertyArray,setFrozenPropertyArray] = useState([]);
    const [formOfSelectAffectedProperty] = Form.useForm();
    const [dataForTree,setDataForTree] = useState([]);

    useEffect(() => {

        post(APIOBJECT["getAllCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setCategoryArrayForSelect(resultAfterHandle["data"]);
            }else{
                message.error("当前大类为空，请先添加大类！");
            }
        }).catch(error=>message.error("出现错误！"+error))


        return () => {
        };
    }, []);
    const loadEntitiesByCategorySelect = (categoryId)=>{
        formOfAddPropertyConstraint.resetFields(["id_of_entity","propertyId"])
        setLoadingOfExistedConstraintCard(true);
        setOptionOfEntitySelect([]);
        setDataForTree([]);
        setAllPropertiesArray([]);
        setHaveConstraintsPropertiesArray([]);
        setOptionOfPropertiesSelect([]);
        setConstrainedDataForOuterTable([]);
        post(APIOBJECT["getEntitiesByCategoryId"],JSON.stringify({"categoryId":categoryId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let options_array = [];
                let result = resultAfterHandle["data"];
                for (let index in result){
                    options_array.push({
                        label:result[index]["chinese_name"],
                        value:result[index]["id_of_entity"]
                    })
                }
                setOptionOfEntitySelect(options_array);
            }
        }).catch(error=>message.error("出错了！"+error))
    }

    const load_properties = (entityId)=>{
        setLoadingOfExistedConstraintCard(true);
        setConstrainedDataForOuterTable([]);
        setDataForTree([]);
        setConstrainedInnerForOuterTable([]);
        post(APIOBJECT["getEntityById"],JSON.stringify({id_of_entity:entityId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                // console.log(resultAfterHandle);
                let allProperties = resultAfterHandle["data"]["constraint"]["properties"];
                let orderOfProperties = resultAfterHandle["data"]["constraint"]["orderOfProperties"];//用于标明顺序的数组
                setAllPropertiesArray(allProperties);
                setHaveConstraintsPropertiesArray(resultAfterHandle["data"]["constraint"]["constraint"]);
                setFrozenPropertyArray(resultAfterHandle["data"]["constraint"]["frozen_properties"]);

                let tempArray = [];

                // console.log(orderOfProperties);

                for (let index in orderOfProperties){
                    tempArray.push({
                        label:allProperties[orderOfProperties[index]]["chinese_name"],
                        value:orderOfProperties[index]
                    })
                }
                // console.log("++++++++++++++++++++++++++++++++++++++++++++")
                // console.log(tempArray);
                setOptionOfPropertiesSelect(tempArray);

            }
        }).catch(error=>message.error("出错啦！"+error))


    }

    const returnCanBeSelectedOptionValue = (propertyId)=>{
        let allOptionArray = [];
        let frozenOptionArray = [];
        //返回属性的可选项，若被冻结则disable为true
        for (let propertyIdInAllProperties in allPropertiesArray){
            if (propertyId===propertyIdInAllProperties){
                allOptionArray = allPropertiesArray[propertyIdInAllProperties]["options"];
            }
        }
        for (let frozenPropertyId in frozenPropertyArray){
            if (frozenPropertyId===propertyId){
                frozenOptionArray = frozenPropertyArray[frozenPropertyId];
            }
        }

        let availableOptionArray = allOptionArray.filter(item=>frozenOptionArray.indexOf(item)===-1);

        let resultArray = [];
        for (let index in availableOptionArray){
            resultArray.push({value:availableOptionArray[index],disable:false});
        }
        for (let index in frozenOptionArray){
            resultArray.push({value:frozenOptionArray[index],disable:true});
        }

        return resultArray;

    }


    const handleSelectingStartingConstraintProperty = (startingConstraintPropertyId)=>{
        // console.log("选择了"+startingConstraintPropertyId);

        //构建用于table的数据array
        let tempArrayForOuterTable = [];
        let tempArrayForInnerTable = {};
        for (let propertyId in haveConstraintsPropertiesArray){
            // console.log(propertyId)
            tempArrayForOuterTable.push({propertyId:propertyId,
                chineseNameOfProerty:optionOfPropertiesSelect.filter(item=>item["value"]===propertyId)[0]["label"]
                ,options:returnCanBeSelectedOptionValue(propertyId)})
            let temp=[];
            //继续往里一层，获得发起约束的可选项以及被约束的属性及其选项
            for (let optionValueHasConstrained in haveConstraintsPropertiesArray[propertyId]){
                for (let beConstrainedPropertyId in haveConstraintsPropertiesArray[propertyId][optionValueHasConstrained]){
                    temp.push({optionValueConstrainOtherProperty:optionValueHasConstrained,
                        constrainedPropertyId:beConstrainedPropertyId,constrainedPropertyName:optionOfPropertiesSelect.filter(item=>item["value"]===beConstrainedPropertyId)[0]["label"],
                        options:haveConstraintsPropertiesArray[propertyId][optionValueHasConstrained][beConstrainedPropertyId]})
                }
            }
            tempArrayForInnerTable[propertyId]=temp;
            // console.log("////////////////////////////////")
            // console.log(tempArrayForInnerTable)
        }

        // console.log("**************************************************")
        // console.log(tempArrayForInnerTable);

        setConstrainedInnerForOuterTable(tempArrayForInnerTable);
        setConstrainedDataForOuterTable(tempArrayForOuterTable);
        setLoadingOfExistedConstraintCard(false);
    }

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const columnsOfOuterTable = [
        {
            title:"起始属性ID",
            key:"propertyId",
            dataIndex:"propertyId",
            ...getColumnSearchProps("propertyId")
        },
        {
            title: "起始属性名称",
            key: "chineseNameOfProerty",
            dataIndex: "chineseNameOfProerty",
            ...getColumnSearchProps("chineseNameOfProerty")
        },
        {
            title: "所有可选项",
            key:"options",
            dataIndex: "options",
            render:(_,{options})=>(
                <Select showSearch optionFilterProp="children" defaultValue={options[0]}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}
                >
                    {options.map((item)=><Option disabled={item["disable"]} key={item["value"]}>{item["value"]}</Option>)}
                </Select>
            )
        }
    ]

    const columnsOfInnerTable = [
        {
            title:"发起约束的可选值",
            key:"optionValueConstrainOtherProperty",
            dataIndex:"optionValueConstrainOtherProperty",
            ...getColumnSearchProps("optionValueConstrainOtherProperty")
        },
        {
            title:"被约束的属性ID",
            key:"constrainedPropertyId",
            dataIndex:"constrainedPropertyId",
            ...getColumnSearchProps("constrainedPropertyId")
        },
        {
            title: "被约束的属性名称",
            key:"constrainedPropertyName",
            dataIndex: "constrainedPropertyName",
            ...getColumnSearchProps("constrainedPropertyName")
        },
        {
            title: "被约束的可选项",
            key:"options",
            dataIndex: "options",
            render:(_,{options})=>(
                <Select showSearch optionFilterProp="children" defaultValue={options[0]}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}
                >
                    {options.map((item)=><Option key={item}>{item}</Option>)}
                </Select>
            )
        }
    ]

    const expandedRow = row =>{
        return <Table bordered columns={columnsOfInnerTable} dataSource={constrainedDataForInnerTable[row["propertyId"]]} rowKey={record => record["constrainedPropertyId"]}/>
    }

    /** 三个用于根据id返回中文名的函数*/
    const returnChineseNameOfProperty = (propertyId)=>{
        let tempArray = optionOfPropertiesSelect.filter(item=>item["value"]===propertyId);
        if (tempArray.length===1){
            return tempArray[0]["label"];
        }else{
            return null;
        }
    }
    const returnChineseNameOfEntity = (entityId)=>{
        let tempArray = optionOfEntitySelect.filter(item=>item["value"]===entityId);
        if (tempArray.length===1){
            return tempArray[0]["label"];
        }else{
            return null;
        }
    }
    const returnChineseNameOfCategory = (categoryId)=>{
        let tempArray = categoryArrayForSelect.filter(item=>item["categoryId"]===categoryId);
        if (tempArray.length===1){
            return tempArray[0]["categoryName"];
        }else{
            return null;
        }
    }
    /** 三个用于根据id返回中文名的函数*/


    const returnTextAboutSelectedOptionValues = ()=>{
        // console.log(haveConstraintsPropertiesArray)
        let fieldValue = formOfAddPropertyConstraint.getFieldValue();
        if (fieldValue["categoryId"]!==undefined&&fieldValue["id_of_entity"]!==undefined&&fieldValue["propertyId"]!==undefined){
            return (<Text>大类[<Text type={"success"}>{returnChineseNameOfCategory(fieldValue["categoryId"])}</Text>]
                下的[<Text type={"success"}>{returnChineseNameOfEntity(fieldValue["id_of_entity"])}</Text>]
                实体的[<Text mark>{returnChineseNameOfProperty(fieldValue["propertyId"])}</Text>]属性</Text>)
        }else{
            return "您有选项未选择。";
        }
    }

    const returnTextAboutConstraintSiturationOfSelectedOptionValues = ()=>{
        let fieldValue = formOfAddPropertyConstraint.getFieldValue();
        if (fieldValue["propertyId"]!==undefined){
            let propertyId = fieldValue["propertyId"];

            let influenedPropertyId = [];
            for (let hasConstraintsPropertyId in haveConstraintsPropertiesArray){
                if (propertyId===hasConstraintsPropertyId){
                    for (let optionValue in haveConstraintsPropertiesArray[hasConstraintsPropertyId]){
                        for (let affectedPropertyId in haveConstraintsPropertiesArray[hasConstraintsPropertyId][optionValue]){
                            if (influenedPropertyId.indexOf(affectedPropertyId)===-1){
                                influenedPropertyId.push(affectedPropertyId);
                            }
                        }
                    }
                }
            }

            if (influenedPropertyId.length===0){
                return <Text>{returnChineseNameOfProperty(propertyId)}属性对其他属性<Text mark>没有约束作用</Text>。</Text>
            }else{
                return (<Text><Text mark>{returnChineseNameOfProperty(propertyId)}</Text>属性对  {influenedPropertyId.map(item=><Text key={item}>
                    <Text type={"danger"}>{returnChineseNameOfProperty(item)}</Text>, </Text>)}属性有影响，具体请看上面的table表格，此处不再赘述，仅供提醒和参考。</Text>)
            }

        }else{
            return (<Text type={"danger"}>您有未选择的选项！全部选择完毕后会显示提示信息。</Text>)
        }
    }

    const handleSubmit = ()=>{
        let resultOfForm1 = formOfAddPropertyConstraint.getFieldValue();
        let resultOfForm2 = formOfSelectAffectedProperty.getFieldValue();

        if (resultOfForm1["categoryId"]!==undefined&&resultOfForm1["id_of_entity"]!==undefined&&resultOfForm1["propertyId"]!==undefined){
            if (resultOfForm2["triggerEffectOptionValue"]===undefined){//说明没选发起约束的选项
                message.error("请选择发起约束的可选值");
                return;
            }
            if (resultOfForm2["selectedPropertyAndOption"]===undefined||resultOfForm2["selectedPropertyAndOption"].length===0){
                message.info("由于您未选被影响的属性及其可选值，故本次请求将以删除模式进行！")
                setTimeout(()=>{
                    let dateToServer = JSON.stringify({idOfEntity:resultOfForm1["id_of_entity"],propertyId:resultOfForm1["propertyId"],
                        triggerEffectOptionValue:resultOfForm2["triggerEffectOptionValue"],affectedProperties:{}});



                    post(APIOBJECT["handleConstraint"],dateToServer).then(resultBeforeHandle=>{
                        let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                        if (resultAfterHandle!==false){
                            message.success("申请处理成功！");
                            setTimeout(()=>window.location.href = window.location.href,1000);
                        }
                    }).catch(error=>message.error("出错啦！"+error))
                },1000)
            }else{
                //以添加模式进行
                //构造要发送的json对象:
                //先获得一共对哪些属性产生了约束
                let selectedPropertyAndOption =resultOfForm2["selectedPropertyAndOption"];
                let affectedProperties = {};
                for (let index in selectedPropertyAndOption){//因为id不能由-组成，
                    let stringArray = selectedPropertyAndOption[index].split("<--->");
                    if (affectedProperties[stringArray[0]]===undefined){//说明还没有添加进去
                        affectedProperties[stringArray[0]]=[stringArray[1]];//初始化数组并添加被影响的可选值进去
                    }else{//说明已经被初始化了
                        affectedProperties[stringArray[0]].push(stringArray[1])
                    }
                }
                let dateToServer = JSON.stringify({idOfEntity:resultOfForm1["id_of_entity"],propertyId:resultOfForm1["propertyId"],
                    triggerEffectOptionValue:resultOfForm2["triggerEffectOptionValue"],affectedProperties:affectedProperties});
                post(APIOBJECT["handleConstraint"],dateToServer).then(resultBeforeHandle=>{
                    let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                    if (resultAfterHandle!==false){
                        message.success("申请处理成功！");
                        setTimeout(()=>window.location.href = window.location.href,1000);
                    }
                }).catch(error=>message.error("出错啦！"+error))

                // console.log(affectedProperties);

            }
        }else {
            message.error("有未选择的项！请选择完毕之后再点击提交！");

        }
    }


    const returnFormForAffectedProperty = ()=>{
        let fieldValue = formOfAddPropertyConstraint.getFieldValue();
        if (fieldValue["categoryId"]!==undefined&&fieldValue["id_of_entity"]!==undefined&&fieldValue["propertyId"]!==undefined){
            //先获取产生约束的属性位于属性选择链的位置，若是最后一个则无法添加约束
            let lengthOfPropertiesArray = optionOfPropertiesSelect.length;

            let propertyIdAfterSelectedPropertyArray = [];
            let flag = false;
            for (let index in optionOfPropertiesSelect){
                if (flag){
                    propertyIdAfterSelectedPropertyArray.push(optionOfPropertiesSelect[index]["value"]);
                }
                if (optionOfPropertiesSelect[index]["value"] === fieldValue["propertyId"]){
                    if (index === lengthOfPropertiesArray-1){
                        return (<Text type={"danger"}>{optionOfPropertiesSelect[index]["label"]}  属性位于属性选择链条的最后一位，无法对其他属性添加约束。</Text>)
                    }else{
                        flag=true;
                    }
                }
            }
            let resultArray = propertyIdAfterSelectedPropertyArray.map(tempPropertyId => {
                return {...allPropertiesArray[tempPropertyId],propertyId:tempPropertyId}
            });


            //下面开始构造treenode
            let jsonDataForTree = [];
            for (let index in resultArray){
                let tempJson = {};
                tempJson["title"]=resultArray[index]["chinese_name"];
                tempJson["selectable"] = false;
                tempJson["value"]=resultArray[index]["propertyId"];
                tempJson["children"] = resultArray[index]["options"].map(item=>{
                    return {title:<Text><Text underline>{resultArray[index]["chinese_name"]}</Text>-<Text type={"success"}>{item}</Text></Text>
                        ,value:resultArray[index]["propertyId"]+"<--->"+item}
                })
                jsonDataForTree.push(tempJson);
            }


            return (
                <Space direction={"vertical"} style={{width:'100%'}}>
                    <Form.Item name={"triggerEffectOptionValue"} label={"发起约束的值"} rules={[{required:true,message:"请选择发起约束的可选值"}]}>
                        <Select showSearch placeholder="请选择发起约束的可选值" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} style={{"width":"100%"}}>
                            {allPropertiesArray[fieldValue["propertyId"]]===undefined?"":allPropertiesArray[fieldValue["propertyId"]]["options"].
                            map(item=><Option key={item} value={item}>{item}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item name={"selectedPropertyAndOption"} label={"被影响的属性及其可选值"}>
                        <TreeSelect showSearch treeData={jsonDataForTree} style={{width: '100%'}} dropdownStyle={{maxHeight: 400, overflow: 'auto',}} placeholder="请选择被影响的属性及其选项" allowClear multiple/>
                    </Form.Item>
                    <Button type={"primary"} size={"small"} onClick={reverseSelectTreeSelect}>反选</Button>
                </Space>

            )


        }else{
            return "";
        }
    }

    const reverseSelectTreeSelect = ()=>{
        let resultOfForm2 = formOfSelectAffectedProperty.getFieldValue();
        if(resultOfForm2["selectedPropertyAndOption"]===undefined||resultOfForm2["selectedPropertyAndOption"].length===0){
            message.error("您未选择被约束的可选值，无法反选！");
            return
        }

        // console.log(resultOfForm2);
        let selectedOption = {};
        for (let index in resultOfForm2["selectedPropertyAndOption"]){
            let splitArray = resultOfForm2["selectedPropertyAndOption"][index].split("<--->");
            let array = selectedOption[splitArray[0]];
            if (array === null||array === undefined){
                let newArray = [];
                newArray.push(splitArray[1]);
                selectedOption[splitArray[0]] = newArray;
            }else{
                array.push(splitArray[1])
                selectedOption[splitArray[0]] = array;
            }
        }
        let afterReversingOption = [];
        for (let key in selectedOption){
            let allOption = allPropertiesArray[key]["options"];
            let afterFilterOptions = allOption.filter(item=>selectedOption[key].indexOf(item) === -1);
            if (afterFilterOptions.length!==0){
                for (let index in afterFilterOptions){
                    afterReversingOption.push(key+"<--->"+afterFilterOptions[index]);
                }
            }
        }
        // console.log(afterReversingOption);
        formOfSelectAffectedProperty.setFieldsValue({selectedPropertyAndOption:afterReversingOption});
    }
    return (
        <Space direction="vertical" style={{"width":"100%"}}>
            <Collapse defaultActiveKey={['1']} >
                <Panel key={1} header={"添加or查询or修改属性约束"}>
                    <Card title={"约束即位于前位的属性的选值会影响后位属性的可选值的情况"} style={{"width":"100%"}}>
                        <Space direction="vertical" style={{"width":"100%"}} size={"middle"}>
                            <Form form={formOfAddPropertyConstraint}>
                                <Form.Item label={"第一步：选择大类"} name={"categoryId"} style={{"width":"100%"}} rules={[{required: true, message: '请选择大类!',}]}>
                                    <Select showSearch placeholder="请选择一个大类" optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        onSelect={(value)=>loadEntitiesByCategorySelect(value)} style={{"width":"100%"}}
                                    >
                                        {categoryArrayForSelect!==null?categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> ):""}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={"第二步：选择实体"} name={"id_of_entity"} style={{"width":"100%"}} rules={[{required: true, message: '请选择实体!',}]}>
                                    <Select showSearch placeholder="请选择实体" optionFilterProp="children" options={optionOfEntitySelect} onSelect={(value)=>load_properties(value)}
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}/>

                                </Form.Item>

                                <Form.Item name={"propertyId"} label={"第三步：选择要添加约束的属性"} style={{"width":"100%"}} rules={[{required: true, message: '请选择要发起约束的属性!',}]}>
                                    <Select showSearch placeholder="请选择要发起约束的属性" optionFilterProp="children" options={optionOfPropertiesSelect}
                                            onSelect={(value)=>handleSelectingStartingConstraintProperty(value)}
                                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}/>
                                </Form.Item>
                            </Form>
                                <Divider />
                                <Collapse>
                                    <Panel key={1} header={"已有的约束"} extra={optionOfPropertiesSelect.length!==0?<Text type={"success"}>属性选择路径为：{optionOfPropertiesSelect.map(item=>"->"+item["label"])}</Text>:""}>
                                        <Card type={"inner"} loading={loadingOfExistedConstraintCard}>
                                            <Table expandedRowRender={expandedRow} columns={columnsOfOuterTable} bordered dataSource={constrainedDataForOuterTable} rowKey={record => record["propertyId"]}/>
                                        </Card>
                                    </Panel>
                                    <Panel key={2} header={"添加新约束"}>
                                        <Space direction={"vertical"} style={{"width":"100%"}}>
                                            <Text type={"warning"}>约束设置推荐是前位的属性对后位的属性进行限制，例如：选择了属性A的选项A1后，位于属性A之后的属性B的选项B1和B2就不能被选择，这时应设置A属性为约束发起的属性
                                                而不是B属性。</Text>
                                            <Paragraph>您已选择的发起约束的属性为：{returnTextAboutSelectedOptionValues()}</Paragraph>
                                            <Paragraph>该属性对其他属性的约束情况：{returnTextAboutConstraintSiturationOfSelectedOptionValues()}</Paragraph>
                                            <Divider/>
                                            <Paragraph><Text type={"danger"}>注意：约束添加是覆盖添加的！<Text underline>若只选择了发起约束的值，未选择被影响的属性的情况，则进行的是删除操作。</Text></Text></Paragraph>
                                            <>
                                                <Form form={formOfSelectAffectedProperty}>
                                                        {returnFormForAffectedProperty()}
                                                </Form>
                                                <Button type={"dashed"} onClick={()=>handleSubmit()}>提交申请</Button>
                                            </>
                                        </Space>
                                    </Panel>
                                </Collapse>

                        </Space>
                    </Card>
                </Panel>

            </Collapse>
        </Space>
    );
}

export default AddPropertiesConstraint;