import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {Button, Card, message, Modal, Space, Table} from "antd";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import WarehouseLocationTable from "../../../components/Query/WarehouseLocationTable";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {handleResponseResultOfQueryEntityDetailsTable} from "../../../utlis/UniversalFunctions";
import ExportJsonExcel from 'js-export-excel';
function QueryWarehouse(props) {


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const navigate = useNavigate();
    const [loadingOfOuterCard,setLoadingOfOuterCard] = useState(true);
    const [visibleOfLocationModal,setVisibleOfLocationModal] = useState(false);
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [dataSourceOfAllEntity,setDataSourceOfAllEntity] = useState([]);
    const [loadingOfLocationButton,setLoadingOfLocationButton] = useState(false);
    const [loadingOfPropertyButton,setLoadingOfPropertyButton] = useState(false);
    const [bianhaoToWarehouseLocationTable,setBianhaoToWarehouseLocationTable] = useState(null);




    const [toBeCheckedBianhao,setToBeCheckedBianhao] = useState();
    const getAllEntityInWarehouse=()=>{
        post(APIOBJECT["queryWarehouseAll"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfAllEntity(resultAfterHandle["data"]);
            }
            setLoadingOfOuterCard(false);
        }).catch(error=>message.error("getAllEntityInWarehouse出错啦！"+error))
    }
    useEffect(() => {
        getAllEntityInWarehouse();
        return () => {};
    }, []);

    const getWarehouseDetails = (bianhao)=>{
        if (bianhao!==bianhaoToWarehouseLocationTable){
            setLoadingOfLocationButton(true);
        }
        setBianhaoToWarehouseLocationTable(bianhao);
        setVisibleOfLocationModal(true);

    }

    const columnsOfOuter = [
        {
            title:"实体关系名",
            key:"chineseNameOfEntity",
            dataIndex:"chineseNameOfEntity",
            width: 240,
            ...getColumnSearchProps("chineseNameOfEntity")
        },
        {
            title:"所属大类",
            key:"categoryName",
            dataIndex:"categoryName",
            width:120,
            ...getColumnSearchProps("categoryName")
        },
        {
            title: "编号",
            key:"bianhao",
            dataIndex: "bianhao",
            ellipsis: true,
            width:90,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "详情",
            key:"propertiesString",
            dataIndex: "propertiesString",
            width:300,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title: "在库数量",
            key:"quantity",
            width:90,
            dataIndex: "quantity"
        },
        {
            title: "具体库位信息",
            key:"warehouseString",
            dataIndex: "warehouseString",
            ellipsis: true,
            width:300,
            ...getColumnSearchProps("warehouseString")
        },
        {
            title: "操作",
            width:200,
            fixed:"right",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Button type={"primary"} size={"small"} onClick={()=>{
                                setToBeCheckedBianhao(record["bianhao"]);
                            setVisibleOfPropertiesModal(true);}} >属性详情</Button>
                        <Button type={"default"} size={"small"} onClick={()=>getWarehouseDetails(record["bianhao"])}>库位详情</Button>
                    </Space>)
            }
        }
    ]

    const downloadFileToExcel = ()=>{
        const datas= dataSourceOfAllEntity ? dataSourceOfAllEntity:'';//表格数据
        var option={};
        let dataTable = [];
        if (datas) {
            datas.map((item)=>{
                let warehouseArray = item["warehouseString"].split(";");
                for(let warehouseItem of warehouseArray){
                    if(warehouseItem === "" || warehouseItem === null || warehouseItem === undefined){
                        continue;
                    }
                    let obj = {
                        chineseNameOfEntity: item.chineseNameOfEntity,
                        idOfEntity: item.idOfEntity,
                        categoryName: item.categoryName,
                        bianhao: item.bianhao,
                        quantity: warehouseItem.split(":")[1].replace(/[^\d.]/g,''),
                        unit: item.unit,
                        propertiesString:item.propertiesString,
                        warehouseName:warehouseItem.split(":")[0]
                    }
                    dataTable.push(obj);
                }

                return dataTable
            })

        }
        let myData = new Date();
        option.fileName = myData.toLocaleDateString() + '库存情况';
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'库存情况',
                sheetFilter:['chineseNameOfEntity','idOfEntity','categoryName','bianhao','unit','propertiesString','warehouseName','quantity'],
                sheetHeader:['实体关系名','实体关系id','所属大类','实体编号','单位','属性详情','库位详情','在库数量'],
            }
        ];
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    return (
        <Card title={"库房总体情况"} loading={loadingOfOuterCard} extra={<Button type={"primary"} onClick={downloadFileToExcel}>导出成Excel</Button>}>
            <Table scroll={{x: 1300}} bordered columns={columnsOfOuter} dataSource={dataSourceOfAllEntity} rowKey={record => record["bianhao"]}/>
            <Modal width={900} title={"各个库位的情况"} visible={visibleOfLocationModal} onOk={()=>setVisibleOfLocationModal(false)}
                   onCancel={()=>setVisibleOfLocationModal(false)} okText={"好的"} cancelText={"取消"}>
                <WarehouseLocationTable bianhao={bianhaoToWarehouseLocationTable} setLoading={setLoadingOfLocationButton}/>
            </Modal>
            <Modal width={800} title={"具体属性情况"} visible={visibleOfPropertiesModal} onOk={()=>setVisibleOfPropertiesModal(false)}
                   onCancel={()=>setVisibleOfPropertiesModal(false)} okText={"好的"} cancelText={"取消"}>
                <QueryEntityDetailsTable bianhao={toBeCheckedBianhao} setLoading={setLoadingOfPropertyButton}/>
            </Modal>
        </Card>
    );
}

export default QueryWarehouse;