import React, {useState} from 'react';
import Avatar from "antd/es/avatar/avatar";
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import {Dropdown, Form, Menu, message, Modal} from "antd";
import {removeLogin_time, removeToken} from "../../utlis/auth";
import "./index.css"
import {useNavigate} from "react-router-dom";
import {Logout, verifyToken} from "../../service/auth";
import {handleResult} from "../../utlis/response";
import {post} from "../../utlis/request";
import {APIOBJECT} from "../../static/CONSTANTS";
import Input from "antd/es/input/Input";
function Index(props) {
    const navigate = useNavigate();
    const {setLogined,setRouteTable,username}=props;
    const [visibleOfChangePasswordModal,setVisibleOfChangePasswordModal] = useState(false);
    const handle_menu_click=(e)=>{
        if (e.key === "logout"){
            console.log("到这里啦！")
            post(APIOBJECT["logoutUrl"],null).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message);
                console.log("进来咯")
                removeToken();
                window.location.href = window.location.href;
            }).catch(error=>message.error("出错啦请联系管理员！"+error))
        }else if (e.key === "changePassword"){
                setVisibleOfChangePasswordModal(true);
        }
    };
    const menu_of_user = [
        {
            key:"changePassword",
            label:"修改密码",
        },
        {
            key:"logout",
            label:"退出登录",
            danger:true
        }
    ];
    const [formOfChangePassword] = Form.useForm();
    const handleSubmit = (value)=>{
        console.log(value)
        post(APIOBJECT["changePassword"],JSON.stringify({"oldPassword":value["oldPassword"],
            "password":value["password"]})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle);
            if (resultAfterHandle!==false){
                message.success("修改密码成功，即将重新登录！");
                removeToken();
                window.location.href = window.location.href;
            }else{
                message.error("旧密码验证失败！")
                setVisibleOfChangePasswordModal(false);
            }
        }).catch(error=>{
            message.error("出错了！"+error);
            setVisibleOfChangePasswordModal(false);
        })
    }
    return (
        <>
        <Dropdown className={"avatar_user"} overlay={<Menu items={menu_of_user} onClick={handle_menu_click}/>}>
            <div>
                <span>{username}</span>
                <a onClick={(e) => e.preventDefault()}>
                    <Avatar size={"large"} icon={<UserOutlined />} />
                </a>
            </div>
        </Dropdown>
        <Modal title={"修改密码"} onCancel={()=>setVisibleOfChangePasswordModal(false)}
               visible={visibleOfChangePasswordModal} onOk={()=>formOfChangePassword.submit()}>
            <Form onFinish={handleSubmit} form={formOfChangePassword}>
                <Form.Item name={"oldPassword"} rules={[{required:true, message:"请输入旧密码"}]} label={"请输入旧密码："}>
                    <Input type={"password"} placeholder={"用户旧密码"}/>
                </Form.Item>
                <Form.Item name={"password"} rules={[{required:true, message:"请输入新密码"}]} label={"请输入新密码："}>
                    <Input type={"password"} placeholder={"用户新密码"}/>
                </Form.Item>
                <Form.Item name={"passwordConfirm"} rules={[{required:true, message:"请再次输入新密码"},
                    ({getFieldValue})=>({validator(_,value){if (value!==getFieldValue("password"))
                        {return Promise.reject(new Error("两次密码输入不一致！"));}return Promise.resolve();}})]} label={"请再次输入新密码："}>
                    <Input type={"password"} placeholder={"请再次用户新密码"}/>
                </Form.Item>
            </Form>
        </Modal>
        </>

    );
}

export default Index;