import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {Button, Card, Form, message, Modal, Popconfirm, Select, Space, Table, Typography} from "antd";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {convertListToString} from "../../../utlis/StringUtils";

function ApplicantsEdit(props) {
    const {Text} = Typography;
    const {Option} = Select;
    const [dataSourceOfTable,setDataSourceOfTable] = useState([]);

    const [loadingOfCard,setLoadingOfCard] = useState(false);
    const [roleExistedForOption,setRoleExistedForOption] = useState([]);
    const [roleExistedArray,setRoleExistedArray] = useState([]);
    const [rolesHad,setRolesHad] = useState(null);

    const [formOfModifyUser] = Form.useForm();
    const [formOfModifyUserPassword] = Form.useForm();
    const [confirmLoading1, setConfirmLoading1] = useState(false);//用于modal的ok的按钮的loading状态
    const [visibleOfFormModifyUserPassword,setVisibleOfFormModifyUserPassword] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);//用于modal的ok的按钮的loading状态
    /**
     * 用于构建可搜索table的固定部分,做了一些修改
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{marginBottom: 8, display: 'block',}}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    const [visibleOfAddingModal,setVisibleOfAddingModal] = useState(false);
    /**
     * 用于构建可搜索table的固定部分
     */
    const getAllApplicant = ()=>{
        post(APIOBJECT["getAllApplicant"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setDataSourceOfTable(resultAfterHandle["data"]);
            }
        }).catch(error=>message.error("出错啦！"+error))
    }
    const addApplicant = (values)=>{
        let {name} = values;
        if (dataSourceOfTable.filter(item => item["name"] === name).length!==0) {
            message.error("名称："+name+"，已存在，请修改！");
            return;
        }
        post(APIOBJECT["addApplicant"],JSON.stringify(values)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("添加成功！");
                setVisibleOfAddingModal(false);
                getAllApplicant();
            }
        }).catch(error=>message.error("出错啦！"+error)).finally(()=>setConfirmLoading1(false))
    }
    const columns = [
        {
            title:"编号",
            key:"id",
            dataIndex:"id",
            ...getColumnSearchProps("id")
        },
        {
            title: "名字",
            key:"name",
            dataIndex: "name",
            ...getColumnSearchProps("name")
        },
        {
            title: "备注",
            key:"remark",
            dataIndex: "remark",
            ...getColumnSearchProps("remark")
        },
        {
            title: "创建时间",
            key:"time",
            dataIndex: "create_time",
            ...getColumnSearchProps("create_time")
        },
        {
            title: "更新时间",
            key:"time",
            dataIndex: "update_time",
            ...getColumnSearchProps("update_time")
        },
        {
            title: "操作",
            width:100,
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>

                        <Popconfirm title={"确定删除该用户吗？"} onConfirm={()=>{console.log("删除");}}>
                            <Button type={"primary"} danger size={"small"}>删除</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    const navigate = useNavigate();

    useEffect(() => {
        setLoadingOfCard(true);
        getAllApplicant();

        setLoadingOfCard(false);
        return () => {};
    }, []);


    return (
        <Card title={"查看申请人"} loading={loadingOfCard} extra={<Button onClick={()=>setVisibleOfAddingModal(true)}>新增申请人</Button>}>
            <Table columns={columns} rowKey={record => record["name"]} dataSource={dataSourceOfTable}/>

            <Modal title={"新增申请人"} okText={"提交申请"} cancelText={"取消"}
                   onCancel={()=>setVisibleOfAddingModal(false)}
                   onOk={()=>formOfModifyUser.submit()}
                   visible={visibleOfAddingModal}
                   confirmLoading={confirmLoading1}>

                <Form form={formOfModifyUser}
                      onFinish={value=>{
                          setConfirmLoading1(true);
                          addApplicant(value);
                      }}
                >
                    <Space direction={"vertical"} size={"middle"} style={{"width":"100%"}}>
                        <Form.Item name={"name"} label={"名称"} rules={[{required:true,message:"请输入申请人名称！"}]}>
                            <Input type={"text"}/>
                        </Form.Item>
                        <Form.Item name={"remark"} label={"备注"}>
                            <Input type={"text"}/>
                        </Form.Item>
                    </Space>
                </Form>

            </Modal>


        </Card>
    );
}

export default ApplicantsEdit;