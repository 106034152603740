import React, {useEffect, useRef, useState} from 'react';
import {Button, message, Modal, Space, Table, Typography} from "antd";
import Input from "antd/es/input/Input";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {post} from "../../utlis/request";
import {APIOBJECT} from "../../static/CONSTANTS";
import {handleResult} from "../../utlis/response";
import {handleResponseResultOfQueryEntityDetailsTable} from "../../utlis/UniversalFunctions";
import {useNavigate} from "react-router-dom";
import QRCode from 'qrcode.react';
import ImageOfEntity from "./ImageOfEntity";

/**
 * 只显示单个实体的具体属性信息，此时只需传bianhao
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function QueryEntityDetailsTable(props) {
    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */
    const navigate = useNavigate();
    const [dataSourceOfOuter,setDataSourceOfOuter] = useState([]);
    const [dataSourceOfInner,setDataSourceOfInner] = useState([]);
    const [visibleOfQrcodeModal,setVisibleOfQrcodeModal] = useState(false);
    const [unitOfEntity,setUnitOfEntity] = useState();
    const [loadingOfTable,setLoadingOfTable] = useState(true);
    const {bianhao,setLoading,setUnitOfBianhao} = props;
    const {Text,Paragraph} = Typography;
    const [visibleOfImageModal,setVisibleOfImageModal] = useState(false);

    useEffect(() => {

        return () => {
        };
    }, []);


    const columns_of_nested_table = [
        {
            title:"属性名",
            key:"chinese_name",
            dataIndex:"chinese_name",
            ...getColumnSearchProps("chinese_name")
        },
        {
            title: "属性id",
            key:"id_of_property",
            dataIndex: "id_of_property",
            ...getColumnSearchProps("id_of_property")
        },
        {
            title: "值",
            key:"value_of_property",
            dataIndex: "value_of_property",
            render:(_,{value_of_property})=>
            {
                if (value_of_property==="空值"){return (<Text type="warning">{value_of_property}</Text>);}
                return(<Text>{value_of_property}</Text>)
            }
        }
    ]
    const columns =[
        {
            title:"实体id",
            key:"id_of_entity",
            dataIndex: "id_of_entity"
        },
        {
            title: "实体名字",
            dataIndex:"name_of_entity",
            key:"name_of_entity"
        }
    ]
    const getBianhaoProperties = (bianhao)=>{
        setDataSourceOfInner(null);
        setLoadingOfTable(true);
        post(APIOBJECT["queryEntityByNumber"],JSON.stringify({"number_of_entity":bianhao})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setUnitOfEntity(resultAfterHandle["data"]["unit"]);
                if(setUnitOfBianhao){
                    setUnitOfBianhao(resultAfterHandle["data"]["unit"]);
                }
                let {outer,inner} = handleResponseResultOfQueryEntityDetailsTable(resultAfterHandle);
                setDataSourceOfOuter(outer);
                setDataSourceOfInner(inner);
            }else{
                setDataSourceOfOuter([]);
                setDataSourceOfInner([]);
                setUnitOfEntity(null);
            }
            setLoadingOfTable(false);
            //如果有回调操作的话，进行回调操作
            if (setLoading !==undefined&&setLoading!==null){
                setLoading(false);
            }
        }).catch(error=>{
            message.error("出错啦！"+error)
            setLoadingOfTable(false);
            if (setLoading !==undefined&&setLoading!==null){
                setLoading(false);
            }
        })
    }
    const [textUnderQRCode,setTextUnderQRCode] = useState(null);
    const [entityNameFroQRCode,setEntityNameFroQRCode] = useState(null);

    const handleClickButtonToGetQRcode = ()=>{
        if (dataSourceOfInner.length===0||dataSourceOfOuter.length===0){
            message.error("该编号查询失败！无法生成二维码！");
            return;
        }

        //构造textUnderQRCode
        let textUnderQRCode ="";
        let entityName = dataSourceOfOuter[0]["name_of_entity"]+"\\";
        for (let index in dataSourceOfInner){
            if (dataSourceOfInner[index]["value_of_property"]==="空值"){
                continue;
            }
            if (Number(index) === 0){
                textUnderQRCode+=dataSourceOfInner[index]["value_of_property"];
            }else{
                textUnderQRCode+="\\"+dataSourceOfInner[index]["value_of_property"];
            }
        }

        let rightSideMostLengthOfWords = 28;


        //二维码右侧区域最多放28个字符
        if (entityName.length<rightSideMostLengthOfWords){
            if (textUnderQRCode.length>28-entityName.length){
                let tempLength = entityName.length;
                entityName+="\\"+textUnderQRCode.substring(0,rightSideMostLengthOfWords-tempLength);
                textUnderQRCode=textUnderQRCode.slice(rightSideMostLengthOfWords-tempLength);
            }else{
                entityName+="\\"+textUnderQRCode;
                textUnderQRCode = "";
            }
        }

        setEntityNameFroQRCode(entityName);
        setTextUnderQRCode(textUnderQRCode);
        setVisibleOfQrcodeModal(true);
    }

    const handleClickCheckImageButton = ()=>{
        if (dataSourceOfInner.length===0||dataSourceOfOuter.length===0){
            message.error("该编号查询失败！无法生成二维码！");
            return;
        }
        setVisibleOfImageModal(true);
    }


    useEffect(() => {
        setVisibleOfQrcodeModal(false);
        getBianhaoProperties(bianhao);
        return () => {};
    }, [bianhao]);




    const expandedRow = row =>{
        return <Table bordered columns={columns_of_nested_table} dataSource={dataSourceOfInner}  rowKey={record => record.id_of_property} />
    }

    return (
        <>
            <Modal width={1000} title={"二维码"} onOk={()=>setVisibleOfQrcodeModal(false)} onCancel={()=>setVisibleOfQrcodeModal(false)} visible={visibleOfQrcodeModal}>
                <Space direction={"vertical"}>
                    <Space>
                        <QRCode value={bianhao===null||bianhao===undefined?""
                            :bianhao} size={400}  fgColor="#000000"/>
                        <span style={{fontSize:"500%"}}>{entityNameFroQRCode===null?"":entityNameFroQRCode}</span>
                    </Space>
                    <span style={{fontSize:"500%"}}>{textUnderQRCode}</span>
                </Space>
            </Modal>

            <Modal visible={visibleOfImageModal} title={"实体关系图片"} width={1000} onOk={()=>setVisibleOfImageModal(false)} onCancel={()=>setVisibleOfImageModal(false)}>
                <ImageOfEntity bianhao={bianhao}/>
            </Modal>

            <Space size={"large"}>
                <Text>该实体关系的单位是：<Text type={"success"} children={unitOfEntity===null?"":unitOfEntity} strong/></Text>
                <Button type={"primary"} size={"small"} onClick={()=>handleClickButtonToGetQRcode()}>获取二维码</Button>
                <Button size={"small"} type={"dashed"} onClick={handleClickCheckImageButton}>查看图片</Button>
            </Space>
            {
                dataSourceOfInner == null || dataSourceOfInner.length <= 0 ? "loading..." :
                    <Table loading={loadingOfTable} columns={columns} bordered pagination={false}
                        //expandedRowRender={expandedRow}
                           expandable={{
                               expandedRowRender:expandedRow,
                               defaultExpandAllRows:true
                           }}
                           dataSource={dataSourceOfOuter} rowKey={record => record.id_of_entity}/>
            }
        </>
    );
}

export default QueryEntityDetailsTable;