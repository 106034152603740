import React, {useEffect} from 'react';
import {post} from "../utlis/request";
import {APIOBJECT} from "../static/CONSTANTS";
import {handleResult} from "../utlis/response";
import {Button, message} from "antd";
import {useNavigate} from "react-router-dom";

function PageNotFound(props) {

    const {setRouteTable} = props;
    const navigate = useNavigate()
    useEffect(() => {
        post(APIOBJECT["getMenuByToken"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setRouteTable(resultAfterHandle["data"]["menu"]);
            }}).catch(error=>message.error("出错啦！"))
        return () => {
        };
    }, []);



    return (
        <div>
            <h1>404</h1>
            <Button type={"primary"} onClick={()=>navigate("/admin")}>返回主页</Button>
            <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021014961号-1</a>
        </div>
    );
}

export default PageNotFound;