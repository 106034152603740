import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Image, message, Popconfirm, Space, Typography} from "antd";
import {APIOBJECT, base64Object, constantBaseURL} from "../../static/CONSTANTS";
import {post} from "../../utlis/request";
import {handleResult} from "../../utlis/response";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import Upload from "antd/es/upload/Upload";
import {getToken} from "../../utlis/auth";

function ImageOfEntity(props) {
    const {entityId,bianhao} = props;
    const navigate = useNavigate();
    const {Text} = Typography
    const [imageUrl,setImageUrl] = useState(null);
    const [entityName, setEntityName] = useState(null);
    const [loadingOfUpload,setLoadingOfUpload] = useState(false);
    const [loadingOfClearImageButton,setLoadingOfClearImageButton] = useState(false);
    const [loadingOfConfirmChangeButton,setLoadingOfConfirmChangeButton] = useState(false);
    const [ifImageChanged,setIfImageChanged] = useState(false);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error('你只能上传jpg或者png格式的图片');
        }

        const isLt2M = file.size / 1024 / 1024 < 4;

        if (!isLt2M) {
            message.error('图片必须小于4MB');
        }

        if (entityId===null||entityId===undefined){
            message.error("属性id为空！！");
            return false;
        }

        return isJpgOrPng && isLt2M;
    };
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoadingOfUpload(true);
            return;
        }
        if (info.file.status === 'done') {
            if (info["file"]["response"]["code"]===200){
                setImageUrl(info["file"]["response"]["msg"])
                setIfImageChanged(true);
            }else{
                setLoadingOfUpload(false);
                console.log(info.file)
                message.error("上传失败！")
            }
        }
    };
    const uploadButton = (
        <div>
            {loadingOfUpload ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{marginTop: 8,}}>Upload</div>
        </div>
    );

    const clearImageUploaded = ()=>{
        if (!imageUrl){
            message.error("您未上传图片，无法清除！");
        }else{
            setLoadingOfClearImageButton(true);
            post(APIOBJECT["deleteImageByUrl"],JSON.stringify({imageUrl:imageUrl})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    message.success(resultAfterHandle["msg"]);
                    setImageUrl(null);
                    setLoadingOfUpload(false);
                    setEntityName(entityName+";该实体未上传图片！")
                }
                setLoadingOfClearImageButton(false);
            }).catch(error=>{
                message.error("clearImageUploaded出错了！"+error);
                setLoadingOfClearImageButton(false);
            })
        }
    }


    const confirmChangeImage = ()=>{
        setLoadingOfConfirmChangeButton(true);
        post(APIOBJECT["changeImageByEntityId"],JSON.stringify({imageUrl:imageUrl,entityId:entityId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setIfImageChanged(false);
                setEntityName(entityName);
            }
            setLoadingOfConfirmChangeButton(false);
        }).catch(error=>{
            message.error("confirmChangeImage出错了！"+error);
            setLoadingOfConfirmChangeButton(false);
        })
    }

    useEffect(() => {
        setIfImageChanged(false);
        post(APIOBJECT["getImageByBianhaoOrEntityId"],JSON.stringify({entityId:entityId,bianhao:bianhao})).then(resultBeforeHandle=>{
            console.log(resultBeforeHandle);
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]["imageUrl"] === "none"){
                    setEntityName(resultAfterHandle["data"]["entityName"]+";该实体未上传图片！")
                    setImageUrl(null);
                }else{
                    setEntityName(resultAfterHandle["data"]["entityName"]);
                    setImageUrl(resultAfterHandle["data"]["imageUrl"])
                }
            }
            setLoadingOfUpload(false);
        }).catch(error=>{message.error("ImageOfEntity出错了！"+error)})
        return () => {};
    }, [entityId,bianhao]);


    return (
        <Space direction={"vertical"}>
            <Text>实体关系名称为{entityName}</Text>
            <Image src={constantBaseURL+imageUrl} width={"100%"} fallback={base64Object["errorPic"]}/>
            {entityId?
                <Space direction={"vertical"} size={"middle"}>
                    <Upload  listType="picture-card" headers={{token:getToken()}}
                             action={APIOBJECT["uploadEntityImage"]} showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
                        {imageUrl ? ("已上传") : (uploadButton)}
                    </Upload>
                    <Popconfirm title={"确定清除此图片吗？实体以此图片作为展示的都会删除。此操作不可撤销！"} okText={"清除"} onCancel={"取消"} onConfirm={clearImageUploaded}>
                        <Button loading={loadingOfClearImageButton} danger >清除图片</Button>
                    </Popconfirm>
                    {ifImageChanged?<Button onClick={confirmChangeImage} loading={loadingOfConfirmChangeButton}>确定改变</Button>:""}
                </Space>
                :""}
        </Space>
    );
}

export default ImageOfEntity;