import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, message, Popconfirm, Select, Space, Table} from "antd";
import $ from "jquery";
import {getToken} from "../../../utlis/auth";
import {QuestionCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {get, post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {useNavigate} from "react-router-dom";
import Input from "antd/es/input/Input";
import Highlighter from 'react-highlight-words';
function PropertiesRepository(props) {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                    placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                    searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});




    const {Option} = Select;
    const [datasource_of_reuse_properties,setDatasource_of_reuse_properties] = useState([]);
    const columns_of_reuse_properties_repository=[
        {
            title:"属性id",
            key:"id_of_reuse_property",
            dataIndex:"id_of_reuse_property",
            ...getColumnSearchProps("id_of_reuse_property")
        },
        {
            title: "属性名",
            key:"chinese_name",
            dataIndex: "chinese_name",
            ...getColumnSearchProps("chinese_name")
        },
        {
            title: "父实体id",
            key:"id_of_entity_from",
            dataIndex: "id_of_entity_from",
            ...getColumnSearchProps("id_of_entity_from")
        },
        {
            title: "可选值",
            key:"json_options",
            dataIndex: "json_options",
            render:(_,{json_options})=>
            {
                console.log(json_options);
                return (
                    <Select showSearch
                        optionFilterProp="children"
                        defaultValue={json_options[0]}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}
                    >
                        {json_options.map((item)=><Option key={item}>{item}</Option>)}
                    </Select>
                )}

        },
        {
            title: "已绑定的实体",
            key:"bind_entities",
            dataIndex: "bind_entities",
            render:(_,{bind_entities})=>
            {
                return (
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={bind_entities[0]}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        style={{"width":"100%"}}
                    >
                        {bind_entities.map((item)=><Option key={item}>{item}</Option>)}
                    </Select>
                )}
        },
        {
            title: "操作",
            render:(txt,record,index)=>{
                return(
                    <Space size={"middle"}>
                        <Popconfirm placement={"leftTop"} icon={<QuestionCircleOutlined style={{color: 'red',}}/>}
                                    title={"删除后，所有与该属性绑定的实体都会解绑，且该过程是不可逆！请慎重！"}  onConfirm={()=>submitDeleteReuseProperty(record)}>
                            <Button type={"primary"} danger size={"middle"}>删除</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]
    const [loading_of_card,setLoading_of_card] = useState(true);
    const navigate = useNavigate();

    const getReuseProperties = ()=>{
        setLoading_of_card(true);
        post(APIOBJECT["getRuseProperties"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let result = resultAfterHandle["data"];
                setDatasource_of_reuse_properties(result);
                console.log(result);
                setLoading_of_card(false);
            }
        }).catch(error=>{message.error("出错啦"+error);})
    }

    const submitDeleteReuseProperty = (record)=>{

        post(APIOBJECT["deleteReuseProperty"],{"id_of_entity_from":record["id_of_entity_from"],
            "id_of_reuse_property":record["id_of_reuse_property"]}).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("删除成功！");
                getReuseProperties();
            }
        }).catch(error=>message.error("出错啦！"+error));


        // $.ajax({
        //     url: APIOBJECT["deleteReuseProperty"],//请求路径
        //     data: JSON.stringify({"id_of_entity_from":record["id_of_entity_from"], "id_of_reuse_property":record["id_of_reuse_property"]}),
        //     type: "POST",//GET
        //     dataType: "json",//需要返回JSON对象(如果Ajax返回的是手动拼接的JSON字符串,需要Key,Value都有引号)
        //     contentType:"application/json",
        //     success: function(result) {
        //         console.log(result);
        //         if (result["status"] === "success"){
        //             message.success("删除成功！")
        //             getReuseProperties();
        //         }else{
        //             message.error("删除出错啦！请联系管理员，出错信息："+result)
        //         }
        //     },
        //     error: function(a, b, c) {
        //         message.error("出错了，请联系管理员！"+b+c);
        //     }
        // })
    }

    useEffect(() => {
        getReuseProperties()
        return () => {};
    }, []);


    return (
        <Card title={"属性库"} loading={loading_of_card}>
            <Table columns={columns_of_reuse_properties_repository} dataSource={datasource_of_reuse_properties} rowKey={record => record.id_of_reuse_property}/>
        </Card>
    );
}

export default PropertiesRepository;