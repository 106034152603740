import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Card, Divider, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Typography} from "antd";
import {post} from "../../../utlis/request";
import {APIOBJECT,taxRateArray} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
    compareByTime, convertArrayResult, findOptionPath, getCascaderOptionArrayForWarehouse,
    handleResponseResultOfQueryEntityDetailsTable,
    judgeIfNum, judgeLegalWarehousingWithPurchaseOrder,
    transferPercentageToNum
} from "../../../utlis/UniversalFunctions";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import QueryEntityByDetail from "../../../components/Query/QueryEntityByDetail";
import QueryPurchaseOrder from "../../../components/Query/QueryPurchaseOrder";
import CascaderSelect from "../../../components/Edit/Warehouse/CascaderSelect";


let index = 0;
function Warehousing(props) {

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */
    const Decimal = require('decimal.js');
    const navigate = useNavigate();
    const [allWarehouseLocation,setAllWarehouseLocation] = useState([]);
    const [loadingOfSubmitToTemporaryTableButton,setLoadingOfSubmitToTemporaryTableButton] = useState(false);
    const [loadingOfSubmitChangeToTemporaryTableButton,setLoadingOfSubmitChangeToTemporaryTableButton] = useState(false);
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [visibleOfChangeModal,setVisibleOfChangeModal] = useState(false);
    const [loadingOfTable,setLoadingOfTable] = useState(true);
    const [submittedRecordInTemporaryTable,setSubmittedRecordInTemporaryTable] = useState(null);
    const [supplierArray,setSupplierArray] = useState([]);
    const [taxValueForAdding,setTaxValueForAdding] = useState(0);
    const [taxValueForChanging,setTaxValueForChanging] = useState(0);
    const [formOfInSubmit] = Form.useForm();
    const [formOfChangeSubmittedRecord] = Form.useForm();
    const [arrayOfApplicants,setArrayOfApplicants] = useState([]);
    const [disableOfBianhaoInput,setDisableOfBianhaoInput] = useState(true);
    const [unitOfBianhao,setUnitOfBianhao] = useState("");
    const [name, setName] = useState('');
    const [purchaseOrderArray,setPurchaseOrderArray] = useState([]);
    const [visibleOfGettingAllPurchaseOrder,setVisibleOfGettingAllPurchaseOrder] = useState(false);
    const [freshOfQueryPurchaseOrder,setFreshOfQueryPurchaseOrder] = useState(false);
    const {Text}=Typography;
    const {Option} = Select;
    const [options, setOptions] = useState([]);
    let completePurchaseOrderArray = useRef();
    
    
    
    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setArrayOfApplicants([...arrayOfApplicants, name || `默认项${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };




    useEffect(() => {
        getPurchaseOrder();
        getAllApplicants();
        getSupplier();
        getSubmittedRecordInTemporaryTable();
        getAllWarehouseLocation();
        return () => {};
    }, []);
    const getSubmittedRecordInTemporaryTable = ()=>{
        setLoadingOfTable(true);
        post(APIOBJECT["getSubmittedInRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"] === null||resultAfterHandle["data"] === undefined){
                    message.info("您的入库暂存表中无记录。")
                    setSubmittedRecordInTemporaryTable([]);//置空，因为有可能是提交主库之后调用的
                }else{
                    console.log(resultAfterHandle)
                    setSubmittedRecordInTemporaryTable(resultAfterHandle["data"]);
                }
            }
            setLoadingOfTable(false);
        }).catch(error=>{
            message.error("getSubmittedRecordInTemporaryTable出错了！"+error);
            setLoadingOfTable(false);
        })
    }
    const onSumBlurChangeOfQuantity = (e,form)=>{
        let quantity = e["target"]["value"];
        // let regex = new RegExp("^[0-9]+(\\.[0-9]{1,6})?$");
        if (!judgeIfNum(quantity)) {
            if(quantity !== "" && quantity !== undefined){
                message.error("数量不正确");
            }
        }else{
            let purchaseOrderMainUniqueId = form.getFieldValue("purchaseOrderMainUniqueId");
            if (purchaseOrderMainUniqueId){
                form.validateFields(["purchaseOrderMainUniqueId"]);
            }
            let sumPrice = form.getFieldValue("sumPrice");
            let price = form.getFieldValue("price");
            if (judgeIfNum(sumPrice)){
                form.setFieldsValue({price:new Decimal(sumPrice).dividedBy(new Decimal(quantity)).toFixed(2)})
            }else if(judgeIfNum(price)){
                form.setFieldsValue({sumPrice:new Decimal(price).mul(new Decimal(quantity)).toFixed(2)})
            }

        }
    }

    const onSumBlurChangeOfSumPrice = (e,form)=>{
        let valueSumPrice = e["target"]["value"];
        if(!valueSumPrice){
            return;
        }
        if (!judgeIfNum(valueSumPrice)) {
            if(valueSumPrice !== "" && valueSumPrice !== undefined){
                message.error("输入总价时，请输入小数");
            }

        }else{
            let quantity = form.getFieldValue("quantity");
            if (!judgeIfNum(quantity)){
                // message.error("请先填写小数形式的入库数量，再填写总价！");
                return;
            }
            let quantityDecimal = new Decimal(quantity);
            let sumPriceDecimal = new Decimal(valueSumPrice);
            form.setFieldsValue({price:sumPriceDecimal.dividedBy(quantityDecimal).toFixed(2)})
        }
    }
    const onSumBlurChangeOfPrice = (e,form)=>{
        let valuePrice = e["target"]["value"];
        // let regex = new RegExp("^[0-9]+(\\.[0-9]{1,6})?$");
        if (!judgeIfNum(valuePrice)) {
            if(valuePrice !== "" && valuePrice !== undefined){
                message.error("输入单价时，请输入小数");
            }
        }else{
            let quantity = form.getFieldValue("quantity");
            if (!judgeIfNum(quantity)){
                // message.error("请先填写小数形式的入库数量，再填写总价！");
                return;
            }
            let quantityDecimal = new Decimal(quantity);
            let priceDecimal = new Decimal(valuePrice);
            form.setFieldsValue({sumPrice:priceDecimal.mul(quantityDecimal).toFixed(2)})
        }
    }
    const changeTaxRate = (taxRate,form,setTax) =>{
        let taxRateDecimal = new Decimal(taxRate);
        let sumPrice = form.getFieldValue("sumPrice");
        let regex = new RegExp("^[0-9]+(\\.[0-9]{1,6})?$");
        if(!judgeIfNum(sumPrice)){
            message.error("请先填写总价！");
            form.setFieldsValue({taxRate:0})
        }else{
            let sumPriceDecimal = new Decimal(sumPrice);
            setTax(sumPriceDecimal.mul(taxRateDecimal.div(new Decimal("1").add(taxRateDecimal))).toFixed(2));
        }
    }

    const getAllWarehouseLocation = ()=>{
        post(APIOBJECT["getAllWarehouseLocation"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setAllWarehouseLocation(resultAfterHandle["data"]);
            }
        }).catch(error=>message.error("getAllWarehouseLocation出错了！"+error))
    }
    const handleSubmitToTemporaryTable = (value)=> {
        setLoadingOfSubmitToTemporaryTableButton(true);
        value["warehouseLocation"] = convertArrayResult(value["warehouseLocation"]);
        post(APIOBJECT["handleInSubmitToTemporaryTable"], JSON.stringify(value)).then(resultBeforeHandle => {
            let resultAfterHandle = handleResult(resultBeforeHandle, message, navigate);
            if (resultAfterHandle !== false) {
                message.success("提交成功！");
                formOfInSubmit.resetFields();
                setTaxValueForAdding(0);
            }
            setLoadingOfSubmitToTemporaryTableButton(false);
        }).then(() => {
            getSubmittedRecordInTemporaryTable();
        }).catch(error => {
            message.error("handleSubmitToTemporaryTable出错了！" + error);
            setLoadingOfSubmitToTemporaryTableButton(false);
        })
    }
    const getAllApplicants = ()=>{
        post(APIOBJECT["getAllApplicantsName"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]!=null&&resultAfterHandle["data"]!==undefined){
                    setArrayOfApplicants(resultAfterHandle["data"]);
                }else{
                    message.error("无供应商信息！请先添加供应商！");
                }

            }
        }).catch(error=>message.error("getSupplier出错了！"+error))
    }
    const getSupplier = ()=>{
        post(APIOBJECT["getAllSupplier"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]!=null&&resultAfterHandle["data"]!==undefined){
                    setSupplierArray(resultAfterHandle["data"]);
                }else{
                    message.error("无供应商信息！请先添加供应商！");
                }

            }
        }).catch(error=>message.error("getSupplier出错了！"+error))
    }
    const handleChangeSubmittedRecord = (recordCreatedTime)=>{
        formOfChangeSubmittedRecord.resetFields();
        let recordArray = submittedRecordInTemporaryTable["dataInProgress"].filter(item=>item["recordCreatedTime"] === recordCreatedTime);
        if (recordArray===null||recordArray.length!==1){
            message.error("recordArray为空，若频繁出现此错误，请联系管理员！");
            return
        }else{
            let record = recordArray[0];
            let warehouseArrayPath = findOptionPath(options,record["toLocationId"]);
            formOfChangeSubmittedRecord.setFieldsValue({...record,warehouseLocation:warehouseArrayPath
                ,quantity:record["quantity"].replace(/[^\d.]/g,'')});
            if (record["purchaseOrderMainUniqueId"]){
                formOfChangeSubmittedRecord.setFieldsValue({purchaseOrderMainUniqueId:record["purchaseOrderMainUniqueId"].split("】")[1]})
            }
            if(record["sumPrice"] !== undefined && record["taxRate"] !== undefined){
                let taxRateDecimal = new Decimal(transferPercentageToNum(record["taxRate"]));
                // console.log(taxRateDecimal);
                setTaxValueForChanging(new Decimal(record["sumPrice"]).mul(taxRateDecimal)
                    .div(new Decimal("1").add(taxRateDecimal)).toFixed(2));
            }else{
                setTaxValueForChanging("暂无");
            }
            setVisibleOfChangeModal(true);
        }
    }
    const submitChangedRecord = (fieldsValue)=>{
        setLoadingOfSubmitChangeToTemporaryTableButton(true);
        //把百分比的taxRate转成小数
        if(String(fieldsValue["taxRate"]).indexOf("%") !== -1){
            fieldsValue["taxRate"] = new Decimal(fieldsValue["taxRate"].replace("%","")).div(new Decimal(100)).toString();
        }
        fieldsValue["warehouseLocation"] = convertArrayResult(fieldsValue["warehouseLocation"]);
        post(APIOBJECT["handleChangeSubmittedTemporaryRecord"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setTaxValueForChanging(0);
            }
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
        }).then(()=>{
            //刷一下暂存表
            getSubmittedRecordInTemporaryTable();
        }).then(()=>{
            //暂存表刷完之后再关闭modal

            setVisibleOfChangeModal(false);
        })
            .catch(error=>{
            message.error("submitChangedRecord出错了！"+error);
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
        })
    }
    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisibleOfPropertiesModal(true);
    }
    const combineToMainTable = ()=>
        post(APIOBJECT["combineInRecordTemporaryToMain"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setFreshOfQueryPurchaseOrder(!freshOfQueryPurchaseOrder);//刷新一下QueryPurchaseOrder
                getSubmittedRecordInTemporaryTable();//重新获得一下暂存表
            }
        })
            .catch(error=>message.error("combineToMainTable出错了！"+error))

    const deleteRecordInTemporaryTable = (recordCreatedTime)=>
        post(APIOBJECT["deleteInRecordInTemporaryTable"],JSON.stringify({recordCreatedTime:recordCreatedTime})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    message.success(resultAfterHandle["msg"]);
                    getSubmittedRecordInTemporaryTable();
                }
            })
            .catch(error=>message.error("deleteRecordInTemporaryTable出错了！"+error))

    const callBackFuncForQueryEntityByDetail = (dataObj)=>{
        if(dataObj){
            let {unit,number_of_properties} = dataObj;
            setUnitOfBianhao(unit);
            formOfInSubmit.setFieldsValue({bianhao:number_of_properties});
            handleBianhaoChange(number_of_properties);
        }

    }
    
    const handleBianhaoChange = (bianhao) =>{
        if (bianhao){
            let suitablePurchaseOrderOption = completePurchaseOrderArray["current"].filter(item => {
                let filterarray2 = item["details"].filter(detail=>detail["bianhao"] === bianhao);
                if (filterarray2){
                    if (filterarray2.length > 0){
                        return true;
                    }
                }
                return false;
            })
            setPurchaseOrderArray(suitablePurchaseOrderOption);
        }
    }
    
    const getPurchaseOrder = ()=>{
        post(APIOBJECT["getPurchaseOrder"]).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle !== false){
                setPurchaseOrderArray(resultAfterHandle["data"]);
                completePurchaseOrderArray["current"] = resultAfterHandle["data"];
                // console.log(completePurchaseOrderArray);
            }
        }).catch(e=>message.error("getPurchaseOrder出错了！"+e))
    }

    const columns = [
        {
            title: "时间",
            key:"recordCreatedTime",
            dataIndex: "recordCreatedTime",
            width: 163,
            // fixed:'left',
            ...getColumnSearchProps("recordCreatedTime")
        },

        {
            title:"名称",
            key:"entityName",
            dataIndex:"entityName",
            // fixed:'left',
            width: 240,
            ...getColumnSearchProps("entityName")
        },
        {
            title:"具体型号",
            key:"propertiesString",
            dataIndex:"propertiesString",
            // fixed:'left',
            width: 300,
            ...getColumnSearchProps("propertiesString")
        },
        {
            title:"号",
            key:"bianhao",
            dataIndex:"bianhao",
            ellipsis: true,
            width:80,
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "数量",
            key:"quantity",
            dataIndex: "quantity",
            width:90,
            ellipsis: true,
            ...getColumnSearchProps("quantity")
        },
        {
            title: "单价",
            key:"price",
            dataIndex: "price",
            width:80,
            ellipsis: true,
            ...getColumnSearchProps("price")
        },
        {
            title: "税率",
            key:"taxRate",
            dataIndex: "taxRate",
            width:80,
            ...getColumnSearchProps("taxRate")
        },
        {
            title: "税金",
            dataIndex: "taxValue",
            width:100,
            ...getColumnSearchProps("taxValue")
        },
        {
            title: "总价",
            key:"sumPrice",
            dataIndex: "sumPrice",
            width:90,
            ...getColumnSearchProps("price")
        },
        {
            title: "库位名称",
            key:"locationName",
            dataIndex: "locationName",
            width: 150,
            // ellipsis: true,
            ...getColumnSearchProps("locationName")
        },
        {
            title: "供应商名称",
            key:"supplierName",
            dataIndex: "supplierName",
            width: 120,
            ...getColumnSearchProps("supplierName")
        },
        {
            title: "采购者",
            key:"applicant",
            dataIndex: "applicant",
            width:100,
            // ellipsis: true,
            ...getColumnSearchProps("applicant")
        },
        {
            title: "关联的采购单",
            key:"purchaseOrderMainUniqueId",
            dataIndex: "purchaseOrderMainUniqueId",
            width:300,
            ellipsis: true,
            ...getColumnSearchProps("purchaseOrderMainUniqueId")
        },
        {
            title: "操作",
            fixed:'right',
            width: 200,
            render:(text,record,index)=>{
                return (<Space size={"small"}>
                    <Button size={"small"} type={"primary"} onClick={()=>handleChangeSubmittedRecord(record["recordCreatedTime"])}>修改</Button>
                    <Popconfirm title={"确认删除该条暂存记录？"} okText={"删除"} cancelText={"取消"} onConfirm={()=>deleteRecordInTemporaryTable(record["recordCreatedTime"])}
                    ><Button danger size={"small"}>删除</Button></Popconfirm>
                    <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])} loading={loadingOfCheckBianhaoPropertiesButton}>详情</Button>
                </Space>)
            }
        }
    ]
    return (
        <Card title={"入库"} extra={<Text>可暂存到暂存表中，点击提交前，记录不会更新到主库中。点击提交后，会提交到主库中。</Text>}>
            <Card type={"inner"} title={"选择实体"}>
                <QueryEntityByDetail setFunc={callBackFuncForQueryEntityByDetail}/>
            </Card>
            <Card type={"inner"} title={"填写入库信息"}
                  extra={
                      <Space size={"large"}>
                          <Button type={"primary"} onClick={()=>checkPropertiesOfBianhao(formOfInSubmit.getFieldValue("bianhao"))}>查看编号属性及单位</Button>
                      </Space>
                  }>
                <Form onFinish={handleSubmitToTemporaryTable} form={formOfInSubmit}>
                    <div style={{display:"flex",justifyContent: "space-between"}}>
                        <Form.Item label={"实体编号"} name={"bianhao"} rules={[{required:true,message:"请输入入库实体的编号！"}]} style={{width:"70%"}} >
                            <Input disabled={disableOfBianhaoInput} type={"text"} onChange={(e)=>handleBianhaoChange(e["target"]["value"])}/>
                        </Form.Item>
                        <div>单位:<strong>{unitOfBianhao}</strong></div>
                        <Button type={"default"} onClick={()=>setDisableOfBianhaoInput(false)}>手动输入</Button>
                    </div>
                    <Form.Item label={"入库的数量"} name={"quantity"}
                               rules={[
                                   {required:true,message:"请输入数量"},
                               ]}>
                        <Input type={"number"} onChange={(e)=>onSumBlurChangeOfQuantity(e,formOfInSubmit)}/>
                    </Form.Item>
                    <Form.Item label={"含税总价"} name={"sumPrice"}
                               rules={[
                                   {required:true,message:"请输入总价"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("单价为正数！"));
                                           }else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>

                        <Input onBlur={(e) => onSumBlurChangeOfSumPrice(e,formOfInSubmit)} type={"number"}/>
                    </Form.Item>
                    <div style={{display:"flex",justifyContent: "space-between"}}>
                        <Form.Item rules={[{required:true,message:"请选择税率"}]} label={"税率"} name={"taxRate"} initialValue={0} style={{width:"70%"}}>
                            <Select showSearch placeholder={"请选择税率"} optionFilterProp={"children"}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxRateArray}  onChange={(value)=>changeTaxRate(value,formOfInSubmit,setTaxValueForAdding)}
                            />
                        </Form.Item>
                        <div>税金为:<strong style={{fontSize:"16px"}}>{taxValueForAdding}</strong>元</div>
                    </div>
                    <Form.Item label={"入库单价"} name={"price"}
                               rules={[
                                   {required:true,message:"请输入单价"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("单价为最多有六位小数的正数！"));
                                           }else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} onBlur={(e)=>onSumBlurChangeOfPrice(e,formOfInSubmit)}/>
                    </Form.Item>
                    <Form.Item label={"申请人"} name={"applicant"} rules={[{required:true,message:"请输入申请人！"}]}>
                        <Select
                            placeholder="请选择申请人"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0',}}/>
                                    <Space style={{padding: '0 8px 4px',}}>
                                        <Input placeholder="请输入"
                                            ref={inputRef}
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                            Add item
                                        </Button>
                                    </Space>
                                </>
                            )}
                            options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                        />
                    </Form.Item>
                    <CascaderSelect setOptionsForProp={setOptions} labelName={"接收库位"} itemName={"warehouseLocation"} warehouseItemArray={allWarehouseLocation.length === 0 ? [] : allWarehouseLocation}/>
                    <Form.Item label={"供应商"} name={"supplierId"} rules={[{required:true,message:"请选择供应商！"}]}>
                        <Select showSearch placeholder="请选择供应商"
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {supplierArray.map(item=><Option disabled={item["disabled"] !== "0"} key={item["supplierId"]} value={item["supplierId"]}>{item["name"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <div style={{display:"flex",justifyContent: "space-between"}}>
                        <Form.Item label={"采购单"} name={"purchaseOrderMainUniqueId"} style={{width:"70%"}}
                                   rules={[
                                       {required:false},
                                       ({getFieldValue})=>({
                                           validator(_,value){
                                               if(!value){
                                                   return Promise.resolve();//不选的时候直接通过
                                               }
                                               let bianhao = getFieldValue("bianhao");
                                               let quantity = getFieldValue("quantity");
                                               if(!bianhao || !quantity){
                                                   formOfInSubmit.resetFields(["purchaseOrderMainUniqueId"]);
                                                   message.error("选择采购单前，请先填充编号和数量！");
                                                   return Promise.reject(new Error("请先填充编号和数量！"));
                                               }

                                                let filteredArray = purchaseOrderArray.filter(item=>item["mainUniqueId"] === value);
                                                if(filteredArray.length !== 1){
                                                    return Promise.reject(new Error("采购单校验失败！请联系管理员！"));
                                                }
                                               let resultOfJudge = judgeLegalWarehousingWithPurchaseOrder(bianhao, quantity, filteredArray[0]);
                                               if (resultOfJudge !== true) {
                                                   return Promise.reject(new Error(resultOfJudge));
                                               }
                                               return Promise.resolve();
                                           }
                                       })
                                   ]}>

                            <Select showSearch placeholder="请选择采购单" allowClear
                                    optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    style={{"width":"100%"}}>
                                {purchaseOrderArray.sort(compareByTime("create_time","inverted")).filter(item=>item["status"] === "采购中")
                                    .map(item=><Option key={item["mainUniqueId"]} value={item["mainUniqueId"]}>{"【"+item["create_time"]+"】"+item["mainUniqueId"]}</Option> )}
                            </Select>
                        </Form.Item>
                        <Button type={"primary"} onClick={()=>setVisibleOfGettingAllPurchaseOrder(true)}>查看所有采购单</Button>
                    </div>
                    <Form.Item>
                        <Button htmlType={"submit"} loading={loadingOfSubmitToTemporaryTableButton}>提交到暂存表</Button>
                    </Form.Item>
                </Form>
            </Card>
            <Modal title={"查看所有采购及其细节"} width={1400} onCancel={()=>setVisibleOfGettingAllPurchaseOrder(false)} onOk={()=>setVisibleOfGettingAllPurchaseOrder(false)} open={visibleOfGettingAllPurchaseOrder}>
                <QueryPurchaseOrder canChangePurchaseOrderDetail={false} fresh={freshOfQueryPurchaseOrder}/>
            </Modal>
            <Modal title={"修改暂存表中的记录"} visible={visibleOfChangeModal}
                   onCancel={()=>{
                       setVisibleOfChangeModal(false);
                       setTaxValueForChanging(0);
                   }}
                    onOk={()=>{
                        formOfChangeSubmittedRecord.submit();
                    }}
                footer={[<Button key={"cancelButton"} danger onClick={()=>setVisibleOfChangeModal(false)} loading={loadingOfSubmitChangeToTemporaryTableButton}>取消</Button>,
                    <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeSubmittedRecord.submit()} loading={loadingOfSubmitChangeToTemporaryTableButton}>提交申请</Button>]}
            >
                <Form form={formOfChangeSubmittedRecord} onFinish={submitChangedRecord}>
                    <Form.Item label={"创建的时间"} name={"recordCreatedTime"} rules={[{required:true,message:"创建的时间不能为空"}]}>
                        <Input type={"text"} disabled/>
                    </Form.Item>
                    <Form.Item label={"实体的编号"} name={"bianhao"} rules={[{required:true,message:"编号不能为空"}]}>
                        <Input type={"text"}/>
                    </Form.Item>
                    <Form.Item label={"入库的数量"} name={"quantity"}
                               rules={[
                                   {required:true,message:"请输入数量"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("数量不正确！"));
                                           } else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} onChange={(e)=>onSumBlurChangeOfQuantity(e,formOfChangeSubmittedRecord)}/>
                    </Form.Item>
                    <Form.Item label={"含税总价"} name={"sumPrice"}
                               rules={[
                                   {required:true,message:"请输入含税总价"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("含税总价为最多有六位小数的正数！"));
                                           }else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input onBlur={(e)=>onSumBlurChangeOfSumPrice(e,formOfChangeSubmittedRecord)} type={"number"}/>
                    </Form.Item>
                    <div style={{display:"flex",justifyContent: "space-between"}}>
                        <Form.Item rules={[{required:true,message:"请选择税率"}]} label={"税率"} name={"taxRate"} initialValue={0} style={{width:"70%"}}>
                            <Select showSearch placeholder={"请选择税率"} optionFilterProp={"children"}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={taxRateArray}  onChange={(value)=>changeTaxRate(value,formOfChangeSubmittedRecord,setTaxValueForChanging)}
                            />
                        </Form.Item>
                        <div>税金为:<strong style={{fontSize:"16px"}}>{taxValueForChanging}</strong>元</div>
                    </div>
                    <Form.Item label={"入库单价"} name={"price"}
                               rules={[
                                   {required:true,message:"请输入单价"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("单价为最多有六位小数的正数！"));
                                           }else{
                                               return Promise.resolve();
                                           }
                                       }
                                   })
                               ]}>
                        <Input type={"number"} onBlur={(e)=>onSumBlurChangeOfPrice(e,formOfChangeSubmittedRecord)}/>
                    </Form.Item>

                    <Form.Item label={"申请人"} name={"applicant"} rules={[{required:true,message:"申请人！"}]}>
                        <Select
                            placeholder="请选择申请人"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider style={{margin: '8px 0',}}/>
                                    <Space style={{padding: '0 8px 4px',}}>
                                        <Input placeholder="请输入"
                                               ref={inputRef}
                                               value={name}
                                               onChange={onNameChange}
                                        />
                                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                            Add item
                                        </Button>
                                    </Space>
                                </>
                            )}
                            options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                        />
                    </Form.Item>
                    <CascaderSelect labelName={"接收库位"} itemName={"warehouseLocation"} warehouseItemArray={allWarehouseLocation.length === 0 ? [] : allWarehouseLocation}/>
                    <Form.Item label={"供应商"} name={"supplierId"} rules={[{required:true,message:"请选择供应商！"}]}>
                        <Select showSearch placeholder="请选择供应商"
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {supplierArray.map(item=><Option key={"change:"+item["supplierId"]} value={item["supplierId"]}>{item["name"]}</Option> )}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"采购单"} name={"purchaseOrderMainUniqueId"}
                               rules={[
                                   {required:false},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           if(!value){
                                               return Promise.resolve();//不选的时候直接通过
                                           }
                                           let bianhao = getFieldValue("bianhao");
                                           let quantity = getFieldValue("quantity");
                                           if(!bianhao || !quantity){
                                               formOfInSubmit.resetFields(["purchaseOrderMainUniqueId"]);
                                               message.error("选择采购单前，请先填充编号和数量！");
                                               return Promise.reject(new Error("请先填充编号和数量！"));
                                           }
                                           let filteredArray = purchaseOrderArray.filter(item=>item["mainUniqueId"] === value);
                                           if(filteredArray.length !== 1){
                                               return Promise.reject(new Error("采购单校验失败！请联系管理员！"));
                                           }
                                           let resultOfJudge = judgeLegalWarehousingWithPurchaseOrder(bianhao, quantity, filteredArray[0]);
                                           if (resultOfJudge !== true) {
                                               return Promise.reject(new Error(resultOfJudge));
                                           }
                                           return Promise.resolve();
                                       }
                                   })
                               ]}>
                        <Select showSearch placeholder="请选择采购单" allowClear
                                optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{"width":"100%"}}>
                            {purchaseOrderArray.sort(compareByTime("create_time","inverted")).filter(item=>item["status"] === "采购中")
                                .map(item=><Option key={item["mainUniqueId"]} value={item["mainUniqueId"]}>{"【"+item["create_time"]+"】"+item["mainUniqueId"]}</Option> )}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal width={1000} title={"属性详情"} visible={visibleOfPropertiesModal} okText={"明白了"} cancelText={"关闭界面"}
                   onCancel={()=>setVisibleOfPropertiesModal(false)} onOk={()=>setVisibleOfPropertiesModal(false)}>
                <QueryEntityDetailsTable setUnitOfBianhao={setUnitOfBianhao} bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
            </Modal>
            <Card title={"暂存表"} type={"inner"} extra={submittedRecordInTemporaryTable === null||submittedRecordInTemporaryTable.length===0?"暂存表中无您的入库记录":
                <Space><Text>第一条记录的提交时间：<Text type={"warning"}>{submittedRecordInTemporaryTable["createdTime"]}</Text></Text>
                    <Popconfirm title={"确定合并到主库吗？该操作不可撤销！"} okText={"合并"} cancelText={"取消"} onConfirm={()=>combineToMainTable()}>
                        <Button type={"dashed"} danger >提交到主库</Button>
                    </Popconfirm></Space>}>
                <Table bordered columns={columns}  dataSource={submittedRecordInTemporaryTable===null?[]:submittedRecordInTemporaryTable["dataInProgress"]}
                       loading={loadingOfTable} rowKey={record => record["recordCreatedTime"]}
                       scroll={{x: 1300}}
                       summary={(pageData) => {
                           let totalSumprice =new Decimal(0);
                           pageData.forEach(({ sumPrice }) => {
                               totalSumprice = totalSumprice.add(new Decimal(sumPrice));
                           });
                           return (
                               <>
                                   <Table.Summary.Row>
                                       <Table.Summary.Cell index={0}>汇总</Table.Summary.Cell>
                                       <Table.Summary.Cell index={1}>
                                           {/*<Text type="danger">{totalBorrow}</Text>*/}
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={2}>
                                           {/*<Text>{totalRepayment}</Text>*/}
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={3}>
                                           <Text></Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={4}>
                                           <Text></Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={5}>
                                           <Text></Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={6}>

                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={7}>
                                           <Text></Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={8}>
                                           <Text>{totalSumprice.toString()}</Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={9}>
                                           <Text></Text>
                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={10} >

                                       </Table.Summary.Cell>
                                       <Table.Summary.Cell index={11} >

                                       </Table.Summary.Cell>
                                   </Table.Summary.Row>
                               </>
                           );
                       }}
                />
            </Card>
        </Card>
    );
}

export default Warehousing;