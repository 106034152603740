import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Input,
    Space,
    Select,
    Form,
    Row,
    Col,
    Tooltip,
    message,
    Popconfirm,
    Modal,
    Table,
    Divider
} from "antd";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import {convertArrayResult, findOptionPath, judgeIfNum} from "../../../utlis/UniversalFunctions";
import CascaderSelect from "../../../components/Edit/Warehouse/CascaderSelect";

let index = 0;
function MoveWarehouse(props) {
    /**
     * 移库的逻辑：
     * step1：先输入编号，点击查询库位，服务端收到编号之后，查询这个编号是否存在库中，若有存，继续检查这个库位是否被禁用
     * 若被禁用了也不能返回给前端，最后如果没有可用的库位，则返回999响应结果，msg是“无可用的出库库位”。
     * step2:若有可用的库位，则返回可用的出库库位以及可用的入库库位（若没有可用的入库库位，则也返回999响应，msg是“无可用的入库库位”）和可以移动的数量的最大值（这里要结合这个用户提交的暂存移库记录），比如
     * 物品A在库位B有20个，用户已经提交了移库记录（移动物品A15个到库位C）到暂存表，这个时候如果用户要继续提交这个物品A的移库记录，则最大的
     * 移动数量为5个。（这是前端限制，后端也要进行验证是否符合要求）
     * step3:合并到主库，合并时检查暂存的各项记录是否是都可以执行的，若出现（1）出库的库位被禁用（2）出库数量超出限制（3）入库库位被禁用的情况，则需要
     * 告知用户，哪里不对如何改正
     *
     */

    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const navigate = useNavigate();
    const {Option} = Select
    const [formOfSubmitAdd] = Form.useForm();
    const [disableOfBianhaoInput,setDisableOfBianhaoInput] = useState(false);
    const [outWarehouseArrayWhenAdd,setOutWarehouseArrayWhenAdd] = useState([]);
    const [inWarehouseArrayWhenAdd,setInWarehouseArrayWhenAdd] = useState([]);

    const [outWarehouseArrayWhenChange,setOutWarehouseArrayWhenChange] = useState([]);
    const [inWarehouseArrayWhenChange,setInWarehouseArrayWhenChange] = useState([]);
    const [visibleOfChangeModal,setVisibleOfChangeModal] = useState(false);
    const [loadingOfSubmitChangeToTemporaryTableButton,setLoadingOfSubmitChangeToTemporaryTableButton] = useState(false);
    const [formOfChangeRecord] = Form.useForm();
    const [disableOfInputWhenChange,setDisableOfInputWhenChange] = useState(true);
    const [loadingOfButtonQueryWarehouseLocationWhenChange,setLoadingOfButtonQueryWarehouseLocationWhenChange] = useState(false);

    const [loadingOfGetOutAndInWarehouseButton,setLoadingOfGetOutAndInWarehouseButton] = useState(false);
    const [loadingOfCheckBianhaoPropertiesButton,setLoadingOfCheckBianhaoPropertiesButton] = useState(false);
    const [visibleOfPropertiesModal,setVisibleOfPropertiesModal] = useState(false);
    const [toBeCheckedPropertiesBianhao,setToBeCheckedPropertiesBianhao] = useState();
    const [loadingOfTable,setLoadingOfTable] = useState(true);
    const [dataSourceOfRecordTable,setDataSourceOfRecordTable] = useState([]);
    const [loadingOfSubmitButton,setLoadingOfSubmitButton] = useState(false);
    const [arrayOfApplicants,setArrayOfApplicants] = useState([]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const [allOptions,setAllOptions] = useState([]);
    const [outOptions,setOutOptions] = useState([]);
    const [changingRecord,setChangingRecord] = useState(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setArrayOfApplicants([...arrayOfApplicants, name || `默认项${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    const getOutAndInWarehouseWhenChange=()=>{
        let bianhao = formOfChangeRecord.getFieldValue("bianhao");
        if (bianhao===undefined||bianhao===null){
            message.error("请先输入编号！");
        }else{
            setLoadingOfButtonQueryWarehouseLocationWhenChange(true);
            post(APIOBJECT["getAbleOutAndInWarehouseLocationAndLegalQuantity"],JSON.stringify({bianhao:bianhao})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    setOutWarehouseArrayWhenChange(resultAfterHandle["data"]["outWarehouseArray"]);
                    setInWarehouseArrayWhenChange(resultAfterHandle["data"]["inWarehouseArray"]);
                    setDisableOfInputWhenChange(true);
                }
                setLoadingOfButtonQueryWarehouseLocationWhenChange(false);
            }).catch(error=>{
                message.error("getOutAndInWarehouse出错了！"+error);
                setLoadingOfButtonQueryWarehouseLocationWhenChange(false);
            })
        }
    }
    const getAllApplicants = ()=>{
        post(APIOBJECT["getAllApplicantsName"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]!=null&&resultAfterHandle["data"]!==undefined){
                    setArrayOfApplicants(resultAfterHandle["data"]);
                }else{
                    message.error("无供应商信息！请先添加供应商！");
                }

            }
        }).catch(error=>message.error("getSupplier出错了！"+error))
    }

    const checkPropertiesOfBianhao = (bianhao)=>{
        if (bianhao !== toBeCheckedPropertiesBianhao){
            //如果已经传进去过一次编号了，这次传的和上次一致，则无需设置button为loading，不同的时候说明要请求API所以设置为loading
            setLoadingOfCheckBianhaoPropertiesButton(true);
        }
        setToBeCheckedPropertiesBianhao(bianhao);
        setVisibleOfPropertiesModal(true);
    }
    const getSubmittedMoveRecord = ()=>{
        setLoadingOfTable(true);
        post(APIOBJECT["getSubmittedMoveRecordInTemporaryTable"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                if (resultAfterHandle["data"]===null||resultAfterHandle["data"]===undefined){
                    message.info("您暂无移库记录！");
                    setDataSourceOfRecordTable([]);
                }else{
                    setDataSourceOfRecordTable(resultAfterHandle["data"]);
                }
            }
            setLoadingOfTable(false);
        }).catch(error=>{
            message.error("getSubmittedMoveRecord出错了！"+error);
            setLoadingOfTable(false);
        })
    }
    const handleClickOnButtonOfChangeRecord = (recordCreatedTime)=>{
        let jsonObject = dataSourceOfRecordTable.filter(item=>item["recordCreatedTime"] === recordCreatedTime)[0];
        setChangingRecord(jsonObject);
        formOfChangeRecord.setFieldsValue({...jsonObject,warehouseInLocation:findOptionPath(allOptions,jsonObject["warehouseInLocation"])});
        getOutAndInWarehouseWhenChange();
        setVisibleOfChangeModal(true);
    }
    useEffect(()=>{
        let tempResult = findOptionPath(outOptions,changingRecord === null ? null : changingRecord["warehouseOutLocation"]);
        if (tempResult.length !== 0){
            formOfChangeRecord.setFieldsValue({warehouseOutLocation:tempResult})
        }
    },[outOptions])

    useEffect(()=>{
        let tempResult = findOptionPath(allOptions,changingRecord === null ? null : changingRecord["warehouseInLocation"]);
        if (tempResult.length !== 0){
            formOfChangeRecord.setFieldsValue({warehouseInLocation:tempResult})
        }
    },[allOptions])

    const combineMoveRecordTemporaryToMain = ()=>
        post(APIOBJECT["combineMoveRecordTemporaryToMain"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                setDataSourceOfRecordTable([]);
                getSubmittedMoveRecord();
            }
        }).catch(error=>{
            message.error("combineMoveRecordTemporaryToMain出错了！"+error);
        })


    const handleSubmitChangeRecordToTemporaryTable = (fieldValues)=>{
        setLoadingOfSubmitChangeToTemporaryTableButton(true);
        fieldValues["warehouseInLocation"] = convertArrayResult(fieldValues["warehouseInLocation"]);
        fieldValues["warehouseOutLocation"] = convertArrayResult(fieldValues["warehouseOutLocation"]);
        post(APIOBJECT["handleChangeMoveRecordSubmittedTemporaryRecord"],JSON.stringify(fieldValues)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                formOfChangeRecord.resetFields();
                setVisibleOfChangeModal(false);
                getSubmittedMoveRecord();
            }
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
        }).catch(error=>{
            message.error("handleSubmitChangeRecordToTemporaryTable出错了！"+error);
            setLoadingOfSubmitChangeToTemporaryTableButton(false);
        })
    }
    const deleteMoveRecordInTemporaryTable = (recordCreatedTime)=>
        post(APIOBJECT["deleteMoveRecordInTemporaryTable"],JSON.stringify({recordCreatedTime:recordCreatedTime})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success(resultAfterHandle["msg"]);
                getSubmittedMoveRecord();
            }
        }).catch(error=>{
            message.error("deleteMoveRecordInTemporaryTable出错了"+error);
        })


    useEffect(() => {
        getSubmittedMoveRecord();
        getAllApplicants();
        return () => {};
    }, []);


    const columns = [
        {
            title: "提交时间",
            width:200,
            ellipsis: true,
            key:"recordCreatedTime",
            dataIndex: "recordCreatedTime",
            ...getColumnSearchProps("recordCreatedTime")
        },
        {
            title:"实体名称",
            width: 300,
            ellipsis:true,
            key:"entityName",
            dataIndex:"entityName",
            ...getColumnSearchProps("entityName")
        },
        {
            title:"具体型号",
            ellipsis:true,
            key:"propertiesString",
            dataIndex:"propertiesString",
            ...getColumnSearchProps("propertiesString")
        },
        {
            title:"实体编号",
            width: 150,
            ellipsis:true,
            key:"bianhao",
            dataIndex:"bianhao",
            ...getColumnSearchProps("bianhao")
        },
        {
            title: "数量",
            key:"quantity",
            width: 150,
            ellipsis:true,
            dataIndex: "quantity",
            render:(text,record,index)=>text+record["unit"]
        },
        {
            title: "申请人",
            key:"applicant",
            width: 100,
            ellipsis:true,
            dataIndex: "applicant",
            ...getColumnSearchProps("applicant")
        },
        {
            title: "出库名称",
            key:"fromLocationName",
            width: 200,
            ellipsis:true,
            dataIndex: "fromLocationName",
            ...getColumnSearchProps("fromLocationName")
        },
        {
            title: "入库名称",
            key:"toLocationName",
            width: 200,
            ellipsis:true,
            dataIndex: "toLocationName",
            ...getColumnSearchProps("toLocationName")
        },
        {
            title: "操作",
            width: 230,
            render:(text,record,index)=>{
                return (<Space>
                    <Button size={"small"} type={"primary"} onClick={()=>handleClickOnButtonOfChangeRecord(record["recordCreatedTime"])}>修改</Button>

                    <Popconfirm title={"确认删除该条暂存记录？"} okText={"删除"}
                                cancelText={"取消"} onConfirm={()=>deleteMoveRecordInTemporaryTable(record["recordCreatedTime"])}>
                        <Button size={"small"} danger>删除</Button>
                    </Popconfirm>

                    <Button size={"small"} type={"dashed"} onClick={()=>checkPropertiesOfBianhao(record["bianhao"])} loading={loadingOfCheckBianhaoPropertiesButton}>实体详情</Button>
                </Space>)
            }
        }
    ]
    const getOutAndInWarehouse = ()=>{
        let bianhao = formOfSubmitAdd.getFieldValue("bianhao");
        if (bianhao===undefined||bianhao===null){
            message.error("请先输入编号！");
        }else{
            setLoadingOfGetOutAndInWarehouseButton(true);
            post(APIOBJECT["getAbleOutAndInWarehouseLocationAndLegalQuantity"],JSON.stringify({bianhao:bianhao})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    setOutWarehouseArrayWhenAdd(resultAfterHandle["data"]["outWarehouseArray"]);
                    setInWarehouseArrayWhenAdd(resultAfterHandle["data"]["inWarehouseArray"]);
                    setDisableOfBianhaoInput(true);
                }
                setLoadingOfGetOutAndInWarehouseButton(false);
            }).catch(error=>{
                message.error("getOutAndInWarehouse出错了！"+error);
                setLoadingOfGetOutAndInWarehouseButton(false);
            })
        }
    }
    const handleSubmitMoveRecordToTemporaryTable = (fieldsValue)=>{
        setLoadingOfSubmitButton(true);
        fieldsValue["warehouseInLocation"] = convertArrayResult(fieldsValue["warehouseInLocation"])
        fieldsValue["warehouseOutLocation"] = convertArrayResult(fieldsValue["warehouseOutLocation"])
        post(APIOBJECT["handleSubmitAddMoveRecordToTemporaryTable"],JSON.stringify(fieldsValue)).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("新增成功！");
                formOfSubmitAdd.resetFields();
                getSubmittedMoveRecord();
            }
            setLoadingOfSubmitButton(false);
        }).catch(error=>{
            message.error("handleSubmitMoveRecordToTemporaryTable出错了！"+error);
            setLoadingOfSubmitButton(false);
        })
    }
    return (
        <Card title={"移库"} extra={<Button type={"primary"} onClick={()=>checkPropertiesOfBianhao(formOfSubmitAdd.getFieldValue("bianhao"))}>查看编号属性及单位</Button>}>
            <Form form={formOfSubmitAdd} onFinish={handleSubmitMoveRecordToTemporaryTable}>
                <Row>
                    <Col span={20}>
                        <Form.Item label={"编号"} name={"bianhao"} rules={[{required:true,message:"请填写出库的实体的编号"}]}>
                            <Input onChange={()=>{setOutWarehouseArrayWhenAdd([]);setInWarehouseArrayWhenAdd([]);}} type={"text"} disabled={disableOfBianhaoInput}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}><Button type={"dashed"} onClick={()=>{
                        setDisableOfBianhaoInput(false);
                        formOfSubmitAdd.resetFields(["warehouseOutLocation","warehouseInLocation"]);
                    }}>修改编号</Button></Col>
                    <Col span={2}>
                        <Tooltip title={"点击查询库位后，若编号为有效编号，则会被锁定。若想修改点击旁边的修改编号按钮即可。"} color={"blue"}>
                            <Button type={"primary"} loading={loadingOfGetOutAndInWarehouseButton} onClick={getOutAndInWarehouse}>查询库位</Button>
                        </Tooltip>
                    </Col>
                </Row>
                <CascaderSelect labelName={"离开的库位"} warehouseItemArray={outWarehouseArrayWhenAdd} itemName={"warehouseOutLocation"}/>
                <CascaderSelect labelName={"进入的库位"} warehouseItemArray={inWarehouseArrayWhenAdd} itemName={"warehouseInLocation"}/>
                <Form.Item label={"出库的数量"} name={"quantity"}
                           rules={[{required:true,message:"请输入数量"},
                               ({getFieldValue})=>({
                                   validator(_,value){
                                       let selectedOutLocationId = getFieldValue(["warehouseOutLocation"]);
                                       if (!judgeIfNum(value)){
                                           return Promise.reject(new Error("数量必须为正数！"));
                                       }
                                       if (selectedOutLocationId!==undefined){
                                           selectedOutLocationId = convertArrayResult(selectedOutLocationId);
                                           let allowedOutQuantity = outWarehouseArrayWhenAdd.filter(item=>item["locationId"] === selectedOutLocationId)[0]["allowOutQuantity"];
                                           if (Number(value)>Number(allowedOutQuantity)){
                                               return Promise.reject(new Error("出库的数量大于库存了！"));
                                           }
                                       }
                                       return Promise.resolve();
                                   }
                               })
                           ]}>
                    <Input type={"number"}/>
                </Form.Item>
                <Form.Item name={"applicant"} label={"移库申请人"} rules={[{required:true,message:"请输入移库申请人！"}]}>
                    <Select placeholder="请选择申请人"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{margin: '8px 0',}}/>
                                <Space style={{padding: '0 8px 4px',}}>
                                    <Input placeholder="请输入"
                                           ref={inputRef}
                                           value={name}
                                           onChange={onNameChange}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                        Add item
                                    </Button>
                                </Space>
                            </>
                        )}
                        options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type={"primary"} htmlType={"submit"} loading={loadingOfSubmitButton}>提交</Button>
                </Form.Item>
            </Form>

            <Modal width={900} title={"属性详情"} visible={visibleOfPropertiesModal} okText={"明白了"} cancelText={"关闭界面"}
                   onCancel={()=>setVisibleOfPropertiesModal(false)} onOk={()=>setVisibleOfPropertiesModal(false)}>
                <QueryEntityDetailsTable bianhao={toBeCheckedPropertiesBianhao} setLoading={setLoadingOfCheckBianhaoPropertiesButton}/>
            </Modal>

            <Modal width={1000} title={"修改暂存的移库记录"} visible={visibleOfChangeModal} okText={"修改"} cancelText={"取消"}
                   onCancel={()=>{setVisibleOfChangeModal(false);}}
                   footer={[<Button key={"cancelButton"} danger onClick={()=>setVisibleOfChangeModal(false)} loading={loadingOfSubmitChangeToTemporaryTableButton}>取消</Button>,
                       <Button key={"submitButton"} type={"primary"} onClick={()=>formOfChangeRecord.submit()} loading={loadingOfSubmitChangeToTemporaryTableButton}>提交申请</Button>]}>
                <Form form={formOfChangeRecord} onFinish={handleSubmitChangeRecordToTemporaryTable}>
                    <Form.Item label={"提交时间"} name={"recordCreatedTime"} rules={[{required:true,message:"提交时间不能为空！"}]}>
                        <Input type={"text"} disabled/>
                    </Form.Item>
                    <Row>
                        <Col span={20}>
                            <Form.Item label={"编号"} name={"bianhao"} rules={[{required:true,message:"请填写出库的实体的编号"}]}>
                                <Input onChange={()=>{
                                    setInWarehouseArrayWhenChange([]);
                                    setOutWarehouseArrayWhenChange([]);
                                    formOfChangeRecord.resetFields(["warehouseOutLocation","warehouseInLocation"]);
                                }} type={"text"} disabled={disableOfInputWhenChange}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}><Button type={"dashed"} onClick={()=>{
                            setDisableOfInputWhenChange(false);formOfChangeRecord.resetFields(["warehouseLocation"]);
                        }}>修改编号</Button></Col>
                        <Col span={2}>
                            <Tooltip title={"点击查询库位后，若编号为有效编号，则会被锁定。若想修改点击旁边的修改编号按钮即可。"} color={"blue"}>
                                <Button type={"primary"} loading={loadingOfButtonQueryWarehouseLocationWhenChange} onClick={getOutAndInWarehouseWhenChange}>查询库位</Button>
                            </Tooltip>
                        </Col>
                    </Row>

                    <Form.Item label={"出库的数量"} name={"quantity"}
                               rules={[
                                   {required:true,message:"请输入数量"},
                                   ({getFieldValue})=>({
                                       validator(_,value){
                                           let selectedLocationId = getFieldValue(["warehouseOutLocation"]);
                                           if (!judgeIfNum(value)){
                                               return Promise.reject(new Error("数量必须为正数！"));
                                           }
                                           if (selectedLocationId!==undefined){
                                               selectedLocationId = convertArrayResult(selectedLocationId);
                                               //判断是否超过了可出库的数量
                                               let allowedOutQuantity = outWarehouseArrayWhenChange.filter(item=>item["locationId"] === selectedLocationId)[0]["allowOutQuantity"];
                                               if (Number(value)>Number(allowedOutQuantity)){
                                                   return Promise.reject(new Error("出库的数量大于库存了！"));
                                               }
                                           }
                                           return Promise.resolve();
                                       }
                                   })
                               ]}>
                        <Input type={"number"}/>
                    </Form.Item>
                    <Form.Item label={"申请人"} name={"applicant"} rules={[{required:true,message:"请输入申请人！"}]}>
                        <Select placeholder="请选择申请人"
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: '8px 0',}}/>
                                        <Space style={{padding: '0 8px 4px',}}>
                                            <Input placeholder="请输入"
                                                   ref={inputRef}
                                                   value={name}
                                                   onChange={onNameChange}
                                            />
                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Add item
                                            </Button>
                                        </Space>
                                    </>
                                )}
                                options={arrayOfApplicants.map((item) => ({label: item, value: item,}))}
                        />
                    </Form.Item>
                    <CascaderSelect labelName={"离开的库位"} warehouseItemArray={outWarehouseArrayWhenChange} itemName={"warehouseOutLocation"} setOptionsForProp={setOutOptions}/>
                    <CascaderSelect labelName={"进入的库位"} warehouseItemArray={inWarehouseArrayWhenChange} itemName={"warehouseInLocation"} setOptionsForProp={setAllOptions}/>
                </Form>
            </Modal>

            <Card title={"暂存表"} extra={
                <Popconfirm title={"确认合并吗？合并操作无法撤销！"} onConfirm={combineMoveRecordTemporaryToMain} okText={"合并"} cancelText={"取消"}>
                    <Button type={"default"}>合并到主库</Button>
                </Popconfirm>}>
                <Table scroll={{x: 1300}} loading={loadingOfTable} bordered columns={columns} dataSource={dataSourceOfRecordTable} rowKey={record => record["recordCreatedTime"]}/>
            </Card>
        </Card>
    );
}

export default MoveWarehouse;