import React, {useEffect,  useState} from 'react';
import {Cascader, Form} from "antd";
import {getCascaderOptionArrayForWarehouse} from "../../../../utlis/UniversalFunctions";

function Index(props) {
    let {labelName, warehouseItemArray, itemName,setOptionsForProp} = props
    const [options, setOptions] = useState([]);
    useEffect(() => {
        let optionTemp = getCascaderOptionArrayForWarehouse(warehouseItemArray);
        setOptions(optionTemp);
        if(optionTemp && optionTemp.length > 0 && setOptionsForProp !== undefined){
            setOptionsForProp(optionTemp);
        }
    }, [warehouseItemArray]);
    const filter = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    return (
        <Form.Item label={labelName} name={itemName} rules={[{required:true,message:"请选择库位！"}]}>
            <Cascader
                options={options}
                placeholder="请选择"
                showSearch={{filter,matchInputWidth:false}}
                showArrow={false}
            />
        </Form.Item>
    )
}
export default Index