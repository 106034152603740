import axios from "axios";
import {getToken} from "./auth";
import {APIOBJECT, constantBaseURL} from "../static/CONSTANTS";
import {useNavigate} from "react-router-dom";
// axios.defaults.baseURL = "http://localhost:3000"

const instance = axios.create({
    baseURL:"https://api.wealthcd.com:5678",
    // baseURL:"http://localhost:5678",
    timeout:1200000//120秒未反应就是超时
});

instance.interceptors.request.use(function (config){
    config["headers"]["Content-Type"] = "application/json;charset=UTF-8";
    if (APIOBJECT["loginUrl"]!==config["url"]){//说明是登录，那就无需
        config["headers"]['token'] = getToken()
    }
    return config;
},function (error){
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response){
    return response.data;
},function (error){
    console.log("response接收到的errror：");
    return Promise.reject(error);
})



export function get(url,params){
    return instance.get(url,{
        params
    });
}

export function post(url,data){
    return instance.post(url,data);
}

export function put(url,data){
    return instance.put(url,data);
}

export function del(url){
    return instance.delete(url);
}