import React, {useEffect, useRef, useState} from 'react';
import "../../../static/css/datePicker.css"
import {
    Button, Card, Collapse, Empty, Form, Input, message, Modal,
    Popconfirm, Select, Space, Spin, Table, Tooltip, Typography, Cascader, Divider, DatePicker
} from "antd";
import {useNavigate, useOutlet} from "react-router-dom";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {post} from "../../../utlis/request";
import {handleResult} from "../../../utlis/response";
import QRCode from 'qrcode.react';
import {deepCopyObj} from "../../../utlis/StringUtils";
import QueryEntityDetailsTable from "../../../components/Query/QueryEntityDetailsTable";
import QueryEntityByDetail from "../../../components/Query/QueryEntityByDetail";
import {
    getUnitOfBianhao,
    handleResponseResultOfQueryEntityDetailsTable,
    judgeIfNum
} from "../../../utlis/UniversalFunctions";
import ImageOfEntity from "../../../components/Query/ImageOfEntity";
import WarehouseLocationTable from "../../../components/Query/WarehouseLocationTable";
import {CalendarOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from "moment";
import RequisitionQuery from "../../../components/Query/RequisitionQuery";
const {Option} = Select;

function RequisitionEdit(props) {


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */

    const { RangePicker } = DatePicker;
    /**用于构建时间范围选择的固定部分
     */
    const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const getColumnSearchProps2 = (dataIndex) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{padding: 4}}>
                    <RangePicker style={{ marginBottom: 8 }}
                                 value={selectedKeys[0]}
                                 onChange={e => {
                                     setSelectedKeys(e ? [e] : [])
                                 }}
                                 onPressEnter={() => {
                                     confirm();
                                 }}
                                 locale={locale}
                    />
                    <Space>
                        <Button type="primary" onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                            搜索
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small" style={{width: 90,}}>
                            重置
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (<CalendarOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
            ),
            onFilter: (value, record) => record[dataIndex] ? moment(record[dataIndex]).isBetween(value[0], value[1], 'day', '[]') : "" ,
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
            },
            render: text => moment(text).format("YYYY/MM/DD HH:mm:ss")}
    );

    /**用于构建时间范围选择的固定部分
     */

    const [formOfAddRequisitionDetail] = Form.useForm();
    const { Panel } = Collapse;
    const [disableOfBianhaoInput,setDisableOfBianhaoInput] = useState(true);
    const [projectArrayOfManager,setProjectArrayOfManager] = useState([]);
    const [unitOfBianhao,setUnitOfBianhao] = useState("");
    const navigate = useNavigate();
    const [freshOfRequisitionQuery,setFreshOfRequisitionQuery] = useState(true);



    useEffect(() => {
        getProjectsByManager();
        return () => {
        };
    }, []);



    const getProjectsByManager = () => {
        post(APIOBJECT["getProjectByManager"]).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false) {
                let arrayOfProject = resultAfterHandle["data"];
                if(arrayOfProject !== null && arrayOfProject !== undefined){
                    if(arrayOfProject.length === 0){
                        message.error("您当前没有分配到项目，请先分配到项目后再进行要货单申请！");
                    }else {
                        setProjectArrayOfManager(arrayOfProject);
                    }
                }
            }
        }).catch(error=>message.error("出错啦！"+error))
    }

    const handleSubmitAddRequisitionDetail = (values) =>{
        post(APIOBJECT["addRequisitionDetail"],JSON.stringify({...values})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if(resultAfterHandle!==false){
                message.success("添加成功！");
                formOfAddRequisitionDetail.resetFields();
                setFreshOfRequisitionQuery(!freshOfRequisitionQuery);
            }
        }).catch(e=>message.error("handleSubmitAddRequisitionDetail出错"+e))

    }



    const callBackFuncForAdding = (dataObj)=>{
        if(dataObj){
            let {unit,number_of_properties} = dataObj;
            setUnitOfBianhao(unit);
            formOfAddRequisitionDetail.setFieldsValue({bianhao:number_of_properties})
        }
    }

    return (
        <Space direction="vertical" style={{"width":"100%"}}>
            <Collapse defaultActiveKey={['1','2']} >
                <Panel header="添加新要货单" key="1">
                    <QueryEntityByDetail setFunc={callBackFuncForAdding}/>
                    <Card title={"添加要货单，首先添加其第一项要货细节"} style={{"width":"100%"}}>
                        <Form form={formOfAddRequisitionDetail} onFinish={handleSubmitAddRequisitionDetail}>
                            <div style={{display:"flex",justifyContent: "space-between"}}>
                                <Form.Item label={"用于的项目"} name={"projectId"} rules={[{required:true,message:"请选择项目"}]} style={{width:"50%"}}>
                                    <Select showSearch allowClear={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                        {projectArrayOfManager.map((option)=><Option key={option["projectId"]} value={option["projectId"]}>{"【"+option["projectId"]+"】"+option["projectName"]}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{width:"15%"}} label={"数量（"+unitOfBianhao+")"} name={"quantity"} rules={[
                                    {required:true,message:"请输入数量"},
                                    ({getFieldValue})=>({
                                        validator(_,value){
                                            if (!judgeIfNum(value)){
                                                return Promise.reject(new Error("数量不正确！"));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}>
                                    <Input type={"number"} step={"any"}/>
                                </Form.Item>
                                <Form.Item style={{width:"20%"}} label={"交货周期（天）"} name={"required_arrival_date"}
                                           rules={[
                                                {required:true,message:"请输入交货周期"},
                                                ({getFieldValue})=>({
                                                    validator(_,value){
                                                        if (!judgeIfNum(value)){
                                                            return Promise.reject(new Error("数量不正确！"));
                                                        }else{
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                })
                                            ]}>
                                    <Input type={"number"}/>
                                </Form.Item>
                            </div>

                            <div style={{display:"flex",justifyContent: "space-between"}}>
                                <Form.Item label={"编号"} name={"bianhao"} rules={[{required: true, message: '请输入编号!',}]} style={{width:"70%"}}>
                                    <Input type={"text"} disabled={disableOfBianhaoInput} onBlur={async (e)=>{
                                        getUnitOfBianhao(e["target"]["value"],message,navigate).then(resultAfterHandle=>{
                                            if(resultAfterHandle!==false){
                                                setUnitOfBianhao(resultAfterHandle["data"]["unit"]);
                                            }else{
                                                setUnitOfBianhao("");
                                            }
                                        }).catch(error=>console.log("getUnitOfBianhao出错了"+error))
                                    }}/>
                                </Form.Item>
                                <Button type={"default"} onClick={()=>setDisableOfBianhaoInput(false)}>手动输入</Button>
                            </div>
                            <Button type={"primary"} onClick={()=>formOfAddRequisitionDetail.submit()}>提交</Button>
                        </Form>
                    </Card>
                </Panel>
                <Panel key={"2"} header={"查询编辑已有的要货单"}>
                    <RequisitionQuery from={"manager"} canSubmitToExamineAndVerify={"true"} fresh={freshOfRequisitionQuery} canChangeRequisitionDetail={true}/>
                </Panel>
            </Collapse>
        </Space>

    );
}

export default RequisitionEdit;