import React, {useEffect, useRef, useState} from 'react';
import Input from "antd/es/input/Input";
import {Button, message, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {post} from "../../utlis/request";
import {APIOBJECT} from "../../static/CONSTANTS";
import {handleResult} from "../../utlis/response";
import {useNavigate} from "react-router-dom";

function WarehouseLocationTable(props) {

    const {bianhao,setLoading,dataSourceFromOuter} = props;
    const [dataSourceOfLocation,setDataSourceOfLocation] = useState([]);
    const navigate = useNavigate();

    const queryWarehouseLocationByBianhao=()=>{
        console.log("进来了queryWarehouseLocationByBianhao");
        console.log(dataSourceFromOuter);

        if(bianhao !== undefined){
            post(APIOBJECT["queryWarehouseLocationByBianhao"],
                JSON.stringify({bianhao:bianhao})).then(resultBeforeHandle=>{
                let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                if (resultAfterHandle!==false){
                    setDataSourceOfLocation(resultAfterHandle["data"]);
                }
            }).catch(error=>{
                message.error("getWarehouseDetails出错啦出错了！"+error)
            }).finally(()=>{
                if (setLoading !==undefined&&setLoading!==null){
                    setLoading(false);
                }
            })
        }else{
            setDataSourceOfLocation(dataSourceFromOuter);
        }
    }

    useEffect(() => {
        queryWarehouseLocationByBianhao();
        return () => {};
    }, [bianhao,dataSourceFromOuter]);


    /**
     * 用于构建可搜索table的固定部分
     */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{padding: 4}}>
                <Input ref={searchInput}
                       placeholder={`搜索 ${dataIndex}`} value={selectedKeys[0]}
                       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                       style={{
                           marginBottom: 8,
                           display: 'block',
                       }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />} size="small" style={{width: 90,}}>
                        搜索
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small" style={{width: 90,}}>
                        重置
                    </Button>
                    <Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});
                        setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex);}}>
                        显示高亮
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (<SearchOutlined style={{color: filtered ? '#1890ff' : undefined,}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {setTimeout(() => searchInput.current?.select(), 100);}
        },
        render: (text) => searchedColumn === dataIndex ? (<Highlighter highlightStyle={{backgroundColor: '#ffc069', padding: 0,}}
                                                                       searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''}/>) : (text),});
    /**
     * 用于构建可搜索table的固定部分
     */
    const columns = [
        {
            title:"库位id",
            key:"locationId",
            dataIndex:"locationId",
            ...getColumnSearchProps("locationId")
        },
        {
            title: "库位名称",
            key:"locationName",
            dataIndex: "locationName",
            ...getColumnSearchProps("locationName")
        },
        {
          title: "库存数量",
          key:"quantity",
          dataIndex: "quantity",
          ...getColumnSearchProps("quantity")
        },
        {
            title: "单位",
            key:"unit",
            dataIndex: "unit",
        },
        {
            title: "描述",
            key:"description",
            dataIndex: "description",
            ...getColumnSearchProps("description")
        }
    ]



    return (
        <Table columns={columns} dataSource={dataSourceOfLocation==null?[]:dataSourceOfLocation} rowKey={record => record["locationId"]}/>
    );
}

export default WarehouseLocationTable;