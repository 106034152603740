import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Collapse, Form, Input, message, Modal, Select, Typography} from "antd";
import {post} from "../../../utlis/request";
import {APIOBJECT} from "../../../static/CONSTANTS";
import {handleResult} from "../../../utlis/response";

function EditCategory(props) {
    const navigate = useNavigate();
    const { Panel } = Collapse;
    const {Text} = Typography;
    const [categoryArrayForSelect,setCategoryArrayForSelect] = useState([]);
    const [optionOfEntitySelect,setOptionOfEntitySelect] = useState([]);
    const [theRestCategory,setTheRestCategory] = useState([]);
    const [formOfAddPropertyConstraint] = Form.useForm();
    const [formOfChangeNameOfCategory] = Form.useForm();
    const {Option} = Select;


    useEffect(() => {

        post(APIOBJECT["getAllCategory"],null).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                setCategoryArrayForSelect(resultAfterHandle["data"]);
            }else{
                message.error("当前大类为空，请先添加大类！");
            }
        }).catch(error=>message.error("出现错误！"+error))


        return () => {
        };
    }, []);

    const loadEntitiesByCategorySelect = (categoryId)=>{
        setTheRestCategory(categoryArrayForSelect.filter(item=>item["categoryId"]!==categoryId));
        post(APIOBJECT["getEntitiesByCategoryId"],JSON.stringify({"categoryId":categoryId})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                let options_array = [];
                let result = resultAfterHandle["data"];
                for (let index in result){
                    options_array.push({
                        label:result[index]["chinese_name"],
                        value:result[index]["id_of_entity"]
                    })
                }
                setOptionOfEntitySelect(options_array);
            }
        }).catch(error=>message.error("出错了！"+error))
    }

    const handleSubmitChangeCategoryOfEntity = ()=>{
        let formValues = formOfAddPropertyConstraint.getFieldValue();
        if (formValues["categoryId"]===undefined||formValues["id_of_entity"]===undefined||formValues["toCategoryId"]===undefined){
            message.error("有选项未选择！");
        }else{
            Modal.confirm({
                title:"确认提交申请？",
                okText:"确认",
                cancelText:"取消",
                onOk:()=>{
                    let data = JSON.stringify({categoryId:formValues["categoryId"],
                        id_of_entity:formValues["id_of_entity"],toCategoryId:formValues["toCategoryId"]});
                    post(APIOBJECT["changeCategoryOfEntity"],data).then(resultBeforeHandle=>{
                        let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
                        if (resultAfterHandle!==false){
                            message.success("修改大类成功！");
                            setTimeout(()=>{
                                window.location.href = window.location.href;
                            },600)

                        }
                    }).catch(error=>message.error("出错了！"+error))
                }
            })
        }
    }

    const postToChangeNameOfCategory = (categoryId,newCategoryName)=>{
        post(APIOBJECT["changeCategoryName"],JSON.stringify({categoryId:categoryId,newCategoryName:newCategoryName})).then(resultBeforeHandle=>{
            let resultAfterHandle = handleResult(resultBeforeHandle,message,navigate);
            if (resultAfterHandle!==false){
                message.success("修改成功！");
                setTimeout(()=>{
                    window.location.href = window.location.href;
                },700)
            }
        }).catch(error=>console.log("出错了！"+error))
    }


    const handleSubmitChangeNameOfCategory=(formValue)=>{
        console.log(formValue);
        //判断新名字是否与旧名字重复
        let sameNameCategory = categoryArrayForSelect.filter(item=>item["categoryName"] === formValue["newCategoryName"]);
        if (sameNameCategory.length!==0){
            //有重复的
            message.error("新名字与已有的大类重复！请重新输入新的大类名字！");

        }else{
            //是否有类名类似的大类，判断依据是是否包含要修改成的新类名
            let likeNameCategory = categoryArrayForSelect.filter(item=>item["categoryName"].indexOf(formValue["newCategoryName"])!==-1);
            if (likeNameCategory.length!==0){
                Modal.confirm({
                    title:"现有类中有类名与您要修改的新类名类似，请确认是否修改？",
                    okText:"确认修改",
                    cancelText:"取消修改",
                    content:"主要与这些类的类名类似："+likeNameCategory.map(item=>item["categoryName"]+","),
                    onOk:()=>{
                        postToChangeNameOfCategory(formValue["beChangedCategoryId"],formValue["newCategoryName"]);
                    }
                })
            }else{
                //没有重名和类似的问题，直接提交post请求
                postToChangeNameOfCategory(formValue["beChangedCategoryId"],formValue["newCategoryName"]);
            }
        }

    }

    return (
        <Collapse defaultActiveKey={1}>
            <Panel key={1} header={"修改实体所属的大类"} extra={<Button onClick={()=>handleSubmitChangeCategoryOfEntity()}>提交申请</Button>}>
                <Form form={formOfAddPropertyConstraint}>
                    <Form.Item label={"第一步：选择大类"} name={"categoryId"} style={{"width":"100%"}} rules={[{required: true, message: '请选择大类!',}]}>
                        <Select showSearch placeholder="请选择一个大类" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                onSelect={(value)=>loadEntitiesByCategorySelect(value)} style={{"width":"100%"}}
                        >
                            {categoryArrayForSelect!==null?categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> ):""}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"第二步：选择实体"} name={"id_of_entity"} style={{"width":"100%"}} rules={[{required: true, message: '请选择实体!',}]}>
                        <Select showSearch placeholder="请选择实体" optionFilterProp="children" options={optionOfEntitySelect} onSelect={(value)=>console.log(categoryArrayForSelect)}
                                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}/>
                    </Form.Item>
                    <Form.Item label={"第三步：选择要改变成的大类"} name={"toCategoryId"} rules={[{required:true,message:"请选择要改变成的大类"}]}>
                        <Select showSearch placeholder="请选择一个要改变成的大类" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                onSelect={(value)=>console.log(value)} style={{"width":"100%"}}
                        >
                            {theRestCategory.length!==0?theRestCategory.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> ):""}
                        </Select>
                    </Form.Item>
                </Form>
            </Panel>
            <Panel key={2} header={"修改大类的名称"}>
                <Form form={formOfChangeNameOfCategory} onFinish={handleSubmitChangeNameOfCategory}>
                    <Form.Item label={"要改名的大类"} name={"beChangedCategoryId"} rules={[{required:true,message:"请选择要改名的大类！"}]}>
                        <Select showSearch placeholder="请选择要改名的大类" optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} style={{"width":"100%"}}>
                            {categoryArrayForSelect!==null?categoryArrayForSelect.map(item=><Option key={item["categoryId"]} value={item["categoryId"]}>{item["categoryName"]}</Option> ):""}
                        </Select>
                    </Form.Item>
                    <Form.Item label={"大类的新名字："} name={"newCategoryName"} rules={[{required:true,message:"请输入新大类的名字"}]}>
                        <Input type={"text"} />
                    </Form.Item>
                    <Form.Item>
                        <Button type={"primary"} htmlType={"submit"}>提交申请</Button>
                    </Form.Item>
                </Form>
            </Panel>
        </Collapse>
    );
}

export default EditCategory;