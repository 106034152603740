import {post} from "../utlis/request";
import {removeLogin_time, removeToken} from "../utlis/auth";
import {useNavigate} from "react-router-dom";
import {APIOBJECT} from "../static/CONSTANTS";

export function loginApi(user){
    return post(APIOBJECT["loginUrl"], user)
}

export function verifyToken(){
    return post(APIOBJECT["verifyTokenUrl"], null);
}

export function Logout(){
    return post(APIOBJECT["logoutUrl"],null);
}

